export const myInfoNationalities = [
  'AFGHAN',
  'ALBANIAN',
  'ALGERIAN',
  'AMERICAN SAMOAN',
  'AMERICAN',
  'ANDORRAN',
  'ANGOLAN',
  'ANGUILLAN',
  'ANTARCTICA',
  'ANTIGUAN',
  'ARGENTINIAN',
  'ARMENIAN',
  'AUSTRALIAN',
  'AUSTRIAN',
  'AZERBAIJANI',
  'BAHAMIAN',
  'BAHRAINI',
  'BANGLADESHI',
  'BARBADOS',
  'BELARUSSIAN',
  'BELGIAN',
  'BELIZEAN',
  'BENINESE',
  'BHUTANESE',
  'BOLIVIAN',
  'BOSNIAN',
  'BOTSWANA',
  'BRAZILIAN',
  'BRITISH NATIONAL OVERSEAS',
  'BRITISH OVERSEAS CITIZEN',
  'BRITISH OVERSEAS TERRITORIES CITIZEN',
  'BRITISH PROTECTED PERSON',
  'BRITISH SUBJECT',
  'BRITISH',
  'BRUNEIAN',
  'BULGARIAN',
  'BURKINABE',
  'BURUNDIAN',
  'CAMBODIAN',
  'CAMEROONIAN',
  'CANADIAN',
  'CAPE VERDEAN',
  'CENTRAL AFRICAN REPUBLIC',
  'CHADIAN',
  'CHILEAN',
  'CHINESE',
  'CHINESE/HONGKONG SAR',
  'CHINESE/MACAO SAR',
  'COLOMBIAN',
  'COMORAN',
  'CONGOLESE',
  'COOK ISLANDER',
  'COSTA RICAN',
  'CROATIAN',
  'CUBAN',
  'CYPRIOT',
  'CZECH',
  'CZECHOSLOVAK',
  'D.P.R. KOREAN',
  'DANISH',
  'DEMOCRATIC REPUBLIC OF THE CONGO',
  'DJIBOUTIAN',
  'DOMINICAN (REPUBLIC)',
  'DOMINICAN',
  'DUTCH',
  'EAST TIMORESE',
  'ECUADORIAN',
  'EGYPTIAN',
  'EQUATORIAL GUINEA',
  'ERITREAN',
  'ESTONIAN',
  'ETHIOPIAN',
  'FIJIAN',
  'FILIPINO',
  'FINNISH',
  'FRENCE SOUTHERN TERRITORIES',
  'FRENCH GUIANESE',
  'FRENCH POLYNESIAN',
  'FRENCH',
  'GABON',
  'GAMBIAN',
  'GEORGIAN',
  'GERMAN',
  'GERMAN, EAST',
  'GERMAN, WEST',
  'GHANAIAN',
  'GREEK',
  'GRENADIAN',
  'GUADELOUPIAN',
  'GUAMANIAN',
  'GUATEMALAN',
  'GUINEAN (BISSAU)',
  'GUINEAN',
  'GUYANESE',
  'HAITIAN',
  'HONDURAN',
  'HUNGARIAN',
  'ICELANDER',
  'INDIAN',
  'INDONESIAN',
  'IRANIAN',
  'IRAQI',
  'IRISH',
  'ISRAELI',
  'ITALIAN',
  'IVORY COAST',
  'JAMAICAN',
  'JAPANESE',
  'JORDANIAN',
  'KAMPUCHEAN',
  'KAZAKHSTANI',
  'KENYAN',
  'KIRGHIZ',
  'KIRIBATI',
  'KITTIAN & NEVISIAN',
  'KOREAN, SOUTH',
  'KOSOVAR',
  'KUWAITI',
  'KYRGHIS',
  'KYRGYZSTAN',
  'LAOTIAN',
  'LATVIAN',
  'LEBANESE',
  'LESOTHO',
  'LIBERIAN',
  'LIBYAN',
  'LIECHTENSTEINER',
  'LITHUANIAN',
  'LUXEMBOURGER',
  'MACEDONIAN',
  'MADAGASY',
  'MALAWIAN',
  'MALAYSIAN',
  'MALDIVIAN',
  'MALIAN',
  'MALTESE',
  'MARSHALLESE',
  'MARTINIQUAIS',
  'MAURITANEAN',
  'MAURITIAN',
  'MEXICAN',
  'MICRONESIAN',
  'MOLDAVIAN',
  'MONACAN',
  'MONGOLIAN',
  'MONTENEGRIN',
  'MONTENEGRIN',
  'MOROCCAN',
  'MOZAMBICAN',
  'MYANMAR',
  'NAMIBIAN',
  'NAURUAN',
  'NEPALESE',
  'NETHERLANDS ANTILILLES',
  'NETHERLANDS',
  'NEW CALEDONIA',
  'NEW ZEALANDER',
  'NI-VANUATU',
  'NICARAGUAN',
  'NIGER',
  'NIGERIAN',
  'NIUEAN',
  'NORWEGIAN',
  'OMANI',
  'OTHERS',
  'PACIFIC ISLAND TRUST TERRITORY',
  'PAKISTANI',
  'PALAUAN',
  'PALESTINIAN',
  'PANAMANIAN',
  'PAPUA NEW GUINEAN',
  'PARAGUAYAN',
  'PERUVIAN',
  'PITCAIRN',
  'POLISH',
  'PORTUGUESE',
  'PUERTO RICAN',
  'QATARI',
  'REFUGEE (OTHER THAN XXB)',
  'REFUGEE (XXB)',
  'REUNIONESE',
  'ROMANIAN',
  'RUSSIAN',
  'RUSSIAN',
  'RWANDAN',
  'SAHRAWI',
  'SALVADORAN',
  'SAMMARINESE',
  'SAMOAN',
  'SAO TOMEAN',
  'SAUDI ARABIAN',
  'SENEGALESE',
  'SERBIAN',
  'SEYCHELLOIS',
  'SIERRA LEONE',
  'SINGAPORE CITIZEN',
  'SLOVAK',
  'SLOVENIAN',
  'SOLOMON ISLANDER',
  'SOMALI',
  'SOUTH AFRICAN',
  'SOUTH SUDANESE',
  'SPANISH',
  'SRI LANKAN',
  'ST. LUCIA',
  'ST. VINCENTIAN',
  'STATELESS',
  'SUDANESE',
  'SURINAMER',
  'SWAZI',
  'SWEDISH',
  'SWISS',
  'SYRIAN',
  'TADZHIK',
  'TAIWANESE',
  'TAJIKISTANI',
  'TANZANIAN',
  'THAI',
  'TIMORENSE',
  'TOGOLESE',
  'TOKELAUAN',
  'TONGAN',
  'TRINIDADIAN & TOBAGONIAN',
  'TUNISIAN',
  'TURK',
  'TURKMEN',
  'TUVALU',
  'UGANDAN',
  'UKRAINIAN',
  'UNITED ARAB EMIRATES',
  'UNKNOWN',
  'UPPER VOLTA',
  'URUGUAYAN',
  'UZBEKISTAN',
  'VATICAN CITY STATE (HOLY SEE)',
  'VENEZUELAN',
  'VIETNAMESE',
  'WALLIS AND FUTUNA ISLANDERS',
  'YEMEN ARAB REP',
  'YEMEN, SOUTH',
  'YEMENI',
  'YUGOSLAVIANS',
  'ZAIRAN',
  'ZAMBIAN',
  'ZIMBABWEAN',
]
