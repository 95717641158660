export const myInfoOccupations = [
  'Not Applicable',
  '2nd Engineer (Special Limit)',
  '2nd/3rd/4th Mate',
  '3d Modeller',
  '3rd/4th/5th Engineer',
  'ABLE SEAMAN',
  'ABRASIVE-COATED CLOTH AND PAPER MAKER',
  'ACADEMIC AFFAIRS DIRECTOR',
  'ACADEMIC ASSISTANT',
  'ACADEMIC CONSULTANT',
  'ACADEMIC COORDINATOR',
  'ACADEMIC DIRECTOR',
  'ACADEMIC INSTRUCTOR',
  'ACADEMIC STAFF',
  'ACCESS NETWORK MANAGER',
  'ACCOUNT ASSISTANT',
  'ACCOUNT CLERK',
  'ACCOUNT DELIVERY MANAGER',
  'ACCOUNT DEVELOPMENT EXECUTIVE',
  'ACCOUNT DEVELOPMENT MANAGER',
  'ACCOUNT DIRECTOR',
  'ACCOUNT EXECUTIVE',
  'ACCOUNT EXECUTIVE, ADVERTISING',
  'ACCOUNT GENERAL MANAGER',
  'ACCOUNT MAINTENANCE INSPECTOR',
  'ACCOUNT MANAGEMENT EXECUTIVE',
  'ACCOUNT MANAGER',
  'ACCOUNT RELATIONSHIP MANAGER',
  'ACCOUNT SALES MANAGER',
  'ACCOUNT SPECIALIST',
  'ACCOUNT SUPPORT EXECUTIVE',
  'ACCOUNT TECHNOLOGIST',
  'Accountant',
  'ACCOUNTANT (EXCLUDING TAX ACCOUNTANT)',
  'ACCOUNTANT (GENERAL)',
  'ACCOUNTANT, ASSISTANT',
  'ACCOUNTANT, AUDIT',
  'ACCOUNTANT, CHARTERED',
  'ACCOUNTANT, COMPANY',
  'ACCOUNTANT, COST',
  'ACCOUNTANT, COST AND WORKS',
  'ACCOUNTING ANALYST',
  'ACCOUNTING ASSISTANT',
  'Accounting Associate Professional (Eg Assistant Accountant, Audit (Accounting) Executive)',
  'ACCOUNTING CLERK',
  'ACCOUNTING CONSULTANT',
  'ACCOUNTING DIRECTOR',
  'ACCOUNTING EXECUTIVE',
  'ACCOUNTING MANAGER',
  'ACCOUNTING MANAGER (EXCLUDING TAX)',
  'ACCOUNTING MANAGER (FINANCE DEPARTMENT)',
  'ACCOUNTING OFFICER',
  'ACCOUNTING PROFESSIONAL',
  'ACCOUNTING PROGRAMMER',
  'ACCOUNTING SECTION MANAGER',
  'ACCOUNTING SOFTWARE MANAGER',
  'ACCOUNTING SPECIALIST',
  'ACCOUNTS ADMINISTRATIVE ASSISTANT MANAGER',
  'ACCOUNTS ADMINISTRATIVE MANAGER',
  'ACCOUNTS ADMINISTRATOR',
  'ACCOUNTS ANALYST',
  'ACCOUNTS ASSISTANT',
  'ACCOUNTS ASSOCIATE',
  'ACCOUNTS CLERK',
  'ACCOUNTS CONSULTANT',
  'ACCOUNTS COORDINATOR',
  'ACCOUNTS DATA EXECUTIVE',
  'ACCOUNTS EXECUTIVE',
  'ACCOUNTS MANAGER',
  'ACCOUNTS OFFICER',
  'ACCOUNTS PAYABLE (OR RECEIVABLE) BOOKKEEPER',
  'ACCOUNTS PAYABLE TEAM LEADER',
  'ACCOUNTS SECTION SUPERVISOR',
  'ACCOUNTS SPECIALIST',
  'ACCOUNTS SUPERVISOR',
  'ACCOUNTS SUPPORT SPECIALIST',
  'ACCOUNTS TRADE EXECUTIVE',
  'ACMV ENGINEER',
  'ACOUSTICS ENGINEER',
  'ACQUISITION MANAGER',
  'ACTOR',
  'ACTOR, DRAMATIC',
  'ACTUARIAL ANALYST',
  'ACTUARIAL ASSOCIATE',
  'ACTUARIAL CONSULTANT',
  'ACTUARIAL EXECUTIVE',
  'ACTUARIAL MANAGER',
  'ACTUARY',
  'ADJUNCT ASSISTANT PROFESSOR',
  'ADJUNCT ASSOCIATE PROFESSOR',
  'ADJUNCT FACULTY',
  'ADJUNCT LECTURER',
  'ADJUNCT PRINCIPAL SCIENTIST',
  'ADJUNCT PROFESSOR',
  'ADJUNCT SENIOR LECTURER',
  'ADJUSTER, CLAIMS',
  'ADJUSTER, INSURANCE',
  'ADJUSTMENT CLERK (INSURANCE)',
  'ADMINISTRATION CLERK',
  'ADMINISTRATION DIRECTOR',
  'ADMINISTRATION MANAGER',
  'ADMINISTRATIVE ACCOUNTS ASSISTANT',
  'ADMINISTRATIVE ACCOUNTS EXECUTIVE',
  'ADMINISTRATIVE ACCOUNTS MANAGER',
  'ADMINISTRATIVE ACCOUNTS OFFICER',
  'ADMINISTRATIVE ASSISTANT',
  'ADMINISTRATIVE ASSISTANT MANAGER',
  'ADMINISTRATIVE CHEF',
  'ADMINISTRATIVE CLERK',
  'ADMINISTRATIVE CONSULTANT',
  'ADMINISTRATIVE COORDINATOR',
  'ADMINISTRATIVE DIRECTOR',
  'ADMINISTRATIVE EXECUTIVE',
  'ADMINISTRATIVE HEAD',
  'ADMINISTRATIVE MANAGER',
  'ADMINISTRATIVE OFFICER',
  'ADMINISTRATIVE OPERATIONS ASSISTANT',
  'ADMINISTRATIVE OPERATIONS EXECUTIVE',
  'ADMINISTRATIVE QC MANAGER',
  'ADMINISTRATIVE SALES CLERK',
  'ADMINISTRATIVE SECRETARY',
  'ADMINISTRATIVE SENIOR EXECUTIVE',
  'ADMINISTRATIVE SUPERVISOR',
  'ADMINISTRATIVE SUPPORT OFFICER',
  'ADMINISTRATOR',
  'ADMINISTRATOR OF BUSINESS ASSOCIATION',
  'ADMINISTRATOR, COMPUTER SYSTEMS',
  'ADMINISTRATOR, DATABASE',
  'ADMINISTRATOR, GOVERNMENT',
  'ADMINISTRATOR, NETWORK',
  'ADMISSIONS DIRECTOR',
  'ADMISSIONS OFFICER',
  'ADVANCED MANUFACTURING ENGINEER',
  'ADVANCED PRODUCTION TECHNICIAN',
  'ADVANCED SOFTWARE ENGINEER',
  'ADVANCED SYSTEMS ENGINEER',
  'ADVENTURE GUIDE',
  'Advertising / Public Relations Manager',
  'ADVERTISING ACCOUNT EXECUTIVE',
  'ADVERTISING ACCOUNTS EXECUTIVE',
  'ADVERTISING ASSISTANT MANAGER',
  'ADVERTISING CLERK',
  'ADVERTISING COPYWRITER',
  'ADVERTISING DESIGN ASSISTANT',
  'ADVERTISING DESIGNER',
  'ADVERTISING EXECUTIVE',
  'ADVERTISING MANAGER',
  'ADVERTISING PHOTOGRAPHER',
  'ADVERTISING REPRESENTATIVE',
  'ADVERTISING SALES DIRECTOR',
  'ADVERTISING SALES EXECUTIVE',
  'ADVERTISING SALES MANAGER',
  'ADVERTISING SPECIALIST',
  'ADVISER',
  'ADVISER, CAREERS',
  'ADVISER, CUSTOMER SERVICE',
  'ADVISER, EDUCATION METHODS',
  'ADVISER, SERVICE (CUSTOMER AFTER-SALES SERVICE)',
  'ADVISER, TEACHING METHODS',
  'ADVISER, TECHNICAL SERVICE',
  'ADVISOR',
  'ADVISORY SALE SPECIALIST',
  'ADVISORY SOFTWARE ENGINEER',
  'ADVOCATE & SOLICITOR (OF THE SUPREME COURT OF SINGAPORE)',
  'Advocate / Solicitor',
  'ADVOCATE AND SOLICITOR (PRACTISING)',
  'AERIAL PHOTOGRAPHER',
  'AERO ENGINE FITTER',
  'AERODYNAMICS ENGINEER',
  'AERONAUTICAL ENGINEER',
  'AERONAUTICAL ENGINEERING TECHNICIAN',
  'AEROSPACE GROUND EQUIPMENT MANAGER',
  'AEROSPACE PHYSIOLOGY INSTRUCTOR',
  'AEROSPACE TECHNICIAN',
  'AESTHETICIAN',
  'AFTER EFFECTS DESIGNER',
  'After Sales Adviser / Client Account Service Executive',
  'AFTER SALES SERVICE ADVISER',
  'AFTER SALES SERVICE ENGINEER',
  'AFTER SCHOOL CARE CENTRE WORKER',
  'AFTERMARKET DIRECTOR',
  'AFTERMARKET OPS MANAGER',
  'AFTERMARKET SALES MANAGER',
  'AFTERMARKET SPARES REP',
  'AFTERSALES & SERVICE ASSISTANT',
  'AFTERSALES EXECUTIVE',
  'AFTERSALES MANAGER',
  'AFTER-SALES SERVICE ADVISER',
  'AGENT, CLEARING AND FORWARDING',
  'AGENT, EMPLOYMENT',
  'AGENT, INSURANCE',
  'AGENT, PATENT',
  'AGENT, SHIPPING',
  'AGRICULTURAL ADVISOR',
  'AGRICULTURAL ENGINEER',
  'AGRICULTURAL ENGINEERING TECHNICIAN',
  'AGRICULTURAL MACHINERY OPERATOR',
  'Agricultural Worker Nec',
  'AGRITECHNICIAN',
  'AGRONOMIST',
  'Ai Chief Data Scientist',
  'Ai Developer',
  'Ai Engineer',
  'Ai Scientist',
  'AIR CARGO OFFICER',
  'AIR FREIGHT OFFICER',
  'AIR STEWARDESS',
  'AIR TICKET MANAGER',
  'AIR TICKETING SUPERVISOR',
  'AIR TRAFFIC CONTROLLER',
  'AIR TRANSPORT EQUIPMENT PROJECT EXECUTIVE',
  'AIR TRANSPORT PILOT',
  'AIR TRANSPORT SERVICE SUPERVISOR',
  'AIR-CON MAINTENANCE OFFICER',
  'AIR-CON PLANT INSTALLER',
  'AIR-CON TECHNICAL MANAGER',
  'AIR-CON TECHNICIAN',
  'Air-Conditioning / Refrigeration Engineer',
  'Air-Conditioning / Refrigeration Engineering Technician',
  'Air-Conditioning / Refrigeration Equipment Mechanics',
  'Air-Conditioning / Refrigeration Plant Installer',
  'AIR-CONDITIONING AND MECHANICAL VENTILATION (ACMV) SERVICE ENGINEER',
  'AIR-CONDITIONING AND REFRIGERATING EQUIPMENT INSTALLER',
  'AIR-CONDITIONING AND REFRIGERATING EQUIPMENT MECHANIC',
  'AIR-CONDITIONING AND REFRIGERATION ENGINEER',
  'AIR-CONDITIONING AND REFRIGERATION ENGINEERING TECHNICIAN',
  'AIR-CONDITIONING AND REFRIGERATION EQUIPMENT INSULATOR',
  'AIR-CONDITIONING ENGINEER',
  'AIR-CONDITIONING ENGINEERING ASSISTANT',
  'AIR-CONDITIONING ENGINEERING TECHNICIAN',
  'AIR-CONDITIONING SUPERVISOR',
  'AIRCRAFT CAPTAIN (AIR TRANSPORT)',
  'AIRCRAFT COMPOSITE TECHNICIAN',
  'AIRCRAFT ENGINE MECHANIC',
  'AIRCRAFT ENGINE TECHNICIAN',
  'AIRCRAFT ENGINEER',
  'AIRCRAFT GROUND ENGINEER',
  'AIRCRAFT LEAD TECHNICIAN',
  'AIRCRAFT MAINTENANCE ENGINEER',
  'AIRCRAFT MAINTENANCE MANAGER',
  'AIRCRAFT MAINTENANCE TECHNICIAN (MECHANICAL)',
  'AIRCRAFT MECHANIC',
  'AIRCRAFT PAINTER',
  'AIRCRAFT PILOT (COMMERCIAL AIRLINE)',
  'AIRCRAFT PLANNER',
  'AIRCRAFT QUALITY INSPECTOR',
  'AIRCRAFT QUALITY OFFICER',
  'AIRCRAFT SHEET METAL WORKER',
  'AIRCRAFT STEWARD',
  'AIRCRAFT TECHNICIAN',
  'AIRFRAME ENGINEER',
  'AIRFREIGHT BRANCH MANAGER',
  'AIRFREIGHT EXECUTIVE',
  'AIRLINE ANALYST',
  'AIRLINE CAPTAIN',
  'AIRLINE LIAISON OFFICER',
  'AIRLINE MANAGER OPERATION',
  'AIRLINE MARKETING ANALYST',
  'AIRLINE PILOT',
  'AIRPORT HOSTESS',
  'AIRPORT MANAGER',
  'AIRPORT MOBILE EQUIPMENT DRIVER',
  'AIRPORT OPERATIONS MANAGER',
  'AIRTICKETING EXECUTIVE',
  'ALCOHOL DISTILLER',
  'ALLIED HEALTH PROFESSIONAL TRAINEE',
  'AMBULANCE DRIVER',
  'AMBULANCE OFFICER',
  'ANAESTHETIST/ANAESTHESIOLOGIST',
  'ANALOG DESIGN ENGINEER',
  'ANALYSIS SALES MANAGER',
  'ANALYST',
  'ANALYST PROGRAMMER',
  'ANALYST, ACCOUNTS',
  'ANALYST, CREDIT',
  'ANALYST, FINANCIAL',
  'ANALYST, JOB',
  'ANALYST, MARKET RESEARCH',
  'ANALYST, OPERATIONS RESEARCH',
  'ANALYST, SOCIAL RESEARCH',
  'ANALYST, SYSTEMS',
  'ANALYTICAL CHEMIST',
  'ANALYTICS MANAGER',
  'ANALYZER ENGINEER',
  'ANALYZER TECHNICIAN',
  'ANIMAL KEEPER (ZOO)',
  'Animal Scientist',
  'ANIMATOR',
  'APPAREL SOURCING MANAGER',
  'APPLICATION ADMINISTRATOR - INFORMATION TECHNOLOGY',
  'APPLICATION ANALYST',
  'Application Architect',
  'Application Consultant',
  'APPLICATION DESIGNER',
  'Application Developer',
  'APPLICATION ENGINEER',
  'Application Manager',
  'APPLICATION PROGRAMMER',
  'APPLICATION SALES SPECIALIST',
  'Application Specialist',
  'APPLICATION SUPPORT ANALYST',
  'APPLICATION SUPPORT ENGINEER',
  'APPLICATION SYSTEM MANAGER',
  'Application Team Leader',
  'APPLICATION TECHNICIAN',
  'Application Technologist',
  'Applications / Systems Programmer',
  'Applications Analyst',
  'Applications Developer',
  'APPLICATIONS MANAGER',
  'APPRAISER',
  'APPRAISER (EXCLUDING INTANGIBLE ASSET VALUER)',
  'Appraiser / Valuer',
  'APPRAISER AND VALUER',
  'APRON OFFICER',
  'AQUACULTURE SPECIALIST',
  'AQUACULTURE TECHNOLOGIST',
  'AQUACULTURIST',
  'AQUARIST',
  'ARBITRATOR',
  'ARBORICULTURIST',
  'ARBORIST',
  'ARCHBISHOP',
  'ARCHERY COACH',
  'ARCHITECT',
  'ARCHITECT, BUILDING',
  'ARCHITECT, LANDSCAPE',
  'ARCHITECT, MARINE',
  'ARCHITECT, NAVAL',
  'ARCHITECTURAL ASSISTANT',
  'ARCHITECTURAL ASSOCIATE',
  'ARCHITECTURAL CONSULTANT',
  'ARCHITECTURAL COORDINATOR',
  'ARCHITECTURAL DESIGNER',
  'ARCHITECTURAL DRAFTER',
  'ARCHITECTURAL DRAUGHTSMAN',
  'ARCHITECTURAL ENGINEER',
  'ARCHITECTURAL EXECUTIVE',
  'ARCHITECTURAL ILLUSTRATOR',
  'ARCHITECTURAL SUPERVISOR',
  'ARCHITECTURAL TECHNICIAN',
  'ARCHIVIST, BUSINESS INTELLIGENCE',
  'ARCHIVIST, TECHNICAL INFORMATION',
  'AREA MANAGER',
  'AREA SALES MANAGER',
  'AREA SUPERINTENDENT',
  'ARMED FORCES (FOREIGN)',
  'ARRANGER, BALLET',
  'ART CONCEPT DESIGNER',
  'ART DEALER',
  'ART DESIGNER',
  'ART DIRECTOR',
  'ART GALLERY CURATOR',
  'ART INSTRUCTOR',
  'ART MANAGER',
  'ART MARKETING MANAGER',
  'ART TEACHER',
  'ART TEACHER (OTHER THAN SECONDARY AND VOCATIONAL SCHOOL)',
  'ART THERAPIST',
  'ARTIFICIAL FLOWER MAKER',
  'Artificial Intelligence Engineer',
  'Artificial Intelligence Specialist',
  'ARTIST',
  'ARTIST (PAINTER)',
  'ARTIST, COMMERCIAL',
  'ARTIST, CREATIVE (GLASS AND CERAMICS)',
  'ARTIST, DISPLAY',
  'ARTIST, GRAPHIC',
  'ARTIST, POTTERY',
  'ARTISTIC DIRECTOR',
  'Artistic Director (Stage, Film, Television And Radio)',
  "ARTIST'S MODEL",
  'ARTS EVENT MANAGER',
  'ARTS MANAGER',
  'ASSEMBLER AND INSTALLER, ELECTRIC SIGN',
  'ASSEMBLER, CHRONOMETER',
  'ASSEMBLER, ELECTRICAL EQUIPMENT',
  'ASSEMBLER, ELECTRONIC COMPONENT',
  'ASSEMBLER, FURNITURE (SHEET METAL)',
  'ASSEMBLER, INDUSTRIAL MACHINERY (ELECTRICAL)',
  'ASSEMBLER, METAL PRODUCTS',
  'ASSEMBLER, MICROELECTRONICS',
  'ASSEMBLER, PLASTIC PRODUCTS',
  'Assembly And Quality Check Supervisor / General Foreman',
  'ASSEMBLY ENGINEER',
  'ASSEMBLY EQUIPMENT ENGINEER',
  'ASSEMBLY MANAGER',
  'ASSEMBLY OPERATOR',
  'ASSEMBLY TECHNICIAN',
  'Assessor',
  'ASSESSOR, INSURANCE',
  'ASSESSOR, LOSS',
  'ASSET MANAGER',
  'ASSET SECURITISATION STRUCTURER',
  'ASSISTANT ACCOUNT MANAGER',
  'ASSISTANT ACCOUNTANT',
  'ASSISTANT ACCOUNTING MANAGER',
  'ASSISTANT ADMINISTRATIVE EXECUTIVE',
  'ASSISTANT ADMINISTRATIVE MANAGER',
  'ASSISTANT AIRCRAFT TECHNICIAN',
  'ASSISTANT ANALYST',
  'ASSISTANT ARCHITECT',
  'ASSISTANT ARCHITECTURAL DESIGNER',
  'ASSISTANT ARTISTIC DIRECTOR',
  'ASSISTANT ASSOCIATE',
  'ASSISTANT AYURVEDIC THERAPIST',
  'Assistant Baker',
  'ASSISTANT BUILDING CONSTRUCTION ENGINEER',
  'ASSISTANT BUSINESS ANALYST',
  'ASSISTANT BUSINESS DEVELOPMENT MANAGER',
  'ASSISTANT CAPTAIN (CATERING SERVICE)',
  'ASSISTANT CAPTAIN (RESTAURANT)',
  'ASSISTANT CATEGORY FINANCE MANAGER',
  'Assistant Chartering Broker',
  'ASSISTANT CHARTERING MANAGER',
  'ASSISTANT CHEF',
  'ASSISTANT CHEMICAL ENGINEER',
  'ASSISTANT CHEMIST',
  'ASSISTANT CHIEF COOK',
  'ASSISTANT CHIEF ENGINEER',
  'ASSISTANT CHIEF EXECUTIVE OFFICER',
  'ASSISTANT CHIEF FINANCIAL OFFICER',
  'ASSISTANT CHIEF INVESTMENT OFFICER',
  'ASSISTANT CHIEF OPERATING OFFICER',
  'ASSISTANT CHIEF REPRESENTATIVE OFFICER',
  'ASSISTANT CHIEF STEWARD',
  'ASSISTANT CHIEF STEWARD (HOTEL)',
  'Assistant Civil And Structural Engineer',
  'ASSISTANT CIVIL ENGINEER',
  'ASSISTANT COMMERCIAL MANAGER',
  'ASSISTANT COMMERCIAL OFFICER',
  'ASSISTANT COOK',
  'ASSISTANT CORPORATE SERVICES MANAGER',
  'ASSISTANT COST CONTROL MANAGER',
  'ASSISTANT DCS CONTROLLER',
  'ASSISTANT DEAN',
  'ASSISTANT DELIVERY MANAGER',
  'ASSISTANT DEPARTMENT HEAD',
  'ASSISTANT DESIGN ENGINEER',
  'Assistant Design Engineer (Marine And Offshore)',
  'ASSISTANT DESIGN MANAGER',
  'ASSISTANT DESIGNER',
  'ASSISTANT DIRECTOR',
  'Assistant Director Of Events (Hotel And Accommodation Services)',
  'Assistant Director Of Public Relations (Hotel And Accommodation Services)',
  'Assistant Director Of Sales (Hotel And Accommodation Services)',
  'ASSISTANT DRILLER',
  'ASSISTANT EDITOR',
  'ASSISTANT ELECTRICAL DESIGN ENGINEER',
  'ASSISTANT ELECTRICAL ENGINEER',
  'ASSISTANT ELECTRICAL SUPERVISOR',
  'ASSISTANT ELECTRONICS ENGINEER',
  'ASSISTANT ENGINEER',
  'ASSISTANT ENGINEER (CHEMICAL)',
  'ASSISTANT ENGINEER (CIVIL)',
  'ASSISTANT ENGINEER (ELECTRICAL)',
  'ASSISTANT ENGINEER (ELECTRONIC)',
  'ASSISTANT ENGINEER (MECHANICAL)',
  'ASSISTANT ENGINEER (PRODUCTION)',
  'Assistant Engineer (Sea Transport)',
  'ASSISTANT ENGINEER (STRUCTURAL)',
  'ASSISTANT ENGINEERING MANAGER',
  'Assistant Equipment Engineer',
  'Assistant Events Manager (Hotel And Accommodation Services)',
  'Assistant Executive Housekeeper (Hotel And Accommodation Services)',
  'ASSISTANT EXECUTIVE MANAGER',
  'Assistant Facility Engineer',
  'ASSISTANT FIELD SERVICE ENGINEER',
  'ASSISTANT FIELD SERVICE MANAGER',
  'ASSISTANT FINANCE MANAGER',
  'ASSISTANT FLOOR MANAGER',
  'ASSISTANT FOOD & BEVERAGE MANAGER',
  'ASSISTANT FOREMAN',
  'ASSISTANT FRAGRANCE DEVELOPMENT MANAGER',
  'ASSISTANT FRONT OFFICE MANAGER',
  'Assistant Front Office Manager (Hotel And Accommodation Services)',
  'ASSISTANT GENERAL MANAGER',
  'ASSISTANT GUIDANCE COUNSELLOR',
  'ASSISTANT HAIR CONSULTANT',
  'ASSISTANT HEAD (INTERNATIONAL SCHOOL)',
  'Assistant Head Chef',
  'ASSISTANT HEAD, LEGAL',
  'ASSISTANT HOUSEKEEPER',
  'Assistant Housekeeper (Hotel And Accommodation Services)',
  'ASSISTANT HYDRAULIC ENGINEER',
  'ASSISTANT INFORMATION TECHNOLOGY MANAGER',
  'ASSISTANT INTERIOR DESIGNER',
  'ASSISTANT INTERNET ENGINEER',
  'ASSISTANT LECTURER',
  'ASSISTANT LEGAL MANAGER',
  'ASSISTANT LIGHTING DESIGNER',
  'ASSISTANT MAINTENANCE ENGINEER',
  'ASSISTANT MANAGEMENT ACCOUNTANT',
  'ASSISTANT MANAGER',
  'ASSISTANT MANAGER (ADMINISTRATION)',
  'ASSISTANT MANAGER (GENERAL)',
  'ASSISTANT MANAGER (LEARNING & DEVELOPMENT)',
  'ASSISTANT MANAGER (PERSONNEL)',
  'ASSISTANT MANAGER (PRODUCTION)',
  'ASSISTANT MANAGING DIRECTOR',
  'ASSISTANT MANUFACTURING ENGINEER',
  'Assistant Manufacturing Manager (Precision Engineering)',
  'ASSISTANT MARKETING MANAGER',
  'ASSISTANT MATERIAL CONTROLLER',
  'ASSISTANT MECHANICAL ENGINEER',
  'ASSISTANT MECHANICAL SUPERVISOR',
  'ASSISTANT NURSE',
  'ASSISTANT OPERATION MANAGER',
  'ASSISTANT OPERATION OFFICER',
  'ASSISTANT OPERATIONS DIRECTOR',
  'ASSISTANT OPERATIONS MANAGER',
  'ASSISTANT OPERATIONS SUPERVISOR',
  'ASSISTANT OUTLET MANAGER',
  'Assistant Outlet Manager (Food Services)',
  'ASSISTANT PASTOR',
  'ASSISTANT PASTRY CHEF',
  'Assistant Pastry Cook',
  'ASSISTANT PHOTOGRAPHER',
  'ASSISTANT PLANNER',
  'ASSISTANT PLANNING ENGINEER',
  'ASSISTANT PRIEST',
  'ASSISTANT PRINCIPAL (COMMERCIAL SCHOOL)',
  'ASSISTANT PRINCIPAL (INTERNATIONAL SCHOOL)',
  'ASSISTANT PRIVATE BANKER',
  'ASSISTANT PROCESS ENGINEER',
  'Assistant Process Engineer (Electronics)',
  'ASSISTANT PRODUCER',
  'Assistant Product Engineer',
  'ASSISTANT PRODUCTION ENGINEER',
  'Assistant Production Engineer (Marine And Offshore)',
  'ASSISTANT PRODUCTION MANAGER',
  'ASSISTANT PRODUCTION OFFICER',
  'ASSISTANT PRODUCTION SUPERVISOR',
  'ASSISTANT PROFESSOR',
  'ASSISTANT PROJECT ENGINEER',
  'ASSISTANT PROJECT EXECUTIVE',
  'ASSISTANT PROJECT MANAGER',
  'ASSISTANT PROJECT SUPERVISOR',
  'Assistant Quality Assurance Engineer (Marine And Offshore)',
  'ASSISTANT QUALITY CONTROL/ASSURANCE ENGINEER',
  'Assistant Quality Engineer',
  'ASSISTANT QUALITY MANAGER',
  'ASSISTANT QUANTITY SURVEYOR',
  'ASSISTANT RECRUITMENT MANAGER',
  'ASSISTANT RESEARCH COORDINATOR',
  'ASSISTANT RESEARCH OFFICER',
  'ASSISTANT RESEARCH SCIENTIST',
  'Assistant Reservations Manager (Hotel And Accommodation Services)',
  'ASSISTANT RESTAURANT MANAGER',
  'ASSISTANT RETAIL MANAGER',
  'ASSISTANT RETAIL SUPERVISOR',
  'Assistant Revenue Manager (Hotel And Accommodation Services)',
  'ASSISTANT SAFETY ENGINEER',
  'ASSISTANT SALES & MARKETING MANAGER',
  'ASSISTANT SALES ENGINEER',
  'ASSISTANT SALES MANAGER',
  'ASSISTANT SAP PROJECT MANAGER',
  'ASSISTANT SERVICE ENGINEER',
  'ASSISTANT SHOP MANAGER',
  'ASSISTANT SITE ENGINEER',
  'ASSISTANT SITE MANAGER',
  'ASSISTANT SITE SUPERVISOR',
  'ASSISTANT SOFTWARE ENGINEER',
  'Assistant Spa Manager',
  'ASSISTANT STALL SUPERVISOR',
  'ASSISTANT STATION MANAGER',
  'ASSISTANT STORE SUPERVISOR',
  'ASSISTANT STRUCTURAL ENGINEER',
  'ASSISTANT SUPERINTENDENT',
  'ASSISTANT SUPERVISOR',
  'ASSISTANT SUPPLY CHAIN MANAGER',
  'ASSISTANT SUPPLY CHAIN PLANNING MANAGER',
  'ASSISTANT SUPPLY MANAGEMENT MANAGER',
  'ASSISTANT SUPPLY MANAGER',
  'ASSISTANT SYSTEM ADMINISTRATOR',
  'ASSISTANT SYSTEMS ENGINEER',
  'ASSISTANT TABLE TENNIS COACH',
  'ASSISTANT TEACHER',
  'ASSISTANT TECHNICAL SUPERINTENDENT',
  'ASSISTANT TECHNICIAN',
  'ASSISTANT TELECOMMUNICATIONS MANAGER',
  'ASSISTANT TEST ENGINEER',
  'ASSISTANT TO GENERAL MANAGER',
  'ASSISTANT TO MANAGING DIRECTOR',
  'ASSISTANT TRAFFIC ENGINEER',
  'ASSISTANT TRAINING MANAGER',
  'ASSISTANT TRAINING OFFICER',
  'ASSISTANT TREASURER',
  'ASSISTANT VICE PRESIDENT',
  'ASSISTANT WAREHOUSE MANAGER',
  'ASSISTANT, ACCOUNTS',
  'ASSISTANT, AUDIT',
  'ASSISTANT, CLERICAL',
  'ASSISTANT, LIBRARY',
  'ASSISTANT, MANAGER (PUBLIC RELATIONS)',
  'ASSISTANT, MANAGER (SALES)',
  'ASSISTANT, MEDICAL',
  'ASSISTANT, PERSONAL',
  'ASSISTANT, PHARMACEUTICAL',
  'ASSISTANT, SHOP',
  'ASSISTANT, STORES',
  'ASSISTANT, VETERINARY',
  'ASSOCIATE',
  'ASSOCIATE ACCOUNT DIRECTOR',
  'ASSOCIATE BANKER',
  'ASSOCIATE BUSINESS MANAGER',
  'ASSOCIATE CONSULTANT',
  'ASSOCIATE CONSULTANT (MEDICAL DOCTOR)',
  'ASSOCIATE CREATIVE DIRECTOR',
  'ASSOCIATE DENTAL SURGEON',
  'ASSOCIATE DESIGNER',
  'ASSOCIATE DIRECTOR',
  'Associate Director (Budgeting And Financial Accounting)',
  'ASSOCIATE EDITOR',
  'ASSOCIATE ENGINEER',
  'Associate Engineer (Precision Engineering)',
  'ASSOCIATE FINANCIAL ADVISOR',
  'ASSOCIATE GAME PRODUCER',
  'ASSOCIATE MANAGER',
  'ASSOCIATE MARKETING DIRECTOR',
  'ASSOCIATE PARTNER',
  'ASSOCIATE PRODUCER',
  'ASSOCIATE PRODUCER, NEWS',
  'ASSOCIATE PROFESSOR',
  'ASSOCIATE RESEARCH FELLOW',
  'ASSOCIATE REVENUE OPERATIONS SPECIALIST',
  'ASSOCIATE SCIENTIST',
  'ATHLETIC COACH',
  'ATRIUM EXECUTIVE',
  'AUCTIONEER',
  'Audio And Video Equipment Engineer',
  'AUDIO AND VIDEO EQUIPMENT MECHANIC',
  'AUDIO AND VIDEO EQUIPMENT TECHNICIAN',
  'AUDIO ENGINEER',
  'AUDIO EQUIPMENT ENGINEER',
  'Audiologist',
  'AUDIOLOGISTS',
  'AUDIOMETRIC TECHNICIAN',
  'AUDIO-VISUAL OPERATIONS SPECIALIST',
  'AUDIT (ACCOUNTING) EXECUTIVE',
  'AUDIT ACCOUNTANT',
  'AUDIT ASSISTANT',
  'AUDIT ASSOCIATE',
  'AUDIT CONSULTANT',
  'AUDIT DIRECTOR',
  'AUDIT EXECUTIVE',
  'AUDIT MANAGER',
  'AUDIT PARTNER',
  'AUDIT SEMI-SENIOR',
  'AUDIT SENIOR',
  'AUDIT SENIOR MANAGER',
  'AUDIT SUPERVISOR',
  'AUDITOR',
  'AUDITOR (ACCOUNTING)',
  'AUDITOR GENERAL',
  'AUDITOR, INFORMATION TECHNOLOGY',
  'AUDITORY VERBAL THERAPIST',
  'AUTHOR',
  'AUTO-BODY REPAIRER',
  'AUTOCAD COMPUTER DESIGNER',
  'AUTOCAD DESIGN ENGINEER',
  'AUTOCAD DESIGNER',
  'AUTOCAD DRAFTSMAN',
  'AUTOCAD ENGINEER',
  'AUTOCAD OPERATOR',
  'AUTOCLAVE OPERATOR (FOODSTUFFS)',
  'AUTO-LATHE SETTER-OPERATOR',
  'AUTOMATED SYSTEMS ENGINEER',
  'AUTOMATION ANALYST',
  'AUTOMATION DESIGNER',
  'AUTOMATION ENGINEER',
  'AUTOMATION SYSTEMS ENGINEER',
  'AUTOMATION TECHNICIAN',
  'AUTOMOBILE MECHANIC',
  'AUTOMOBILE PAINTER',
  'AUTOMOBILE PAINTER (MOTOR VEHICLE ASSEMBLY)',
  'AUTOMOBILE REPAIR PAINTER',
  'AUTOMOBILE UPHOLSTERER',
  'AUTOMOTIVE ENGINEER',
  'AUTOMOTIVE ENGINEERING ASSISTANT',
  'AUTOMOTIVE ENGINEERING MANAGER',
  'AUTOMOTIVE ENGINEERING TECHNICIAN',
  'AUTOMOTIVE SPECIALIST',
  'Auxiliary Police Officer',
  'AVIATION DEVELOPMENT MANAGER',
  'AVIATION GROUND INSTRUCTOR',
  'AVIATION INSTRUCTOR',
  'AVIATION SURVEYOR',
  'AVIONICS ENGINEER',
  'AVIONICS TECHNICIAN',
  'AVIONICS TESTING TECHNICIAN',
  'AYURVEDIC CONSULTANT',
  'AYURVEDIC THERAPIST',
  'BABYSITTER',
  'BACK-END MACHINE OPERATOR (PAPERMAKING)',
  'BADMINTON COACH',
  'BAKED GOODS PRODUCTS MACHINE OPERATOR',
  'BAKER',
  'Baker (Food Services)',
  'BAKER (GENERAL)',
  'BAKER, BREAD',
  'BAKER, PASTRY',
  'BALLET ARRANGER',
  'BALLET DANCER',
  'BAND PERFORMER',
  'BAND-SAW OPERATOR',
  'BANK ASSOCIATE',
  'BANK CASHIER',
  'BANK CUSTOMER SERVICE CLERK',
  'BANK EXECUTIVE',
  'BANK MANAGER (BRANCH)',
  'BANK OFFICER',
  'BANK OPERATIONS CLERK',
  'BANK TELLER',
  'BANKER',
  'BANKING CONSULTANT',
  'BANKING OPERATIONS CONSULTANT',
  'BANKING OPERATIONS MANAGER',
  'BANQUET COORDINATOR',
  'BANQUET MANAGER',
  'BANQUET MANAGER (HOTEL)',
  'BAR ATTENDANT',
  'BAR BENDER (CONSTRUCTION)',
  'BAR MANAGER',
  'BAR SUPERVISOR',
  'BARBER',
  'BARGE SUPERINTENDENT',
  'BARISTA',
  'Barista Supervisor',
  'BARMAN',
  'BARTENDER',
  'Bartender Supervisor',
  'BASEOIL STRATEGY MANAGER',
  'BASKETBALL COACH',
  'BASKETBALL PLAYER',
  'BEAN CURD MAKING MACHINE OPERATOR',
  'BEATER, PANEL (VEHICLE)',
  'BEAUTICIAN',
  'BEAUTICIAN SUPERVISOR',
  'BEAUTY ADVISER',
  'BEAUTY ADVISOR',
  'BEAUTY CENTRE MANAGER',
  'BEAUTY CONSULTANT',
  'BEAUTY DIRECTOR',
  'BEAUTY MANAGER',
  'BEAUTY THERAPIST',
  'BEAUTY TUTOR',
  'Beginning Educarer (Early Childhood Care And Education)',
  'Beginning Pre-School Teacher (Early Childhood Care And Education)',
  'BEHAVIORAL THERAPIST',
  'BENCH CARPENTER',
  'BENDER AND FIXER, STEEL (REINFORCED CONCRETE)',
  'BENDER, METAL PLATE',
  'BENDING PRESS OPERATOR, METAL',
  'BICYCLE REPAIRMAN',
  'BID MANAGER',
  'BILL COLLECTOR',
  'BILLING ASSISTANT',
  'BILLING CLERK',
  'BILLING EXECUTIVE',
  'BILLING OFFICER',
  'BILLING SPECIALIST',
  'BILLING SYSTEMS ENGINEER',
  'BIOCHEMIST',
  'BIOCHEMISTRY SCIENTIST',
  'BIOINFORMATICS MANAGER',
  'BIOINFORMATICS SPECIALIST',
  'BIOLOGICAL CHEMIST',
  'BIOLOGICAL SCIENTIST',
  'BIOLOGIST (GENERAL)',
  'BIOLOGY BIOTECHNOLOGIST',
  'BIOLOGY LABORATORY TECHNICIAN',
  'BIOLOGY RESEARCH SCIENTIST',
  'BIOLOGY SCIENCE RESEARCHER',
  'BIOMEDICAL ENGINEER',
  'BIOMEDICAL NANOTECHNOLOGY RESEARCH SCIENTIST',
  'BIOPROCESS LEAD ENGINEER',
  'BIOTECHNOLOGIST, PLANT',
  'BISHOP',
  'BLASTER',
  'BLENDER, PETROLEUM REFINING',
  'BLOGGER',
  'BOARDING HOUSE MANAGER',
  'BOARDING OFFICER',
  'BOATBUILDER, WOOD',
  'BOATMAN',
  'BOATSWAIN',
  'BOILER ATTENDANT',
  'BOILER SUPERVISOR',
  'BOILER TECHNICIAN',
  'BOILERMAN, POWER PLANT',
  'BONER, MEAT',
  'BOOK EDITOR',
  'BOOKBINDING MACHINE OPERATOR',
  'BOOKKEEPER',
  'BOOKKEEPER, ACCOUNTS PAYABLE (OR RECEIVABLE)',
  'BOOKKEEPER, GENERAL LEDGER',
  'BOOKKEEPING CLERK',
  'BORED PILING MACHINE OPERATOR',
  'BORING MACHINE OPERATOR',
  'BORING MACHINE OPERATOR (METALWORKING)',
  'BORING MACHINE SETTER-OPERATOR (METALWORKING)',
  'BOUTIQUE ASSISTANT',
  'BOUTIQUE ASSISTANT MANAGER',
  'BOUTIQUE ASSOCIATE',
  'BOUTIQUE EXECUTIVE',
  'BOUTIQUE MANAGER',
  'BOUTIQUE SUPERVISOR',
  'BOX-OFFICE CASHIER',
  'BRANCH GENERAL MANAGER',
  'BRANCH MANAGER',
  'BRANCH SUPERVISOR',
  'Brand Associate (Retail)',
  'BRAND COMMUNICATIONS DIRECTOR',
  'BRAND DEVELOPMENT DIRECTOR',
  'BRAND DEVELOPMENT MANAGER',
  'BRAND DEVELOPMENT OFFICER',
  'BRAND DIRECTOR',
  'BRAND EXECUTIVE',
  'Brand Executive (Retail)',
  'BRAND INNOVATION MANAGER',
  'BRAND MANAGER',
  'Brand Manager (Retail)',
  'BRAND STRATEGIST',
  'BRANDED CONTENT ADVERTISING VIDEOS PRODUCER',
  'BREAD BAKER',
  'BREWER',
  'BREWMASTER',
  'BRICKLAYER',
  'BRICKLAYER (CONSTRUCTION)',
  'BRICKLAYING, INSTRUCTOR OF',
  'BRIDGE CONSTRUCTION ENGINEER',
  'BRIDGE OR GANTRY CRANE OPERATOR',
  'BROADCAST DESIGNER',
  'BROADCAST ENGINEER',
  'BROADCAST INFORMATION TECHNOLOGY ENGINEER',
  'BROADCAST JOURNALIST',
  'BROADCASTING EQUIPMENT OPERATOR (EXCEPT SOUND RECORDING)',
  'BROADCASTING OPERATIONS TECHNICIAN',
  'BROKER, COMMODITY',
  'BROKER, COMMODITY (FINANCIAL)',
  'BROKER, INSURANCE',
  'BROKER, INVESTMENT',
  'BROKER, MONEY',
  'BROKER, SECURITIES',
  'BROKER, SHIP',
  'BROKER, TRADE',
  'BROKERAGE CLERK',
  'Brokerage Supervisor',
  "BROKER'S CLERK (INSURANCE)",
  'BRUSH PAINTER (BUILDING)',
  'BUDDHIST MASTER',
  'BUDGET MANAGER',
  'Budgeting And Financial Accounting Manager',
  'Budgeting And Financial Accounting Manager (Including Financial Controller)',
  'Buffing / Polishing Machine Operator',
  'BUFFING AND POLISHING MACHINE OPERATOR',
  'Build And Release Specialist',
  'Building And Construction Project Manager',
  'BUILDING ARCHITECT',
  'BUILDING CONSTRUCTION ENGINEER',
  'BUILDING CONSTRUCTION GENERAL FOREMAN',
  'BUILDING CONSTRUCTION PROJECT ENGINEER',
  'BUILDING CONSTRUCTION SUPERVISOR',
  'BUILDING CONSTRUCTION SUPERVISOR AND GENERAL FOREMAN',
  'BUILDING DESIGNER',
  'BUILDING DRAFTER',
  'BUILDING ELECTRICIAN',
  'BUILDING ENGINEER',
  'BUILDING GENERAL FOREMAN',
  'BUILDING INFORMATION MODELLING (BIM) TECHNICIAN',
  'BUILDING MAINTENANCE ELECTRICIAN',
  'BUILDING MAINTENANCE HANDYMAN',
  'BUILDING MAINTENANCE INSPECTOR',
  'BUILDING MAINTENANCE MAN',
  'BUILDING MAINTENANCE SUPERVISOR',
  'BUILDING MAINTENANCE WORKER',
  'BUILDING PAINTER',
  'BUILDING SERVICES DESIGNER',
  'BUILDING SERVICES ENGINEER',
  'BUILDING SUPERVISOR',
  'BUILDING TECHNICIAN',
  'BULLDOZER OPERATOR',
  'BUNKER MANAGER',
  'BUNKER TRADER',
  'BUREAU CHIEF',
  'BUS CAPTAIN',
  'BUS DRIVER',
  'BUS, MOTOR, MECHANIC',
  'BUSINESS ACCOUNT MANAGER',
  'BUSINESS ADMINISTRATOR',
  'BUSINESS ADVISOR',
  'BUSINESS ADVISORY SERVICES CONSULTANT',
  'BUSINESS ANALYST',
  'BUSINESS AND FINANCIAL PROJECT MANAGEMENT PROFESSIONAL',
  'BUSINESS AND INDUSTRY CONSULTANT',
  'BUSINESS APPLICATIONS MANAGER',
  'BUSINESS CONSULTANT',
  'BUSINESS CONTROL MANAGER',
  'Business Controller',
  'BUSINESS COORDINATION MANAGER',
  'BUSINESS COORDINATOR',
  'BUSINESS COST ANALYST',
  'BUSINESS DEVELOPMENT ADVISOR',
  'BUSINESS DEVELOPMENT ANALYST',
  'BUSINESS DEVELOPMENT ASSISTANT MANAGER',
  'BUSINESS DEVELOPMENT ASSOCIATE',
  'BUSINESS DEVELOPMENT CONSULTANT',
  'BUSINESS DEVELOPMENT DIRECTOR',
  'Business Development Director (Logistics)',
  'BUSINESS DEVELOPMENT ENGINEER',
  'BUSINESS DEVELOPMENT EXECUTIVE',
  'BUSINESS DEVELOPMENT MANAGER',
  'Business Development Manager (Logistics)',
  'BUSINESS DEVELOPMENT OFFICER',
  'BUSINESS DIRECTOR',
  'BUSINESS EFFICIENCY EXPERT',
  'BUSINESS EXECUTIVE',
  'BUSINESS INTELLIGENCE ARCHIVIST',
  'BUSINESS INTELLIGENCE MANAGER',
  'BUSINESS MACHINE MECHANIC',
  'BUSINESS MANAGER',
  'BUSINESS OPERATION PLANNING SPECIALIST',
  'BUSINESS OPERATIONS ANALYST',
  'BUSINESS OPERATIONS MANAGER',
  'BUSINESS PLANNING MANAGER',
  'BUSINESS PROCESS ANALYST',
  'BUSINESS PROCESS ASSOCIATE',
  'BUSINESS PROCESS ENGINEER',
  'Business Process Excellence Engineer',
  'BUSINESS PROCESS MANAGER',
  'BUSINESS PROJECT MANAGER',
  'BUSINESS RELATIONSHIP MANAGER',
  'BUSINESS SOLUTION MANAGER',
  'BUSINESS SOLUTIONS CONSULTANT',
  'BUSINESS SUPPORT EXECUTIVE',
  'BUSINESS SYSTEM ANALYST',
  'BUSINESS SYSTEMS DEVELOPER',
  'BUTLER',
  'Butler Supervisor (Hotel And Accommodation Services)',
  'BUYER',
  'BUYER, MERCHANDISE (WHOLESALE OR RETAIL TRADE)',
  'BUYER, SUPPLY REQUIREMENTS',
  'CABIN ATTENDANT, AIRCRAFT',
  'CABIN CREW',
  'CABIN MECHANIC',
  'CABIN TECHNICIAN',
  'CABLE AND ROPE SPLICER (GENERAL)',
  'CABLE JOINTER (ELECTRIC CABLE)',
  'CABLE LAYING SPECIALIST',
  'CAD DESIGNER',
  'CAD DRAFTSMAN',
  'CAD MANAGER',
  'CAD SPECIALIST',
  'CAD TECHNICIAN',
  'CAD-CAM ENGINEER',
  'CADET PILOT',
  'CAFE MANAGER',
  'CAFETERIA COOK',
  'CALIBRATION ENGINEER',
  'CALIBRATOR, PRECISION INSTRUMENT',
  'CALL CENTRE AGENT',
  'CALL CENTRE MANAGER',
  'CALL CENTRE OPERATOR',
  'CALL CENTRE SUPERVISOR',
  'CAMERA CONTROL OPERATOR (TELEVISION BROADCASTING)',
  'CAMERA OPERATOR, TELEVISION',
  'CAMERA OPERATOR, VIDEO',
  'CAMERAMAN',
  'CAMP BOSS',
  'CAMP SUPERVISOR',
  'CAMPAIGN EXECUTIVE',
  'CAMPAIGN MANAGER',
  'CANTEEN ASSISTANT',
  'CANTEEN COOK',
  'CANTEEN KEEPER (WORKING PROPRIETOR)',
  'CANVASSER, SALES',
  'CAPABILITY BUILDING MANAGER',
  'CAPABILITY DIRECTOR',
  'CAPACITY ANALYST',
  'CAPTAIN (CATERING SERVICE)',
  'CAPTAIN PILOT',
  'Captain Waiter / Waiter Supervisor',
  'CAPTAIN, AIRCRAFT (AIR TRANSPORT)',
  'CAPTAIN, PORT',
  'CAPTAIN, RESTAURANT',
  'CAPTAIN, SHIP',
  'CAR DELIVERY DRIVER',
  'CAR DRIVER (PRIVATE SERVICE)',
  'CAR PAINTER',
  'CAR WASH SUPERVISOR',
  'CAR WASHING MACHINE SUPERVISOR',
  'CAR, MOTOR, MECHANIC',
  'CARDIAC TECHNICIAN',
  'CAREER COACH',
  'CAREERS ADVISER',
  'CARGO MANAGER',
  'CARGO OFFICER',
  'CARGO OFFICER, AIR',
  "CARGO SUPERINTENDENT, SHIP'S",
  'Carpenter',
  'CARPENTER (GENERAL)',
  'CARPENTER SUPERVISOR',
  'CARPENTER, BENCH',
  'CARPENTER, CONSTRUCTION',
  'CARPENTER, MAINTENANCE',
  'CARPENTER, STAGE AND STUDIO',
  'CARPET LAYER',
  'CARPET MAKER (HAND KNOTTING)',
  'CARVING MACHINE OPERATOR (WOODWORKING)',
  'CASE ADMINISTRATOR',
  'CASE MANAGEMENT OFFICER',
  'CASEWORKER, FAMILY',
  'CASEWORKER, SOCIAL WELFARE',
  'CASH ACCOUNTING CLERK',
  'CASH DESK CASHIER',
  'CASHIER (GENERAL)',
  'CASHIER, CASH DESK',
  'CASHIER, OFFICE',
  'CASHIER, RESTAURANT',
  'CASHIER, STORE',
  'CASINO CAGE ASSISTANT MANAGER',
  'CASINO CAGE CASHIER',
  'CASINO CAGE MANAGER',
  'CASINO CAGE SUPERVISOR',
  'CASINO CROUPIER',
  'CASINO DEALER',
  'CASINO PIT MANAGER',
  'CASINO PIT SUPERVISOR',
  'CASINO SHIFT MANAGER',
  'CASINO SLOTS ASSISTANT MANAGER',
  'CASINO SLOTS WORKER',
  'CASINO SURVEILLANCE OFFICER',
  'CASTER, CONCRETE PRODUCT',
  'CASTING MACHINE OPERATOR (PRINTING TYPE)',
  'CASUALTY UNDERWRITER',
  'CATALOGUER',
  'CATALYST SUPERVISOR',
  'CATEGORY DEVELOPMENT DIRECTOR',
  'CATEGORY FINANCE ASSISTANT',
  'CATEGORY FINANCE ASSISTANT MANAGER',
  'CATEGORY FINANCE DIRECTOR',
  'CATEGORY FINANCE MANAGER',
  'CATEGORY MANAGER',
  'CATEGORY OPERATIONS DIRECTOR',
  'CATEGORY OPERATIONS MANAGER',
  'CATEGORY SUPPLY ASSISTANT MANAGER',
  'CATEGORY SUPPLY CHAIN ASSISTANT',
  'CATERING EXECUTIVE',
  'CATERING MANAGER',
  'CATERING OFFICER',
  'CATERING SALES EXECUTIVE',
  'Catering Sales Executive (Hotel And Accommodation Services)',
  'CATERING SALES MANAGER',
  'Catering Sales Manager (Hotel And Accommodation Services)',
  'Catering Services Manager',
  'CATERING SUPERVISOR',
  'CEILING INSTALLER',
  'CELLAR MASTER',
  'CEMENT FINISHER',
  'Central Kitchen Cook',
  'Central Kitchen Director',
  'CENTRAL KITCHEN MANAGER',
  'Central Kitchen Section Lead',
  'Central Kitchen Sous Chef',
  'Centre Leader (Early Childhood Care And Education)',
  'CENTRE MANAGER',
  'CERAMIC SPRAY PAINTER',
  'CERAMICS TECHNOLOGIST',
  'CERTIFICATION ANALYST',
  'CERTIFIED PUBLIC ACCOUNTANT',
  'CHAIN STORE MANAGER',
  'CHAIRMAN',
  'CHAIRMAN (COMPANY)',
  'CHAIRMAN (STATUTORY BOARD)',
  'CHAIRMAN AND CHIEF EXECUTIVE OFFICER',
  'CHAIRMAN, COMPANY',
  'CHANGE MANAGEMENT DIRECTOR',
  'CHANGE MANAGEMENT MANAGER',
  'CHAPLAIN',
  'CHARGEHAND',
  'CHARTERED ACCOUNTANT',
  'Charterer (Sea Transport)',
  'Chartering Broker',
  'CHARTERING EXECUTIVE',
  'CHARTERING MANAGER',
  'Chauffeur',
  'CHAUFFEUR, PRIVATE MOTOR CAR',
  'CHECKER, FOOD (CATERING SERVICE)',
  'CHECKER, MECHANICAL PRODUCTS (QUALITY ASSURANCE)',
  'CHEF',
  'CHEF DE CUISINE',
  'CHEF DE CUISINE (RESTAURANT)',
  'CHEF DE PARTIE',
  'CHEF DE PARTIE (RESTAURANT)',
  'CHEF DE RANG',
  'CHEF SUPERVISOR',
  'CHEMICAL ANALYST',
  'CHEMICAL ENGINEER',
  'CHEMICAL ENGINEER (GENERAL)',
  'CHEMICAL ENGINEER (PETROCHEMICALS)',
  'CHEMICAL ENGINEER (PETROLEUM)',
  'CHEMICAL ENGINEERING ASSISTANT (GENERAL)',
  'CHEMICAL ENGINEERING ASSISTANT (PETROCHEMICALS)',
  'CHEMICAL ENGINEERING TECHNICIAN',
  'CHEMICAL ENGINEERING TECHNICIAN (GENERAL)',
  'CHEMICAL ENGINEERING TECHNICIAN (PETROCHEMICALS)',
  'CHEMICAL GROUT ENGINEER',
  'CHEMICAL INFORMATION SPECIALIST',
  'CHEMICAL MANAGER',
  'CHEMICAL MIXING MACHINE OPERATOR',
  'CHEMICAL PROCESS ENGINEER',
  'CHEMICAL PROCESS ENGINEERING TECHNICIAN',
  'CHEMICAL PROCESSING AND PRODUCTS PLANT AND MACHINE OPERATOR',
  'CHEMICAL SALES MANAGER',
  'CHEMICAL SUPERVISOR',
  'CHEMICAL TECHNICIAN',
  'Chemist',
  'CHEMIST (GENERAL)',
  'CHEMIST, FOOD',
  'CHEMIST, METALLURGICAL',
  'CHEMIST, PAINT',
  'CHEMIST, PETROLEUM',
  'CHEMIST, PHARMACEUTICAL',
  'CHEMIST, PLASTICS',
  'CHEMIST, POLYMERS',
  'CHEMIST, QUALITY CONTROL',
  'CHEMIST, RUBBER',
  'CHEMISTRY TECHNICIAN',
  'CHEMISTRY TUTOR (PRIVATE TUITION)',
  'CHESS COACH',
  'CHIEF ACMV ENGINEER',
  'CHIEF ADMINISTRATIVE OFFICER',
  'Chief Audit Executive',
  'CHIEF CHEF',
  'CHIEF COMMERCIAL OFFICER (CCO)',
  'CHIEF COMPLIANCE OFFICER',
  'CHIEF COOK',
  'CHIEF DATA OFFICER (IT)',
  'CHIEF EDITOR',
  'CHIEF EDITOR (NEWSPAPER)',
  'CHIEF ENGINEER',
  'Chief Engineer (Precision Engineering)',
  'Chief Engineer (Sea Transport)',
  'CHIEF ENGINEER (SHIP)',
  'CHIEF ENGINEER OFFICER (SHIP)',
  "CHIEF ENGINEER, SHIP'S",
  'CHIEF EXECUTIVE (COMPANY)',
  'CHIEF EXECUTIVE CHEF',
  'CHIEF EXECUTIVE OFFICER',
  'CHIEF EXECUTIVE OFFICER (BANKING INDUSTRY)',
  'CHIEF EXECUTIVE OFFICER (CEO) (COMPANY)',
  'Chief Executive Officer (Logistics)',
  'CHIEF EXECUTIVE OFFICER (MANUFACTURING INDUSTRY)',
  'CHIEF EXECUTIVE OFFICER (MARINE INDUSTRY)',
  'CHIEF EXECUTIVE OFFICER (MEDIA INDUSTRY)',
  'Chief Executive Officer (Retail)',
  'CHIEF FIELD ENGINEER',
  'CHIEF FINANCE MANAGER',
  'CHIEF FINANCE OFFICER (CFO)',
  'CHIEF FINANCIAL OFFICER',
  'CHIEF GEOLOGIST',
  'CHIEF HUMAN RESOURCE (HR) OFFICER',
  'CHIEF HUMAN RESOURCE OFFICER',
  'CHIEF INFORMATION OFFICER',
  'CHIEF INFORMATION OFFICER (IT)',
  'Chief Information Officer / Chief Technology Officer / Chief Security Officer',
  'Chief Information Security Officer',
  'CHIEF INVESTMENT OFFICER',
  'CHIEF JOINTER',
  'CHIEF LEGAL COUNSEL',
  'CHIEF MARKETING OFFICER',
  'CHIEF MARKETING OFFICER (CMO)',
  'CHIEF MATE',
  'CHIEF OPERATING OFFICER',
  'CHIEF OPERATING OFFICER (COO)',
  'Chief Operating Officer / General Manager',
  'CHIEF OPERATION OFFICER',
  'CHIEF PRIEST',
  'CHIEF REPRESENTATIVE',
  'CHIEF REPRESENTATIVE OFFICER',
  'CHIEF RIDING INSTRUCTOR',
  'CHIEF SECRETARY',
  'CHIEF SECURITY OFFICER',
  'CHIEF STEWARD',
  'CHIEF STEWARD (HOTEL)',
  'CHIEF STEWARD (SHIP)',
  'CHIEF STRATEGY OFFICER',
  'CHIEF SURVEYOR',
  'CHIEF TECHNICIAN',
  'CHIEF TECHNOLOGY OFFICER',
  'CHIEF TECHNOLOGY OFFICER (IT)',
  'Child / After School Care Centre Worker',
  'Child / Youth Residential Care Assistant',
  'CHILD CARE ASSISTANT',
  'CHILD CARE INSPECTOR',
  'CHILD CARE SERVICES MANAGER',
  'CHILD CARE TEACHER',
  'CHILD CARE WORKER',
  'CHILDCARE TEACHER',
  'CHILLER ROOM TECHNICIAN',
  'CHINESE HERB DISPENSER',
  'CHINESE HERBALIST',
  'CHINESE PHYSICIAN',
  'CHINESE PHYSICIAN (ACUPUNCTURIST)',
  'CHIROPODIST',
  'CHIROPRACTOR',
  'CHOREOGRAPHER',
  'CIGARETTE MAKING MACHINE OPERATOR',
  'CINEMA EXECUTIVE',
  'CINEMA MANAGER',
  'CINEMA PROJECTIONIST',
  'CINEMATOGRAPHER',
  'CIRCULATION REPRESENTATIVE',
  'CIVIL & STRUCTURAL DESIGN ENGINEER',
  'CIVIL & STRUCTURAL DESIGNER',
  'CIVIL & STRUCTURAL ENGINEER',
  'Civil / Structural Engineering Draughtsman',
  'CIVIL CONSTRUCTION PROJECT ENGINEER',
  'CIVIL DRAFTSMAN',
  'CIVIL ENGINEER',
  'CIVIL ENGINEER (AERODROME CONSTRUCTION)',
  'CIVIL ENGINEER (BRIDGE CONSTRUCTION)',
  'CIVIL ENGINEER (BUILDING CONSTRUCTION)',
  'CIVIL ENGINEER (DREDGING)',
  'CIVIL ENGINEER (GENERAL)',
  'CIVIL ENGINEER (HIGHWAY AND STREET CONSTRUCTION)',
  'CIVIL ENGINEER (HYDRAULICS)',
  'CIVIL ENGINEER (PILING)',
  'CIVIL ENGINEER (RAILWAY CONSTRUCTION)',
  'CIVIL ENGINEER (ROAD CONSTRUCTION)',
  'CIVIL ENGINEER (SANITARY)',
  'CIVIL ENGINEER (SEWERAGE)',
  'CIVIL ENGINEER (SOIL MECHANICS)',
  'CIVIL ENGINEER (TUNNEL CONSTRUCTION)',
  'CIVIL ENGINEER, ASSISTANT',
  'CIVIL ENGINEERING ASSISTANT (GENERAL)',
  'CIVIL ENGINEERING CONSTRUCTION SUPERVISOR',
  'CIVIL ENGINEERING CONSTRUCTION SUPERVISOR AND GENERAL FOREMAN',
  'CIVIL ENGINEERING CONSULTANT',
  'CIVIL ENGINEERING CONSULTANT (GENERAL)',
  'CIVIL ENGINEERING DRAFTER',
  'CIVIL ENGINEERING DRAUGHTSMAN',
  'Civil Engineering Technician',
  'CIVIL ENGINEERING TECHNICIAN (GENERAL)',
  'CIVIL MANAGER',
  'CIVIL SUPERINTENDENT',
  'CIVIL SUPERVISOR',
  'Cladding / Curtain Wall Erector',
  'CLADDING AND CURTAIN WALL ERECTOR',
  'CLAIMS ADJUSTER',
  'CLAIMS ADMINISTRATOR',
  'CLAIMS CLERK, INSURANCE',
  'CLAIMS HANDLER',
  'CLAIMS MANAGER',
  'CLAIMS OFFICER',
  'CLAIMS SPECIALIST',
  'CLAIMS SUPERVISOR',
  'CLEANER',
  'CLEANER (RESTAURANT)',
  'CLEANER SUPERVISOR',
  'CLEANER, CARPET',
  'Cleaner, Residential Area',
  'CLEANING OPERATIONS MANAGER',
  'CLEANING SERVICES MANAGER',
  'Cleaning Supervisor',
  'CLEARANCE SPECIALIST',
  'CLEARING AND FORWARDING AGENT',
  'CLERICAL ASSISTANT',
  'CLERICAL OFFICER',
  'Clerical Supervisor',
  'CLERICAL SUPERVISOR (GENERAL)',
  'CLERK',
  'CLERK OF WORKS',
  'CLERK, ACCOUNTS',
  'CLERK, BILLING',
  'CLERK, BROKERAGE',
  "CLERK, BROKER'S (INSURANCE)",
  'CLERK, CASH ACCOUNTING',
  'CLERK, CHIEF (GENERAL OFFICE)',
  'CLERK, CODING (DATA PROCESSING)',
  'CLERK, CONVEYANCING',
  'CLERK, DESPATCHING AND RECEIVING',
  'CLERK, FINANCE',
  'CLERK, FREIGHT RECEIVING',
  'CLERK, GENERAL OFFICE',
  'CLERK, INSURANCE',
  'CLERK, INVENTORY (STOCK CONTROL)',
  'CLERK, INVOICE',
  'CLERK, LAW',
  'CLERK, OFFICE (GENERAL)',
  'CLERK, ORDER (MATERIAL PLANNING)',
  'CLERK, PLANNING (MATERIALS SUPPLY)',
  'CLERK, POLICY (INSURANCE)',
  'CLERK, POSTAL SERVICE COUNTER',
  'CLERK, PRODUCTION PLANNING',
  'CLERK, PURCHASING',
  'CLERK, RECORDS (PERSONNEL DEPARTMENT)',
  'CLERK, RESERVATIONS (TRAVEL AGENCY)',
  'CLERK, SALES',
  'CLERK, SHIPPING',
  'CLERK, SHIPPING (FREIGHT)',
  'CLERK, STOCK CONTROL',
  'CLERK, STOREROOM',
  'CLERK, STORES',
  'CLERK, TICKETING (TRAVEL AGENCY)',
  'CLERK, TRAFFIC (FREIGHT)',
  'CLERK, TRAVEL RESERVATIONS',
  'CLERK, WAREHOUSE',
  'CLIENT ACCOUNT SERVICE EXECUTIVE',
  'CLIENT ADVISOR',
  'CLIENT DATA MANAGEMENT EXECUTIVE',
  'CLIENT EXECUTIVE',
  'CLIENT MANAGER',
  'CLIENT RELATIONS OFFICER',
  'CLIENT RELATIONSHIP EXECUTIVE',
  'CLIENT RELATIONSHIP MANAGER',
  'CLIENT RELATIONSHIP OFFICER',
  'CLIENT SERVICE ASSISTANT',
  'CLIENT SERVICE CONSULTANT',
  'CLIENT SERVICE DIRECTOR',
  'CLIENT SERVICE REPRESENTATIVE',
  'CLIENT SERVICES MANAGER',
  'CLIENT SOLUTIONS DIRECTOR',
  'CLIENT SUPPORT ANALYST',
  'CLIMATOLOGIST',
  'CLINIC ASSISTANT',
  'CLINIC MANAGER',
  'CLINIC RECEPTIONIST',
  'CLINICAL AUDIOLOGIST',
  'CLINICAL CODER',
  'CLINICAL FELLOW (GENERAL MEDICAL PRACTICE)',
  'CLINICAL FELLOW (MEDICAL DOCTOR)',
  'CLINICAL LABORATORY TECHNICIAN',
  'CLINICAL MONITOR',
  'CLINICAL OPERATIONS DIRECTOR',
  'CLINICAL OPERATIONS MANAGER',
  'CLINICAL PSYCHOLOGIST',
  'CLINICAL QUALITY EXECUTIVE',
  'CLINICAL RESEARCH ASSOCIATE',
  'CLINICAL RESEARCH COORDINATOR',
  'CLINICAL RESEARCH FELLOW',
  'Clinical Research Professional',
  'CLINICAL TEAM LEAD',
  'CLINICAL TECHNICIAN',
  'CLINICAL TECHNOLOGIST',
  'CLOCK AND WATCH REPAIRER',
  'CLOTHES DESIGNER',
  'CLOTHES STORE MANAGER',
  'Club Floor Executive (Supervisor) (Hotel And Accommodation Services)',
  'Club Floor Manager (Supervisor) (Hotel And Accommodation Services)',
  'CLUB MANAGER',
  'CNC MACHINIST',
  'CNC PROGRAMMER',
  'CNC SPECIALIST',
  'CNC TECHNICIAN',
  'COACH DRIVER',
  'COACH, ATHLETIC',
  'COACH, GAMES',
  'COASTAL ENGINEER',
  'COATING ADVISOR',
  'COATING ENGINEER',
  'COATING TECHNICIAN',
  'COBBLER',
  'Coffee / Cocoa Bean Roasting And Grinding Machine Operator',
  'Coffee / Tea Blending Machine Operator',
  'COFFEE AND TEA TASTER',
  'COFFEE BEAN ROASTING MACHINE OPERATOR',
  'COFFEE HOUSE COOK',
  'COFFEE SHOP ASSISTANT',
  'COLLECTOR, PUBLIC OPINION',
  'COLOUR SPECIALIST',
  'COLUMNIST',
  'COMBINER OPERATOR (PAPERMAKING)',
  'COMISSIONING ENGINEER',
  'COMMENTATOR, SPORTS',
  'COMMERCIAL AIRLINE PILOT',
  'COMMERCIAL ARTIST',
  'COMMERCIAL BID MANAGER, ASIA PACIFIC',
  'COMMERCIAL DIRECTOR',
  'COMMERCIAL DIVER',
  'COMMERCIAL EXECUTIVE',
  'COMMERCIAL FINANCE ADVISOR',
  'COMMERCIAL MANAGER',
  'COMMERCIAL MANAGER (MANUFACTURING OR MINING COMPANY)',
  'COMMERCIAL OFFICER',
  'COMMERCIAL PHOTOGRAPHER',
  'COMMERCIAL PRODUCTS DESIGNER',
  'COMMERCIAL REPRESENTATIVE',
  'COMMERCIAL SCHOOL TEACHER',
  'COMMERCIAL SCHOOL TEACHER (HIGHER EDUCATION)',
  'COMMERCIALS, DIRECTOR OF',
  'COMMISSIONING CONSULTANT',
  'COMMISSIONING COORDINATOR',
  'COMMISSIONING ENGINEER',
  'COMMISSIONING LEAD',
  'COMMISSIONING OPERATOR',
  'COMMISSIONING SUPERVISOR',
  'COMMODITIES BROKER',
  'Commodities Derivatives Broker',
  'COMMODITIES TRADER',
  'COMMODITIES TRADER (FINANCIAL)',
  'COMMODITIES TRADING ADVISOR',
  'COMMODITY BROKER',
  'COMMODITY FUTURES BROKER',
  'COMMODITY FUTURES POOL OPERATOR',
  'COMMODITY TRADER (FINANCIAL)',
  'COMMODITY TRADING ADVISOR',
  'COMMUNICATION CONSULTANT',
  'COMMUNICATION DESIGNER',
  'COMMUNICATION ENGINEER',
  'COMMUNICATION MANAGER',
  'COMMUNICATION SPECIALIST',
  'COMMUNICATIONS ASSISTANT',
  'COMMUNICATIONS EXECUTIVE',
  'COMMUNICATIONS PLANNING DIRECTOR',
  'COMMUNITY MANAGER',
  'COMMUNITY PARTNERSHIP OFFICER',
  'COMMUNITY SERVICES EXECUTIVE',
  'COMMUNITY SOCIAL WORKER',
  'COMPANY ACCOUNTANT',
  'COMPANY CHAIRMAN',
  'COMPANY DIRECTOR',
  'COMPANY SECRETARY (EXECUTIVE)',
  'COMPENSATION & BENEFITS MANAGER',
  'COMPERE',
  'COMPERE, STAGE',
  'COMPETITIVE INTELLIGENCE MANAGER',
  'COMPLIANCE & RISK MANAGEMENT OFFICER',
  'COMPLIANCE ANALYST',
  'COMPLIANCE ASSISTANT',
  'COMPLIANCE MANAGER',
  'COMPLIANCE MANAGER (FINANCIAL)',
  'COMPLIANCE OFFICER',
  'COMPLIANCE OFFICER (FINANCIAL)',
  'COMPLIANCE SPECIALIST',
  'COMPONENTS ENGINEER',
  'COMPOSER, MUSIC',
  'COMPOSITION TILE LAYER',
  'COMPOSITOR',
  'COMPOUNDER (CHEMICAL AND RELATED PROCESSES)',
  'COMPRESSOR SERVICE TECHNICIAN',
  'COMPUTER ANALYST',
  'COMPUTER AND INFORMATION SYSTEMS MANAGER',
  'Computer And Related Electronic Equipment Mechanic',
  'COMPUTER AND RELATED EQUIPMENT FITTER',
  'COMPUTER AND RELATED EQUIPMENT MECHANIC',
  'COMPUTER CONSULTANT',
  'COMPUTER ENGINEER',
  'COMPUTER GRAPHIC ARTIST (CG ARTIST)',
  'COMPUTER NUMERICAL CONTROL (CNC) PROGRAMMER',
  'COMPUTER NUMERICAL CONTROL PROGRAMMER',
  'COMPUTER OPERATIONS AND NETWORK MANAGER',
  'COMPUTER OPERATOR',
  'COMPUTER PROGRAMMER',
  'COMPUTER SALES ENGINEER',
  'COMPUTER SALES REPRESENTATIVE',
  'COMPUTER SCIENTIST',
  'COMPUTER SYSTEM HARDWARE ANALYST',
  'COMPUTER SYSTEMS ADMINISTRATOR',
  'COMPUTER SYSTEMS ENGINEER',
  'COMPUTER SYSTEMS OPERATION OFFICER',
  'Computer Systems Operator',
  'COMPUTER SYSTEMS TECHNICIAN',
  'COMPUTER TEACHER (COMPUTER TRAINING SCHOOL)',
  'COMPUTER TECHNICIAN',
  'Computer Technician (Including It User Helpdesk Technician)',
  'COMPUTER-AIDED DESIGN (CAD) DRAFTER',
  'COMPUTER-ASSISTED DESIGN / COMPUTER-ASSISTED MANUFACUTRING (CAD/CAM) PROGRAMMER',
  'CONCEPT ARTIST',
  'CONCEPT DESIGN DEVELOPER',
  'CONCIERGE',
  'CONCIERGE (APARTMENT HOUSE)',
  'CONCIERGE, HOTEL',
  'CONCRETE FORM WORKER',
  'Concrete Mix Truck Driver',
  'CONCRETE MIXER OPERATOR',
  'CONCRETE MIXING PLANT OPERATOR',
  'CONCRETE PUMP OPERATOR',
  'CONCRETE REINFORCING IRON WORKER',
  'CONCRETE TRUCK DRIVER',
  'CONDITION MONITORING ENGINEER',
  'CONDOMINIUM MANAGER',
  'Conductor / Director Of Orchestra / Band / Choir',
  'CONFECTIONERY MAKER',
  'CONFECTIONERY SUPERVISOR',
  'CONFERENCE MANAGER',
  'CONFERENCE PRODUCER',
  'CONFERENCE SALES EXECUTIVE',
  'CONSERVATOR (ART WORKS)',
  'CONSTABLE, POLICE',
  'CONSTRUCTION CARPENTER',
  'CONSTRUCTION CONSULTANT',
  'CONSTRUCTION DIRECTOR',
  'CONSTRUCTION DIVER',
  'CONSTRUCTION ENGINEER',
  'CONSTRUCTION GENERAL FOREMAN',
  'CONSTRUCTION INSPECTOR',
  'CONSTRUCTION JOINER',
  'CONSTRUCTION LABOURER',
  'CONSTRUCTION MACHINERY MECHANIC',
  'CONSTRUCTION MANAGER',
  'CONSTRUCTION PLANT AND EQUIPMENT INSPECTOR',
  'CONSTRUCTION PROJECT MANAGER',
  'CONSTRUCTION SITE ENGINEER',
  'CONSTRUCTION SITE MANAGER',
  'CONSTRUCTION SITE SUPERVISOR',
  'CONSTRUCTION SUPERINTENDENT',
  'CONSTRUCTION SUPERVISOR',
  'CONSTRUCTION TECHNICIAN',
  'CONSTRUCTION WORKER',
  'CONSTRUCTION WORKER-CUM-DRIVER',
  'CONSTRUCTIONAL STEEL ERECTOR',
  'CONSULAR ASSISTANT',
  'CONSULAR OFFICIAL, FOREIGN',
  'CONSULTANT',
  'CONSULTANT ENGINEER',
  'Consultant, Safety And Security',
  'CONSULTING ANALYST',
  'CONSULTING ENGINEER',
  'CONSULTING MANAGER',
  'CONSUMER MARKET INSIGHT ASSISTANT MANAGER',
  'CONSUMER MARKET INSIGHT DIRECTOR',
  'CONSUMER MARKET INSIGHT MANAGER',
  'CONTACT CENTRE INFORMATION CLERK',
  'CONTACT ENGINEER',
  'CONTAINER CONTROLLER',
  'CONTAINER DRIVER',
  'CONTAINER FILLING MACHINE OPERATOR (EXCEPT COMPRESSED AND LIQUEFIED GAS)',
  'CONTAINER SURVEYOR',
  'CONTAINER TRUCK DRIVER',
  'CONTENT DEVELOPMENT ASSOCIATE',
  'CONTRACT ENGINEER',
  'CONTRACT MANAGER',
  'CONTRACT OPERATIONS DIRECTOR',
  'CONTRACTOR',
  'CONTRACTOR, EMPLOYMENT',
  'CONTRACTOR, RENOVATION (WORKING PROPRIETOR)',
  'CONTRACTOR, ROAD BUILDING (WORKING PROPRIETOR)',
  'CONTRACTS ENGINEER',
  'Contracts Manager',
  'CONTRACTS OFFICER',
  'CONTROL AND INSTRUMENTATION (C&I) TECHNICIAN',
  'CONTROL AND INSTRUMENTATION ENGINEER',
  'CONTROL ENGINEER',
  'CONTROL EXECUTIVE',
  'CONTROL MANAGER',
  'CONTROL OPERATOR',
  'CONTROL SPECIALIST',
  'CONTROL SYSTEM ENGINEER',
  'CONTROL VALVE SPECIALIST',
  'CONTROLLER, FINANCIAL',
  'CONVENTION CO-ORDINATOR',
  'CONVENTION MANAGER',
  'CONVEYANCING CLERK',
  'COOK',
  'Cook (Food Services)',
  'COOK SUPERVISOR',
  'COOK, CAFETERIA',
  'COOK, CANTEEN',
  'COOK, COFFEE HOUSE',
  'COOK, HOTEL',
  'COOK, PASTRY (RESTAURANT)',
  'COOK, PRIVATE SERVICE',
  'COOK, RESTAURANT',
  'CO-ORDINATING CLERK (PRODUCTION PLANNING)',
  'COORDINATOR',
  'CO-ORDINATOR, EXHIBITION',
  'CO-ORDINATOR, PRODUCTION (COMPUTER OPERATIONS)',
  'CO-ORDINATOR, TECHNICAL SALES',
  'CO-PILOT',
  'COPYWRITER',
  'COPYWRITER, ADVERTISING',
  'CORN MILLING MACHINE OPERATOR',
  'CORPORATE ACCOUNT EXECUTIVE',
  'CORPORATE ACCOUNT MANAGER',
  'CORPORATE BRAND MARKETING MANAGER',
  'CORPORATE COMMUNICATIONS DIRECTOR',
  'CORPORATE COMMUNICATIONS MANAGER',
  'CORPORATE COMMUNICATIONS OFFICER',
  'CORPORATE COMPLIANCE DIRECTOR',
  'CORPORATE CONSULTANT',
  'CORPORATE CONTRACTS MANAGER',
  'CORPORATE DEALER',
  'CORPORATE DEVELOPMENT MANAGER',
  'CORPORATE EXECUTIVE',
  'CORPORATE FINANCE ASSOCIATE',
  'CORPORATE FINANCE EXECUTIVE',
  'CORPORATE FINANCE MANAGER',
  'CORPORATE FINANCE SENIOR ASSOCIATE',
  'CORPORATE PLANNING DIRECTOR',
  'CORPORATE PLANNING EXECUTIVE',
  'CORPORATE PLANNING MANAGER',
  'CORPORATE PLANNING SENIOR MANAGER',
  'CORPORATE RECOVERY ASSOCIATE',
  'CORPORATE RECOVERY SENIOR',
  'CORPORATE RESEARCH MANAGER',
  'CORPORATE RESOURCE EXECUTIVE',
  'CORPORATE SALES EXECUTIVE',
  'CORPORATE SALES MANAGER',
  'CORPORATE SALES REPRESENTATIVE',
  'CORPORATE SECRETARIAL ASSISTANT',
  'CORPORATE SECRETARIAL EXECUTIVE',
  'CORPORATE SECRETARIAL MANAGER',
  'CORPORATE SERVICES ASSISTANT',
  'CORPORATE SERVICES MANAGER',
  'CORPORATE STRATEGY PLANNER',
  'CORPORATE SUPPORT OFFICER',
  'CORPORATE TRAINER',
  'CORPORATE VICE PRESIDENT',
  'CORRESPONDENT, NEWSPAPER',
  'CORRUGATING MACHINE OPERATOR (PAPERMAKING)',
  'COSMETOLOGIST',
  'COST ACCOUNTANT',
  'COST ACCOUNTING ASSISTANT',
  'COST ACCOUNTING MANAGER',
  'COST ANALYST',
  'COST AND WORKS ACCOUNTANT',
  'COST CONTROL ENGINEER',
  'COST CONTROL MANAGER',
  'COST CONTROLLER',
  'COST ENGINEER',
  'COST ESTIMATING MANAGER',
  'COST ESTIMATOR',
  'COST SCHEDULE ENGINEER',
  'COSTING OFFICER',
  'COUNSELLOR',
  'COUNSELLOR (FAMILY)',
  'COUNSELLOR, EMPLOYMENT',
  'COUNSELLOR, STUDENT VOCATIONAL',
  'COUNTRY MANAGER',
  'COURIER (DESPATCH)',
  'COURIER SERVICE SUPERVISOR',
  'COURSE CONSULTANT',
  'COURSE MANAGER',
  'COURT REPORTER',
  'CRAFTSMAN',
  'CRAFTSTEACHER (OTHER THAN SECONDARY AND VOCATIONAL SCHOOL)',
  'CRANE & HOIST OPERATOR',
  'Crane / Hoist Operator (General)',
  'CRANE BARGE CHIEF ENGINEER',
  'CRANE ERECTION SUPERVISOR',
  'CRANE OPERATOR (FLOATING CRANE)',
  'CRANE OPERATOR (MOBILE CRANE)',
  'Crane Operator (Port)',
  'CRANE OPERATOR (TOWER CRANE)',
  'CRANE/HOIST OPERATOR (EXCLUDING PORT)',
  'CRAWLER CRANE OPERATOR',
  'CRAWLER-CRANE OPERATOR',
  'CREASING AND CUTTING PRESS OPERATOR (PAPER BOX)',
  'CREATIVE ADVERTISING PROFESSIONAL',
  'CREATIVE ASSISTANT',
  'CREATIVE CONSULTANT',
  'CREATIVE DESIGN PROGRAMMER',
  'CREATIVE DESIGNER',
  'CREATIVE DIRECTOR',
  'Creative Director (Advertising)',
  'CREATIVE EXECUTIVE',
  'CREATIVE GROUP HEAD',
  'CREATIVE LIFESTYLIST',
  'CREATIVE MANAGER',
  'CREDIT ADMINISTRATIVE ASSISTANT',
  'CREDIT ADMINISTRATIVE OFFICER',
  'CREDIT ANALYST',
  'Credit And Loans Officer',
  'CREDIT CONTROL ASSISTANT',
  'CREDIT CONTROL CLERK',
  'CREDIT CONTROL EXECUTIVE',
  'CREDIT CONTROL MANAGER',
  'CREDIT CONTROL SENIOR SUPERVISOR',
  'CREDIT CONTROLLER',
  'CREDIT MANAGER',
  'CREDIT OFFICER',
  'CREDIT PORTFOLIO ANALYST',
  'CREDIT RESEARCH ANALYST',
  'CREDIT RISK MANAGER',
  'CREDIT RISK OFFICER',
  'CREDIT RISK REVIEWER',
  'CREDIT SPECIALIST',
  'CREDIT STRATEGIST',
  'CREDIT TRADER',
  'CREDIT UNDERWRITER',
  'CREW COORDINATOR',
  'CREW LEADER',
  'CREW MANAGER',
  'CREW MANNING EXECUTIVE',
  'CREW, SHIP',
  'Crewing Executive (Ship)',
  'CRIME REPORTER',
  'CRUDE OIL MANAGER',
  'CRUDE OIL TRADER',
  'CUISINE CHEF',
  'CUISINE COORDINATOR',
  'CULINARY CHEF',
  'CULINARY CONSULTANT',
  'CULINARY DIRECTOR',
  'CULINARY HEAD CHEF',
  'CULINARY INSTRUCTOR',
  'CULINARY TRAINER',
  'CURATOR',
  'CURATOR, ART GALLERY',
  'CURATOR, MUSEUM',
  'CURER, MEAT',
  'CURRENCY SPECIALIST',
  'CURRICULUM DEVELOPER',
  'CURRICULUM SPECIALIST',
  'CURTAIN DESIGNER',
  'CUSTOM OFFICER',
  'CUSTOMER ACCEPTANCE MANAGER',
  'CUSTOMER ACCOUNT EXECUTIVE',
  'CUSTOMER ACQUISITION EXECUTIVE',
  'CUSTOMER BUSINESS EXECUTIVE',
  'CUSTOMER CALL CENTRE OFFICER',
  'CUSTOMER CARE MANAGER',
  'CUSTOMER CARE OFFICER',
  'CUSTOMER DEVELOPMENT MANAGER',
  'CUSTOMER DEVELOPMENT VICE PRESIDENT',
  'CUSTOMER FINANCIAL SERVICE REPRESENTATIVE',
  'CUSTOMER FINANCIAL SERVICES MANAGER',
  'CUSTOMER LIAISON OFFICER',
  'CUSTOMER RELATIONS EXECUTIVE (ENTERTAINMENT OUTLET)',
  'CUSTOMER RELATIONS MANAGER',
  'CUSTOMER RELATIONS OFFICER',
  'CUSTOMER RETENTION OFFICER',
  'CUSTOMER SALES EXECUTIVE',
  'CUSTOMER SALES MANAGER',
  'CUSTOMER SALES SUPPORT ENGINEER',
  'CUSTOMER SERVICE ADMINISTRATOR',
  'CUSTOMER SERVICE ADVISER',
  'CUSTOMER SERVICE ASSISTANT',
  'CUSTOMER SERVICE CLERK',
  'CUSTOMER SERVICE CONSULTANT',
  'CUSTOMER SERVICE COORDINATOR',
  'CUSTOMER SERVICE ENGINEER',
  'CUSTOMER SERVICE EXECUTIVE',
  'CUSTOMER SERVICE MANAGER',
  'Customer Service Manager (Logistics)',
  'CUSTOMER SERVICE OFFICER',
  'Customer Service Officer (Logistics)',
  'CUSTOMER SERVICE OPERATIONS DIRECTOR',
  'CUSTOMER SERVICE OPERATIONS MANAGER',
  'CUSTOMER SERVICE REPRESENTATIVE',
  'CUSTOMER SERVICE SPECIALIST',
  'CUSTOMER SERVICE SUPERVISOR',
  'CUSTOMER SERVICE TEAM LEADER',
  'CUSTOMER SUPPORT ENGINEER',
  'CUSTOMER SUPPORT MANAGER',
  'CUSTOMER SUPPORT REPRESENTATIVE',
  'CUSTOMS & DOCUMENT OFFICER',
  'CUSTOMS MANAGERIAL OFFICER',
  'CUTTER AND FINISHER, STONE',
  'CUTTER AND POLISHER, JEWEL',
  'CUTTER, GARMENT',
  'CUTTER, GLASS',
  'CUTTER, MEAT',
  'Cyber Risk Manager',
  'CYBERSECURITY RESEARCHER',
  'CYCLIST, MOTOR (DELIVERY MAN)',
  'CYLINDER PRESSMAN (PRINTING)',
  'CYTOTECHNOLOGIST',
  'DANCE ARTISTE',
  'DANCE CHOREOGRAPHER',
  'DANCE INSTRUCTOR',
  'Dance Instructor (Extracurriculum)',
  'DANCER',
  'DANCING TEACHER (PRIVATE)',
  'Data Analyst',
  'DATA ARCHITECT MANAGER',
  'DATA CENTER OPERATOR',
  'DATA CENTRE ENGINEER',
  'DATA COMMUNICATION ANALYST',
  'DATA COORDINATOR',
  'Data Engineer',
  'DATA ENTRY CLERK',
  'DATA ENTRY COORDINATOR',
  'DATA ENTRY MACHINE OPERATOR',
  'DATA ENTRY OPERATOR',
  'DATA NETWORK ANALYST',
  'DATA NETWORK ENGINEERING ANALYST',
  'DATA PROCESSING CONTROL CLERK',
  'DATA PROCESSING EXECUTIVE',
  'DATA PROCESSING MANAGER',
  'DATA QUALITY ASSISTANT',
  'DATA RESEARCH EXECUTIVE',
  'DATA SCIENTIST',
  'DATA SECURITY ANALYST',
  'DATA SPECIALIST',
  'DATA WAREHOUSING ANALYST',
  'DATA WAREHOUSING CONSULTANT',
  'DATABASE ADMINISTRATOR',
  'DATABASE ANALYST',
  'Database And Network Professional Nec',
  'DATABASE ARCHITECT',
  'DATABASE CONSULTANT',
  'DATABASE COORDINATOR',
  'DATABASE DESIGNER',
  'DATABASE DEVELOPER',
  'DATABASE ENGINEER',
  'DATABASE EXECUTIVE',
  'DATABASE MANAGER',
  'DATABASE PROGRAMMER',
  'DATABASE SPECIALIST',
  'DATAWAREHOUSE CONSULTANT',
  'DATAWAREHOUSING ANALYST',
  'DAY CARE TEACHER',
  'DCS CONTROLLER',
  'DEALER, FOREIGN EXCHANGE',
  'DEALER, SECURITIES',
  'DEAN',
  'DEAN OF RESEARCH',
  'DECK OFFICER, SHIP',
  'Deckhand (Including Lighterman)',
  'Decorator And Related Worker (Eg Window Dresser And Set Designer)',
  'DECORATOR, INTERIOR',
  'DEEP-BORING MACHINE OPERATOR',
  'DELEGATE SALES EXECUTIVE',
  'DELEGATE SALES MANAGER',
  'DELIVERY DRIVER',
  'DELIVERY MAN',
  'DELIVERY MAN, MOTOR-CYCLE',
  'DEMAND GENERATION MANAGER',
  'DEMAND PLANNING ANALYST',
  'DEMI CHEF',
  'DEMOLITION WORKER',
  'DEMONSTRATOR',
  'Dental / Oral Health Therapist',
  'DENTAL ASSISTANT',
  'DENTAL CLINIC ADMINISTRATOR',
  'DENTAL HYGIENISTS',
  'DENTAL NURSE',
  'DENTAL NURSE ASSISTANT',
  'DENTAL NURSE, SCHOOL',
  'DENTAL PROSTHESIS MAKER AND REPAIRER',
  'DENTAL SALES REPRESENTATIVE',
  'DENTAL SUPPORT OFFICER',
  'DENTAL SURGEON',
  'DENTAL TECHNICIAN',
  'Dentist (General)',
  'DENTIST, GENERAL',
  'DENTIST, SPECIALISED',
  "DENTIST'S RECEPTIONIST",
  'DEPARTMENT HEAD',
  'DEPARTMENT MANAGER',
  'DEPARTMENTAL STORE MANAGER',
  'DEPLOYMENT PROJECT LEADER',
  'DEPLOYMENT PROJECT MANAGER',
  'DEPOT SUPERINTENDENT, ROAD TRANSPORT',
  'DEPUTY ACCOUNTS MANAGER',
  'DEPUTY BUREAU CHIEF',
  'DEPUTY CHIEF EXECUTIVE OFFICER',
  'DEPUTY CHIEF FINANCIAL OFFICER',
  'DEPUTY CHIEF INVESTMENT OFFICER',
  'DEPUTY CHIEF MARKETING OFFICER',
  'DEPUTY CHIEF OPERATING OFFICER',
  'DEPUTY CHIEF OPERATION OFFICER',
  'DEPUTY CHIEF REPRESENTATIVE OFFICER',
  'DEPUTY CHIEF TECHNOLOGY OFFICER',
  'DEPUTY DEPARTMENT MANAGER',
  'DEPUTY DIRECTOR',
  'DEPUTY DIVISION MANAGER',
  'DEPUTY EDITOR',
  'DEPUTY FINANCE DIRECTOR',
  'DEPUTY FOREIGN EDITOR',
  'DEPUTY GENERAL MANAGER',
  'Deputy General Manager (Marine And Offshore)',
  'DEPUTY HEAD',
  'DEPUTY HEAD OF COLLEGE (INTERNATIONAL SCHOOL)',
  'DEPUTY HEAD OF DEPARTMENT (COMMERCIAL SCHOOL)',
  'DEPUTY HEAD OF DEPARTMENT (INTERNATIONAL SCHOOL)',
  'DEPUTY HEAD OF ENGINEERING',
  'DEPUTY HEAD, UNIVERSITY',
  'DEPUTY MANAGER',
  'DEPUTY MANAGING DIRECTOR',
  'DEPUTY PRINCIPAL',
  'DEPUTY SENIOR PASTOR',
  'DEPUTY VICE PRESIDENT',
  'DERIVATIVES ANALYST',
  'DERIVATIVES BROKER',
  'DERIVATIVES TRADER',
  'DERRICKMAN (OIL AND GAS WELLS)',
  'DESIGN ARCHITECT',
  'DESIGN CONSULTANT',
  'DESIGN COORDINATOR',
  'DESIGN DIRECTOR',
  'DESIGN DRAFTSPERSON',
  'DESIGN ENGINEER',
  'DESIGN ENGINEER (MARINE)',
  'DESIGN EXECUTIVE',
  'DESIGN MANAGER',
  'DESIGN PROJECT MANAGER',
  'Design Section Manager',
  'DESIGN STRATEGIST',
  'DESIGNER',
  'DESIGNER, AUTOMATION',
  'DESIGNER, COMMERCIAL PRODUCTS',
  'DESIGNER, DATABASE',
  'DESIGNER, EXHIBITION',
  'DESIGNER, FASHION',
  'DESIGNER, FURNITURE',
  'DESIGNER, GARMENT',
  'DESIGNER, GRAPHIC',
  'DESIGNER, INDUSTRIAL PRODUCTS',
  'DESIGNER, INTEGRATED CIRCUIT',
  'DESIGNER, INTERIOR',
  'DESIGNER, INTERIOR DECORATION',
  'DESIGNER, JEWELLERY',
  'DESIGNER, PACKAGE',
  'DESIGNER, STAGE SET',
  'DESIGNER, SYSTEMS (COMPUTER)',
  'DESIGNER, TEXTILE',
  'DESKTOP ARTIST',
  'DESKTOP SUPPORT ENGINEER',
  'Despatch Worker',
  'DESPATCHING AND RECEIVING CLERK',
  'DESSERT CHEF',
  'DEVELOPMENT DIRECTOR',
  'DEVELOPMENT ENGINEER',
  'DEVELOPMENT SPECIALIST',
  'DEVICE ENGINEER',
  'DIAGNOSTIC ENGINEER',
  'DIESEL ENGINE MECHANIC (EXCEPT MOTOR VEHICLE)',
  'DIETITIAN',
  'DIGITAL ARTIST',
  'DIGITAL COMPUTER OPERATOR',
  'DIGITAL FORENSICS SPECIALIST',
  'Digital Illustrator',
  'DIGITAL IMAGING ARTIST',
  'DIGITAL MANAGER',
  'Digital Marketing Manager (Hotel And Accommodation Services)',
  'DIGITAL MARKETING STRATEGIST',
  'DIGITAL MEDIA EDITOR',
  'Digital Media Marketing Manager',
  'DIGITAL SYSTEMS ENGINEER',
  'DINING MANAGER',
  'DIPLOMATIC REPRESENTATIVE, FOREIGN',
  'DIRECTING MANAGER',
  'DIRECTOR',
  'DIRECTOR (BANKING INDUSTRY)',
  'DIRECTOR (GOVERNMENT DEPARTMENT)',
  'DIRECTOR (MANUFACTURING INDUSTRY)',
  'DIRECTOR (MARINE INDUSTRY)',
  'DIRECTOR (MEDIA INDUSTRY)',
  'Director (Stage, Film, Television And Radio)',
  'DIRECTOR OF BOARDING (INTERNATIONAL SCHOOL)',
  'DIRECTOR OF COLLEGE ADVANCEMENT (INTERNATIONAL SCHOOL)',
  'DIRECTOR OF COMMERCIALS',
  'DIRECTOR OF CORPORATE AND INTERNATIONAL RELATIONS',
  'DIRECTOR OF EDUCATION',
  'DIRECTOR OF EVENTS',
  'Director Of Events (Hotel And Accommodation Services)',
  'DIRECTOR OF ICT (COMMERCIAL SCHOOL)',
  'DIRECTOR OF ICT (INTERNATIONAL SCHOOL)',
  'DIRECTOR OF INFORMATION SYSTEMS',
  'DIRECTOR OF MEDIA RELATIONS',
  'DIRECTOR OF PHOTOGRAPHY (MOTION PICTURE)',
  'Director Of Revenue Management (Hotel And Accommodation Services)',
  'Director Of Sales (Hotel And Accommodation Services)',
  'Director Of Sales And Marketing (Hotel And Accommodation Services)',
  'DIRECTOR OF SPORT, EXPEDITIONS & ACTIVITIES (INTERNATIONAL SCHOOL)',
  'DIRECTOR, ART',
  'DIRECTOR, CLIENT SOLUTIONS',
  'DIRECTOR, COMPANY',
  'DIRECTOR, EXECUTIVE (COMPANY)',
  'DIRECTOR, FINANCIAL SYSTEMS',
  'DIRECTOR, FUNERAL',
  'DIRECTOR, MANAGING',
  'DIRECTOR, MUSIC',
  'DIRECTOR, PROJECT EXECUTION',
  'DIRECTOR, SERVICE DELIVERY',
  'DIRECTOR, SOFTWARE POLICY',
  'DIRECTOR, TELEVISION',
  'DIRECTOR-GENERAL',
  'DISC JOCKEY',
  'DISCIPLINE MASTER/MISTRESS',
  'Discotheque / Karaoke / Nightclub Manager',
  'DISHWASHER (RESTAURANT)',
  'Dispatch Operator',
  'DISPENSING OPTICIAN',
  'DISPLAY ARTIST',
  'DISTILLATION OPERATOR (CHEMICAL PROCESSES, EXCEPT PETROLEUM)',
  'DISTRIBUTED CONTROL SYSTEM ENGINEER',
  'DISTRIBUTION COORDINATOR',
  'DISTRIBUTION EXECUTIVE',
  'DISTRIBUTION MANAGER',
  'DISTRICT MANAGER',
  'DIVER, CONSTRUCTION',
  'DIVING SUPERVISOR',
  'DIVISION HEAD',
  'DIVISION MANAGER',
  'DIVISION MANAGING DIRECTOR',
  'DIVISIONAL DIRECTOR',
  'DIVISIONAL MANAGER',
  'DIVISIONAL OPERATING OFFICER',
  'DOCKMASTER, DRY DOCK',
  'DOCTOR (GENERAL MEDICAL PRACTICE)',
  'DOCTOR, FAMILY',
  'DOCUMENT CONTROL ASSISTANT',
  'DOCUMENT CONTROL MANAGER',
  'DOCUMENT CONTROL SUPERVISOR',
  'DOCUMENT CONTROLLER',
  'DOCUMENT PROCESSING OFFICER',
  'DOCUMENT SPECIALIST',
  'DOCUMENTATION ASSISTANT',
  'DOCUMENTATION SPECIALIST MANAGER',
  'DOG TRAINER',
  'DOOR-TO-DOOR SALESMAN',
  'DORMITORY SUPERVISOR',
  'DOSIMETRIST',
  'DOUGH MAKER (BREAD)',
  'DRAFT ENGINEER',
  'DRAFTING DESIGNER',
  'DRAFTING SUPERVISOR',
  'DRAFTSMAN',
  'DRAFTSPERSON',
  'DRAMATIC ACTOR',
  'DRAUGHTSMAN (GENERAL)',
  'DRAUGHTSMAN, ADVERTISEMENT',
  'DRAUGHTSMAN, ARCHITECTURAL',
  'DRAUGHTSMAN, CIVIL ENGINEERING',
  'DRAUGHTSMAN, ELECTRICAL',
  'DRAUGHTSMAN, INTERIOR DESIGN',
  'DRAUGHTSMAN, JIG, TOOL AND DIE',
  'DRAUGHTSMAN, MARINE ENGINEERING',
  'DRAUGHTSMAN, MECHANICAL',
  'DRAUGHTSMAN, STRUCTURAL ENGINEERING',
  'DRAWING COORDINATOR',
  'DREDGE OPERATOR',
  'DREDGER CREWMAN',
  'DREDGING ENGINEER',
  'DRESS DESIGNER',
  'DRESSER, MEAT (GENERAL)',
  'DRESSMAKER',
  'DRIER OPERATOR (CHEMICAL AND RELATED PROCESSES)',
  'DRILLER',
  'DRILLER AND BORER, WELL (EXCEPT OIL AND GAS WELLS)',
  'DRILLER, JACKHAMMER (MINE AND QUARRY)',
  'DRILLING MACHINE OPERATOR (METALWORKING)',
  'DRILLING MACHINE OPERATOR (MINE AND QUARRY)',
  'DRILLING SYSTEM ENGINEER',
  'DRIVER',
  'Driver Supervisor',
  'DRIVER, BULLDOZER',
  'DRIVER, BUS',
  'DRIVER, CAR (PRIVATE SERVICE)',
  'DRIVER, CAR DELIVERY',
  'DRIVER, COACH',
  'DRIVER, CONCRETE PAVING MACHINE',
  'DRIVER, CONTAINER TRUCK',
  'DRIVER, DUMPER',
  'DRIVER, EXCAVATING MACHINE',
  'DRIVER, LIFTING TRUCK',
  'DRIVER, LORRY',
  'DRIVER, PICK-UP',
  'DRIVER, POSTAL VAN',
  'DRIVER, PRIME MOVER',
  'DRIVER, REFUSE TRUCK',
  'DRIVER, ROAD ROLLER',
  'DRIVER, TANKER',
  'DRIVER, TRAILER TRUCK',
  'DRIVER, TRAIN',
  'DRIVER, TRUCK',
  'DRIVER, VAN',
  'DRIVER, WATER-TANK',
  'Driving Instructor / Tester',
  'DRIVING TESTER',
  'DRUG SAFETY ASSOCIATE',
  'DRUMMER',
  'DRY DOCK MASTER',
  'DTP ENGINEER',
  'DTP EXECUTIVE',
  'DUMPER DRIVER',
  'DUTY MANAGER',
  'Duty Manager (Hotel And Accommodation Services)',
  'EARLY YEARS TEACHER',
  'EARTH BORING MACHINE OPERATOR (CONSTRUCTION)',
  'EARTH MOVING EQUIPMENT MECHANIC',
  'EBUSINESS MANAGER',
  'ECOLOGIST',
  'E-COMMERCE ASSISTANT MANAGER',
  'E-COMMERCE ASSOCIATE',
  'E-Commerce Associate (Retail)',
  'E-Commerce Director (Retail)',
  'E-COMMERCE EXECUTIVE',
  'E-Commerce Executive (Retail)',
  'E-COMMERCE MANAGER',
  'E-Commerce Manager (Retail)',
  'Economist',
  'ECONOMIST (GENERAL)',
  'ECONOMIST (SPECIALISED)',
  'EDITOR',
  'Editor (Film/Tv)',
  'Editor (News And Periodicals)',
  'Editor (Radio, Television And Video)',
  'EDITOR, ART AND GRAPHIC',
  'EDITOR, ART AND PHOTO',
  'EDITOR, CONTINUITY AND SCRIPT',
  'EDITOR, DIGITAL MEDIA',
  'EDITOR, NEWS (NEWSPAPER)',
  'EDITOR, NEWSPAPER',
  'EDITOR, PHOTO',
  'EDITOR, RADIO, TELEVISION AND VIDEO',
  'EDITOR-IN-CHIEF, NEWSPAPER',
  'Educarer 1 (Early Childhood Care And Education)',
  'Educarer 2 (Early Childhood Care And Education)',
  'EDUCATION CONSULTANT',
  'EDUCATION COUNSELLOR',
  'EDUCATION DEVELOPMENT TRAINER',
  'EDUCATION MANAGER',
  'EDUCATION METHODS ADVISER',
  'EDUCATION OFFICER',
  'EDUCATION TECHNOLOGY ASSISTANT',
  'EDUCATIONAL PSYCHOLOGIST',
  'ELECTRIC CABLE JOINTER',
  'ELECTRIC CIRCUIT DESIGNER',
  'ELECTRIC POWER ENGINEER',
  'ELECTRIC POWER LINEMAN',
  'Electrical / Electronic Products Quality Checker And Tester',
  'Electrical / Electronics Draughtsman',
  'ELECTRICAL AND ELECTRONIC INSTALLATION AND REPAIR (EXCLUDING LIFT) GENERAL FOREMAN',
  'ELECTRICAL AND ELECTRONIC INSTALLATION AND REPAIR (EXCLUDING LIFT) SUPERVISOR',
  'ELECTRICAL AND ELECTRONICS ENGINEER',
  'ELECTRICAL AND INSTRUMENT TECHNICIAN',
  'ELECTRICAL AND INSTRUMENTATION ENGINEER',
  'ELECTRICAL AND INSTRUMENTATION INSPECTOR',
  'ELECTRICAL AND INSTRUMENTATION SUPERVISOR',
  'ELECTRICAL AND INSTRUMENTATION TECHNICIAN',
  'ELECTRICAL ARCHITECT',
  'ELECTRICAL COMMISSIONING ENGINEER',
  'ELECTRICAL COMMISSIONING TECHNICIAN',
  'ELECTRICAL DESIGN ENGINEER',
  'ELECTRICAL DESIGNER',
  'ELECTRICAL DRAFTER',
  'ELECTRICAL DRAUGHTSMAN',
  'ELECTRICAL ELEVATOR AND RELATED EQUIPMENT FITTER',
  'ELECTRICAL ELEVATOR INSTALLATION SUPERVISOR',
  'ELECTRICAL ENGINEER',
  'ELECTRICAL ENGINEER (GENERAL)',
  'ELECTRICAL ENGINEER (POWER DISTRIBUTION AND TRANSMISSION)',
  'ELECTRICAL ENGINEER (POWER GENERATION)',
  'ELECTRICAL ENGINEER, ASSISTANT',
  'ELECTRICAL ENGINEERING ASSISTANT (GENERAL)',
  'ELECTRICAL ENGINEERING TECHNICIAN',
  'ELECTRICAL ENGINEERING TECHNICIAN (GENERAL)',
  'ELECTRICAL ENGINEERING TECHNICIAN (HIGH VOLTAGE)',
  'Electrical Equipment / Component Assembler',
  'ELECTRICAL EQUIPMENT ASSEMBLER',
  'ELECTRICAL EQUIPMENT ENGINEER',
  'ELECTRICAL FITTER',
  'ELECTRICAL FITTER (GENERAL)',
  'ELECTRICAL FITTER (REFRIGERATION AND AIR-CONDITIONING EQUIPMENT)',
  'Electrical Household Appliance Repairer',
  'ELECTRICAL INSTALLATION SUPERVISOR',
  'ELECTRICAL INSTRUMENT ENGINEER',
  'ELECTRICAL INSTRUMENT FITTER',
  'ELECTRICAL LIFT FITTER',
  'ELECTRICAL LIFT INSTALLATION SUPERVISOR',
  'Electrical Lift, Escalator And Related Equipment Fitter',
  'ELECTRICAL MAINTENANCE ENGINEER',
  'ELECTRICAL MAINTENANCE TECHNICIAN',
  'ELECTRICAL MOTOR AND GENERATOR FITTER',
  'ELECTRICAL NETWORK ENGINEER',
  'ELECTRICAL PLANNER',
  'ELECTRICAL SITE SUPERVISOR',
  'ELECTRICAL SUPERVISOR',
  'ELECTRICAL SWITCHGEAR AND CONTROL APPARATUS FITTER',
  'ELECTRICAL TECHNICIAN',
  'ELECTRICIAN',
  'ELECTRICIAN (GENERAL)',
  'ELECTRICIAN (HOUSEHOLD ELECTRICAL EQUIPMENT)',
  'ELECTRICIAN, BUILDING',
  'ELECTRICIAN, BUILDING MAINTENANCE',
  'ELECTRICIAN, INSTALLATION (BUILDING)',
  'ELECTRICIAN, MAINTENANCE (BUILDING)',
  'ELECTRICIAN, MOTOR VEHICLE',
  'ELECTRICIAN, SHIP',
  'ELECTRICIAN, VEHICLE',
  'ELECTRONIC COMPONENT ASSEMBLER',
  'ELECTRONIC DATA PROCESSING SUPERVISOR',
  'ELECTRONIC DESIGN ENGINEER',
  'Electronic Equipment / Component Assembler',
  'ELECTRONIC EQUIPMENT ASSEMBLER',
  'ELECTRONIC PRE-PRESS TECHNICIAN',
  'ELECTRONIC PRODUCTS INSPECTOR AND TESTER',
  'ELECTRONIC PRODUCTS QUALITY CHECKER',
  'ELECTRONICS DESIGN ENGINEER',
  'ELECTRONICS ENGINEER',
  'ELECTRONICS ENGINEER (GENERAL)',
  'ELECTRONICS ENGINEER, ASSISTANT',
  'ELECTRONICS ENGINEERING ASSISTANT (GENERAL)',
  'ELECTRONICS ENGINEERING TECHNICIAN (GENERAL)',
  'ELECTRONICS FITTER',
  'ELECTRONICS FITTER (GENERAL)',
  'ELECTRONICS FITTER (INSTRUMENTS)',
  'ELECTRONICS SERVICE ENGINEER',
  'ELECTROPLATER',
  'Electro-Plater',
  'ELEVATOR ENGINEER',
  'ELEVATOR FITTER (ELECTRICAL)',
  'EMBALMER',
  'EMBEDDED SOFTWARE ENGINEER',
  'EMBEDDED SYSTEM ENGINEER',
  'Embedded Systems Engineer',
  'EMBROIDERER, HAND',
  'EMBRYOLOGIST',
  'Emergency Medical Technician',
  'EMPLOYMENT AGENT',
  'Employment Agent / Labour Contractor',
  'EMPLOYMENT COUNSELLOR',
  'ENAMELLER, JEWELLERY',
  'ENERGY BROKER',
  'ENERGY ENGINEER',
  'ENERGY MANAGER',
  'ENERGY TRADER',
  'Engine Build Technician',
  'ENGINE SETTER-OPERATOR (METALWORKING)',
  'ENGINEER',
  'Engineer (Ndt Level 3) (Aircraft Engine / Component Maintenance)',
  'Engineer (Ndt Level 3) (Aircraft Maintenance)',
  'Engineer (Ndt Level 3) (Manufacturing)',
  'Engineer (Precision Engineering)',
  'Engineer (Sea Transport)',
  'ENGINEER OFFICER, MARINE',
  'ENGINEER OFFICER, SHIP',
  'ENGINEER SCIENTIST',
  'ENGINEER, AERONAUTICAL',
  'ENGINEER, AIR-CONDITIONING',
  'ENGINEER, ASSISTANT (CIVIL)',
  'ENGINEER, ASSISTANT (ELECTRICAL)',
  'ENGINEER, ASSISTANT (ELECTRONIC)',
  'ENGINEER, ASSISTANT (MECHANICAL)',
  'ENGINEER, ASSISTANT (PRODUCTION)',
  'ENGINEER, ASSISTANT (STRUCTURAL)',
  'ENGINEER, AUTOMATION',
  'ENGINEER, AUTOMOTIVE',
  'ENGINEER, BIOMEDICAL',
  'ENGINEER, BUILDING CONSTRUCTION',
  'ENGINEER, CAD-CAM',
  'ENGINEER, CHEMICAL (GENERAL)',
  'ENGINEER, CHIEF (SHIP)',
  'ENGINEER, CIVIL (GENERAL)',
  'ENGINEER, COMPUTER',
  'ENGINEER, COMPUTER SYSTEMS',
  'ENGINEER, CUSTOMER SERVICE',
  'ENGINEER, ELECTRICAL (GENERAL)',
  'ENGINEER, ELECTRONICS (GENERAL)',
  'ENGINEER, ELEVATOR',
  'ENGINEER, FIRST (SHIP)',
  'ENGINEER, GAS (PRODUCTION AND DISTRIBUTION)',
  'ENGINEER, GAS TURBINE',
  'ENGINEER, HARDWARE (COMPUTER)',
  'ENGINEER, HIGHWAY AND STREET CONSTRUCTION',
  'ENGINEER, HYDRAULICS',
  'ENGINEER, INDUSTRIAL MACHINERY AND TOOLS',
  'ENGINEER, INSTRUMENTATION',
  'ENGINEER, LIFT',
  'ENGINEER, MACHINERY AND TOOLS (INDUSTRIAL)',
  'ENGINEER, MANUFACTURING (GENERAL)',
  'ENGINEER, MARINE',
  'ENGINEER, MATERIALS',
  'ENGINEER, MECHANICAL (AUTOMOTIVE)',
  'ENGINEER, MECHANICAL (GENERAL)',
  'ENGINEER, MECHANICAL (HEATING, VENTILATION AND REFRIGERATION)',
  'ENGINEER, MECHANICAL (INDUSTRIAL MACHINERY AND TOOLS)',
  'ENGINEER, MECHANICAL (MARINE)',
  'ENGINEER, MECHANICAL (MOTORS AND ENGINES, EXCEPT MARINE)',
  'ENGINEER, PETROCHEMICALS',
  'ENGINEER, PILING',
  'ENGINEER, POWER DISTRIBUTION AND TRANSMISSION',
  'ENGINEER, POWER GENERATION',
  'ENGINEER, PROCUREMENT',
  'ENGINEER, PRODUCTION',
  'ENGINEER, QUALITY ASSURANCE',
  'ENGINEER, RADIO',
  'ENGINEER, RAILWAY CONSTRUCTION',
  'ENGINEER, REFRIGERATION',
  'ENGINEER, ROAD CONSTRUCTION',
  'ENGINEER, ROBOTIC',
  'ENGINEER, SAFETY (INDUSTRIAL)',
  'ENGINEER, SANITARY',
  'ENGINEER, SECOND (SHIP)',
  'ENGINEER, SEMI-CONDUCTOR',
  'ENGINEER, SEWERAGE',
  'ENGINEER, SHIP CONSTRUCTION',
  "ENGINEER, SHIP'S",
  'ENGINEER, SIGNAL SYSTEMS (ELECTRONIC)',
  'ENGINEER, SOFTWARE',
  'ENGINEER, STRUCTURAL (BUILDINGS)',
  'ENGINEER, STRUCTURAL (GENERAL)',
  'ENGINEER, SYSTEMS (COMPUTER)',
  'ENGINEER, TELECOMMUNICATIONS',
  'ENGINEER, TELEPHONE',
  'ENGINEER, TELEVISION',
  'ENGINEER, TOOLS AND INDUSTRIAL MACHINERY',
  'ENGINEER, TRAFFIC',
  'ENGINEER, TUNNEL CONSTRUCTION',
  'ENGINEER, VIDEO EQUIPMENT',
  'ENGINEERING ASSISTANT',
  'ENGINEERING ASSISTANT, AIR-CONDITIONING',
  'ENGINEERING ASSISTANT, AUTOMOTIVE',
  'ENGINEERING ASSISTANT, CHEMICAL (GENERAL)',
  'ENGINEERING ASSISTANT, CIVIL (GENERAL)',
  'ENGINEERING ASSISTANT, ELECTRICAL (GENERAL)',
  'ENGINEERING ASSISTANT, ELECTRONICS (GENERAL)',
  'ENGINEERING ASSISTANT, MANUFACTURING (GENERAL)',
  'ENGINEERING ASSISTANT, MARINE',
  'ENGINEERING ASSISTANT, MECHANICAL (GENERAL)',
  'ENGINEERING ASSISTANT, MECHANICAL (MOTORS AND ENGINES)',
  'ENGINEERING ASSISTANT, PRODUCTION',
  'ENGINEERING ASSISTANT, STRUCTURAL (GENERAL)',
  'ENGINEERING COORDINATOR',
  'ENGINEERING DIRECTOR',
  'ENGINEERING DIVISION MANAGER',
  'ENGINEERING GEOLOGIST',
  'ENGINEERING INSPECTOR',
  'ENGINEERING INTERN',
  'ENGINEERING MANAGER',
  'ENGINEERING OFFICER',
  'ENGINEERING OPERATOR',
  'ENGINEERING RESEARCH MANAGER',
  'ENGINEERING SALESMAN',
  'Engineering Service Engineer (Aircraft Engine / Component Maintenance)',
  'Engineering Service Engineer (Fleet Management)',
  'ENGINEERING SERVICES MANAGER',
  'ENGINEERING SERVICES MANAGER (EXCLUDING TRANSPORT)',
  'ENGINEERING SERVICES PROJECT MANAGER (EXCLUDING TRANSPORT)',
  'ENGINEERING SYSTEMS ADMINISTRATOR',
  'ENGINEERING TECHNICIAN',
  'ENGINE-LATHE OPERATOR (METALWORKING)',
  'ENGRAVER, METAL',
  'Enrolled / Assistant Nurse',
  'ENROLLED NURSE',
  'Enterprise Architect',
  'ENTERTAINMENT MANAGER',
  'ENTOMOLOGIST',
  'ENVELOPE AND PAPER BAG MAKER',
  'ENVIRONMENT HEALTH OFFICER',
  'ENVIRONMENTAL COMPLIANCE MANAGER',
  'ENVIRONMENTAL CONSULTANT',
  'ENVIRONMENTAL CONTROL OFFICER',
  'ENVIRONMENTAL ENGINEER',
  'ENVIRONMENTAL HEALTH AND SAFETY MANAGER',
  'ENVIRONMENTAL HEALTH AND SAFETY SPECIALIST',
  'ENVIRONMENTAL HEALTH OFFICER',
  'ENVIRONMENTAL OFFICER',
  'ENVIRONMENTAL PROGRAMME COORDINATOR',
  'ENVIRONMENTAL PROTECTION SPECIALIST',
  'ENVIRONMENTAL SCIENTIST',
  'EPIDEMIOLOGIST',
  'EPIDEMIOLOGY MANAGER',
  'EQUINE DENTIST',
  'EQUINE THERAPIST',
  'EQUIPMENT ENGINEER',
  'Equipment Engineer (Electronics)',
  'EQUIPMENT MAINTENANCE ASSISTANT',
  'EQUIPMENT MANAGER',
  'Equipment Specialist',
  'EQUIPMENT SUPERVISOR',
  'EQUIPMENT TECHNICIAN',
  'EQUITIES ANALYST',
  'EQUITY TRADER',
  'ERECTOR AND INSTALLER, MACHINERY',
  'ERECTOR, CONSTRUCTIONAL STEEL',
  'ERECTOR, EXHIBITION STAND',
  'ERECTOR, STRUCTURAL STEEL',
  'ESCALATOR FITTER',
  'ESTATE MANAGEMENT EXECUTIVE',
  'ESTATE MANAGER',
  'ESTIMATOR',
  'ETCHER, PHOTOGRAVURE',
  'EVALUATION MANAGER',
  'EVENTS COORDINATOR',
  'EVENTS DESIGNER',
  'EVENTS EXECUTIVE',
  'EVENTS MANAGER',
  'Events Manager (Hotel And Accommodation Services)',
  'EVENTS MARKETING EXECUTIVE',
  'EVENTS PLATFORM MANAGER',
  'EVENTS SERVICES EXECUTIVE',
  'Excavating / Trench Digging Machine Operator',
  'EXCAVATING MACHINE OPERATOR',
  'EXCAVATOR OPERATOR',
  'EXECUTIVE',
  'Executive (Food Services)',
  'EXECUTIVE ASSISTANT',
  'EXECUTIVE ASSISTANT MANAGER, FOOD & BEVERAGE',
  'EXECUTIVE CHEF',
  'EXECUTIVE COOK',
  'EXECUTIVE DESIGNER',
  'EXECUTIVE DIRECTOR',
  'EXECUTIVE DIRECTOR (COMPANY)',
  'EXECUTIVE DIRECTOR (PLANNING AND DEVELOPMENT)',
  'EXECUTIVE DIRECTOR (REAL ESTATE)',
  'EXECUTIVE EDITOR',
  'EXECUTIVE ENGINEER',
  'Executive Housekeeper (Hotel And Accommodation Services)',
  'EXECUTIVE INFORMATION SYSTEM MANAGER',
  'EXECUTIVE MANAGER',
  'EXECUTIVE MANAGING COORDINATOR',
  'EXECUTIVE OFFICER',
  'EXECUTIVE OFFICER (GOVERNMENT)',
  'EXECUTIVE OFFICER (STATUTORY BOARD)',
  'EXECUTIVE PASTRY CHEF',
  'Executive Pastry Chef (Food Services) ',
  'EXECUTIVE PRINCIPAL',
  'Executive Producer (Film/Tv)',
  'EXECUTIVE PRODUCER, CURRENT AFFAIRS',
  'EXECUTIVE SEARCH CONSULTANT',
  'EXECUTIVE SECRETARY',
  'EXECUTIVE SOUS CHEF',
  'EXECUTIVE VICE PRESIDENT',
  'EXECUTIVE, CHIEF (COMPANY)',
  'EXECUTIVE, ESTATE MANAGEMENT',
  'EXECUTIVE, MANAGEMENT',
  'EXECUTIVE, MARKETING (PLANNING)',
  'EXECUTIVE, PERSONNEL',
  'EXECUTIVE, PROPERTY MANAGEMENT',
  'EXECUTIVE, TRAINING',
  'EXEMPT- NON OFFICER',
  'Exhibition / Conference / Event Planner',
  'EXHIBITION CO-ORDINATOR',
  'EXHIBITION DESIGNER',
  'EXHIBITION ORGANISER',
  'EXPEDITOR',
  'EXPERIENCE EXECUTIVE',
  'EXPERIENCE MANAGER',
  'EXPORT DIRECTOR',
  'EXPORT MANAGER',
  'EXPORT MARKETING EXECUTIVE',
  'EXPORT OPERATIONS COORDINATOR',
  'EXPORT SALES ENGINEER',
  'EXPORT SALES EXECUTIVE',
  'EXPORT SALES MANAGER',
  'EXTERMINATOR, PEST',
  'EXTERNAL AUDITOR',
  'EXTRACTIVE METALLURGIST',
  'EXTRACTOR OPERATOR (CHEMICALS AND RELATED MATERIALS)',
  'EXTRACURRICULAR SUBJECTS TEACHER',
  'F&B EXECUTIVE',
  'F&B SUPERVISOR',
  'FABRICATION ENGINEER',
  'FABRICATION MANAGER',
  'FABRICATION TECHNICIAN',
  'FABRICATOR',
  'FABRICATOR (PLASTIC PRODUCTS)',
  'FABRICS EXAMINER',
  'FABRICS INSPECTOR',
  'FACADE DESIGNER',
  'FACIAL THERAPIST',
  'FACILITIES ENGINEER (ELECTRONICS)',
  'FACILITIES MAINTENANCE OFFICER',
  'FACILITIES MAINTENANCE TECHNICIAN',
  'FACILITIES MANAGEMENT EXECUTIVE',
  'FACILITIES MANAGEMENT MANAGER',
  'FACILITIES MANAGER',
  'Facility Engineer (Electronics)',
  'FACILITY EXECUTIVE',
  'FACILITY MANAGER',
  'FACILITY SERVICES SPECIALIST',
  'FACTORY MANAGER',
  'FACTORY PRODUCTION MANAGER',
  'FACTORY SUPERVISOR',
  'FACULTY HEAD',
  'FAILURE ANALYSIS ENGINEER',
  'FAILURE ANALYSIS TECHNICIAN',
  'FAMILY PHYSICIAN',
  'FARM MANAGER',
  'FARM PRODUCTION MANAGER',
  'FARM SUPERVISOR (FRUIT TREES)',
  'FARM SUPERVISOR (LIVESTOCK)',
  'FARM SUPERVISOR (POULTRY)',
  'FARM TECHNICIAN (HORTICULTURE)',
  'FARM WORKER (GENERAL)',
  'FARM WORKER, FISH',
  'FARM WORKER, LIVESTOCK',
  'FARMER (GENERAL)',
  'FARMER (NURSERY)',
  'FARMER, FISH',
  'Farming Technician (Animal Production)',
  'FARMING TECHNICIAN (LIVESTOCK AND FISHERIES)',
  'FARRIER',
  'Fashion / Garment Designer',
  'FASHION AND GARMENT DESIGNER',
  'FASHION ARTIST',
  'FASHION CONSULTANT',
  'FASHION DESIGNER',
  'FASHION EDITOR',
  'FASHION MERCHANDISER',
  'FASHION MODEL',
  'FASHION REPORTER',
  'FASHION STYLIST',
  'FAST FOOD PREPARER',
  'FAST FOOD RESTAURANT MANAGER',
  'FEATURE WRITER',
  'FELLOW',
  'FELLOW TRAINEE (MEDICAL DOCTOR)',
  'FENCING COACH',
  'FIELD APPLICATION ENGINEER',
  'FIELD ENGINEER',
  'FIELD ENGINEER, INFORMATION TECHNOLOGY (IT)',
  'FIELD MANAGER',
  'FIELD MECHANIC',
  'Field Sales Executive',
  'FIELD SALES MANAGER',
  'FIELD SERVICE ENGINEER',
  'FIELD SERVICE ENGINEER (TELECOMMUNICATIONS)',
  'FIELD SERVICE MANAGER',
  'FIELD SERVICE SPECIALIST',
  'FIELD SERVICE TECHNICIAN',
  'FIELD SUPERVISOR',
  'FIELD SUPERVISOR (MARKET RESEARCH)',
  'FIELD SUPPORT ENGINEER',
  'FIELD SURVEYOR',
  'FIELD TECHNICAL SPECIALIST',
  'FILING CLERK',
  'FILM MAKER, PHOTOGRAPHIC',
  'FILM TECHNICIAN',
  'FINANCE & CONTROL DIRECTOR',
  'FINANCE ADVISOR',
  'FINANCE ANALYST',
  'FINANCE AND ADMINISTRATIVE MANAGER',
  'FINANCE ASSISTANT',
  'FINANCE ASSISTANT OFFICER',
  'FINANCE ASSOCIATE',
  'FINANCE BUSINESS PARTNER',
  'FINANCE CLERK',
  'FINANCE CONTROLLER',
  'FINANCE DIRECTOR',
  'FINANCE EXECUTIVE',
  'FINANCE INTERN',
  'FINANCE MANAGER',
  'FINANCE OFFICER',
  'FINANCE PROCESS LEAD',
  'FINANCE SECONDEE',
  'FINANCE SUPPORT MANAGER',
  'FINANCE VICE PRESIDENT, ASIA PACIFIC',
  'Financial / Insurance Services Manager (Eg Financial Institution Branch Manager)',
  'Financial / Investment Adviser',
  'FINANCIAL ACCOUNTANT',
  'FINANCIAL ACCOUNTING EXECUTIVE',
  'FINANCIAL ACCOUNTING MANAGER',
  'FINANCIAL ADVISER',
  'FINANCIAL ADVISOR',
  'FINANCIAL ANALYSIS MANAGER',
  'FINANCIAL ANALYST',
  'FINANCIAL AUDITOR',
  'FINANCIAL CONSULTANT',
  'FINANCIAL CONTROLLER',
  'Financial Derivatives Dealer / Broker',
  'FINANCIAL EDITOR',
  'FINANCIAL ENGINEER',
  'FINANCIAL EXECUTIVE',
  'FINANCIAL FUTURES DEALER',
  'FINANCIAL MANAGER',
  'FINANCIAL MARKET RISK MANAGER',
  'FINANCIAL PLANNER',
  'Financial Planning & Analysis (Fp&A) Manager',
  'FINANCIAL PLANNING & ANALYSIS DIRECTOR',
  'FINANCIAL PLANNING MANAGER',
  'FINANCIAL PRODUCT CONTROLLER',
  'FINANCIAL PRODUCTS INSTITUTIONAL SALES REPRESENTATIVE',
  'FINANCIAL RESEARCHER',
  'FINANCIAL RISK ANALYST',
  'FINANCIAL RISK MANAGEMENT DIRECTOR',
  'FINANCIAL RISK MANAGER',
  'FINANCIAL SERVICES CONSULTANT',
  'FINANCIAL SERVICES PROJECT MANAGER',
  'FINANCIAL TRADER',
  'FINISHER (METAL POLISHING)',
  'FINISHER, WOODEN FURNITURE',
  'Fire And Safety Inspector',
  'FIRE AND SAFETY OFFICER',
  'FIRE FIGHTER (GENERAL)',
  'FIRE INVESTIGATOR',
  'FIRE PREVENTION SPECIALIST',
  'Firmware Engineer',
  'FIRST AID OFFICER',
  'FIRST ENGINEER (SHIP)',
  'FIRST MATE (SHIP)',
  'FISH HATCHERY WORKER (AQUARIUM)',
  'FISH PROCESSING MACHINE OPERATOR',
  'FITNESS CENTRE MANAGER',
  'FITNESS CONSULTANT',
  'FITNESS INSTRUCTOR',
  'FITNESS TRAINER',
  'FITTER, AIRCRAFT ENGINE',
  'FITTER, ELECTRICAL (GENERAL)',
  'FITTER, ELECTRICAL (REFRIGERATION AND AIR-CONDITIONING EQUIPMENT)',
  'FITTER, MACHINERY (GENERAL)',
  'FITTER, MAINTENANCE',
  'FITTER, MARINE ENGINE',
  'FITTER, PIPE (GENERAL)',
  'FITTER, PIPE (MARINE)',
  'FITTER, WOODWORKING MACHINERY',
  'FITTER-ASSEMBLER, MACHINERY (GENERAL)',
  'FITTER-ASSEMBLER, METALWORKING MACHINE TOOL',
  'FIXED EQUIPMENT ENGINEER',
  'FIXED EQUIPMENT RELIABILITY ENGINEER',
  'FIXER (CARPENTER)',
  'FLAME CUTTER',
  'FLAME CUTTER (METAL CUTTING MACHINE)',
  'FLAVORIST',
  'FLAVOURIST',
  'FLEET MANAGEMENT MANAGER',
  'FLEET MANAGEMENT SENIOR MANAGER',
  'FLEET MANAGER',
  'FLEET OPERATION MANAGER',
  'FLIGHT INSTRUCTOR (EXCEPT AIR FORCE)',
  'FLIGHT OPERATIONS OFFICER',
  'FLIGHT STEWARD',
  'FLOATING CRANE OPERATOR',
  'Floor / Wall Tiler',
  'FLOOR AND WALL TILE SETTER',
  'FLOOR MANAGER',
  'FLOOR SUPERVISOR (RETAIL TRADE)',
  'FLOOR TILER',
  'FLORAL DESIGNER',
  'FLORICULTURIST',
  'FLORIST',
  'FLOWER ARRANGEMENT TEACHER',
  'FLOWER MAKER, ARTIFICIAL',
  'Flying Instructor (Except Air Force)',
  'FLYING INSTRUCTOR (EXCEPT AIRFORCE)',
  'FOOD & BEVERAGE ADVISOR',
  'FOOD & BEVERAGE DIRECTOR',
  'FOOD & BEVERAGE EXECUTIVE',
  'FOOD & BEVERAGE MANAGER',
  'FOOD & BEVERAGE OPERATIONS MANAGER',
  'FOOD & BEVERAGE OUTLET ASSISTANT',
  'FOOD & BEVERAGE OUTLET ASSISTANT SUPERVISOR',
  'FOOD & BEVERAGE OUTLET ATTENDANT',
  'FOOD & BEVERAGE OUTLET CAPTAIN',
  'FOOD & BEVERAGE OUTLET EXECUTIVE',
  'FOOD & BEVERAGE OUTLET SUPERVISOR',
  'FOOD & BEVERAGE QUALITY MANAGER',
  'FOOD & BEVERAGE SALES MANAGER',
  'FOOD & BEVERAGE SUPERVISOR',
  'FOOD & BEVERAGE TEAM LEADER',
  'FOOD AND BEVERAGE (F&B) MANAGER',
  'FOOD AND BEVERAGE DEPARTMENT MANAGER (HOTEL)',
  'FOOD AND DRINK TECHNOLOGIST',
  'FOOD CHECKER (CATERING SERVICE)',
  'FOOD CHEMIST',
  'FOOD MANUFACTURING SUPERVISOR',
  'FOOD OUTLET MANAGER',
  'FOOD PROCESS ENGINEER',
  'Food Processing And Related Trades Worker Nec (Eg Fruit Pickler)',
  'FOOD PROCESSING ENGINEER',
  'FOOD PROCESSING MANAGER',
  'FOOD PROCESSING SUPERVISOR',
  'FOOD PROCESSING WORKER',
  'FOOD PRODUCTION MANAGER',
  'FOOD SCIENCE TECHNICIAN',
  'Food Service Counter Attendant',
  'FOOD SERVICES OPERATIONS DIRECTOR',
  'FOOD SERVICES OPERATIONS MANAGER',
  'FOOD SERVICES STORE MANAGER',
  'FOOD TECHNOLOGIST',
  'FOOT REFLEXOLOGIST',
  'FOOT SPECIALIST',
  'FOOT/OTHER REFLEXOLOGIST',
  'FOOTBALL COACH',
  'FOOTBALL PLAYER',
  'FOOTWEAR REPAIRER',
  'FOREIGN ARMED FORCES',
  'Foreign Armed Forces Personnel',
  'FOREIGN CONSULAR GENERAL',
  'FOREIGN CONSULAR OFFICIAL',
  'FOREIGN DIPLOMATIC REPRESENTATIVE',
  'FOREIGN EDITOR',
  'FOREIGN EXCHANGE DEALER',
  'Foreign Exchange Dealer / Broker',
  "FOREIGN LAWYER (PRACTISING FOREIGN LAW & S'PORE LAW IN APPROVED AREAS OF LEGAL PRACTICE)",
  'FOREIGN LAWYER (PRACTISING FOREIGN LAW)',
  'Foreigner Not Reporting Any Occupation',
  'FOREMAN',
  'FOREMAN, GENERAL (BUILDING TRADES)',
  'FOREMAN, GENERAL (BUILDING)',
  'FOREMAN, GENERAL (CLOTHING MANUFACTURING)',
  'FOREMAN, GENERAL (CONSTRUCTION)',
  'FOREMAN, GENERAL (ELECTRICAL AND ELECTRONIC INSTALLATION AND REPAIR)',
  'FOREMAN, GENERAL (ELECTRICAL EQUIPMENT MANUFACTURING AND REPAIRING)',
  'FOREMAN, GENERAL (ELECTRONIC EQUIPMENT MANUFACTURING AND REPAIRING)',
  'FOREMAN, GENERAL (FOOD MANUFACTURING)',
  'FOREMAN, GENERAL (FURNITURE AND RELATED PRODUCTS MANUFACTURING)',
  'FOREMAN, GENERAL (GAS PRODUCTION AND DISTRIBUTION)',
  'FOREMAN, GENERAL (GLASS PRODUCTS MANUFACTURING)',
  'FOREMAN, GENERAL (JEWELLERY MANUFACTURING)',
  'FOREMAN, GENERAL (LEATHER GOODS MAKING)',
  'FOREMAN, GENERAL (METAL AND MACHINERY TRADES)',
  'FOREMAN, GENERAL (METAL PROCESSING)',
  'FOREMAN, GENERAL (PLUMBING INSTALLATION AND REPAIR)',
  'FOREMAN, GENERAL (PRECISION INSTRUMENT MANUFACTURING)',
  'FOREMAN, GENERAL (SHIP BUILDING AND REPAIRING)',
  'FOREMAN, SHIPYARD',
  'FORENSIC TECHNICIAN',
  'FORK LIFT TRUCK OPERATOR',
  'FORMULATION CHEMIST',
  'FOUNDRY METALLURGIST',
  'FRAGRANCE DEVELOPMENT MANAGER',
  'FRAME MAKER, PICTURE',
  'FRANCHISE MANAGER',
  'FREIGHT DERIVATIVES BROKER',
  'Freight Officer',
  'FREIGHT PRICING MANAGER (EXCEPT PORT/SHIPPING OPERATIONS)',
  'FREIGHT PRICING MANAGER (SHIPPING OPERATIONS)',
  'FREIGHT TRADER',
  'FRINGING MACHINE OPERATOR',
  'FRONT DESK EXECUTIVE',
  'FRONT OFFICE AGENT',
  'Front Office Agent (Officer)(Hotel And Accommodation Services)',
  'FRONT OFFICE ASSISTANT',
  'Front Office Executive (Supervisor)(Hotel And Accommodation Services)',
  'FRONT OFFICE MANAGER',
  'FRONT OFFICE MANAGER (HOTEL)',
  'FUEL OIL TRADER',
  'Fumigators / Pest And Weed Controllers',
  'FUND ACCOUNTANT',
  'FUND ACCOUNTING MANAGER',
  'FUND CONSULTANT',
  'FUND MANAGER',
  'FUNERAL DIRECTOR',
  'FURNACEMAN, METAL REHEATING',
  'FURNITURE DESIGNER',
  'FURNITURE MAKER (BASKETRY)',
  'FURNITURE MAKER (WOOD)',
  'FURNITURE UPHOLSTERER',
  'FUTURES TRADER',
  'GALLERY MANAGER',
  'GAME DESIGNER',
  'GAME DEVELOPER',
  'GAME PRODUCER',
  'Game Story Writer',
  'Games Artist',
  'GAMES COACH',
  'GAMES PROGRAMMER',
  'GANTRY OR BRIDGE CRANE OPERATOR',
  'GARAGE MECHANIC (AUTOMOBILE)',
  'GARDENER',
  'GARLAND MAKER',
  'GARMENT CUTTER',
  'GARMENT CUTTING MARKER',
  'GARMENT DESIGNER',
  'GARMENT PATTERNMAKER',
  'Garment Pattern-Maker',
  'GARMENT-SEWING MACHINE OPERATOR',
  'GAS CUTTING MACHINE OPERATOR (METAL)',
  'GAS ENGINEER (PRODUCTION AND DISTRIBUTION)',
  'GAS PIPE FITTER',
  'GAS TURBINE ENGINEER',
  'GAS TURBINE SPECIALIST',
  'GEMOLOGIST',
  'GENERAL COUNSEL',
  'GENERAL DENTIST',
  'GENERAL FOREMAN',
  'GENERAL FOREMAN (BUILDING TRADES)',
  'GENERAL FOREMAN (BUILDING)',
  'GENERAL FOREMAN (CONSTRUCTION)',
  'GENERAL FOREMAN (ELECTRICAL AND ELECTRONIC INSTALLATION AND REPAIR)',
  'GENERAL FOREMAN (FOOD MANUFACTURING)',
  'GENERAL FOREMAN (LEATHER GOODS MAKING)',
  'GENERAL FOREMAN (METAL AND MACHINERY TRADES)',
  'GENERAL FOREMAN (METAL PROCESSING)',
  'GENERAL FOREMAN (PETROLEUM REFINING)',
  'GENERAL FOREMAN (PLUMBING INSTALLATION AND REPAIR)',
  'GENERAL FOREMAN (PRECISION INSTRUMENT MANUFACTURING)',
  'GENERAL FOREMAN (PRINTING)',
  'GENERAL FOREMAN (SHIP BUILDING AND REPAIRING)',
  'GENERAL FOREMAN (WOOD PROCESSING)',
  'GENERAL MANAGER',
  'GENERAL MANAGER (AGRICULTURE)',
  'GENERAL MANAGER (BUSINESS DEVELOPMENT)',
  'GENERAL MANAGER (BUSINESS MANAGEMENT)',
  'GENERAL MANAGER (CONSTRUCTION)',
  'GENERAL MANAGER (CONSUMER MARKETING)',
  'GENERAL MANAGER (FINANCE & ACCOUNTS)',
  'GENERAL MANAGER (HOTEL)',
  'GENERAL MANAGER (MANUFACTURING)',
  'General Manager (Marine And Offshore)',
  'GENERAL MANAGER (MEDIA INDUSTRY)',
  'GENERAL MANAGER (MINING AND QUARRYING)',
  'GENERAL MANAGER (RESTAURANT)',
  'GENERAL MANAGER (RETAIL TRADE)',
  'GENERAL MANAGER (SERVICE FIRM)',
  'GENERAL MANAGER (SHIPPING)',
  'GENERAL MANAGER (TRANSPORT)',
  'GENERAL MANAGER (WHOLESALE TRADE)',
  'GENERAL OFFICE CLERK',
  'GENERAL OFFICE SUPERVISOR',
  'GENERAL PRACTITIONER',
  'General Practitioner / Physician',
  'GENERAL SALES MANAGER',
  'GENERAL TECHNICAL MANAGER',
  'General Worker (Manufacturing)',
  'GEOLOGICAL ENGINEER',
  'GEOLOGIST',
  'GEOLOGY TECHNICIAN',
  'GEOPHYSICIST',
  'GEOSPATIAL CONSULTANT',
  'GEOTECHNICAL DESIGN MANAGER',
  'GEOTECHNICAL ENGINEER',
  'GEOTECHNICAL MANAGER',
  'GLASS CUTTER',
  'GLASS LATHE OPERATOR',
  'GLAZIER',
  'GLAZIER, ROOFING',
  'GLOBAL ACCOUNT DIRECTOR',
  'GLOBAL ASSISTANT BRAND MANAGER',
  'GLOBAL BRAND DEVELOPMENT DIRECTOR',
  'GLOBAL BRAND DIRECTOR',
  'GLOBAL BRAND MANAGER',
  'GLOBAL BRAND VICE PRESIDENT',
  'GLOBAL BUSINESS MANAGER',
  'GLOBAL CATEGORY MANAGER',
  'GLOBAL CONSUMER MARKET INSIGHT DIRECTOR',
  'GLOBAL CONSUMER MARKET INSIGHT MANAGER',
  'GLOBAL CUSTOMER SERVICE MANAGER',
  'GLOBAL MARKETING OFFICER',
  'GLOBAL PLANNING DIRECTOR',
  'GLOBAL PRODUCT LEADER',
  'GLOBAL STRATEGY DIRECTOR',
  'GLOBAL SUPPLY MANAGEMENT DIRECTOR',
  'GLOBAL SUPPLY MANAGEMENT TECHNICAL MANAGER',
  'GLOBAL TECHNICAL PROJECT MANAGER',
  'GLOBAL VICE PRESIDENT',
  'GLOBAL WEALTH STRUCTURING CONSULTANT',
  'GOLD BEATER',
  'GOLDSMITH',
  'Goldsmith / Silversmith',
  'GOLF COURSE MANAGER',
  'GOLF COURSE SHAPER',
  'GOLF TEACHER',
  'GOLFING OFFICER',
  'GOVERNMENT ADMINISTRATOR',
  'GOVERNMENT EXECUTIVE OFFICER',
  'GRAB-BUCKET OPERATOR',
  'Grain / Spice Milling Machine Operator',
  'GRAIN GRINDER OPERATOR',
  'GRAIN MILLER',
  'GRAPHIC ARTIST',
  'GRAPHIC DESIGNER',
  'GRAPHIC WEB DESIGNER',
  'Graphics Designer',
  'GRINDER, MACHINE TOOL',
  'GRINDING MACHINE OPERATOR, PRECISION (METALWORKING)',
  'GRINDING MACHINE SETTER-OPERATOR, PRECISION (METALWORKING)',
  'GROOMER',
  'GROUND SUPPORT EQUIPMENT OPERATOR',
  'GROUP ACCOUNT DIRECTOR',
  'Group Beverage Manager',
  'GROUP CHIEF EXECUTIVE OFFICER',
  'GROUP FINANCIAL CONTROLLER',
  'GROUP MANAGEMENT ACCOUNTANT',
  'GROUP PUBLISHER',
  'GROUP VICE PRESIDENT',
  'GUARD, SECURITY (PRIVATE)',
  'Guest Relation Manager (Hotel And Accommodation Services)',
  'GUEST RELATIONS EXECUTIVE',
  'GUEST RELATIONS MANAGER',
  'GUEST RELATIONS OFFICER',
  'GUEST SERVICES MANAGER',
  'GUEST SERVICES OFFICER',
  'GUEST-HOUSE MANAGER',
  'GUIDE, TOURIST',
  'GURKHA POLICE OFFICER',
  'GYMNASTICS COACH',
  'HAIR CONSULTANT',
  'HAIR RESTORATION TECHNOLOGIST',
  'HAIR SALON MANAGER',
  'Hair Stylist / Hairdresser',
  'HAIRDRESSER',
  "HAIRDRESSER, MEN'S",
  "HAIRDRESSER, WOMEN'S",
  'HAIRSTYLIST',
  'HALL MANAGER',
  'HAND PACKER',
  'HANDYMAN, BUILDING MAINTENANCE',
  'HARBOUR PILOT',
  'HARDWARE ENGINEER (COMPUTER)',
  'Hawker / Stall Holder (Prepared Food Or Drinks)',
  'Head Baker',
  'Head Barista',
  'HEAD BARTENDER',
  'Head Bartender (Mixologist)',
  'Head Butler (Hotel And Accommodation Services)',
  'HEAD CHEF',
  'HEAD COOK',
  'HEAD OF ACCOUNT MANAGEMENT',
  'Head Of Central Kitchen Operations',
  'HEAD OF CHANGE MANAGEMENT',
  'HEAD OF CLIENT DEVELOPMENT, ASIA PACIFIC',
  'HEAD OF CLINICAL RESEARCH',
  'HEAD OF COLLEGE (INTERNATIONAL SCHOOL)',
  'HEAD OF COMPENSATION AND BENEFITS',
  'HEAD OF CORPORATE AFFAIRS, ASIA PACIFIC',
  'HEAD OF CORPORATE TAX',
  'HEAD OF CREDIT RESEARCH',
  'HEAD OF CUSTOMER DEVELOPMENT IT & PROCESS EXCELLENCE',
  'HEAD OF DATA ANALYTICS',
  'HEAD OF DEPARTMENT (COMMERCIAL SCHOOL)',
  'Head Of Department (Electronics)',
  'HEAD OF DEPARTMENT (INTERNATIONAL SCHOOL)',
  'HEAD OF DESK',
  'HEAD OF GRADE (INTERNATIONAL SCHOOL)',
  'HEAD OF HUMAN RESOURCE (HR)',
  'HEAD OF HUMAN RESOURCE, ASIA PACIFIC',
  'HEAD OF INFORMATION TECHNOLOGY, ASIA PACIFIC',
  'HEAD OF INSTITUTIONAL SALES. ASIA',
  'HEAD OF INTERNATIONAL TRADE',
  'HEAD OF LEGAL',
  'Head Of Occupational Therapy',
  'HEAD OF OPERATIONAL AUDITING',
  'HEAD OF PERFORMANCE AND ATTRIBUTION',
  'HEAD OF PLANNING',
  'HEAD OF PRICING MANAGEMENT',
  'HEAD OF SALES',
  'HEAD OF SALES, ASIA PACIFIC',
  'HEAD OF TRADING',
  'Head Sommelier',
  'HEAD WAITER',
  'HEAD WAITRESS',
  'HEAD, MARINE CARGO OPERATIONS',
  'HEAD, TREASURY AND SPECIALISED PRODUCTS',
  'HEAD, VETERINARY',
  'HEALTH AND SAFETY ADVISOR',
  'Health Care Assistant',
  'HEALTH CARE CONSULTANT',
  'HEALTH CARE EXECUTIVE',
  'HEALTH CARE SUPPORT OFFICER',
  'HEALTH SAFETY EXECUTIVE',
  'HEALTH SERVICES DIRECTOR',
  'HEALTH SERVICES MANAGER',
  'HEALTH, SAFETY & ENVIRONMENT SITE CLERK',
  'HEALTH, SAFETY & ENVIRONMENTAL ANALYST',
  'HEALTH, SAFETY & ENVIRONMENTAL MANAGER',
  'HEALTH, SAFETY & ENVIRONMENTAL OFFICER',
  'HEALTH, SAFETY & ENVIRONMENTAL SPECIALIST',
  'HEALTH, SAFETY AND ENVIRONMENTAL (HSE) OFFICER',
  'Health, Safety And Environmental Coordinator',
  'HEALTHCARE ASSISTANT',
  'HEAT TRANSFER ENGINEER',
  'HEAVY EQUIPMENT TECHNICIAN',
  'HEDGE FUND ADMINISTRATOR',
  'HEDGE FUND MANAGER',
  'HELMSMAN',
  'HELPDESK CONSULTANT',
  'HELPDESK ENGINEER',
  'HIGH-SPEED PRINTER OPERATOR (ELECTRONIC DATA PROCESSING)',
  'HISTOPATHOLOGIST',
  'HOCKEY COACH',
  'HOISTING EQUIPMENT RIGGER',
  'HOISTING EQUIPMENT RIGGER (CONSTRUCTION)',
  'HONING MACHINE OPERATOR (METALWORKING)',
  'HORSE TRAINER',
  'HORTICULTURAL TECHNICIAN',
  'HORTICULTURIST',
  'Horticulturist / Arborist',
  'HOSPITAL PHARMACIST',
  'Host (Food Services)',
  'HOSTEL MANAGER',
  'HOTEL CLEANER',
  'HOTEL CONCIERGE',
  'HOTEL COOK',
  'HOTEL DUTY MANAGER',
  'HOTEL EXECUTIVE',
  'HOTEL FRONT OFFICE MANAGER',
  'HOTEL MANAGER',
  'Hotel Operations / Lodging Services Manager',
  'HOTEL RECEPTIONIST',
  'HOTEL RESIDENT MANAGER',
  'HOTEL STEWARD',
  'HOUSE BRAND MANAGER',
  'HOUSE OFFICER',
  'HOUSEHOLD APPLIANCE REPAIRMAN, ELECTRICAL',
  'HOUSEKEEPER (EXCEPT PRIVATE SERVICE)',
  'HOUSEKEEPER (HOTELS AND OTHER ESTABLISHMENTS)',
  'HOUSEKEEPER (PRIVATE SERVICE)',
  'HOUSEKEEPING ATTENDANT',
  'Housekeeping Attendant (Hotel And Accommodation Services)',
  'Housekeeping Coordinator (Hotel And Accommodation Services)',
  'HOUSEKEEPING MANAGER',
  'HOUSEKEEPING MATRON',
  'HOUSEKEEPING SUPERVISOR',
  'HOUSEKEEPING SUPERVISOR (EXCEPT PRIVATE SERVICE)',
  'HOUSEPARENT',
  'HULL ENGINEER',
  'HUMAN RESOURCE & ADMINISTRATION MANAGER',
  'HUMAN RESOURCE & ADMINISTRATIVE MANAGER',
  'HUMAN RESOURCE & ADMINISTRATIVE OFFICER',
  'HUMAN RESOURCE (HR) DIRECTOR',
  'HUMAN RESOURCE (HR) MANAGER',
  'HUMAN RESOURCE ADVISOR',
  'HUMAN RESOURCE ANALYST',
  'HUMAN RESOURCE ASSISTANT',
  'HUMAN RESOURCE ASSISTANT MANAGER',
  'HUMAN RESOURCE BUSINESS PARTNER',
  'HUMAN RESOURCE CLERK',
  'HUMAN RESOURCE CONSULTANT',
  'Human Resource Consultant (Excluding Executive Search Consultant)',
  'HUMAN RESOURCE DEVELOPMENT OFFICER',
  'HUMAN RESOURCE DIRECTOR',
  'HUMAN RESOURCE EXECUTIVE',
  'HUMAN RESOURCE GENERALIST',
  'HUMAN RESOURCE MANAGER',
  'HUMAN RESOURCE OFFICER',
  'HUMAN RESOURCE PROJECT LEAD',
  'HUMAN RESOURCE SPECIALIST',
  'HVAC ENGINEER',
  'HYDRAULIC EXCAVATOR OPERATOR',
  'HYDRAULIC TECHNICIAN',
  'HYDRAULICS ENGINEER',
  'HYDROGRAPHIC SURVEYOR',
  'HYDROLOGIST',
  'Hypertext Preprocessor (Php) Developer',
  'ICE MAKING MACHINE OPERATOR',
  'ICT CUSTOMER SUPPORT OFFICER',
  'ICT QUALITY ASSURANCE TESTER',
  'ICT SALES AND SERVICES PROFESSIONAL',
  'ICT SUPPORT TECHNICIAN',
  'ICT TECHNICAL SUPPORT SPECIALIST',
  'IMMIGRATION OFFICER',
  'IMPLANT TECHNICIAN',
  'IMPLEMENTATION CONSULTANT',
  'IMPLEMENTATION DIRECTOR',
  'IMPLEMENTATION ENGINEER',
  'IMPLEMENTATION LEAD',
  'IMPLEMENTATION MANAGER',
  'IMPLEMENTATION PROJECT MANAGER',
  'IMPLEMENTATION SPECIALIST',
  'IMPORT & EXPORT ASSISTANT',
  'IMPORT & EXPORT COORDINATOR',
  'IMPORT & EXPORT INSPECTOR',
  'IMPORT & EXPORT MANAGER',
  'IMPORT & EXPORT SUPERVISOR',
  'IMPORT EXECUTIVE',
  'Import Export Administrative Assistant',
  'Import Export Manager',
  'IMPORT MANAGER',
  'Incident Investigation Manager',
  'Incoming Quality Coordinator',
  'INDEX ANALYST',
  'Industrial / Office Machinery Mechanic',
  'INDUSTRIAL ENGINEER',
  'INDUSTRIAL HEALTH OFFICER',
  'INDUSTRIAL MACHINE ASSEMBLER (ELECTRICAL)',
  'INDUSTRIAL MACHINERY AND TOOLS ENGINEER',
  'INDUSTRIAL MACHINERY MECHANIC',
  'INDUSTRIAL PLANT SUPERVISOR AND GENERAL FOREMAN',
  'INDUSTRIAL PRODUCT DESIGNER',
  'INDUSTRIAL PRODUCTS DESIGNER',
  'INDUSTRIAL RELATIONS MANAGER',
  'INDUSTRIAL ROBOT OPERATOR',
  'INDUSTRIAL SAFETY ENGINEER',
  'INDUSTRIAL TOOL DEVELOPMENT ENGINEER',
  'INDUSTRIAL TRUCK OPERATOR',
  'INDUSTRY DEVELOPMENT DIRECTOR',
  'INDUSTRY DEVELOPMENT EXECUTIVE',
  'INDUSTRY DEVELOPMENT MANAGER',
  'INDUSTRY MANAGER',
  'INFANT EDUCARER',
  'INFOCOMM EXECUTIVE',
  'INFORMATION & COMMUNICATION TECHNOLOGY MANAGER',
  'Information Architect',
  'INFORMATION CLERK',
  'INFORMATION DEVELOPMENT LEAD',
  'INFORMATION ENGINEER',
  'INFORMATION EXECUTIVE',
  'INFORMATION MANAGEMENT ANALYST',
  'INFORMATION MANAGEMENT MANAGER',
  'INFORMATION OFFICER',
  'INFORMATION PROGRAMMER',
  'INFORMATION SECURITY ADMINISTRATOR',
  'Information Security Consultant',
  'Information Security Manager',
  'INFORMATION SERVICES EXECUTIVE',
  'Information System Analyst',
  'INFORMATION SYSTEM ENGINEER',
  'INFORMATION SYSTEM OFFICER',
  'Information Systems Architect',
  'INFORMATION SYSTEMS MANAGER',
  'INFORMATION TECHNOLOGY (IT) AUDITOR',
  'INFORMATION TECHNOLOGY (IT) FIELD ENGINEER',
  'INFORMATION TECHNOLOGY (IT) MARKETING REPRESENTATIVE',
  'INFORMATION TECHNOLOGY ANALYST',
  'INFORMATION TECHNOLOGY AUDITOR',
  'INFORMATION TECHNOLOGY BUSINESS ANALYST',
  'INFORMATION TECHNOLOGY BUSINESS PARTNER',
  'INFORMATION TECHNOLOGY BUSINESS PARTNERING ANALYST',
  'INFORMATION TECHNOLOGY BUSINESS PARTNERING DIRECTOR',
  'INFORMATION TECHNOLOGY BUSINESS PARTNERING MANAGER',
  'INFORMATION TECHNOLOGY BUSINESS PROCESS CONSULTANT',
  'INFORMATION TECHNOLOGY BUSINESS SOLUTION MANAGER',
  'Information Technology Consultant',
  'INFORMATION TECHNOLOGY COORDINATOR',
  'INFORMATION TECHNOLOGY DIRECTOR',
  'INFORMATION TECHNOLOGY ENGINEER',
  'INFORMATION TECHNOLOGY EXECUTIVE',
  'INFORMATION TECHNOLOGY HELPDESK COORDINATOR',
  'INFORMATION TECHNOLOGY IMPLEMENTATION MANAGER',
  'INFORMATION TECHNOLOGY INFRASTRUCTURE ANALYST',
  'INFORMATION TECHNOLOGY JUNIOR RESEARCH SCIENTIST',
  'INFORMATION TECHNOLOGY MANAGER',
  'INFORMATION TECHNOLOGY MASTER DATA MANAGEMENT ANALYST',
  'INFORMATION TECHNOLOGY MASTER DATA MANAGEMENT HEAD',
  'INFORMATION TECHNOLOGY PRE-SALES CONSULTANT',
  'INFORMATION TECHNOLOGY PROGRAM MANAGER',
  'INFORMATION TECHNOLOGY PROJECT LEADER',
  'INFORMATION TECHNOLOGY PROJECT MANAGER',
  'INFORMATION TECHNOLOGY PROJECT MANAGER (SOFTWARE AND APPLICATION)',
  'INFORMATION TECHNOLOGY QUALITY ASSURANCE SPECIALIST',
  'Information Technology Security Officer',
  'INFORMATION TECHNOLOGY SECURITY SPECIALIST',
  'INFORMATION TECHNOLOGY SENIOR RESEARCH SCIENTIST',
  'INFORMATION TECHNOLOGY SERVICE MANAGER',
  'INFORMATION TECHNOLOGY SPECIALIST',
  'INFORMATION TECHNOLOGY SUPERVISOR',
  'INFORMATION TECHNOLOGY SUPPLY CHAIN ANALYST',
  'INFORMATION TECHNOLOGY SUPPORT ENGINEER',
  'INFORMATION TECHNOLOGY SUPPORT EXECUTIVE',
  'INFORMATION TECHNOLOGY SUPPORT MANAGER',
  'INFORMATION TECHNOLOGY SUPPORT OFFICER',
  'INFORMATION TECHNOLOGY SYSTEM SUPPORT SPECIALIST',
  'INFORMATION TECHNOLOGY TECHNICIAN',
  'Information Technology Testing / Quality Assurance Specialist',
  'INFORMATION TECHNOLOGY TESTING SPECIALIST',
  'INFORMATION VISUALISATION ENGINEER',
  'INFRASTRUCTURE ARCHITECT',
  'INFRASTRUCTURE ARCHITECT (IT)',
  'INFRASTRUCTURE ASSOCIATE',
  'INFRASTRUCTURE ENGINEER',
  'INFRASTRUCTURE ENGINEER (IT)',
  'INFRASTRUCTURE SUPPORT SPECIALIST',
  'INFRASTRUCTURE TECHNICAL SPECIALIST',
  'IN-HOUSE COUNSEL/LAWYER (PUBLIC OR PRIVATE CORPORATION OR ORGANISATION)',
  'IN-HOUSE LEGAL COUNSEL',
  'INITIATIVE PLANNING MANAGER',
  'INJECTION MOULDING MACHINE OPERATOR, PLASTICS',
  'INNOVATION PLANNER',
  'INNOVATION PLANNING MANAGER',
  'INNOVATION PLANNING SENIOR MANAGER',
  'INSIDE SALES OFFICER',
  'INSPECTION ENGINEER',
  'INSPECTION TECHNICIAN',
  'INSPECTOR AND TESTER, MECHANICAL PRODUCTS',
  'INSPECTOR OF POSTMEN',
  'INSPECTOR, CONSTRUCTION',
  'INSTALLATION ELECTRICIAN (BUILDING)',
  'INSTALLATION ENGINEER',
  'INSTALLATION MANAGER',
  'INSTALLATION TECHNICIAN',
  'INSTALLER, ELECTRIC SIGN',
  'INSTALLER, GLASS (VEHICLES)',
  'INSTALLER, REFRIGERATION AND AIR-CONDITIONING EQUIPMENT',
  'INSTRUCTIONAL DESIGNER',
  'INSTRUCTOR',
  'INSTRUCTOR, DANCE',
  'INSTRUCTOR, FITNESS',
  'INSTRUCTOR, MARTIAL ARTS',
  'INSTRUCTOR, MUSIC',
  'INSTRUCTOR, PHYSICAL FITNESS',
  'INSTRUCTOR, SPORTS',
  'INSTRUMENT ASSEMBLER (PRECISION INSTRUMENT)',
  'INSTRUMENT COMMISSIONING INSPECTOR',
  'INSTRUMENT COMMISSIONING LEAD',
  'INSTRUMENT DESIGNER',
  'INSTRUMENT ELECTRICAL ENGINEER',
  'INSTRUMENT FIELD ENGINEER',
  'INSTRUMENT LEAD',
  'INSTRUMENTAL MUSICIAN',
  'INSTRUMENTALIST',
  'INSTRUMENTATION AND CONTROL ENGINEER',
  'INSTRUMENTATION AND ELECTRICAL ENGINEER',
  'INSTRUMENTATION AND ELECTRICAL MAINTENANCE ENGINEER',
  'INSTRUMENTATION AND ELECTRICAL MANAGER',
  'INSTRUMENTATION ENGINEER',
  'INSTRUMENTATION TECHNICIAN',
  'INSULATOR, BOILER AND PIPE',
  'Insurance / Underwriting Clerk',
  'INSURANCE ACTUARY',
  'INSURANCE AGENT',
  'INSURANCE BROKER',
  'INSURANCE CLAIM ADJUSTER',
  'INSURANCE CLAIMS CLERK',
  'INSURANCE CLERK',
  'INSURANCE POLICY CLERK',
  'INSURANCE REGIONAL MANAGER',
  'Insurance Sales Agent / Broker (Including Independent Financial Planner)',
  'INSURANCE SALES REPRESENTATIVE',
  'INSURANCE SERVICES MANAGER',
  'INSURANCE SPECIALIST',
  'INSURANCE UNDERWRITER',
  'INTEGRATED CIRCUIT DESIGNER',
  'INTEGRATED CONTROL SYSTEM ENGINEER',
  'INTEGRATED PLANNING PROCESS LEAD',
  'INTEGRATION ENGINEER',
  'INTEGRATION SPECIALIST',
  'INTEGRATION TECHNICAL PROJECT MANAGER',
  'Intellectual Property Brokers And Transaction Specialists',
  'INTELLECTUAL PROPERTY COUNSEL',
  'Intellectual Property Information Analyst',
  'INTERACTIVE AND DIGITAL MEDIA PRODUCER',
  'INTERACTIVE AND DIGITAL MEDIA PRODUCTION MANAGER',
  'INTEREST RATE TRADER',
  'INTERFACE ENGINEER',
  'INTERFACE MANAGER',
  'INTERIOR ARCHITECT',
  'INTERIOR CONSULTANT',
  'INTERIOR DECORATION DESIGNER',
  'INTERIOR DECORATOR',
  'INTERIOR DESIGN DIRECTOR',
  'INTERIOR DESIGN DRAUGHTSMAN',
  'INTERIOR DESIGNER',
  'INTERIOR VISUALIZER',
  'INTERMEDIATE DESIGNER',
  'INTERNAL AUDIT ASSISTANT',
  'Internal Audit Assistant Manager',
  'INTERNAL AUDIT DIRECTOR',
  'INTERNAL AUDIT EXECUTIVE',
  'INTERNAL AUDIT MANAGER',
  'INTERNAL AUDIT SUPERVISOR',
  'INTERNAL AUDITOR',
  'INTERNAL COMMUNICATIONS DIRECTOR',
  'INTERNAL SALES ENGINEER',
  'INTERNATIONAL EVENTS MANAGER',
  'International Market Representative',
  'INTERNATIONAL MARKETING DIRECTOR',
  'INTERNATIONAL MOBILITY ANALYST',
  'INTERNATIONAL MOBILITY MANAGER',
  'INTERNATIONAL MOVE COORDINATOR',
  'INTERNET ENGINEER',
  'INTERPRETER',
  'INTERVIEWER, MARKET RESEARCH',
  'INVENTORY ANALYST',
  'INVENTORY CLERK (STOCK CONTROL)',
  'INVENTORY CONTROL CLERK',
  'INVENTORY COORDINATOR',
  'INVENTORY MANAGEMENT MANAGER',
  'INVENTORY MANAGER',
  'INVENTORY SPECIALIST',
  'INVESTIGATOR',
  'INVESTMENT ACCOUNTANT',
  'INVESTMENT ADVISER',
  'INVESTMENT ADVISOR',
  'INVESTMENT ANALYST',
  'INVESTMENT BANKER',
  'INVESTMENT BROKER',
  'INVESTMENT CLERK',
  'INVESTMENT CONSULTANT',
  'INVESTMENT MANAGER',
  'INVESTMENT RESEARCH ANALYST',
  'INVESTMENT SALES SPECIALIST',
  'INVESTMENT SALES SUPPORT',
  'INVESTMENT STRATEGIST',
  'INVESTOR RELATIONS MANAGER',
  'INVIGILATOR',
  'INVOICE CLERK',
  'INVOICE SECTION SUPERVISOR',
  'IT AUDIT EXECUTIVE',
  'It Business Process Consultant / Business Analyst',
  'It Business Solutions Manager',
  'It Business Solutions Project Specialist',
  'IT DEVELOPMENT OFFICER',
  'IT HARDWARE ENGINEER',
  'IT INFRASTRUCTURE MANAGER',
  'IT NETWORK MANAGER',
  'IT OPERATIONS TECHNICIAN',
  'IT SECURITY OFFICER',
  'It Service Manager',
  'IT SUPPORT MANAGER',
  'IT SUPPORT TECHNICIAN',
  'IT TRAINER',
  'IT USER HELPDESK TECHNICIAN',
  'JAVA DEVELOPER',
  'JET GROUTING MACHINE OPERATOR',
  'JEWEL SETTER',
  'JEWELLER (GENERAL)',
  'JEWELLERY DESIGNER',
  'JEWELLERY ENGRAVER',
  'JEWELLERY REPAIRER',
  'JEWELLERY WORKER',
  'JEWELLERY WORKER (GENERAL)',
  'JOCKEY',
  'Joiner',
  'JOINER (GENERAL)',
  'JOINER, CONSTRUCTION',
  'JOINT GENERAL MANAGER',
  'JOINTER, ELECTRIC CABLE',
  'JOINTER, PIPE LAYING',
  'JOSS PAPER AND OTHER CEREMONIAL PAPER MAKER',
  'JOURNALIST',
  'JOURNALIST, RADIO AND TELEVISION',
  'JUNIOR ARCHITECT',
  'JUNIOR ART DIRECTOR',
  'JUNIOR ASSISTANT MANAGER',
  'JUNIOR BUTLER',
  'JUNIOR COLLEGE TEACHER',
  'JUNIOR CONSULTANT',
  'JUNIOR COPYWRITER',
  'JUNIOR DESIGN ENGINEER',
  'JUNIOR DESIGNER',
  'JUNIOR ENGINEER',
  'JUNIOR FRONT OFFICE AGENT',
  'JUNIOR MEDICAL TECHNOLOGIST',
  'JUNIOR QUANTITY SURVEYOR',
  'JUNIOR RESEARCH ASSISTANT',
  'JUNIOR RESEARCH FELLOW',
  'JUNIOR SOUS CHEF',
  'JUNIOR VICE PRESIDENT',
  'KARAOKE LOUNGE MANAGER',
  'KEEPER, ANIMAL (ZOO)',
  'KEY ACCOUNT DIRECTOR',
  'KEY ACCOUNT EXECUTIVE',
  'KEY ACCOUNT MANAGER',
  'KEY ACCOUNT MANAGER (SALES)',
  'KEY ACCOUNTS MANAGER',
  'KINDERGARTEN TEACHER',
  'KINESIOLOGIST',
  'KITCHEN ASSISTANT',
  'Kitchen Assistant (Central Kitchen)',
  'KITCHEN ASSISTANT (RESTAURANT)',
  'KITCHEN EQUIPMENT TECHNICIAN',
  'KITCHEN HELPER',
  'KITCHEN SUPERVISOR',
  'LABORATORY ANALYST',
  'LABORATORY ASSISTANT',
  'LABORATORY BIOLOGIST',
  'LABORATORY ENGINEER',
  'LABORATORY EXECUTIVE',
  'LABORATORY HEAD',
  'LABORATORY MANAGER',
  'LABORATORY TECHNICIAN',
  'LABORATORY TECHNICIAN (BIOLOGY)',
  'LABORATORY TECHNICIAN (CHEMISTRY)',
  'LABORATORY TECHNOLOGIST',
  'LABORATORY TECHNOLOGIST, MEDICAL',
  "LADIES' HAIRDRESSER",
  'LAMINATED GLASS WORKER',
  'LAND SURVEYING TECHNICIAN',
  'LAND SURVEYOR',
  'LANDSCAPE ARCHITECT',
  'LANDSCAPE ARCHITECTURE ASSISTANT',
  'LANDSCAPE DESIGNER',
  'LANDSCAPE MAINTENANCE MANAGER',
  'LANDSCAPE MAINTENANCE SUPERVISOR',
  'Landscape Nursery Supervisor',
  'LANDSCAPE OPERATIONS MANAGER',
  'LANDSCAPE OPERATIONS OFFICER',
  'Landscape Technician',
  'Language Instructor (Extracurriculum)',
  'LANGUAGE SCHOOL TEACHER',
  'LANGUAGE SPECIALIST',
  'LANGUAGE TEACHER',
  'LANGUAGE TEACHER (LANGUAGE SCHOOL)',
  'LASER ENGINEER',
  'LASER SCAN OPERATOR',
  'LASER TECHNICIAN',
  'LAST MILE DELIVERY DRIVER (VAN)',
  'LATHE OPERATOR (METALWORKING)',
  'LATHE SETTER-OPERATOR',
  'Laundry And Dry Cleaning Worker (Machine, Non-Household)',
  'Laundry Manager (Hotel And Accommodation Services)',
  'LAW CLERK',
  'Lawyer (Excluding Advocate And Solicitor)',
  'LAYOUT ENGINEER',
  'LAYOUT MANAGER',
  'LEAD COMMISSIONING ENGINEER',
  'LEAD CONTRACTS ENGINEER',
  'LEAD CONTROL AND INSTRUMENTATION ENGINEER',
  'LEAD COST ENGINEER',
  'LEAD CUSTOMER EXPERIENCE DESIGNER',
  'LEAD DESIGNER',
  'LEAD ELECTRICAL ENGINEER',
  'LEAD ENGINEER',
  'LEAD ENGINEER (CIVIL)',
  'LEAD INSTRUMENTATION ENGINEER',
  'LEAD MECHANICAL COMMISSIONING ENGINEER',
  'LEAD MECHANICAL ENGINEER',
  'LEAD PRODUCTION TECHNICIAN',
  'LEAD PROGRAMMER',
  'LEAD STRUCTURAL STEEL DESIGNER',
  'Lead Teacher (Early Childhood Care And Education)',
  'LEAD TECHNICIAN',
  'LEAD VALIDATION ENGINEER',
  'LEADERSHIP DEVELOPMENT MANAGER',
  'LEARNING & DEVELOPMENT EXECUTIVE',
  'LEARNING & DEVELOPMENT HEAD',
  'LEARNING & DEVELOPMENT MANAGER',
  'LEARNING AND DEVELOPMENT ANALYST',
  'LEARNING AND DEVELOPMENT MANAGER',
  'LEARNING CENTRE MANAGER',
  'LEARNING DESIGNER',
  'LEARNING DEVELOPMENT SPECIALIST',
  'LEARNING SUPPORT ASSISTANT',
  'LEARNING SUPPORT TEACHER',
  'LEASE MANAGEMENT EXECUTIVE',
  'LEASE MANAGEMENT MANAGER',
  'LEATHER GOODS MAKER',
  'Leather Goods Maker / Assembler',
  'LEATHER SEWER',
  'LEATHER TANNER',
  'LECTURER',
  'LECTURER, POLYTECHNIC',
  'LECTURER, UNIVERSITY',
  'LEGAL ASSISTANT',
  'LEGAL ASSOCIATE',
  'Legal Associate Professional (Eg Paralegal)',
  'LEGAL CLERK',
  'LEGAL CONSULTANT',
  'LEGAL COUNSEL (IN-HOUSE)',
  'LEGAL EXECUTIVE (PUBLIC OR PRIVATE CORPORATION OR ORGANISATION)',
  'LEGAL INTERN',
  'LEGAL MANAGER',
  'Legal Officer',
  'LEGAL SECRETARY',
  'Legal Technology Manager',
  'LENDING SPECIALIST',
  'LENS GRINDING MACHINE OPERATOR',
  'LIAISON OFFICER',
  'LIBRARIAN',
  'LIBRARY ASSISTANT',
  'LIBRARY OFFICER',
  'Licensed Aircraft Maintenance Engineer - B1 (Mechanical) (Aircraft Maintenance)',
  'Licensed Aircraft Maintenance Engineer - B2 (Avionics) (Aircraft Maintenance)',
  'LICENSING COMPLIANCE MANAGER',
  'LICENSING OPERATIONS SPECIALIST',
  'LIFEGUARD',
  'LIFESTYLE CONSULTANT',
  'LIFT ENGINEER',
  'LIFT MAINTENANCE SUPERVISOR',
  'LIFT SUPERVISOR',
  'LIFT TECHNICIAN',
  'LIFTING ENGINEER',
  'Lifting Supervisor',
  'LIFTING TRUCK OPERATOR',
  'LIGHTING CAMERAMAN',
  'LIGHTING DESIGNER',
  'LIGHTING TECHNICIAN',
  'LINE LEADER',
  'LINEMAN, TELECOMMUNICATIONS AND TELEPHONE',
  'LIQUEFIED PETROLEUM GAS (LPG) TEAM LEADER',
  'LIVING QUARTERS ENGINEER',
  'LOAD-SHEET OFFICER (AIRCRAFT)',
  'LOANS OFFICER',
  'LOBBY EXECUTIVE',
  'LODGING HOUSE MANAGER',
  'LOGISTICS ADMINISTRATOR',
  'LOGISTICS ANALYST',
  'LOGISTICS AND PROCUREMENT COORDINATOR',
  'LOGISTICS ASSISTANT',
  'LOGISTICS CLERK',
  'Logistics Contracts Executive',
  'Logistics Contracts Manager',
  'LOGISTICS COORDINATOR',
  'LOGISTICS CUSTOMER SERVICE CENTRE AGENT',
  'Logistics Data Management Coordinator',
  'Logistics Data Specialist',
  'LOGISTICS DIRECTOR',
  'LOGISTICS ENGINEER',
  'LOGISTICS EXECUTIVE',
  'Logistics Innovation & Process Improvement Manager',
  'Logistics It Executive',
  'LOGISTICS MANAGER',
  'LOGISTICS OFFICER',
  'LOGISTICS OPERATIONS ANALYST',
  'LOGISTICS OPERATIONS EXECUTIVE',
  'LOGISTICS OPERATIONS SPECIALIST',
  'LOGISTICS PLANNER',
  'LOGISTICS PROJECT MANAGER',
  'Logistics Solutions & Implementation Director',
  'Logistics Solutions Analyst',
  'Logistics Solutions Manager',
  'Logistics Solutions Specialist',
  'LOGISTICS SUPERVISOR',
  'LORRY DRIVER',
  'LORRY, MOTOR, MECHANIC',
  'LOUNGE MANAGER',
  'Macaroni / Noodle / Vermicelli Making Machine Operator',
  'MACHINE OPERATOR',
  'MACHINE OPERATOR, ASBESTOS CEMENT PRODUCT',
  'MACHINE OPERATOR, BACK-END (PAPERMAKING)',
  'MACHINE OPERATOR, BLENDING (CHEMICAL AND RELATED PROCESSES)',
  'MACHINE OPERATOR, BLENDING (COFFEE)',
  'MACHINE OPERATOR, BOOK-SEWING',
  'MACHINE OPERATOR, BORING (METALWORKING)',
  'MACHINE OPERATOR, CAPPING AND SEALING',
  'MACHINE OPERATOR, CHEMICAL PRODUCTS',
  'MACHINE OPERATOR, DAIRY PRODUCT PROCESSING (GENERAL)',
  'Machine Operator, Earth Boring (Construction)',
  'MACHINE OPERATOR, GLASS TEMPERING',
  'MACHINE OPERATOR, MEAT PROCESSING',
  'MACHINE OPERATOR, METAL BENDING',
  'MACHINE OPERATOR, METAL BORING',
  'MACHINE OPERATOR, METAL BUFFING AND POLISHING',
  'MACHINE OPERATOR, METAL GRINDING',
  'MACHINE OPERATOR, METAL MILLING',
  'MACHINE OPERATOR, METALWORKING (GENERAL)',
  'MACHINE OPERATOR, MILLING (METALWORKING)',
  'MACHINE OPERATOR, MIXING (CHEMICAL AND RELATED PROCESSES)',
  'MACHINE OPERATOR, MOULDING (PLASTICS)',
  'MACHINE OPERATOR, NUMERICAL CONTROL (METALWORKING)',
  'MACHINE OPERATOR, PACKING',
  'MACHINE OPERATOR, PAPER PRODUCT MAKING',
  'MACHINE OPERATOR, PAPERBOARD',
  'MACHINE OPERATOR, PLASTIC PRODUCT MAKING',
  'MACHINE OPERATOR, POLISHING (METALWORKING)',
  'MACHINE OPERATOR, PRECISION GRINDING (METALWORKING)',
  'MACHINE OPERATOR, PRINTING',
  'MACHINE OPERATOR, SAUCE MAKING',
  'MACHINE OPERATOR, SEWING (GARMENTS)',
  'MACHINE OPERATOR, SHAPING (WOODWORKING)',
  'MACHINE OPERATOR, SHEARING (METAL CUTTING)',
  'MACHINE OPERATOR, SPICE MILLING',
  'MACHINE OPERATOR, TAMPING (CONSTRUCTION)',
  'MACHINE OPERATOR, TUNNELLING (CONSTRUCTION)',
  'MACHINE PACKER',
  'MACHINE SETTER, HONING (METALWORKING)',
  'MACHINE SETTER, METAL BENDING',
  'MACHINE SETTER, METAL GRINDING',
  'MACHINE SETTER, METAL MILLING',
  'MACHINE SETTER, METALWORKING (GENERAL)',
  'MACHINE SETTER, MILLING (METALWORKING)',
  'MACHINE SETTER, NAIL MAKING',
  'MACHINE SETTER, NUMERICAL CONTROL (METALWORKING)',
  'MACHINE SETTER, PRECISION GRINDING (METALWORKING)',
  'MACHINE SEWER (GENERAL)',
  'MACHINE TOOL GRINDER',
  'MACHINERY AND TOOLS ENGINEER (INDUSTRIAL)',
  'MACHINERY ENGINEER',
  'MACHINERY ENGINEERING CONSULTANT',
  'MACHINERY ERECTOR AND INSTALLER',
  'MACHINERY FITTER',
  'MACHINERY FITTER (GENERAL)',
  'MACHINERY FITTER-ASSEMBLER (GENERAL)',
  'MACHINERY MAINTENANCE ENGINEER',
  'MACHINERY MECHANIC',
  'MACHINERY MECHANIC (GENERAL)',
  'MACHINERY TECHNICIAN',
  'Machining / Tooling Technician',
  'MACHINING AND TOOLING TECHNICIAN',
  'MACHINING TECHNICIAN',
  'MACHINIST',
  'Machinist (Precision Engineering)',
  'MAIL ROOM SUPERVISOR',
  'MAINTENANCE CARPENTER',
  'MAINTENANCE COORDINATOR',
  'MAINTENANCE ELECTRICIAN (BUILDING)',
  'MAINTENANCE ENGINEER',
  'MAINTENANCE FITTER',
  'MAINTENANCE MAN, BUILDING',
  'MAINTENANCE MANAGER',
  'MAINTENANCE OFFICER',
  'MAINTENANCE PLANNER',
  'MAINTENANCE SCHEDULER',
  'MAINTENANCE SUPERVISOR',
  'MAINTENANCE TECHNICIAN',
  "MAITRE D'HOTEL",
  'MAKEUP ARTIST',
  'MAKE-UP ARTIST (STAGE AND STUDIO)',
  'MANAGEMENT ACCOUNTANT',
  'MANAGEMENT ASSISTANT OFFICER',
  'Management Associate',
  'MANAGEMENT CONSULTANT',
  'MANAGEMENT ENGINEER',
  'MANAGEMENT EXECUTIVE',
  'MANAGEMENT INFORMATION SYSTEMS EXECUTIVE',
  'MANAGEMENT INFORMATION SYSTEMS MANAGER',
  'MANAGEMENT SUPERVISOR',
  'MANAGEMENT SUPPORT EXECUTIVE',
  'MANAGEMENT TRAINEE',
  'MANAGER',
  'Manager (Electronics)',
  'MANAGER, ACCOUNTING (FINANCIAL DEPARTMENT)',
  'MANAGER, ADMINISTRATION',
  'MANAGER, ADVERTISING',
  'MANAGER, AIRPORT OPERATIONS',
  'MANAGER, ASSISTANT (ADMINISTRATION)',
  'MANAGER, ASSISTANT (GENERAL)',
  'MANAGER, ASSISTANT (PRODUCTION)',
  'MANAGER, ASSISTANT (PUBLIC RELATIONS)',
  'MANAGER, ASSISTANT (SALES)',
  'MANAGER, BANK (BRANCH)',
  'MANAGER, BANQUET (HOTEL)',
  'MANAGER, BAR',
  'MANAGER, BOARDING HOUSE',
  'MANAGER, BUSINESS DEVELOPMENT',
  'MANAGER, CHAIN STORE',
  'MANAGER, COMPUTER AND SYSTEMS INFORMATION',
  'MANAGER, COMPUTER OPERATIONS AND NETWORK',
  'MANAGER, CONSULTING',
  'MANAGER, CORPORATE PLANNING',
  'MANAGER, CUSTOMER RELATIONS (HOTEL)',
  'MANAGER, CUSTOMER RELATIONSHIP MANAGEMENT',
  'MANAGER, ELECTRONIC DATA PROCESSING',
  'MANAGER, EXPORT',
  'MANAGER, FARM',
  'MANAGER, FAST FOOD RESTAURANT',
  'MANAGER, FINANCE',
  'MANAGER, FOOD AND BEVERAGE DEPARTMENT (HOTEL)',
  'MANAGER, FRONT OFFICE (HOTEL)',
  'MANAGER, FUND',
  'MANAGER, HOTEL',
  'MANAGER, HUMAN RESOURCE',
  'MANAGER, INDUSTRIAL RELATIONS',
  'MANAGER, INFORMATION SYSTEMS',
  'MANAGER, INFORMATION TECHNOLOGY',
  'MANAGER, MARKETING',
  'MANAGER, MUSIC LOUNGE',
  'MANAGER, NIGHTCLUB',
  'MANAGER, OCEAN FREIGHT STRATEGY & DEVELOPMENT',
  'MANAGER, OPERATIONS (CONSTRUCTION)',
  'MANAGER, OPERATIONS (TELECOMMUNICATIONS)',
  'MANAGER, OPERATIONS (TRANSPORT)',
  'MANAGER, PERSONNEL',
  'MANAGER, PETROCHEMICALS',
  'MANAGER, PROCUREMENT',
  'MANAGER, PRODUCTION (CONSTRUCTION)',
  'MANAGER, PRODUCTION (MANUFACTURING)',
  'MANAGER, PROJECT (CONSTRUCTION)',
  'MANAGER, PUBLIC RELATIONS',
  'MANAGER, PUBLICITY',
  'MANAGER, PURCHASING',
  'MANAGER, QUALITY ASSURANCE',
  'MANAGER, RESEARCH AND DEVELOPMENT',
  'MANAGER, RESTAURANT',
  'MANAGER, RETAIL TRADE',
  'MANAGER, STAGE',
  'MANAGER, STORES DEPARTMENT (MANUFACTURING COMPANY)',
  'MANAGER, SUPERMARKET',
  'MANAGER, SUPPLIES',
  'MANAGER, SUPPLY AND DISTRIBUTION',
  'MANAGER, TRAINING',
  'MANAGER, TRANSPORT OPERATIONS DEPARTMENT',
  'MANAGER, TREASURY',
  'MANAGER, WAREHOUSING DEPARTMENT',
  'MANAGER, WHOLESALE TRADE',
  'MANAGING DIRECTOR',
  'MANAGING DIRECTOR (BANKING INDUSTRY)',
  'MANAGING DIRECTOR (COMPANY)',
  'Managing Director (Electronics)',
  'MANAGING DIRECTOR (MANUFACTURING INDUSTRY)',
  'MANAGING DIRECTOR (MARINE INDUSTRY)',
  'MANAGING DIRECTOR (MEDIA INDUSTRY)',
  'MANAGING DIRECTOR (STATUTORY BOARD)',
  'Managing Director / Chief Executive Officer',
  'MANAGING EDITOR',
  'MANAGING PARTNER',
  'Manicurist',
  'Manicurist (Non-Massage)',
  'MANIPULATIVE THERAPIST',
  'MANPOWER DEVELOPMENT MANAGER',
  "MANUFACTURER'S SALES REPRESENTATIVE",
  'MANUFACTURING ANALYST',
  'MANUFACTURING APPLICATIONS SUPPORT ANALYST',
  'MANUFACTURING ASSISTANT',
  'MANUFACTURING DIRECTOR',
  'MANUFACTURING ENGINEER',
  'Manufacturing Engineer (Electronics)',
  'MANUFACTURING ENGINEER (GENERAL)',
  'Manufacturing Engineer (Manufacturing)',
  'MANUFACTURING ENGINEER, ASSISTANT',
  'MANUFACTURING ENGINEERING ASSISTANT (GENERAL)',
  'MANUFACTURING ENGINEERING MANAGER',
  'MANUFACTURING ENGINEERING TECHNICIAN',
  'MANUFACTURING ENGINEERING TECHNICIAN (GENERAL)',
  'MANUFACTURING EXCELLENCE DIRECTOR',
  'MANUFACTURING EXECUTIVE',
  'MANUFACTURING MANAGER',
  'MANUFACTURING OPERATIONS MANAGER',
  'Manufacturing Plant / Production Manager',
  'MANUFACTURING PLANT MANAGER',
  'MANUFACTURING PRODUCTION MANAGER',
  'MANUFACTURING SPECIALIST',
  'MANUFACTURING SUPERVISOR',
  'MANUFACTURING SYSTEMS ENGINEER',
  'MANUFACTURING TECHNICIAN',
  'MANUFACTURING TEST ENGINEER',
  'MARINE BIOLOGIST',
  'MARINE CIVIL ENGINEER',
  'MARINE CONSULTANT',
  'Marine Draughtsman',
  'MARINE ELECTRICAL ENGINEER',
  'MARINE ELECTRICAL TECHNICIAN',
  'MARINE ELECTRONIC ENGINEER',
  'MARINE ENGINE FITTER',
  'MARINE ENGINEER',
  'Marine Engineer (Shore-Based)',
  'MARINE ENGINEER OFFICER',
  'MARINE ENGINEERING ASSISTANT',
  'MARINE ENGINEERING DRAUGHTSMAN',
  'MARINE ENGINEERING TECHNICIAN',
  'MARINE EQUIPMENT ENGINEER',
  'MARINE INSPECTION ENGINEER',
  'MARINE MANAGER',
  'MARINE MECHANIC',
  'MARINE MECHANICAL ENGINEER',
  'MARINE MECHANICAL ENGINEERING TECHNICIAN',
  'MARINE OPERATIONS EXECUTIVE',
  'MARINE OPERATIONS MANAGER',
  'MARINE PIPE FITTER',
  'MARINE PIPING ENGINEER',
  'MARINE REPAIR ENGINEER (SHORE-BASED)',
  'MARINE SAFETY SUPERINTENDENT',
  'MARINE SERVICE ENGINEER',
  'MARINE SUPERINTENDENT',
  'MARINE SUPERINTENDENT (DECK)',
  'MARINE SUPERINTENDENT (TECHNICAL)',
  'MARINE SUPERINTENDENT ENGINEER',
  'MARINE SUPERVISOR',
  'MARINE SURVEYOR',
  'MARINE SURVEYOR (SHIP AND NAUTICAL)',
  'MARINE SYSTEMS ENGINEER',
  'MARINE TECHNICIAN',
  'MARINE TRADE WORKER',
  'MARITIME CENTRE MANAGER',
  'MARKER, SHEET METAL',
  'MARKER, STRUCTURAL METAL',
  'MARKER, WOODWORKING',
  'MARKET ANALYST',
  'MARKET COORDINATOR',
  'MARKET DATA ADMINISTRATOR',
  'MARKET DEVELOPMENT EXECUTIVE',
  'MARKET DEVELOPMENT MANAGER',
  'MARKET PLANNER',
  'MARKET RESEARCH ANALYST',
  'MARKET RESEARCH INTERVIEWER',
  'MARKET RESEARCH SUPERVISOR',
  'MARKET RESEARCHER',
  'MARKETING ADVISOR',
  'Marketing And Sales Representative (Food & Beverage Services)',
  'Marketing And Sales Representative (Ict)',
  'Marketing And Sales Representative (Institutional Sales Of Financial Products)',
  'Marketing And Sales Representative (Medical And Pharmaceutical Products)',
  'Marketing And Sales Representative (Technical)',
  'MARKETING ASSISTANT',
  'MARKETING ASSISTANT MANAGER',
  'MARKETING ASSOCIATE',
  'Marketing Associate (Retail)',
  'MARKETING COMMUNICATIONS EXECUTIVE',
  'MARKETING COMMUNICATIONS MANAGER',
  'MARKETING CONSULTANT',
  'MARKETING COORDINATOR',
  'MARKETING DIRECTOR',
  'Marketing Director (Retail)',
  'MARKETING ENGINEER',
  'MARKETING EXECUTIVE',
  'MARKETING EXECUTIVE (EXCLUDING FOOD & BEVERAGE SERVICES)',
  'MARKETING EXECUTIVE (PLANNING)',
  'Marketing Executive (Retail)',
  'MARKETING MANAGER',
  'Marketing Manager (Retail)',
  'MARKETING OFFICER',
  'MARKETING OPERATIONS MANAGER',
  'MARKETING PLANNING PROFESSIONAL',
  'MARKETING PROJECT MANAGER',
  'MARKETING REPRESENTATIVE',
  'MARKETING SALES EXECUTIVE',
  'MARKETING SPECIALIST, TECHNICAL PUBLICATIONS',
  'MARKETING STRATEGIST',
  'MARKETING STRATEGY MANAGER',
  'MARKETING STRATEGY PROFESSIONAL',
  'MARKETING SUPERVISOR',
  'MARKETING SUPPORT EXECUTIVE',
  'MARTIAL ARTS INSTRUCTOR',
  'MASON, STONE (CONSTRUCTION)',
  'Massage Therapist',
  'Masseur (Medical)',
  'Masseur (Non-Medical)',
  'Masseur (Non-Medical) (Including Foot Reflexologist)',
  'Master (Special Limit)',
  'MASTER CHEF',
  'Master Craftsman (Precision Engineering)',
  'MASTER HAIRSTYLIST',
  'MASTER SCHEDULERS - SUPPLY CHAIN',
  'MASTER, SHIP',
  'MATE, SECOND (SHIP)',
  'MATERIAL AND FREIGHT HANDLING WORKER',
  'MATERIAL CONTROL EXECUTIVE',
  'MATERIAL CONTROLLER',
  'MATERIAL COORDINATOR',
  'MATERIAL HANDLER',
  'MATERIAL HANDLING SUPERVISOR',
  'MATERIAL PLANNER',
  'MATERIAL PLANNING CLERK',
  'MATERIALS CONTROLLER',
  'MATERIALS ENGINEER',
  'MATERIALS LOGISTICS MANAGER',
  'MATERIALS MANAGER',
  'MATERIALS PLANNER',
  'MATERIALS PROCUREMENT REPRESENTATIVE',
  'MATERIALS PRODUCTION ENGINEER',
  'MATERIALS RESOURCE OFFICER',
  'MATHEMATICAL TECHNICIAN',
  'MATHEMATICIAN',
  'MEASUREMENT TECHNICIAN',
  'MEAT BONER',
  'MEAT CURER',
  'MEAT CUTTER',
  'MEAT PROCESSING MACHINE OPERATOR',
  'MECHANIC',
  'MECHANIC, AERONAUTICAL',
  'MECHANIC, AIR-CONDITIONING AND REFRIGERATING EQUIPMENT',
  'MECHANIC, AUTOMOBILE',
  'MECHANIC, BUS',
  'MECHANIC, COMPUTER AND RELATED EQUIPMENT',
  'MECHANIC, CONSTRUCTION MACHINERY',
  'MECHANIC, DIESEL ENGINE (EXCEPT MOTOR VEHICLE)',
  'MECHANIC, EARTH MOVING EQUIPMENT',
  'MECHANIC, GARAGE (AUTOMOBILE)',
  'MECHANIC, INDUSTRIAL MACHINERY',
  'MECHANIC, MACHINERY (GENERAL)',
  'MECHANIC, METALWORKING MACHINE TOOL',
  'MECHANIC, MOTOR (GENERAL)',
  'MECHANIC, MOTOR TRUCK',
  'MECHANIC, MOTOR VEHICLE (AUTOMOBILE)',
  'MECHANIC, MOTOR-CYCLE',
  'MECHANIC, PLANT MAINTENANCE',
  "MECHANIC, SHIP'S ENGINE-ROOM",
  'MECHANIC, TELEPHONE AND TELECOMMUNICATIONS',
  'MECHANIC, TELEPHONE AND TELEGRAPH',
  'MECHANIC, TRACTOR',
  'MECHANIC, TRUCK',
  'MECHANIC, WORKSHOP (AUTOMOBILE)',
  'MECHANICAL & ELECTRICAL (M&E) COORDINATOR',
  'MECHANICAL & ELECTRICAL (M&E) ENGINEER',
  'MECHANICAL & ELECTRICAL (M&E) SUPERVISOR',
  'MECHANICAL & ELECTRICAL (M&E) TECHNICIAN',
  'MECHANICAL ADVISOR',
  'MECHANICAL ASSISTANT',
  'MECHANICAL COMMISSIONING ENGINEER',
  'MECHANICAL COMMISSIONING SUPERVISOR',
  'MECHANICAL COMPONENT ASSEMBLER',
  'MECHANICAL DESIGN ENGINEER',
  'MECHANICAL DESIGNER',
  'MECHANICAL DRAUGHTSMAN',
  'MECHANICAL ENGINEER',
  'MECHANICAL ENGINEER (AUTOMOTIVE)',
  'MECHANICAL ENGINEER (GENERAL)',
  'MECHANICAL ENGINEER (HEATING, VENTILATION AND REFRIGERATION)',
  'MECHANICAL ENGINEER (INDUSTRIAL MACHINERY AND TOOLS)',
  'MECHANICAL ENGINEER (MARINE)',
  'MECHANICAL ENGINEER (MOTORS AND ENGINES, EXCEPT MARINE)',
  'MECHANICAL ENGINEER, ASSISTANT',
  'MECHANICAL ENGINEERING ASSISTANT (GENERAL)',
  'MECHANICAL ENGINEERING ASSISTANT (MOTORS AND ENGINES)',
  'MECHANICAL ENGINEERING MANAGER',
  'MECHANICAL ENGINEERING TECHNICIAN',
  'MECHANICAL ENGINEERING TECHNICIAN (GENERAL)',
  'MECHANICAL ENGINEERING TECHNICIAN (MOTORS AND ENGINES)',
  'MECHANICAL EQUIPMENT DESIGN ENGINEER',
  'MECHANICAL EQUIPMENT TECHNICIAN',
  'MECHANICAL FITTER',
  'MECHANICAL MAINTENANCE ENGINEER',
  'MECHANICAL MAINTENANCE TECHNICIAN',
  'MECHANICAL PACKAGE ENGINEER',
  'MECHANICAL PIPING ENGINEER',
  'MECHANICAL PRODUCTS CHECKER (QUALITY ASSURANCE)',
  'MECHANICAL PRODUCTS QUALITY CHECKER',
  'MECHANICAL SUPERINTENDENT',
  'MECHANICAL SUPERVISOR',
  'MECHANICAL TECHNICIAN',
  'MECHANIST',
  'MECHANTRONICS ENGINEER',
  'MECHANTRONICS TECHNICIAN',
  'Mechatronics Technician',
  'MEDIA ANALYST',
  'MEDIA AND BROADCASTING MANAGER',
  'MEDIA AND PUBLIC RELATIONS DIRECTOR',
  'MEDIA DESIGNER',
  'MEDIA DIRECTOR',
  'MEDIA ENGINEER',
  'MEDIA EXECUTIVE',
  'MEDIA MANAGER',
  'MEDIA SALES EXECUTIVE',
  'MEDIA SALES MANAGER',
  'Medical / Dental Receptionist',
  'MEDICAL ADVISOR',
  'MEDICAL AFFAIRS DIRECTOR',
  'MEDICAL AFFAIRS SENIOR DIRECTOR',
  'MEDICAL ASSISTANT',
  'MEDICAL COORDINATOR (REGISTRATION)',
  'Medical Diagnostic Radiographer',
  'MEDICAL DIRECTOR',
  'MEDICAL DOCTOR',
  'MEDICAL DOCTOR (GENERAL MEDICAL PRACTICE)',
  'MEDICAL IMAGING SPECIALIST',
  'MEDICAL LABORATORY TECHNOLOGIST',
  'MEDICAL MANAGER',
  'MEDICAL OFFICER',
  'MEDICAL RECEPTIONIST',
  'MEDICAL RESEARCH OFFICER',
  'MEDICAL SALES REPRESENTATIVE',
  'MEDICAL SCIENTIST',
  'MEDICAL SOCIAL WORKER',
  'MEDICAL TECHNICIAN',
  'MEDICAL TECHNOLOGIST',
  'MEDICAL TRANSCRIPTIONIST',
  'MEDICAL WRITER',
  'MEDICO MARKETING MANAGER',
  'MEMBERSHIP LIAISON OFFICER',
  "MEN'S HAIRDRESSER",
  'MENTALLY HANDICAPPED, TEACHER OF THE',
  'MERCHANDISE BUYER (WHOLESALE OR RETAIL TRADE)',
  'MERCHANDISE PURCHASER (WHOLESALE OR RETAIL TRADE)',
  'MERCHANDISER',
  'MERCHANDISER, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'MERCHANDISING AND CATEGORY MANAGER',
  'MERCHANDISING ASSISTANT',
  'MERCHANDISING ASSISTANT MANAGER',
  'MERCHANDISING ASSOCIATE',
  'Merchandising Director',
  'MERCHANDISING EXECUTIVE',
  'MERCHANDISING MANAGER',
  'MERGERS AND ACQUISITIONS DIRECTOR',
  'METAL BENDING MACHINE OPERATOR',
  'METAL CUTTER (FLAME)',
  'METAL FINISHER',
  'METAL GRINDING MACHINE SETTER-OPERATOR',
  'Metal Heat Treating Plant Operator',
  'METAL MARKER',
  'METAL MILLING MACHINE OPERATOR',
  'METAL PRESS OPERATOR',
  'METAL PRODUCTION MANAGER',
  'METAL PRODUCTS ASSEMBLER',
  'METAL REHEATING FURNACEMAN',
  'METAL ROLLING MILL WORKER',
  'METAL SPRAYER',
  'METAL WORKING MACHINE TOOL FITTER',
  'METAL WORKING MACHINE TOOL FITTER-ASSEMBLER',
  'METALLURGICAL TECHNICIAN (EXTRACTIVE)',
  'METALLURGIST',
  'METALWORKING LATHE OPERATOR',
  'METALWORKING LATHE SETTER-OPERATOR',
  'METALWORKING MACHINE SETTER-OPERATOR',
  'METALWORKING MACHINE SETTER-OPERATOR (GENERAL)',
  'METALWORKING MACHINE-TOOL OPERATOR (GENERAL)',
  'METEOROLOGIST',
  'METER READER',
  'METHOD ENGINEER',
  'METHODS ENGINEER',
  'METROLOGY ENGINEER',
  'MICROBIOLOGIST',
  'MIDWIFE',
  'MILLER, GRAIN',
  'Milling Machine Setter-Operator',
  'MILLING MACHINE SETTER-OPERATOR (METALWORKING)',
  'MILLWRIGHT',
  'MINER',
  'MINISTER OF RELIGION',
  'MOBILE CRANE OPERATOR',
  'MOBILE ENGINEER',
  'MOBILE MACHINERY SUPERVISOR AND GENERAL FOREMAN',
  'MOBILE PRODUCT MANAGER',
  'MOBILE SERVICE ELECTRICIAN',
  'MOBILE SERVICE ENGINEER',
  'MOBILE SERVICE MECHANIC',
  'MODEL MAKER (WOOD)',
  'MONEY BROKER',
  'MONEY CHANGER',
  'MONEY MARKET DEALER',
  'MONK',
  'MONK (ORDAINED)',
  'MONTESSORI TEACHER',
  'MORTGAGE ANALYST',
  'MORTICIAN',
  'Motion Graphics Designer',
  'MOTION PICTURE CAMERA OPERATOR',
  'MOTOR BUS MECHANIC',
  'MOTOR CAR MECHANIC',
  'MOTOR EQUIPMENT TECHNICIAN',
  'MOTOR MECHANIC',
  'MOTOR MECHANIC (GENERAL)',
  'MOTOR SERVICE ADVISOR',
  'Motor Vehicle Mechanic',
  'MOTOR VEHICLE MECHANIC (AUTOMOBILE)',
  'MOTOR VEHICLE MECHANIC SUPERVISOR',
  'MOTOR VEHICLE PAINTER',
  'Motor Vehicle Spray Painter',
  'MOTOR VEHICLE TECHNICIAN',
  'MOTOR-CYCLE DELIVERY MAN',
  'MOTORCYCLE MECHANIC',
  'MOTORMAN, SHIP',
  'MOULD DESIGNER',
  'MOULD LOFTER',
  'MOULD MAINTENANCE SENIOR TECHNICIAN',
  'MOULD MAKER, POTTERY AND PORCELAIN',
  'MOULDER (METAL)',
  'MOULDER, SQUEEZE MACHINE',
  'MOULDING ENGINEER',
  'MOULDMAKER',
  'MOVE COORDINATOR',
  'MOVE MANAGEMENT EXECUTIVE',
  'MUD-PLANT OPERATOR (OIL AND GAS WELLS)',
  'MULTIMEDIA ANIMATOR',
  'MULTIMEDIA ARTIST',
  'MULTIMEDIA ARTIST AND ANIMATOR',
  'MULTIMEDIA DESIGNER',
  'MULTIMEDIA DEVELOPER',
  'MULTIMEDIA EXECUTIVE',
  'MULTIMEDIA MANAGER',
  'MULTIMEDIA MARKETING MANAGER',
  'MULTIMEDIA PROGRAMMER',
  'Multi-Outlet Manager',
  'MUSIC COMPOSER',
  'MUSIC DIRECTOR',
  'MUSIC INSTRUCTOR',
  'Music Instructor (Extracurriculum)',
  'MUSIC TEACHER (OTHER THAN SECONDARY SCHOOL)',
  'MUSIC TEACHER (PRIVATE TUITION)',
  'MUSIC THERAPIST',
  'MUSICAL INSTRUMENT MAKER (STRINGED)',
  'Musical Instrument Maker / Repairer / Tuner',
  'MUSICAL INSTRUMENT REPAIRER',
  'MUSICAL INSTRUMENT TUNER',
  'MUSICAL PROGRAMME DIRECTOR',
  'MUSICIAN',
  'MUSICIAN, INSTRUMENTAL',
  'Nail Technician',
  'NANO-ENGINEER',
  'NAVAL ARCHITECT',
  "NAVIGATING OFFICER, SHIP'S",
  'NEON-LIGHTING ELECTRICIAN',
  'NETBALL COACH',
  'Network / Infrastructure Architect And Engineer',
  'NETWORK ADMINISTRATOR',
  'NETWORK ANALYST',
  'NETWORK AND COMMUNICATIONS MANAGER',
  'NETWORK ARCHITECT',
  'NETWORK CONSULTANT',
  'NETWORK CONSULTING ENGINEER',
  'NETWORK ENGINEER',
  'NETWORK ENGINEER/MANAGER',
  'NETWORK MANAGEMENT CENTRE ENGINEER',
  'NETWORK MANAGER',
  'NETWORK OPERATIONS ENGINEER',
  'NETWORK SECURITY ANALYST',
  'NETWORK SPECIALIST',
  'NETWORK STRATEGY MANAGER',
  'NETWORK SUPPORT ANALYST',
  'NETWORK SUPPORT ENGINEER',
  'NETWORK SUPPORT TECHNICIAN',
  'NETWORK SYSTEMS ANALYST',
  'NETWORK SYSTEMS ENGINEER',
  'NETWORK SYSTEMS PROGRAMMER',
  'NETWORK TECHNICIAN',
  'NETWORK TRANSMISSION EXECUTIVE',
  'NETWORKING EXECUTIVE',
  'NEURO TECHNICIAN',
  'NEW PRODUCT INTRODUCTION ENGINEER',
  'NEWS EDITOR (NEWSPAPER)',
  'NEWSPAPER CORRESPONDENT',
  'NIGHT MANAGER',
  'NON-DESTRUCTIVE TESTING (NDT) TECHNICIAN',
  'NONDESTRUCTIVE TESTING ENGINEER',
  'NONDESTRUCTIVE TESTING INSPECTOR',
  'NONDESTRUCTIVE TESTING OPERATOR',
  'NONDESTRUCTIVE TESTING SUPERVISOR',
  'NON-EXECUTIVE DIRECTOR',
  'NON-OFFICER',
  'NON-ORDAINED RELIGIOUS ASSOCIATE PROFESSIONAL',
  'NOODLE MAKING MACHINE OPERATOR',
  'NUCLEAR MEDICINE TECHNOLOGIST',
  'NUN',
  'NURSE',
  'NURSE MANAGER',
  'NURSE, DENTAL',
  'NURSERY SCHOOL TEACHER',
  'NURSERY WORKER (CHILD CARE)',
  'NURSING AID',
  'NURSING INSTRUCTOR',
  'NURSING OFFICER',
  'NUTRITIONIST',
  'OBSTETRICIAN, GYNAECOLOGIST, OBSTETRICIAN & GYNAECOLOGIST',
  'OCCUPATIONAL HEALTH AND SAFETY PROFESSIONAL',
  'Occupational Health Manager',
  'Occupational Health Professional',
  'Occupational Therapist',
  'OFFICE ATTENDANT',
  'OFFICE CASHIER',
  'OFFICE CLEANER',
  'OFFICE CLERK (GENERAL)',
  'OFFICE SUPERVISOR (GENERAL)',
  'OFFICER',
  'OFFSET PRESSMAN',
  'OFFSHORE INSTALLATION MANAGER',
  'OIL BROKER',
  'OIL SPILL SPECIALIST',
  'OIL TRADER',
  "OILER AND GREASER, SHIP'S",
  'OPERA SINGER',
  'OPERATING THEATRE TECHNICIAN',
  'OPERATION MANAGER',
  'Operation Officer',
  'OPERATION SUPERINTENDENT',
  'OPERATIONS ACCOUNTANT',
  'OPERATIONS ADMINISTRATOR',
  'OPERATIONS ADVISOR',
  'OPERATIONS ANALYST',
  'OPERATIONS ASSISTANT',
  'OPERATIONS CONTROLLER',
  'OPERATIONS COORDINATOR',
  'OPERATIONS DIRECTOR',
  'Operations Director (Food Services)',
  'OPERATIONS ENGINEER',
  'OPERATIONS EXECUTIVE',
  'Operations Executive (Sea Transport)',
  'OPERATIONS HEAD',
  'Operations Integration Specialist',
  'OPERATIONS LIAISON ASSISTANT',
  'OPERATIONS MANAGER',
  'OPERATIONS MANAGER (CONSTRUCTION)',
  'Operations Manager (Food Services)',
  'OPERATIONS MANAGER (GENERAL)',
  'Operations Manager (Manufacturing)',
  'Operations Manager (Marine And Offshore)',
  'OPERATIONS MANAGER (PORT)',
  'OPERATIONS MANAGER (POSTAL SERVICE)',
  'OPERATIONS MANAGER (TELECOMMUNICATIONS)',
  'OPERATIONS MANAGER (TRANSPORT)',
  'OPERATIONS MANAGER, COMPUTER',
  'OPERATIONS OFFICER',
  'Operations Officer (Except Transport Operations)',
  'OPERATIONS OFFICER (FINANCE)',
  'OPERATIONS RESEARCH ANALYST',
  'OPERATIONS SPECIALIST',
  'OPERATIONS SUPERINTENDENT',
  'OPERATIONS SUPERVISOR',
  'OPERATIONS TECHNICIAN',
  'OPERATOR',
  'Operator (Electronics)',
  'OPHTHALMIC INVESTIGATION TECHNOLOGIST',
  'OPTICAL ENGINEER',
  'OPTICIAN',
  'OPTICS ENGINEER',
  'OPTICS PHYSICIST',
  'OPTIMIZER',
  'OPTIONS BROKER',
  'OPTOMETRIST',
  'ORAL HEALTH THERAPIST',
  'ORAL HYGIENIST',
  'ORDER ADMINISTRATIVE ENGINEER',
  'ORDER CLERK (MATERIALS)',
  'Order Fulfilment Assistant',
  'Order Fulfilment Coordinator',
  'Order Management Executive',
  'ORF CONTROLLER',
  'ORGANISATION AND METHODS ENGINEER',
  'ORGANISATIONAL DEVELOPMENT DIRECTOR',
  'ORGANISATIONAL DEVELOPMENT MANAGER',
  'ORGANISATIONAL EXCELLENCE MANAGER',
  'ORGANISER, SALES (REGIONAL OR OVERSEAS)',
  'ORGANISER, STAFF TRAINING',
  'ORTHODONTIST',
  'ORTHOPAEDIC TECHNICIAN',
  'ORTHOPTIST',
  'OSTEOPATH',
  'Other Administrative And Related Associate Professionals Nec',
  'Other Agronomy, Horticultural And Farming Related Technicians',
  'Other Aircraft Pilots And Related Associate Professionals',
  'Other Assistant Engineers',
  'Other Associate Professionals Nec',
  'Other Biologists, Botanists, Zoologists And Related Professionals',
  'Other Brewers And Wine And Other Beverage Machine Operators',
  'Other Building And Fire Inspectors',
  'Other Chemical Engineers',
  'Other Chemical Processing And Chemical Products Plant And Machine Operators',
  'Other Civil Engineers',
  'Other Commercial And Marketing Sales Representatives',
  'Other Crane, Hoist And Related Equipment Operators',
  'Other Earth Moving And Related Machinery Operators',
  'Other Education Managers (Including Registrars And Deans Of Education Institutions)',
  'Other Education Methods Specialists',
  'Other Electrical Engineers',
  'Other Electrical Line Installers And Repairers',
  'Other Electronics Engineering Technicians',
  'Other Electronics Engineers',
  'Other Engineering Professionals Nec',
  'Other Environmental And Occupational Health And Hygiene Professionals',
  'Other Environmental Protection And Related Professionals',
  'Other Extracurriculum Instructors',
  'Other Farming, Forestry And Fishery Advisers',
  'Other Finance And Insurance Clerks (Eg Credit Clerk)',
  'Other Finance Dealers And Brokers',
  'Other Financial Analysts And Related Professionals (Eg Financial Product Structurer)',
  'Other Food And Related Products Machine Operators Nec',
  'Other Garment And Related Pattern-Makers And Cutters',
  'Other Graphic And Multimedia Designers And Artists',
  'Other Hair Care Workers (Eg Hair Therapist / Consultant)',
  'Other Health Associate Professionals Nec',
  'Other Health Professionals Nec',
  'Other Heavy Truck And Lorry Drivers',
  'Other Horticultural And Nursery Farm Workers',
  'Other Information Professionals',
  'Other Insulation Workers',
  'Other Life Science Technicians',
  'Other Lifting Truck Operators',
  'Other Machinery Mechanics And Repairers (Eg Agricultural Machinery Mechanic, Bicycle Repairer)',
  'Other Manufacturing Engineering Technicians',
  'Other Meat And Fish Preparers',
  'Other Meat And Fish Processing Machine Operators',
  'Other Mechanical Engineering Technicians',
  'Other Mechanical Engineers',
  "Other Models (Eg Artist'S Model)",
  'Other Painters And Related Workers',
  'Other Personal Care Worker (Eg Old Folks Home)',
  'Other Physical Science Professionals',
  'Other Pre-Press Trades Workers',
  'Other Printers',
  'Other Printing, Binding And Related Machine Operators',
  'Other Professional, Financial, Community And Social Services Managers Nec',
  'Other Professionals Nec',
  'Other Quality Checkers And Testers',
  'Other Receptionists And Information Clerks',
  'Other Related Planners (Eg Traffic Planner)',
  'Other Religious Professionals',
  'Other Riggers And Cable Splicers',
  "Other Ships' Deck Officers And Pilots",
  'Other Social Science Professionals (Eg Philosopher)',
  'Other Social Work And Counselling Professionals',
  'Other Special Education Teachers',
  'Other Statistical, Mathematical And Actuarial Associate Professionals',
  'Other Structural Metal Preparers And Erectors',
  'Other Teaching Professionals Nec',
  'Other Tellers And Counter Clerks',
  'Other Toolmakers And Related Workers',
  'Other Transport Controllers And Related Workers Nec',
  'Other Transport Operations Supervisors',
  'Other University, Polytechnic And Higher Education Teachers',
  'Other Upholsterers And Related Workers',
  'Other Visual Artists',
  'Other Water Treatment And Related Machine Operators',
  'Other Wellness Related Workers Nec',
  'OUTDOOR SALESMAN',
  'OUTLET MANAGER',
  'Outlet Manager (Food Services)',
  'OVERHEAD WIREMAN (ELECTRIC POWER)',
  "OWNER'S REPRESENTATIVE",
  'PACKAGE DESIGNER',
  'Package Development Engineer',
  'PACKAGING ENGINEER',
  'PACKAGING TECHNICIAN',
  'PACKER, MACHINE',
  'PAINT CHEMIST',
  'PAINT INSPECTOR',
  'Painter',
  'PAINTER (ARTIST)',
  'PAINTER SUPERVISOR',
  'PAINTER, AUTOMOBILE',
  'PAINTER, AUTOMOBILE REPAIR',
  'PAINTER, CONSTRUCTION',
  'PAINTER, MOTOR VEHICLE',
  'PAINTER, SHIP HULL AND STRUCTURAL STEEL',
  'PAINTER, SPRAY (MOTOR VEHICLE ASSEMBLY)',
  'PAINTER, SPRAY (MOTOR VEHICLE)',
  'PAINTER, STRUCTURAL STEEL AND SHIP HULL',
  'PAINTING RESTORER',
  'PAINTING SUPERVISOR',
  'Panel Beater',
  'PANEL BEATER (VEHICLE)',
  'PANEL ENGINEER',
  'PARALEGAL',
  'Paramedic',
  'PARENT LIAISON EXECUTIVE',
  'PARENT LIAISON MANAGER',
  'PARQUETRY WORKER',
  'PARTNER',
  'PARTNER (CONSULTING)',
  'PASSENGER SERVICE AGENT',
  'PASTOR',
  'PASTRY & CONFECTIONERY MAKER',
  'Pastry And Confectionery Maker',
  'PASTRY BAKER',
  'PASTRY CHEF',
  'Pastry Cook',
  'PASTRY MAKER',
  'PASTRYCOOK (RESTAURANT)',
  'PATENT AGENT',
  'Patent Associate',
  'Patent Drafter',
  'Patent Examiner',
  'PATENT MANAGER',
  'PATENT TRADEMARK CONSULTANT',
  'PATHOLOGIST ASSISTANT',
  'PATIENT ACCOUNTS REPRESENTATIVE',
  'PATIENT RELATIONS OFFICER',
  'PATIENT SERVICE ASSOCIATE',
  'PATIENT SERVICE OFFICERS, ADMINISTRATIVE',
  'PAWNBROKER',
  'PAYROLL ASSISTANT',
  'PAYROLL CLERK',
  'PAYROLL EXECUTIVE',
  'PAYROLL MANAGER',
  'PAYROLL SPECIALIST',
  'PERCUSSIONIST',
  'PERFORMANCE ANALYST',
  'PERFORMANCE CONSULTANT',
  'PERFORMANCE ENGINEER',
  'PERFORMING ARTISTE',
  'PERFORMING ARTISTE MANAGER',
  'PERFUME STILLMAN',
  'PERFUMER',
  'PERFUMER ASSISTANT',
  'PERFUSIONIST',
  'Permit Coordinator',
  'PERSONAL ASSISTANT',
  'PERSONAL STYLIST',
  'Personnel / Human Resource Clerk',
  'Personnel / Human Resource Manager',
  'Personnel / Human Resource Officer',
  'PERSONNEL ADMINISTRATOR',
  'PERSONNEL ASSISTANT',
  'PERSONNEL CLERK',
  'PERSONNEL EXECUTIVE',
  'PERSONNEL MANAGER',
  'PERSONNEL OFFICER',
  'PEST CONTROL TECHNICIAN',
  'PEST EXTERMINATOR',
  'PET GROOMER',
  'PET GROOMING INSTRUCTOR',
  'PETROCHEMICAL BROKER',
  'PETROCHEMICALS TECHNICIAN (CHEMICAL ENGINEERING)',
  'Petroleum And Natural Gas Refining Plant Operator',
  'PETROLEUM REFINING TECHNICIAN',
  'PHARMACEUTICAL ASSISTANT',
  'PHARMACEUTICAL DISPENSER',
  'PHARMACEUTICAL SALES REPRESENTATIVE',
  'PHARMACEUTICAL TECHNICIAN',
  'PHARMACIST',
  'PHARMACIST (DISPENSING)',
  'PHARMACIST, INDUSTRIAL',
  'PHARMACIST, RETAIL',
  'PHARMACOVIGILANCE SPECIALIST',
  'PHARMACY ASSISTANT',
  'PHARMACY TECHNICIAN',
  'PHLEBOTOMIST',
  'PHOTOGRAPH PRINTER',
  'Photographer',
  'PHOTOGRAPHER (COMMERCIAL ILLUSTRATOR)',
  'PHOTOGRAPHER (GENERAL)',
  'PHOTOGRAPHER, AERIAL',
  'PHOTOGRAPHER, COMMERCIAL',
  'PHOTOGRAPHER, FASHION',
  'PHOTOGRAPHER, PORTRAIT',
  'PHOTOGRAVURE RETOUCHER',
  'PHYSICAL EDUCATION TEACHER',
  'PHYSICAL FITNESS INSTRUCTOR',
  'Physical Fitness Instructor (Including Yoga Instructor And Aerobics Instructor)',
  'PHYSICAL THERAPIST',
  'PHYSICAL TRAINER',
  'PHYSICIAN ASSISTANT',
  'PHYSICIAN, PUBLIC HEALTH',
  'PHYSICIST (GENERAL)',
  'PHYSIOLOGIST',
  'PHYSIOTHERAPIST',
  'PHYSIOTHERAPY AIDE',
  'PIANIST',
  'PIANO TEACHER',
  'PIANO TEACHER (PRIVATE TUITION)',
  'PIANO TUNER',
  'PICTURE FRAME MAKER',
  'PILATES INSTRUCTOR',
  'Pile Driver / Drilling Machine Operator',
  'PILE-DRIVER OPERATOR',
  'PILING MACHINE OPERATOR',
  'PILOT, AIR TRANSPORT',
  'PILOT, AIRFORCE',
  'PILOT, COMMERCIAL AIRLINE',
  'Pipe Fitter',
  'PIPE FITTER (GENERAL)',
  'PIPE FITTER, GAS',
  'PIPE FITTER, MARINE',
  'PIPE STRESS ENGINEER',
  'PIPING DESIGN ENGINEER',
  'PIPING DESIGNER',
  'PIPING DRAFTER',
  'PIPING ENGINEER',
  'PIPING INSPECTOR',
  'PIPING SUPERINTENDENT',
  'PIPING SUPERVISOR',
  'PIPING TECHNICIAN',
  'PIT MANAGER',
  'PIT SUPERVISOR',
  'Planing Machine Setter-Operator',
  'PLANNER',
  'PLANNER, TRAFFIC',
  'PLANNER, URBAN',
  'PLANNING ANALYST',
  'PLANNING AND FORECASTING MANAGER',
  'PLANNING ASSISTANT',
  'Planning Assistant (Manufacturing)',
  'PLANNING ENGINEER',
  'PLANNING EXECUTIVE',
  'Planning Executive (Manufacturing)',
  'PLANNING MANAGER',
  'PLANNING OFFICER',
  'Planning Supervisor (Fleet Management)',
  'Planning Supervisor (Manufacturing)',
  'PLANT BIOTECHNOLOGIST',
  'PLANT CONSTRUCTION ENGINEER',
  'PLANT MAINTENANCE ENGINEER',
  'PLANT MAINTENANCE MECHANIC',
  'PLANT MANAGER',
  'PLANT OPERATION ENGINEER',
  'PLASTERER',
  'PLASTERER FOREMAN',
  'PLASTERER, ORNAMENTAL',
  'PLASTERER, STUCCO',
  'PLASTIC MOULD DESIGNER',
  'Plastic Products Machine Operator',
  'PLATE GLASS FITTER',
  'PLATE MAKER (PHOTOGRAVURE)',
  'PLATER (ELECTROPLATING)',
  'PLAY SCHOOL TEACHER',
  'Plumber',
  'PLUMBER (GENERAL)',
  'PLUMBING INSTALLATION AND REPAIR GENERAL FOREMAN',
  'PLUMBING INSTALLATION AND REPAIR SUPERVISOR',
  'PLYWOOD GRADER',
  'PODIATRIST',
  'PODIATRY ASSISTANT',
  'POLICE OFFICER',
  'POLICEMAN',
  'POLICEWOMAN',
  'Policy Administration Professional (Eg Policy Analyst)',
  'POLICY AND PLANNING MANAGER',
  'POLICY CLERK, INSURANCE',
  'POLICY MANAGER',
  'POLISHER (METALWORKING)',
  'POLISHER, CAR',
  'POLITICAL SCIENCE RESEARCHER',
  'POLYFOAM PRODUCT MAKING MACHINE OPERATOR',
  'POLYTECHNIC LECTURER',
  'POPULAR MUSIC SINGER',
  'Port / Shipping Operations Supervisor',
  'PORT CAPTAIN',
  'PORT OPERATIONS MANAGER',
  'Port Operations Supervisor',
  'PORTFOLIO ANALYST',
  'PORTFOLIO MANAGEMENT ASSISTANT',
  'PORTFOLIO MANAGER',
  'Post Fixture Executive',
  'POSTDOCTORAL FELLOW',
  'POSTDOCTORAL RESEARCH FELLOW',
  'POSTDOCTORAL RESEARCHER',
  'POSTDOCTORAL SCHOLAR',
  'POSTDOCTORAL VISITOR',
  'POTTERY ARTIST',
  'POWER DISTRIBUTION AND TRANSMISSION ENGINEER',
  'POWER DISTRIBUTION TECHNICIAN (ELECTRICITY)',
  'Power Generation And Distribution Engineer',
  'POWER PLANT BOILERMAN',
  'POWER PLANT OPERATOR, ELECTRICITY GENERATING',
  'PREACHER (NON-ORDAINED)',
  'PRECAST CONCRETE DETAILER',
  'PRECISION ENGINEER',
  'PRECISION ENGINEERING TECHNICIAN',
  'PRECISION GRINDING MACHINE OPERATOR',
  'PRECISION GRINDING MACHINE SETTER-OPERATOR',
  'PRECISION INSTRUMENT ASSEMBLER',
  'PRECISION INSTRUMENT MAKER AND REPAIRER',
  'PRECISION MACHINE TECHNICIAN',
  'PRECISION MACHINIST',
  'PRECISION OPTICS TECHNICIAN',
  'Premises And Facilities Maintenance Manager',
  'Premises And Facilities Maintenance Officer',
  'PREMISES MAINTENANCE OFFICER',
  'PRE-PRIMARY EDUCATION TEACHER',
  'PRE-PRIMARY SCHOOL TEACHER',
  'PRE-REGISTRATION PHARMACIST',
  'PRE-SALES CONSULTANT',
  'PRE-SCHOOL DIRECTOR',
  'PRE-SCHOOL LEAD TEACHER',
  'PRE-SCHOOL TEACHER',
  'PRESENTER',
  'PRESIDENT',
  'PRESIDENT, ASIA PACIFIC',
  'PRESIDENT, COMPANY',
  'PRESS OPERATOR, METAL (EXCEPT FORGING OR PLATE BENDING)',
  'PRESS SECRETARY',
  'PRESS TOOL MAKER',
  'PRESSMAN, OFFSET',
  'PRESSMAN, PRINTING',
  'PRESSURE VESSEL INSPECTOR',
  'Pre-University (Including Junior College) And Secondary School Teacher',
  'PRE-UNIVERSITY TEACHER',
  'PRICING ANALYST',
  'PRICING MANAGER',
  'PRIEST',
  'PRIMARY SCHOOL TEACHER',
  'PRIME MOVER DRIVER',
  'PRINCIPAL (ARCHITECTURAL FIRM)',
  'PRINCIPAL (COMMERCIAL SCHOOL)',
  'PRINCIPAL (INTERNATIONAL SCHOOL)',
  'PRINCIPAL BUSINESS CONSULTANT',
  'PRINCIPAL CONSULTANT',
  'PRINCIPAL CORRESPONDENT',
  'PRINCIPAL ELECTRICAL ENGINEER',
  'PRINCIPAL ENGINEER',
  'Principal Engineer (Electronics)',
  'PRINCIPAL INSTRUCTOR',
  'PRINCIPAL INVESTIGATOR',
  'PRINCIPAL LECTURER',
  'Principal Marine Surveyor (Class)',
  'PRINCIPAL OFFICER',
  'PRINCIPAL PLANNING ENGINEER',
  'PRINCIPAL PROJECT ENGINEER',
  'PRINCIPAL RESEARCHER',
  'Principal Security Architect',
  'Principal Security Engineer',
  'PRINCIPAL TECHNICAL CONSULTANT',
  'PRINCIPAL, CREATIVE SERVICES',
  'PRINCIPAL, SCHOOL',
  'PRINT PRODUCTION MANAGER',
  'PRINTER, SILK SCREEN',
  'PRINTING MACHINE OPERATOR',
  'PRINTING MACHINERY FITTER-ASSEMBLER',
  'PRINTING MACHINERY MECHANIC',
  'PRINTING PRESSMAN',
  'PRINTING SUPERVISOR',
  'PRINTING TECHNICIAN',
  'Prison Officer',
  'PRIVATE BANKER',
  'PRIVATE TUTOR',
  'Private Tutor (Academic)',
  'PRIVATE TUTOR (PRE-UNIVERSITY EDUCATION)',
  'PROCESS (CONTROL) ENGINEER',
  'PROCESS ANALYST',
  'PROCESS ASSISTANT',
  'PROCESS DESIGN ENGINEER',
  'PROCESS ENGINEER',
  'Process Engineer (Electronics)',
  'PROCESS ENGINEER MANAGER',
  'PROCESS ENGINEERING TECHNICIAN',
  'PROCESS LEAD',
  'PROCESS MANAGER',
  'PROCESS SAFETY ENGINEER',
  'Process Specialist (Manufacturing)',
  'Process Specialist (Precision Engineering)',
  'PROCESS TECHNICIAN',
  'PROCESS TECHNOLOGIST',
  'Procurement / Purchasing Manager',
  'PROCUREMENT AGENT',
  'Procurement Agent (Marine And Offshore)',
  'PROCUREMENT ANALYST',
  'PROCUREMENT ASSISTANT',
  'PROCUREMENT ASSOCIATE',
  'PROCUREMENT CLERK',
  'PROCUREMENT COORDINATOR',
  'Procurement Coordinator (Marine And Offshore)',
  'PROCUREMENT COORDINATOR/EXECUTIVE',
  'PROCUREMENT DIRECTOR',
  'PROCUREMENT ENGINEER',
  'PROCUREMENT EXECUTIVE',
  'PROCUREMENT MANAGER',
  'Procurement Manager (Marine And Offshore)',
  'PROCUREMENT OFFICER',
  'PRODUCER',
  'Producer (Stage, Film, Television, Computer Games, Video And Radio)',
  'Producer / Director Of Commercials',
  'PRODUCER OF COMMERCIALS',
  'PRODUCER PRESENTER',
  'PRODUCER, CURRENT AFFAIRS',
  'PRODUCER, MOTION PICTURE',
  'PRODUCER, NEWS',
  'PRODUCER, RADIO AND TELEVISION',
  'PRODUCER, STAGE',
  'PRODUCT ADMINISTRATOR',
  'PRODUCT ANALYST',
  'PRODUCT APPLICATION ENGINEER',
  'PRODUCT BUSINESS MANAGER',
  'PRODUCT CONSULTANT',
  'PRODUCT CONTROLLER',
  'PRODUCT DESIGN ENGINEER',
  'PRODUCT DESIGNER',
  'PRODUCT DEVELOPMENT ANALYST',
  'PRODUCT DEVELOPMENT ENGINEER',
  'PRODUCT DEVELOPMENT EXECUTIVE',
  'PRODUCT DEVELOPMENT MANAGER',
  'PRODUCT ENGINEER',
  'Product Engineer (Electronics)',
  'PRODUCT ENGINEERING DIRECTOR',
  'PRODUCT ENGINEERING MANAGER',
  'PRODUCT EXECUTIVE',
  'PRODUCT LINE MANAGER',
  'PRODUCT MANAGEMENT DIRECTOR',
  'PRODUCT MANAGER',
  'PRODUCT MARKETING DIRECTOR',
  'PRODUCT MARKETING ENGINEER',
  'PRODUCT MARKETING MANAGER',
  'PRODUCT QUALITY ENGINEER',
  'PRODUCT REGULATORY MANAGER',
  'PRODUCT SALES REPRESENTATIVE',
  'PRODUCT SALES SPECIALIST',
  'PRODUCT SCHEDULER',
  'PRODUCT SPECIALIST',
  'PRODUCT SUPPORT ENGINEER',
  'PRODUCT SUPPORT MANAGER',
  'PRODUCT SUPPORT TECHNICIAN',
  'PRODUCT TEST ENGINEER',
  'PRODUCTION ASSISTANT',
  'PRODUCTION CONTROL MANAGER',
  'PRODUCTION CONTROL PLANNER',
  'PRODUCTION COORDINATOR',
  'PRODUCTION CO-ORDINATOR (COMPUTER OPERATIONS)',
  'PRODUCTION CO-ORDINATOR (DATA PROCESSING)',
  'PRODUCTION DIRECTOR',
  'PRODUCTION ENGINEER',
  'PRODUCTION ENGINEER (BIOMEDICAL)',
  'Production Engineer (Marine And Offshore)',
  'PRODUCTION ENGINEER, ASSISTANT',
  'PRODUCTION ENGINEERING ASSISTANT',
  'PRODUCTION ENGINEERING EXPERT',
  'PRODUCTION ENGINEERING MANAGER',
  'PRODUCTION ENGINEERING MANAGER (EXCLUDING TRANSPORT)',
  'PRODUCTION ENGINEERING TECHNICIAN',
  'PRODUCTION EXECUTIVE',
  'PRODUCTION FOREMAN',
  'PRODUCTION MANAGER',
  'PRODUCTION MANAGER (CONSTRUCTION)',
  'PRODUCTION MANAGER (FARM)',
  'PRODUCTION MANAGER (MANUFACTURING)',
  'PRODUCTION OFFICER',
  'PRODUCTION OPERATIONS MANAGER',
  'PRODUCTION OPERATOR',
  'PRODUCTION OPERATOR LEADER',
  'PRODUCTION PLANNER',
  'Production Planner (Food Services)',
  'PRODUCTION PLANNING CLERK',
  'PRODUCTION PLANNING OFFICER',
  'PRODUCTION QUALITY ENGINEER',
  'PRODUCTION SPECIALIST',
  'PRODUCTION SUPERINTENDENT',
  'PRODUCTION SUPERVISOR',
  'Production Supervisor (Electronics)',
  'Production Supervisor (Precision Engineering)',
  'PRODUCTION TECHNICIAN',
  'PRODUCTION TECHNOLOGIST',
  'PRODUCTION/MATERIALS PLANNER',
  'PRODUCTS DESIGNER',
  'PROFESSIONAL DEVELOPMENT MANAGER',
  'Professional Sportsman',
  'PROFESSOR',
  'PROFESSORIAL FELLOW',
  'PROGRAM ADMINISTRATOR',
  'PROGRAM DIRECTOR',
  'PROGRAM MANAGEMENT REGIONAL DIRECTOR',
  'PROGRAM MANAGER',
  'PROGRAMME COORDINATOR',
  'PROGRAMME DIRECTOR',
  'PROGRAMME EXECUTIVE',
  'PROGRAMME MANAGER',
  'PROGRAMMER, ANALYST',
  'PROGRAMMER, APPLICATION',
  'PROGRAMMER, MULTIMEDIA',
  'PROGRAMMER, SYSTEMS',
  'PROGRAMMER, TECHNICAL',
  'PROJECT ACCOUNTANT',
  'PROJECT ADMINISTRATOR',
  'PROJECT ANALYST',
  'PROJECT APPLICATION ENGINEER',
  'PROJECT ASSISTANT',
  'PROJECT CARGO SALES MANAGER',
  'PROJECT CONSULTANT',
  'PROJECT CONTROL ENGINEER',
  'PROJECT CONTROL EXECUTIVE',
  'PROJECT CONTROL MANAGER',
  'PROJECT CONTROL SPECIALIST',
  'PROJECT CONTROLLER',
  'PROJECT COORDINATION MANAGER',
  'PROJECT COORDINATOR',
  'PROJECT DESIGN ENGINEER',
  'PROJECT DESIGN MANAGER',
  'PROJECT DESIGNER',
  'PROJECT DEVELOPER',
  'PROJECT DEVELOPMENT DIRECTOR',
  'PROJECT DEVELOPMENT EXECUTIVE',
  'PROJECT DEVELOPMENT MANAGER',
  'PROJECT DIRECTOR',
  'PROJECT ENGINEER',
  'Project Engineer (Logistics)',
  'Project Engineer (Marine And Offshore)',
  'PROJECT ENGINEER/MANAGER (ENGINEERING)',
  'PROJECT ESTIMATING ENGINEER',
  'PROJECT ESTIMATOR',
  'PROJECT EXECUTIVE',
  'PROJECT FINANCE DIRECTOR',
  'PROJECT HEAD',
  'PROJECT LEAD',
  'PROJECT LIAISON MANAGER',
  'PROJECT MANAGEMENT COORDINATOR',
  'PROJECT MANAGEMENT DIRECTOR',
  'PROJECT MANAGEMENT MANAGER',
  'PROJECT MANAGER',
  'PROJECT MANAGER (CONSTRUCTION)',
  'Project Manager (Logistics)',
  'Project Manager (Marine And Offshore)',
  'PROJECT MANAGER (MARINE)',
  'PROJECT MANAGER, INFORMATION TECHNOLOGY',
  'PROJECT PLANNER',
  'PROJECT PROCUREMENT AND CONTRACTS MANAGER',
  'PROJECT QUANTITY SURVEYOR',
  'PROJECT SAFETY MANAGER',
  'PROJECT SALES ENGINEER',
  'PROJECT SECRETARY',
  'PROJECT SERVICES MANAGER',
  'Project Services System Specialist',
  'PROJECT SUPERINTENDENT',
  'PROJECT SUPERVISOR',
  'PROJECT TECHNICIAN',
  'PROMOTER, EXHIBITIONS',
  'PROMOTER, PERFUME',
  'PROMOTER, SALES',
  'PROMOTER, SKIN CARE &/OR COSMETICS',
  'PROMOTIONS EXECUTIVE',
  'PROMOTIONS PRODUCER',
  'PROPERTY MANAGEMENT EXECUTIVE',
  'PROPERTY MANAGER',
  'PROPERTY OFFICER',
  'PROPERTY UNDERWRITER',
  'PROPOSAL ENGINEER',
  'PROSTHETIST/ORTHOTIST',
  'PROTOCOL EXECUTIVE',
  'PSYCHOLOGIST',
  'PSYCHOLOGIST, CLINICAL',
  'PSYCHOLOGIST, COUNSELLING',
  'PSYCHOLOGIST, SPORTS',
  'PSYCHOLOGY ASSOCIATE',
  'PUBLIC AFFAIRS EXECUTIVE',
  'PUBLIC AFFAIRS MANAGER',
  'PUBLIC HEALTH VETERINARIAN',
  'PUBLIC LIAISON OFFICER',
  'PUBLIC RELATIONS (PR) MANAGER',
  'Public Relations / Corporate Communications Officer',
  'PUBLIC RELATIONS ASSISTANT',
  'Public Relations Consultant And Related Professional',
  'PUBLIC RELATIONS EXECUTIVE',
  'PUBLIC RELATIONS MANAGER',
  'PUBLIC RELATIONS OFFICER',
  'PUBLISHING EXECUTIVE',
  'PUPIL/TRAINEE LAWYER (FOREIGN LAWYER)',
  'PUPIL/TRAINEE LAWYER (SINGAPORE LAW FIRM OR ANY OTHER APPROVED PUBLIC OR PRIVATE ORGANISATION)',
  'PURCHASER',
  'PURCHASER, MERCHANDISE (WHOLESALE OR RETAIL TRADE)',
  'PURCHASER, TECHNICAL',
  'PURCHASING AGENT',
  'PURCHASING ASSISTANT',
  'PURCHASING CLERK',
  'PURCHASING CONTROLLER',
  'PURCHASING COORDINATOR',
  'PURCHASING DIRECTOR',
  'PURCHASING EXECUTIVE',
  'PURCHASING MANAGER',
  'PURCHASING OFFICER',
  'QUALITY ASSESSMENT MANAGER',
  'QUALITY ASSURANCE & QUALITY CONTROL ADMINISTRATOR',
  'QUALITY ASSURANCE & QUALITY CONTROL ANALYST',
  'QUALITY ASSURANCE & QUALITY CONTROL ASSISTANT',
  'QUALITY ASSURANCE & QUALITY CONTROL ENGINEER',
  'QUALITY ASSURANCE & QUALITY CONTROL EXECUTIVE',
  'QUALITY ASSURANCE & QUALITY CONTROL INSPECTOR',
  'QUALITY ASSURANCE & QUALITY CONTROL MANAGER',
  'QUALITY ASSURANCE & QUALITY CONTROL OFFICER',
  'QUALITY ASSURANCE & QUALITY CONTROL SPECIALIST',
  'QUALITY ASSURANCE & QUALITY CONTROL SUPERVISOR',
  'QUALITY ASSURANCE & QUALITY CONTROL TECHNICIAN',
  'QUALITY ASSURANCE ADMINISTRATOR',
  'QUALITY ASSURANCE ANALYST',
  'QUALITY ASSURANCE ASSISTANT',
  'QUALITY ASSURANCE AUDITOR',
  'QUALITY ASSURANCE ENGINEER',
  'Quality Assurance Engineer (Marine And Offshore)',
  'QUALITY ASSURANCE EXECUTIVE',
  'QUALITY ASSURANCE INSPECTOR',
  'QUALITY ASSURANCE MANAGER',
  'Quality Assurance Manager (Marine And Offshore)',
  'QUALITY ASSURANCE OFFICER',
  'QUALITY ASSURANCE PROCESS LEAD',
  'QUALITY ASSURANCE SPECIALIST',
  'QUALITY ASSURANCE SPECIALIST, INFORMATION TECHNOLOGY',
  'QUALITY ASSURANCE SUPERVISOR',
  'QUALITY ASSURANCE TECHNICIAN',
  'QUALITY ASSURANCE TESTER',
  'QUALITY CHECKER & TESTER',
  'QUALITY CHECKER, MECHANICAL PRODUCTS',
  'QUALITY CONSULTANT',
  'Quality Control / Assurance Engineer',
  'QUALITY CONTROL ADMINISTRATOR',
  'QUALITY CONTROL ANALYST',
  'QUALITY CONTROL ASSISTANT',
  'QUALITY CONTROL ASSISTANT ENGINEER',
  'QUALITY CONTROL CHEMIST',
  'QUALITY CONTROL ENGINEER',
  'QUALITY CONTROL EXECUTIVE',
  'QUALITY CONTROL INSPECTOR',
  'Quality Control Inspector (Food Services)',
  'QUALITY CONTROL MANAGER',
  'QUALITY CONTROL OFFICER',
  'QUALITY CONTROL SPECIALIST',
  'QUALITY CONTROL SUPERVISOR',
  'Quality Control Supervisor (Food Services)',
  'QUALITY CONTROL TECHNICIAN',
  'QUALITY CONTROLLER',
  'QUALITY DIRECTOR',
  'QUALITY ENGINEER',
  'Quality Engineer (Aircraft Maintenance)',
  'Quality Engineer (Electronics) ',
  'Quality Engineer (Manufacturing)',
  'QUALITY IMPROVEMENT EXECUTIVE',
  'QUALITY INSPECTOR',
  'QUALITY LEAD',
  'QUALITY MANAGER',
  'Quality Manager (Aircraft Engine / Component Maintenance)',
  'Quality Manager (Food Services)',
  'Quality Manager (Manufacturing)',
  'QUALITY TECHNOLOGIST',
  'QUANTITATIVE ANALYST',
  'QUANTITATIVE STRATEGIST',
  'QUANTITY ENGINEER',
  'QUANTITY SURVEYING TECHNICIAN',
  'QUANTITY SURVEYOR',
  'QUOTE ANALYST',
  'QUOTE DESK EXECUTIVE',
  'R&D ENGINEER',
  'RABBI',
  'RACE HORSE SENIOR TRACK RIDER',
  'RACE HORSE TRACK RIDER',
  'RACEHORSE TRAINER',
  'RADIATION PHYSICIST',
  'RADIATION THERAPIST',
  'RADIO ENGINEERING TECHNICIAN',
  'RADIO FREQUENCY ENGINEER',
  'RADIO PRESENTER',
  'RADIOGRAPHER, DIAGNOSTIC',
  'RADIOGRAPHY ASSISTANT',
  'RADIOLOGIST/DIAGNOSTIC RADIOLOGIST',
  'RAIL TRACK ENGINEER',
  'RAILWAY SERVICE SUPERVISOR',
  'RATTAN FURNITURE MAKER',
  'REACTOR SPECIALIST',
  'READING SPECIALIST',
  'REAL ESTATE ADMINISTRATOR',
  'REAL ESTATE ADVISOR',
  'Real Estate Agent',
  'RECEIVING AND DESPATCHING CLERK',
  'RECEPTIONIST (GENERAL)',
  'RECEPTIONIST, CLINIC',
  "RECEPTIONIST, DOCTOR'S",
  'RECEPTIONIST, HOTEL',
  'RECEPTIONIST, MEDICAL',
  'RECLAMATION WORKER',
  'RECORD MANAGER',
  'RECREATION MANAGER',
  'RECRUITMENT ASSISTANT',
  'RECRUITMENT CONSULTANT',
  'RECRUITMENT EXECUTIVE',
  'RECRUITMENT MANAGER',
  'RECTOR',
  'RECYCLING COORDINATOR',
  'REFINERY MANAGER',
  'REFINERY SUPPLY PLANNER',
  'REFRACTORY SPECIALIST',
  'REFRIGERATION AND AIR-CONDITIONING EQUIPMENT INSULATOR',
  'REFRIGERATION AND AIR-CONDITIONING PLANT INSTALLER',
  'REFRIGERATION ENGINEER',
  'REFRIGERATION ENGINEERING TECHNICIAN',
  'REFRIGERATION TECHNICIAN',
  'REFUSE TRUCK DRIVER',
  'REGIONAL ACCOUNTANT',
  'REGIONAL ADVISOR',
  'REGIONAL ASSISTANT BRAND MANAGER',
  'REGIONAL BRAND ASSISTANT MANAGER',
  'REGIONAL BRAND DEVELOPMENT ASSISTANT MANAGER',
  'REGIONAL BRAND DIRECTOR',
  'REGIONAL BRAND MANAGER',
  'REGIONAL BRAND SUPPORT MANAGER',
  'REGIONAL BUSINESS DEVELOPMENT MANAGER',
  'REGIONAL CATEGORY DIRECTOR',
  'REGIONAL CATEGORY PLANNING DIRECTOR',
  'REGIONAL CATEGORY PLANNING MANAGER',
  'REGIONAL CATEGORY VICE PRESIDENT',
  'REGIONAL COMMERCIAL DIRECTOR',
  'REGIONAL CONSUMER MARKET INSIGHT DIRECTOR',
  'REGIONAL CONSUMER MARKET INSIGHT MANAGER',
  'REGIONAL CONTROLLER',
  'REGIONAL CUSTOMER DEVELOPMENT DIRECTOR',
  'REGIONAL CUSTOMER DEVELOPMENT MANAGER',
  'REGIONAL CUSTOMER MANAGER',
  'REGIONAL CUSTOMER MARKETING DIRECTOR',
  'REGIONAL DIRECTOR',
  'REGIONAL FINANCE AND ADMINISTRATIVE MANAGER',
  'REGIONAL FINANCIAL MANAGER',
  'REGIONAL FRAGRANCE MANAGER',
  'REGIONAL GENERAL MANAGER',
  'REGIONAL GROUP CHIEF EXECUTIVE OFFICER',
  'REGIONAL GROUP CHIEF FINANCIAL OFFICER',
  'REGIONAL GROUP CUSTOMER SERVICE MANAGER, ASIA',
  'REGIONAL HEAD',
  'REGIONAL HEALTH AND BENEFITS PRACTICE LEADER',
  'REGIONAL HEALTH ECONOMICS MANAGER',
  'REGIONAL HUMAN RESOURCE ASSISTANT MANAGER',
  'REGIONAL HUMAN RESOURCE DIRECTOR',
  'REGIONAL KEY ACCOUNT MANAGER',
  'REGIONAL KEY ACCOUNTS MANAGER',
  'REGIONAL LEARNING & DEVELOPMENT MANAGER',
  'REGIONAL LEASING DIRECTOR',
  'REGIONAL LOGISTICS DIRECTOR',
  'REGIONAL LOGISTICS EXCELLENCE MANAGER',
  'REGIONAL LOGISTICS PROJECT MANAGER',
  'REGIONAL MANAGER',
  'REGIONAL MANAGING DIRECTOR',
  'REGIONAL MANAGING DIRECTOR, ASIA PACIFIC',
  'REGIONAL MANUFACTURING EXCELLENCE MANAGER',
  'REGIONAL MARKETING ADVISOR',
  'REGIONAL MARKETING MANAGER',
  'REGIONAL MARKETING OPERATIONS MANAGER',
  'REGIONAL MEDICAL ADVISOR',
  'REGIONAL MEDICAL OFFICER',
  'REGIONAL NEW BUILDING MANAGER',
  'REGIONAL OPERATIONS DIRECTOR',
  'REGIONAL OPERATIONS MANAGER',
  'REGIONAL PLANNING AND FORECASTING MANAGER',
  'REGIONAL PLANNING MANAGER',
  'REGIONAL PRIEST',
  'REGIONAL PROCESS ASSISTANT',
  'REGIONAL PROCESS LEAD',
  'REGIONAL PROGRAM DIRECTOR',
  'REGIONAL PROGRAM MANAGER',
  'REGIONAL PROJECT MANAGER',
  'REGIONAL QUALITY MANAGER',
  'REGIONAL REIMBURSEMENT MANAGER',
  'REGIONAL REVENUE DIRECTOR',
  'REGIONAL SALES DIRECTOR',
  'REGIONAL SALES MANAGER',
  'REGIONAL SECURITY DIRECTOR',
  'REGIONAL SENIOR BRAND MANAGER',
  'REGIONAL SERVICE MANAGER',
  'REGIONAL SUPPLY CHAIN APPLICATIONS MANAGER',
  'REGIONAL SUPPLY CHAIN DIRECTOR',
  'REGIONAL SUPPLY CHAIN FINANCE DIRECTOR',
  'REGIONAL SUPPLY CHAIN MANAGER',
  'REGIONAL SUPPLY CHAIN NETWORK PLANNER',
  'REGIONAL SUPPLY CHAIN PLANNING MANAGER',
  'REGIONAL SUPPLY CHAIN PROGRAM MANAGER',
  'REGIONAL SUPPLY MANAGEMENT DIRECTOR',
  'REGIONAL SUPPLY MANAGEMENT MANAGER',
  'REGIONAL SUPPLY MANAGEMENT PROCESS LEAD',
  'REGIONAL SUPPLY MANAGER',
  'REGIONAL TECHNICAL ADVISER',
  'REGIONAL TECHNICAL ADVISOR',
  'REGIONAL TECHNICAL MANAGER',
  'REGIONAL TECHNICAL SPECIALIST',
  'REGIONAL TRAINING DIRECTOR',
  'REGIONAL VICE PRESIDENT, SALES',
  'Registered Nurse',
  'REGISTRAR (ACADEMIC)',
  'REGISTRAR (MEDICAL DOCTOR)',
  'REGISTRY CLERK',
  'REGULATORY AFFAIRS ASSISTANT',
  'REGULATORY AFFAIRS ASSOCIATE',
  'REGULATORY AFFAIRS EXECUTIVE',
  'REGULATORY AFFAIRS MANAGER',
  'REGULATORY AFFAIRS OFFICER (PHARMACEUTICAL)',
  'REGULATORY COMPLIANCE ENGINEER',
  'REHAB ASSISTANT',
  'REHABILITATION TECHNICIAN',
  'REIMBURSEMENT MANAGER',
  'REINFORCED CONCRETER (GENERAL)',
  'REINFORCING IRON WORKER',
  'Reinforcing Iron Worker / Steel Reinforcement Worker',
  'RELATIONSHIP MANAGEMENT CONSULTANT',
  'RELATIONSHIP MANAGEMENT EXECUTIVE',
  'RELATIONSHIP MANAGER',
  'RELATIONSHIP MANAGER (FINANCIAL)',
  'RELIABILITY ENGINEER',
  'RELIEF TEACHER',
  'RELIGIOUS TEACHER',
  'RELIGIOUS WORKER (MEMBER OF RELIGIOUS ORDER)',
  'REMITTANCE CLERK',
  'REMITTANCE OFFICER',
  'RENOVATION CONTRACTOR (WORKING PROPRIETOR)',
  'RENTAL ADMINISTRATOR',
  'Repair Engineer (Aircraft Engine / Component Maintenance)',
  'REPAIRER, VENDING MACHINE',
  'REPORTER',
  'REPORTER, NEWSPAPER',
  'REPRESENTATIVE',
  'REPRESENTATIVE CONSULTANT',
  'Research & Development Chef',
  'RESEARCH ANALYST, OPERATIONS',
  'RESEARCH ANALYST, SOCIAL',
  'RESEARCH AND DEVELOPMENT (R&D) DIRECTOR',
  'RESEARCH AND DEVELOPMENT (R&D) MANAGER',
  'RESEARCH AND DEVELOPMENT MANAGER',
  'RESEARCH ASSISTANT',
  'RESEARCH ASSOCIATE',
  'RESEARCH CONSULTANT',
  'RESEARCH COORDINATOR',
  'RESEARCH ENGINEER',
  'RESEARCH EXECUTIVE',
  'RESEARCH FELLOW',
  'RESEARCH MANAGER',
  'RESEARCH OFFICER',
  'Research Officer (Non-Statistical)',
  'Research Officer (Statistical)',
  'RESEARCH SCIENTIST',
  'RESEARCH SCIENTIST (BIOMEDICAL SCIENCE)',
  'RESEARCH SCIENTIST (ENVIRONMENTAL ENGINEERING)',
  'RESEARCH SCIENTIST (GENERAL)',
  'RESEARCH SCIENTIST (PHYSICAL AND ENGINEERING SCIENCE)',
  'RESEARCH TECHNOLOGIST',
  'RESEARCH WRITER',
  'RESEARCHER',
  'Reservations Agent (Hotel And Accommodation Services)',
  'RESERVATIONS EXECUTIVE',
  'Reservations Executive (Hotel And Accommodation Services)',
  'RESERVATIONS MANAGER',
  'Reservations Manager (Hotel And Accommodation Services)',
  'RESERVATIONS OFFICER',
  'Reservations Supervisor (Hotel And Accommodation Services)',
  'RESIDENT ARCHITECT',
  'RESIDENT ARCHITECTURAL REPRESENTATIVE',
  'RESIDENT DIRECTOR',
  'RESIDENT ENGINEER',
  'RESIDENT MANAGER',
  'RESIDENT PHYSICIAN',
  'RESIDENT SUPERVISOR',
  'RESIDENT TECHNICAL OFFICER',
  'RESOURCING OFFICER',
  'RESPIRATORY THERAPIST',
  'RESTAURANT AND CATERING MANAGER',
  'RESTAURANT CAPTAIN',
  'RESTAURANT CASHIER',
  'RESTAURANT COOK',
  'RESTAURANT EXECUTIVE',
  'RESTAURANT KEEPER (WORKING PROPRIETOR)',
  'RESTAURANT MANAGER',
  'RESTAURANT RECEPTIONIST',
  'RESTAURANT SALES MANAGER',
  'RESTAURANT SUPERVISOR',
  'RESTAURANTEUR',
  'Retail / Shop Sales Manager',
  'RETAIL AREA MANAGER',
  'RETAIL ASSISTANT',
  'RETAIL ASSISTANT MANAGER',
  'RETAIL ASSISTANT, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'RETAIL ASSOCIATE',
  'RETAIL ASSOCIATE, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'RETAIL BANKING BUSINESS ANALYST',
  'RETAIL DEVELOPMENT DIRECTOR',
  'RETAIL DEVELOPMENT MANAGER',
  'RETAIL DEVELOPMENT VICE PRESIDENT',
  'RETAIL EXECUTION MANAGER',
  'RETAIL EXECUTIVE',
  'RETAIL MANAGER',
  'RETAIL MANAGER, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'RETAIL MERCHANDISER, FASHION',
  'Retail Operations Director',
  'RETAIL OPERATIONS EXECUTIVE',
  'RETAIL OPERATIONS MANAGER',
  'RETAIL PHARMACIST',
  'RETAIL SALES ASSOCIATE',
  'RETAIL SUPERVISOR',
  'RETAIL TRADE MANAGER',
  'RETAIL TRADE SALESMAN',
  'Revenue Analyst (Hotel And Accommodation Services)',
  'REVENUE MANAGER',
  'Revenue Manager (Hotel And Accommodation Services)',
  'REVENUE OPERATIONS AGENT',
  'REVENUE OPERATIONS SPECIALIST',
  'REVENUE SPECIALIST',
  'RIDER, BICYCLE OR TRICYCLE (NOT MOTORISED)',
  'RIDING INSTRUCTOR',
  'Rigger',
  'RIGGER, HOISTING EQUIPMENT (GENERAL)',
  'RIGGER, OIL AND GAS WELL DRILLING',
  'RIGGER, SCAFFOLDING',
  'RIGGER, SHIP',
  'RISK ANALYST',
  'Risk Analyst (Financial)',
  'RISK CONSULTANT',
  'RISK CONTROLLER',
  'RISK MANAGEMENT ASSISTANT',
  'RISK MANAGEMENT COORDINATOR',
  'RISK MANAGEMENT DIRECTOR',
  'RISK MANAGEMENT EXECUTIVE',
  'RISK MANAGEMENT MANAGER',
  'RISK MANAGER',
  'ROAD CONSTRUCTION SUPERVISOR AND GENERAL FOREMAN',
  'ROAD INSPECTOR',
  'ROAD TRANSPORT DEPOT SUPERINTENDENT',
  'ROAD TRANSPORT SERVICE SUPERVISOR',
  'ROBOTIC ENGINEER',
  'ROBOTICS ENGINEER',
  'ROLLER, HOT-ROLLING (STEEL)',
  'ROOFER, METAL',
  'Room Steward / Chambermaid',
  'ROOM SUPERVISOR',
  'Rooms Division Manager (Hotel And Accommodation Services)',
  'ROTATING EQUIPMENT ENGINEER',
  'ROUTE MANAGER',
  'ROUTING CLERK (FREIGHT)',
  'Safety And Security Consultant',
  'SAFETY CONSULTANT',
  'SAFETY COORDINATOR',
  'SAFETY ENGINEER',
  'Safety Inspector (Vehicles, Processes And Products)',
  'SAFETY INSTRUCTOR',
  'SAFETY MANAGER',
  'SAFETY OFFICER',
  'SAFETY SUPERINTENDENT',
  'SAFETY SUPERVISOR',
  'SAFETY TECHNICIAN',
  'SAFETY, HEALTH & ENVIRONMENT COORDINATOR',
  'SAILOR',
  'SALES & LOGISTICS MANAGER',
  'SALES & MARKETING ASSOCIATE',
  'SALES & MARKETING ENGINEER',
  'SALES & MARKETING EXECUTIVE',
  'SALES & MARKETING EXECUTIVE (EXCLUDING FOOD & BEVERAGE SERVICES)',
  'SALES & MARKETING MANAGER',
  'SALES & MARKETING SUPERVISOR',
  'SALES ACCOUNT EXECUTIVE',
  'SALES ACCOUNT MANAGER',
  'SALES ADMINISTRATION COORDINATOR',
  'SALES ADMINISTRATOR',
  'SALES ADVISOR',
  'SALES AGENT',
  'SALES ANALYST',
  'SALES AND MARKETING DIRECTOR',
  'Sales And Marketing Manager',
  'Sales And Purchase Broker',
  'Sales And Related Associate Professional Nec',
  'SALES ASSISTANT',
  'SALES ASSISTANT MANAGER',
  'SALES ASSISTANT MANAGER, SKIN CARE &/OR COSMETICS',
  'SALES ASSISTANT, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'SALES ASSOCIATE',
  'SALES ASSOCIATE, BEER (&/OR OTHER ALCOHOLIC DRINKS)',
  'SALES ASSOCIATE, DEPARTMENTAL STORE',
  'SALES ASSOCIATE, SKIN CARE &/OR COSMETICS',
  'SALES CLERK',
  'SALES CONSULTANT',
  'SALES COORDINATOR',
  'SALES DEMONSTRATOR',
  'SALES DEMONSTRATOR, SKIN CARE &/OR COSMETICS',
  'SALES DEVELOPMENT MANAGER',
  'SALES DIRECTOR',
  'SALES ENGINEER',
  'SALES ENGINEER, COMPUTER',
  'SALES ENGINEER, TECHNICAL',
  'SALES EXECUTIVE',
  'SALES EXECUTIVE, PERFUME',
  'SALES EXECUTIVE, SKIN CARE &/OR COSMETICS',
  'SALES MANAGER',
  'SALES MANAGER, SKIN CARE &/OR COSMETICS',
  'SALES MERCHANDISER',
  'SALES OPERATIONS EXECUTIVE',
  'SALES OPERATIONS MANAGEMENT ASSISTANT',
  'SALES OPERATIONS MANAGEMENT COORDINATOR',
  'SALES ORGANISER (REGIONAL OR OVERSEAS)',
  'SALES PLANNING MANAGER',
  'SALES PROMOTER',
  'SALES PROMOTER, SKIN CARE &/OR COSMETICS',
  'SALES REPRESENTATIVE',
  'SALES REPRESENTATIVE (MEDICAL AND PHARMACEUTICAL PRODUCTS)',
  'SALES REPRESENTATIVE, COMPUTER',
  'SALES REPRESENTATIVE, INSURANCE',
  "SALES REPRESENTATIVE, MANUFACTURER'S",
  'SALES REPRESENTATIVE, MOTOR VEHICLE',
  'SALES REPRESENTATIVE, PHARMACEUTICAL',
  'SALES REPRESENTATIVE, SKIN CARE &/OR COSMETICS',
  'SALES REPRESENTATIVE, TECHNICAL',
  'SALES SPECIALIST',
  'SALES SUPERVISOR',
  'Sales Supervisor (Retail)',
  'SALES SUPPORT ENGINEER',
  'SALES SUPPORT EXECUTIVE',
  'SALES SUPPORT MANAGER',
  'SALES SUPPORT SPECIALIST',
  'SALES TEAM LEADER',
  'SALES TRAINING MANAGER',
  'SALESMAN',
  'SALESMAN (LEASING OF VEHICLES)',
  'SALESMAN, RETAIL TRADE',
  'SALESMAN, WHOLESALE TRADE',
  'Salesperson (Door-To-Door)',
  'SALON MANAGER',
  'SALVAGE CONSULTANT',
  'Sandblaster / Shotblaster (Metal)',
  'SANITARY ENGINEER',
  'SANITARY INSPECTOR',
  'Sap Consultant',
  'SAP PRE-SALES CONSULTANT',
  'SAP PRE-SALES SOLUTIONS ENGINEER',
  'Sap Project Manager',
  'Sap Specialist',
  'SAUSAGE MAKING MACHINE OPERATOR',
  'SCAFFOLD SUPERVISOR',
  'SCAFFOLDER',
  'SCHEDULER',
  'SCHEDULER (COMPUTER OPERATIONS)',
  'SCHOOL COUNSELLOR',
  'SCHOOL DENTAL NURSE',
  'SCHOOL NURSE',
  'SCHOOL PRINCIPAL',
  'SCHOOL VICE PRINCIPAL',
  'SCIENCE OFFICER',
  'SCIENTIFIC GLASS BLOWER',
  'SCIENTIFIC OFFICER',
  'SCIENTIST',
  'SCRAPER AND GRADER OPERATOR (CONSTRUCTION)',
  'SCRIPT WRITER',
  'Scriptwriter',
  'SCUBA DIVING INSTRUCTOR',
  'SCULPTOR',
  'SEA EXPORT OFFICER',
  'SEA TRANSPORT CHARTERING MANAGER',
  'SEAFREIGHT EXECUTIVE',
  'SEAFREIGHT MANAGER',
  'SEAMAN',
  'SECOND ENGINEER (SHIP)',
  'SECOND MATE (SHIP)',
  'SECONDARY SCHOOL TEACHER',
  'SECRETARIAL ASSISTANT',
  'SECRETARIAL EXECUTIVE',
  'SECRETARY',
  'SECRETARY GENERAL (NON-GOVERNMENTAL ORGANIZATION)',
  'SECRETARY, COMPANY (EXECUTIVE)',
  'SECRETARY, EMBASSY (SINGAPORE DIPLOMATIC OFFICER)',
  'SECTION CHEF',
  'SECTION CHIEF (SALES)',
  'SECTION MANAGER',
  'Securities And Finance Dealer / Broker',
  'SECURITIES AND INVESTMENT MANAGER',
  'SECURITIES DEALER',
  'SECURITIES SALES REPRESENTATIVE',
  'SECURITY ADMINISTRATOR',
  'SECURITY ANALYST, INFORMATION TECHNOLOGY',
  'SECURITY ASSISTANT',
  'SECURITY GUARD (PRIVATE)',
  'SECURITY MANAGER',
  'SECURITY OFFICER',
  'Security Operations Manager',
  'Security Penetration Testing Manager',
  'SECURITY SCREENING ASSISTANT',
  'SECURITY SOFTWARE ENGINEER',
  'SECURITY SPECIALIST, INFORMATION TECHNOLOGY',
  'SECURITY SUPERVISOR',
  'SECURITY SYSTEMS ENGINEER',
  'SEGMENT BUSINESS DEVELOPMENT MANAGER',
  'SEGMENT MARKETING MANAGER',
  'SELF-SERVICE STORE MANAGER',
  'SEMI-CONDUCTOR ENGINEER',
  'SEMI-CONDUCTOR TECHNICIAN',
  'SEMI-CONDUCTOR TECHNOLOGIST',
  'SENIOR ACADEMIC CONSULTANT',
  'SENIOR ACADEMIC COORDINATOR',
  'SENIOR ACADEMIC INSTRUCTOR',
  'SENIOR ACCOUNT DELIVERY MANAGER',
  'SENIOR ACCOUNT DEVELOPMENT EXECUTIVE',
  'SENIOR ACCOUNT DEVELOPMENT MANAGER',
  'SENIOR ACCOUNT DIRECTOR',
  'SENIOR ACCOUNT EXECUTIVE',
  'SENIOR ACCOUNT EXECUTIVE, ADVERTISING',
  'SENIOR ACCOUNT GENERAL MANAGER',
  'SENIOR ACCOUNT MAINTENANCE INSPECTOR',
  'SENIOR ACCOUNT MANAGEMENT EXECUTIVE',
  'SENIOR ACCOUNT MANAGER',
  'SENIOR ACCOUNT RELATIONSHIP MANAGER',
  'SENIOR ACCOUNT SALES MANAGER',
  'SENIOR ACCOUNT SPECIALIST',
  'SENIOR ACCOUNT SUPPORT EXECUTIVE',
  'SENIOR ACCOUNTANT (GENERAL)',
  'SENIOR ACCOUNTANT, AUDIT',
  'SENIOR ACCOUNTANT, CHARTERED',
  'SENIOR ACCOUNTANT, COMPANY',
  'SENIOR ACCOUNTANT, COST',
  'SENIOR ACCOUNTANT, COST AND WORKS',
  'SENIOR ACCOUNTING ASSISTANT',
  'SENIOR ACCOUNTING DIRECTOR',
  'SENIOR ACCOUNTING EXECUTIVE',
  'SENIOR ACCOUNTING MANAGER',
  'SENIOR ACCOUNTING MANAGER (FINANCE DEPARTMENT)',
  'SENIOR ACCOUNTING OFFICER',
  'SENIOR ACCOUNTING PROFESSIONAL',
  'SENIOR ACCOUNTING PROGRAMMER',
  'SENIOR ACCOUNTING SPECIALIST',
  'SENIOR ACCOUNTS ADMINISTRATIVE MANAGER',
  'SENIOR ACCOUNTS ASSISTANT',
  'SENIOR ACCOUNTS CONSULTANT',
  'SENIOR ACCOUNTS COORDINATOR',
  'SENIOR ACCOUNTS MANAGER',
  'SENIOR ACCOUNTS SPECIALIST',
  'SENIOR ACMV ENGINEER',
  'SENIOR ACOUSTICS ENGINEER',
  'SENIOR ACTUARIAL ANALYST',
  'SENIOR ACTUARIAL ASSOCIATE',
  'SENIOR ACTUARIAL EXECUTIVE',
  'SENIOR ACTUARIAL MANAGER',
  'SENIOR ADMINISTRATION MANAGER',
  'SENIOR ADMINISTRATIVE ACCOUNTS ASSISTANT',
  'SENIOR ADMINISTRATIVE ACCOUNTS EXECUTIVE',
  'SENIOR ADMINISTRATIVE ACCOUNTS MANAGER',
  'SENIOR ADMINISTRATIVE ACCOUNTS OFFICER',
  'SENIOR ADMINISTRATIVE ASSISTANT',
  'SENIOR ADMINISTRATIVE CONSULTANT',
  'SENIOR ADMINISTRATIVE COORDINATOR',
  'SENIOR ADMINISTRATIVE DIRECTOR',
  'SENIOR ADMINISTRATIVE EXECUTIVE',
  'SENIOR ADMINISTRATIVE HEAD',
  'SENIOR ADMINISTRATIVE MANAGER',
  'SENIOR ADMINISTRATIVE OFFICER',
  'SENIOR ADMINISTRATIVE QC MANAGER',
  'SENIOR ADMINISTRATIVE SECRETARY',
  'SENIOR ADMINISTRATIVE SENIOR EXECUTIVE',
  'SENIOR ADMINISTRATIVE SUPERVISOR',
  'SENIOR ADMINISTRATOR',
  'SENIOR ADMINISTRATOR, COMPUTER SYSTEMS',
  'SENIOR ADMINISTRATOR, DATABASE',
  'SENIOR ADMINISTRATOR, NETWORK',
  'SENIOR ADMISSIONS OFFICER',
  'SENIOR ADVANCED PRODUCTION TECHNICIAN',
  'SENIOR ADVANCED SOFTWARE ENGINEER',
  'SENIOR ADVANCED SYSTEMS ENGINEER',
  'SENIOR ADVERTISEMENT DRAUGHTSMAN',
  'SENIOR ADVERTISING ACCOUNT EXECUTIVE',
  'SENIOR ADVERTISING DESIGNER',
  'SENIOR ADVERTISING EXECUTIVE',
  'SENIOR ADVERTISING MANAGER',
  'SENIOR ADVERTISING SALES EXECUTIVE',
  'SENIOR ADVERTISING SALES MANAGER',
  'SENIOR ADVISER',
  'SENIOR ADVISER, CUSTOMER SERVICE',
  'SENIOR ADVISER, EDUCATION METHODS',
  'SENIOR ADVISER, SERVICE (CUSTOMER AFTER-SALES SERVICE)',
  'SENIOR ADVISER, TECHNICAL SERVICE',
  'SENIOR ADVISOR',
  'SENIOR ADVISORY SALE SPECIALIST',
  'SENIOR ADVISORY SOFTWARE ENGINEER',
  'SENIOR AERONAUTICAL ENGINEERING TECHNICIAN',
  'SENIOR AEROSPACE TECHNICIAN',
  'SENIOR AFTER SALES SERVICE ADVISER',
  'SENIOR AFTER SALES SERVICE ENGINEER',
  'SENIOR AFTERMARKET OPS MANAGER',
  'SENIOR AFTERSALES & SERVICE ASSISTANT',
  'SENIOR AFTERSALES EXECUTIVE',
  'SENIOR AFTERSALES MANAGER',
  'SENIOR AGRITECHNICIAN',
  'SENIOR AGRONOMIST',
  'SENIOR AIR CARGO OFFICER',
  'SENIOR AIR-CON MAINTENANCE OFFICER',
  'SENIOR AIR-CON PLANT INSTALLER',
  'SENIOR AIR-CON TECHNICAL MANAGER',
  'SENIOR AIR-CON TECHNICIAN',
  'SENIOR AIR-CONDITIONING AND REFRIGERATION ENGINEER',
  'SENIOR AIR-CONDITIONING AND REFRIGERATION ENGINEERING TECHNICIAN',
  'SENIOR AIR-CONDITIONING ENGINEER',
  'SENIOR AIR-CONDITIONING ENGINEERING ASSISTANT',
  'SENIOR AIR-CONDITIONING ENGINEERING TECHNICIAN',
  'SENIOR AIRCRAFT COMPOSITE TECHNICIAN',
  'SENIOR AIRCRAFT ENGINE TECHNICIAN',
  'SENIOR AIRCRAFT MECHANIC',
  'SENIOR AIRCRAFT PAINTER',
  'SENIOR AIRFRAME ENGINEER',
  'SENIOR AIRFREIGHT EXECUTIVE',
  'SENIOR AIRLINE MARKETING ANALYST',
  'SENIOR AIRPORT OPERATIONS MANAGER',
  'SENIOR ANALOG DESIGN ENGINEER',
  'SENIOR ANALYSIS SALES MANAGER',
  'SENIOR ANALYST',
  'SENIOR ANALYST PROGRAMMER',
  'SENIOR ANALYST, CREDIT',
  'SENIOR ANALYST, FINANCIAL',
  'SENIOR ANALYST, MARKET RESEARCH',
  'SENIOR ANALYST, OPERATIONS RESEARCH',
  'SENIOR ANALYST, SYSTEMS',
  'SENIOR ANALYTICS MANAGER',
  'SENIOR ANALYZER TECHNICIAN',
  'SENIOR ANIMATOR',
  'SENIOR APPLICATION ADMINISTRATOR - INFORMATION TECHNOLOGY',
  'SENIOR APPLICATION ANALYST',
  'Senior Application Architect',
  'Senior Application Consultant',
  'SENIOR APPLICATION DESIGNER',
  'Senior Application Developer',
  'SENIOR APPLICATION ENGINEER',
  'Senior Application Manager',
  'SENIOR APPLICATION PROGRAMMER',
  'SENIOR APPLICATION SALES SPECIALIST',
  'Senior Application Specialist',
  'SENIOR APPLICATION SUPPORT ANALYST',
  'SENIOR APPLICATION SUPPORT ENGINEER',
  'SENIOR APPLICATION SYSTEM MANAGER',
  'Senior Application Team Leader',
  'SENIOR APPLICATION TECHNICIAN',
  'Senior Application Technologist',
  'SENIOR AQUACULTURE SPECIALIST',
  'SENIOR AQUACULTURE TECHNOLOGIST',
  'SENIOR ARBORICULTURIST',
  'SENIOR ARCHITECT',
  'SENIOR ARCHITECT, BUILDING',
  'SENIOR ARCHITECT, NAVAL',
  'SENIOR ARCHITECTURAL ASSISTANT',
  'SENIOR ARCHITECTURAL ASSOCIATE',
  'SENIOR ARCHITECTURAL CONSULTANT',
  'SENIOR ARCHITECTURAL COORDINATOR',
  'SENIOR ARCHITECTURAL DESIGNER',
  'SENIOR ARCHITECTURAL DRAFTER',
  'SENIOR ARCHITECTURAL DRAUGHTSMAN',
  'SENIOR ARCHITECTURAL ENGINEER',
  'SENIOR ARCHITECTURAL EXECUTIVE',
  'SENIOR ARCHITECTURAL TECHNICIAN',
  'SENIOR ARCHIVIST, BUSINESS INTELLIGENCE',
  'SENIOR AREA MANAGER',
  'SENIOR AREA SALES MANAGER',
  'SENIOR ART CONCEPT DESIGNER',
  'SENIOR ART DESIGNER',
  'SENIOR ART DIRECTOR',
  'SENIOR ART INSTRUCTOR',
  'SENIOR ART MANAGER',
  'SENIOR ART MARKETING MANAGER',
  'Senior Artificial Intelligence Engineer',
  'Senior Artificial Intelligence Specialist',
  'SENIOR ARTIST',
  'SENIOR ARTS EVENT MANAGER',
  'SENIOR ASSAYER',
  'SENIOR ASSEMBLY TECHNICIAN',
  'SENIOR ASSESSOR, INSURANCE',
  'SENIOR ASSISTANT ACCOUNTANT',
  'SENIOR ASSISTANT ADMINISTRATIVE EXECUTIVE',
  'SENIOR ASSISTANT ADMINISTRATIVE MANAGER',
  'SENIOR ASSISTANT CIVIL ENGINEER',
  'SENIOR ASSISTANT DIRECTOR',
  'SENIOR ASSISTANT ELECTRICAL ENGINEER',
  'SENIOR ASSISTANT ELECTRONICS ENGINEER',
  'SENIOR ASSISTANT ENGINEER (CIVIL)',
  'SENIOR ASSISTANT ENGINEER (ELECTRICAL)',
  'SENIOR ASSISTANT ENGINEER (ELECTRONIC)',
  'SENIOR ASSISTANT ENGINEER (MECHANICAL)',
  'SENIOR ASSISTANT ENGINEER (PRODUCTION)',
  'SENIOR ASSISTANT ENGINEER (STRUCTURAL)',
  'SENIOR ASSISTANT MANUFACTURING ENGINEER',
  'SENIOR ASSISTANT MECHANICAL ENGINEER',
  'SENIOR ASSISTANT NURSE',
  'SENIOR ASSOCIATE',
  'SENIOR ASSOCIATE CONSULTANT',
  'SENIOR ASSOCIATE DIRECTOR',
  'SENIOR ASSOCIATE ENGINEER',
  'SENIOR ASSOCIATE SCIENTIST',
  'SENIOR AUCTIONEER',
  'SENIOR AUDIO AND VIDEO EQUIPMENT TECHNICIAN',
  'SENIOR AUDIO ENGINEER',
  'SENIOR AUDIO EQUIPMENT ENGINEER',
  'SENIOR AUDIOLOGISTS',
  'SENIOR AUDIO-VISUAL OPERATIONS SPECIALIST',
  'SENIOR AUDIT ACCOUNTANT',
  'SENIOR AUDIT ASSOCIATE',
  'SENIOR AUDIT DIRECTOR',
  'SENIOR AUDIT EXECUTIVE',
  'SENIOR AUDIT MANAGER',
  'SENIOR AUDITOR',
  'SENIOR AUDITOR (ACCOUNTING)',
  'SENIOR AUDITOR, INFORMATION TECHNOLOGY',
  'SENIOR AUDITORY VERBAL THERAPIST',
  'SENIOR AUTOCAD COMPUTER DESIGNER',
  'SENIOR AUTOCAD DESIGN ENGINEER',
  'SENIOR AUTOCAD DESIGNER',
  'SENIOR AUTOCAD DRAFTSMAN',
  'SENIOR AUTOCAD ENGINEER',
  'SENIOR AUTOMATION ANALYST',
  'SENIOR AUTOMATION DESIGNER',
  'SENIOR AUTOMATION ENGINEER',
  'SENIOR AUTOMATION TECHNICIAN',
  'SENIOR AUTOMOTIVE ENGINEER',
  'SENIOR AUTOMOTIVE ENGINEERING ASSISTANT',
  'SENIOR AUTOMOTIVE ENGINEERING TECHNICIAN',
  'SENIOR AVIATION DEVELOPMENT MANAGER',
  'SENIOR AVIATION GROUND INSTRUCTOR',
  'SENIOR AVIONICS TECHNICIAN',
  'SENIOR BANK ASSOCIATE',
  'SENIOR BANK EXECUTIVE',
  'SENIOR BANK MANAGER (BRANCH)',
  'SENIOR BANK OFFICER',
  'SENIOR BANKER',
  'SENIOR BANKING CONSULTANT',
  'SENIOR BANKING OPERATIONS CONSULTANT',
  'SENIOR BANQUET MANAGER (HOTEL)',
  'SENIOR BAR MANAGER',
  'SENIOR BEAUTY DIRECTOR',
  'SENIOR BEAUTY MANAGER',
  'SENIOR BEHAVIORAL THERAPIST',
  'SENIOR BID MANAGER',
  'SENIOR BILLING SPECIALIST',
  'SENIOR BIOINFORMATICS SPECIALIST',
  'SENIOR BIOMEDICAL ENGINEER',
  'SENIOR BIOPROCESS LEAD ENGINEER',
  'SENIOR BIOSTATISTICIAN',
  'SENIOR BIOTECHNOLOGIST, PLANT',
  'SENIOR BOARDING OFFICER',
  'SENIOR BOILER TECHNICIAN',
  'SENIOR BOUTIQUE MANAGER',
  'SENIOR BRANCH MANAGER',
  'SENIOR BRAND DEVELOPMENT MANAGER',
  'SENIOR BRAND EXECUTIVE',
  'SENIOR BRAND MANAGER',
  'SENIOR BREWERY TECHNOLOGIST',
  'SENIOR BROADCAST DESIGNER',
  'SENIOR BROADCAST ENGINEER',
  'SENIOR BUDGET MANAGER',
  'SENIOR BUILDING CONSTRUCTION ENGINEER',
  'SENIOR BUILDING DESIGNER',
  'SENIOR BUILDING ENGINEER',
  'SENIOR BUILDING SERVICES DESIGNER',
  'SENIOR BUILDING SERVICES ENGINEER',
  'SENIOR BUILDING TECHNICIAN',
  'SENIOR BUSINESS ACCOUNT MANAGER',
  'SENIOR BUSINESS ADMINISTRATOR',
  'SENIOR BUSINESS ADVISOR',
  'SENIOR BUSINESS ANALYST',
  'SENIOR BUSINESS APPLICATIONS MANAGER',
  'SENIOR BUSINESS CONSULTANT',
  'SENIOR BUSINESS CONTROL MANAGER',
  'SENIOR BUSINESS COORDINATION MANAGER',
  'SENIOR BUSINESS COORDINATOR',
  'SENIOR BUSINESS DEVELOPMENT ADVISOR',
  'SENIOR BUSINESS DEVELOPMENT ANALYST',
  'SENIOR BUSINESS DEVELOPMENT ASSOCIATE',
  'SENIOR BUSINESS DEVELOPMENT CONSULTANT',
  'SENIOR BUSINESS DEVELOPMENT DIRECTOR',
  'SENIOR BUSINESS DEVELOPMENT ENGINEER',
  'SENIOR BUSINESS DEVELOPMENT EXECUTIVE',
  'SENIOR BUSINESS DEVELOPMENT MANAGER',
  'SENIOR BUSINESS DEVELOPMENT OFFICER',
  'SENIOR BUSINESS DIRECTOR',
  'SENIOR BUSINESS EFFICIENCY EXPERT',
  'SENIOR BUSINESS EXECUTIVE',
  'SENIOR BUSINESS INTELLIGENCE ARCHIVIST',
  'SENIOR BUSINESS MANAGER',
  'SENIOR BUSINESS OPERATIONS MANAGER',
  'SENIOR BUSINESS PLANNING MANAGER',
  'SENIOR BUSINESS PROCESS ANALYST',
  'SENIOR BUSINESS PROCESS ENGINEER',
  'SENIOR BUSINESS PROCESS MANAGER',
  'SENIOR BUSINESS PROJECT MANAGER',
  'SENIOR BUSINESS RELATIONSHIP MANAGER',
  'SENIOR BUSINESS SOLUTION MANAGER',
  'SENIOR BUSINESS SOLUTIONS CONSULTANT',
  'SENIOR BUSINESS SUPPORT EXECUTIVE',
  'SENIOR BUSINESS SYSTEM ANALYST',
  'SENIOR BUSINESS SYSTEMS DEVELOPER',
  'SENIOR BUTLER',
  'SENIOR BUYER',
  'SENIOR CABLE LAYING SPECIALIST',
  'SENIOR CAD SPECIALIST',
  'SENIOR CADASTRAL SURVEYOR',
  'SENIOR CAD-CAM ENGINEER',
  'SENIOR CAFE MANAGER',
  'SENIOR CALIBRATION ENGINEER',
  'SENIOR CALL CENTRE SUPERVISOR',
  'SENIOR CAMPAIGN EXECUTIVE',
  'SENIOR CAMPAIGN MANAGER',
  'SENIOR CAPABILITIES MANAGER',
  'SENIOR CAPACITY ANALYST',
  'SENIOR CAPTAIN',
  'SENIOR CAPTAIN (CATERING SERVICE)',
  'SENIOR CARDIAC TECHNICIAN',
  'SENIOR CAREER COACH',
  'SENIOR CARGO MANAGER',
  'SENIOR CARGO OFFICER, AIR',
  'SENIOR CASE MANAGEMENT OFFICER',
  'SENIOR CATALYST SUPERVISOR',
  'SENIOR CATEGORY MANAGER',
  'SENIOR CATEGORY SOURCING MANAGER',
  'SENIOR CATERING MANAGER',
  'SENIOR CATERING SALES MANAGER',
  'Senior Centre Leader (Early Childhood Care And Education)',
  'SENIOR CENTRE MANAGER',
  'SENIOR CHAIN STORE MANAGER',
  'SENIOR CHANGE MANAGEMENT DIRECTOR',
  'SENIOR CHANGE MANAGEMENT MANAGER',
  'Senior Charterer (Sea Transport)',
  'SENIOR CHARTERING EXECUTIVE',
  'SENIOR CHARTERING MANAGER',
  'SENIOR CHEF',
  'SENIOR CHEMICAL ANALYST',
  'SENIOR CHEMICAL ENGINEER (GENERAL)',
  'SENIOR CHEMICAL ENGINEER (PETROCHEMICALS)',
  'SENIOR CHEMICAL ENGINEER (PETROLEUM)',
  'SENIOR CHEMICAL ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR CHEMICAL GROUT ENGINEER',
  'SENIOR CHEMICAL SALES MANAGER',
  'SENIOR CHEMICAL SUPERVISOR',
  'SENIOR CHEMICAL TECHNICIAN',
  'SENIOR CHEMIST (GENERAL)',
  'SENIOR CHEMIST, BIOLOGICAL',
  'SENIOR CHEMIST, FOOD',
  'SENIOR CHEMIST, PAINT',
  'SENIOR CHEMIST, PETROLEUM',
  'SENIOR CHEMIST, PHARMACEUTICAL',
  'SENIOR CHEMIST, POLYMERS',
  'SENIOR CHEMIST, QUALITY CONTROL',
  'SENIOR CHIEF FIELD ENGINEER',
  'SENIOR CHROMATOGRAPHY',
  'SENIOR CIVIL & STRUCTURAL DESIGN ENGINEER',
  'SENIOR CIVIL & STRUCTURAL DESIGNER',
  'SENIOR CIVIL & STRUCTURAL ENGINEER',
  'SENIOR CIVIL DRAFTSMAN',
  'SENIOR CIVIL ENGINEER (BRIDGE CONSTRUCTION)',
  'SENIOR CIVIL ENGINEER (BUILDING CONSTRUCTION)',
  'SENIOR CIVIL ENGINEER (GENERAL)',
  'SENIOR CIVIL ENGINEER (PILING)',
  'SENIOR CIVIL ENGINEER (ROAD CONSTRUCTION)',
  'SENIOR CIVIL ENGINEER (TUNNEL CONSTRUCTION)',
  'SENIOR CIVIL ENGINEERING ASSISTANT (GENERAL)',
  'SENIOR CIVIL ENGINEERING CONSULTANT (GENERAL)',
  'SENIOR CIVIL ENGINEERING DRAUGHTSMAN',
  'SENIOR CIVIL ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR CIVIL MANAGER',
  'SENIOR CLAIMS ADJUSTER',
  'SENIOR CLAIMS ADMINISTRATOR',
  'SENIOR CLAIMS MANAGER',
  'SENIOR CLAIMS SPECIALIST',
  'SENIOR CLAIMS SUPERVISOR',
  'SENIOR CLIENT ADVISOR',
  'SENIOR CLIENT DATA MANAGEMENT EXECUTIVE',
  'SENIOR CLIENT EXECUTIVE',
  'SENIOR CLIENT MANAGER',
  'SENIOR CLIENT RELATIONS OFFICER',
  'SENIOR CLIENT RELATIONSHIP EXECUTIVE',
  'SENIOR CLIENT RELATIONSHIP MANAGER',
  'SENIOR CLIENT RELATIONSHIP OFFICER',
  'SENIOR CLIENT SERVICE REPRESENTATIVE',
  'SENIOR CLIENT SERVICES MANAGER',
  'SENIOR CLIENT SOLUTIONS DIRECTOR',
  'SENIOR CLIENT SUPPORT ANALYST',
  'SENIOR CLINIC MANAGER',
  'SENIOR CLINICAL AUDIOLOGIST',
  'SENIOR CLINICAL CODER',
  'SENIOR CLINICAL OPERATIONS MANAGER',
  'SENIOR CLINICAL RESEARCH ASSOCIATE',
  'SENIOR CLINICAL RESEARCH COORDINATOR',
  'SENIOR CLINICAL RESEARCH FELLOW',
  'SENIOR CLINICAL TEAM LEAD',
  'SENIOR CLINICAL TECHNOLOGIST',
  'SENIOR CLOTHES DESIGNER',
  'SENIOR CNC MACHINIST',
  'SENIOR CNC PROGRAMMER',
  'SENIOR CNC TECHNICIAN',
  'SENIOR COASTAL ENGINEER',
  'SENIOR COATING ENGINEER',
  'SENIOR COATING TECHNICIAN',
  'SENIOR COMMERCIAL ARTIST',
  'SENIOR COMMERCIAL DIRECTOR',
  'SENIOR COMMERCIAL DIVER',
  'SENIOR COMMERCIAL MANAGER',
  'SENIOR COMMERCIAL PRODUCTS DESIGNER',
  'SENIOR COMMERCIAL REPRESENTATIVE',
  'SENIOR COMMERCIAL SCHOOL TEACHER',
  'SENIOR COMMISSIONING CONSULTANT',
  'SENIOR COMMISSIONING COORDINATOR',
  'SENIOR COMMISSIONING ENGINEER',
  'SENIOR COMMUNICATION CONSULTANT',
  'SENIOR COMMUNICATION DESIGNER',
  'SENIOR COMMUNICATION MANAGER',
  'SENIOR COMMUNICATION SPECIALIST',
  'SENIOR COMMUNICATIONS EXECUTIVE',
  'SENIOR COMMUNITY MANAGER',
  'SENIOR COMMUNITY SERVICES EXECUTIVE',
  'SENIOR COMPANY ACCOUNTANT',
  'SENIOR COMPANY DIRECTOR',
  'SENIOR COMPANY SECRETARY (EXECUTIVE)',
  'SENIOR COMPENSATION & BENEFITS MANAGER',
  'SENIOR COMPLIANCE ANALYST',
  'SENIOR COMPLIANCE ASSISTANT',
  'SENIOR COMPLIANCE MANAGER',
  'SENIOR COMPLIANCE OFFICER',
  'SENIOR COMPLIANCE SPECIALIST',
  'SENIOR COMPRESSOR SERVICE TECHNICIAN',
  'SENIOR COMPUTER AND INFORMATION SYSTEMS MANAGER',
  'SENIOR COMPUTER CONSULTANT',
  'SENIOR COMPUTER ENGINEER',
  'SENIOR COMPUTER GRAPHIC ARTIST (CG ARTIST)',
  'SENIOR COMPUTER INTEGRATED MANUFACTURING ENGINEER',
  'SENIOR COMPUTER OPERATIONS AND NETWORK MANAGER',
  'SENIOR COMPUTER OPERATOR',
  'SENIOR COMPUTER PROGRAMMER',
  'SENIOR COMPUTER SALES ENGINEER',
  'SENIOR COMPUTER SCIENTIST',
  'SENIOR COMPUTER SYSTEM HARDWARE ANALYST',
  'SENIOR COMPUTER SYSTEMS ADMINISTRATOR',
  'SENIOR COMPUTER SYSTEMS ENGINEER',
  'SENIOR COMPUTER SYSTEMS TECHNICIAN',
  'SENIOR COMPUTER TECHNICIAN',
  'SENIOR CONCEPT ARTIST',
  'SENIOR CONCEPT DESIGN DEVELOPER',
  'SENIOR CONDITION MONITORING ENGINEER',
  'SENIOR CONFERENCE MANAGER',
  'SENIOR CONFERENCE PRODUCER',
  'SENIOR CONFERENCE SALES EXECUTIVE',
  'SENIOR CONSERVATOR (ART WORKS)',
  'SENIOR CONSOLE OPERATOR (ELECTRONIC DATA PROCESSING)',
  'SENIOR CONSTRUCTION DIRECTOR',
  'SENIOR CONSTRUCTION ENGINEER',
  'SENIOR CONSTRUCTION MANAGER',
  'SENIOR CONSTRUCTION SITE ENGINEER',
  'SENIOR CONSTRUCTION SUPERVISOR',
  'SENIOR CONSTRUCTION TECHNICIAN',
  'SENIOR CONSULTANT',
  'SENIOR CONSULTANT ENGINEER',
  'SENIOR CONSULTING EDITOR',
  'SENIOR CONSULTING ENGINEER',
  'SENIOR CONTACT ENGINEER',
  'SENIOR CONTAINER SURVEYOR',
  'SENIOR CONTINUITY AND SCRIPT EDITOR',
  'SENIOR CONTRACT ENGINEER',
  'SENIOR CONTRACT MANAGER',
  'SENIOR CONTRACTOR',
  'SENIOR CONTRACTS ENGINEER',
  'SENIOR CONTRACTS OFFICER',
  'SENIOR CONTROL AND INSTRUMENTATION ENGINEER',
  'SENIOR CONTROL ENGINEER',
  'SENIOR CONTROL EXECUTIVE',
  'SENIOR CONTROL SYSTEM ENGINEER',
  'SENIOR CONTROL VALVE SPECIALIST',
  'SENIOR CONTROLLER, FINANCIAL',
  'SENIOR COOK',
  'SENIOR COORDINATOR',
  'SENIOR CO-ORDINATOR, TECHNICAL SALES',
  'SENIOR COPYWRITER',
  'SENIOR COPYWRITER, ADVERTISING',
  'SENIOR CORPORATE ACCOUNT EXECUTIVE',
  'SENIOR CORPORATE ACCOUNT MANAGER',
  'SENIOR CORPORATE BRAND MARKETING MANAGER',
  'SENIOR CORPORATE COMMUNICATIONS DIRECTOR',
  'SENIOR CORPORATE COMMUNICATIONS MANAGER',
  'SENIOR CORPORATE DEALER',
  'SENIOR CORPORATE DEVELOPMENT MANAGER',
  'SENIOR CORPORATE EXECUTIVE',
  'SENIOR CORPORATE FINANCE EXECUTIVE',
  'SENIOR CORPORATE FINANCE MANAGER',
  'SENIOR CORPORATE PLANNING MANAGER',
  'SENIOR CORPORATE RESEARCH MANAGER',
  'SENIOR CORPORATE SALES EXECUTIVE',
  'SENIOR CORPORATE SALES MANAGER',
  'SENIOR CORPORATE SERVICES MANAGER',
  'SENIOR CORPORATE STRATEGY PLANNER',
  'SENIOR CORPORATE TRAINER',
  'SENIOR CORRESPONDENT, NEWSPAPER',
  'SENIOR CORROSION ENGINEER',
  'SENIOR COST ACCOUNTANT',
  'SENIOR COST ANALYST',
  'SENIOR COST CONTROL MANAGER',
  'SENIOR COST CONTROLLER',
  'SENIOR COST ENGINEER',
  'SENIOR COST ESTIMATING MANAGER',
  'SENIOR COST ESTIMATOR',
  'SENIOR COUNSELLOR, STUDENT VOCATIONAL',
  'SENIOR COUNSELLOR, VOCATIONAL GUIDANCE',
  'SENIOR COUNTRY MANAGER',
  'SENIOR COURSE CONSULTANT',
  'SENIOR CRAFTSMAN',
  'SENIOR CREATIVE CONSULTANT',
  'SENIOR CREATIVE DESIGN PROGRAMMER',
  'SENIOR CREATIVE DESIGNER',
  'SENIOR CREATIVE DIRECTOR',
  'SENIOR CREATIVE EXECUTIVE',
  'SENIOR CREATIVE MANAGER',
  'SENIOR CREDIT ADMINISTRATIVE OFFICER',
  'SENIOR CREDIT ANALYST',
  'SENIOR CREDIT CONTROL EXECUTIVE',
  'SENIOR CREDIT CONTROL MANAGER',
  'SENIOR CREDIT CONTROLLER',
  'SENIOR CREDIT MANAGER',
  'SENIOR CREDIT OFFICER',
  'SENIOR CREDIT PORTFOLIO ANALYST',
  'SENIOR CREDIT RESEARCH ANALYST',
  'SENIOR CREDIT RISK OFFICER',
  'SENIOR CREDIT SPECIALIST',
  'SENIOR CREDIT UNDERWRITER',
  'SENIOR CREW MANAGER',
  'SENIOR CREW MANNING EXECUTIVE',
  'SENIOR CULINARY CONSULTANT',
  'SENIOR CULINARY DIRECTOR',
  'SENIOR CURATOR, MUSEUM',
  'SENIOR CURRICULUM DEVELOPER',
  'SENIOR CURTAIN DESIGNER',
  'SENIOR CUSTOMER ACCEPTANCE MANAGER',
  'SENIOR CUSTOMER ACCOUNT EXECUTIVE',
  'SENIOR CUSTOMER ACQUISITION EXECUTIVE',
  'SENIOR CUSTOMER BUSINESS EXECUTIVE',
  'SENIOR CUSTOMER CARE MANAGER',
  'SENIOR CUSTOMER CARE OFFICER',
  'SENIOR CUSTOMER DEVELOPMENT MANAGER',
  'SENIOR CUSTOMER FINANCIAL SERVICE REPRESENTATIVE',
  'SENIOR CUSTOMER RELATIONS MANAGER',
  'SENIOR CUSTOMER SALES EXECUTIVE',
  'SENIOR CUSTOMER SALES MANAGER',
  'SENIOR CUSTOMER SALES SUPPORT ENGINEER',
  'SENIOR CUSTOMER SERVICE ADMINISTRATOR',
  'SENIOR CUSTOMER SERVICE ADVISER',
  'SENIOR CUSTOMER SERVICE ENGINEER',
  'SENIOR CUSTOMER SERVICE EXECUTIVE',
  'SENIOR CUSTOMER SERVICE MANAGER',
  'SENIOR CUSTOMER SERVICE OFFICER',
  'Senior Customer Service Officer (Logistics)',
  'SENIOR CUSTOMER SERVICE OPERATIONS DIRECTOR',
  'SENIOR CUSTOMER SERVICE OPERATIONS MANAGER',
  'SENIOR CUSTOMER SERVICE REPRESENTATIVE',
  'SENIOR CUSTOMER SUPPORT ENGINEER',
  'SENIOR CUSTOMER SUPPORT MANAGER',
  'SENIOR CUSTOMS & DOCUMENT OFFICER',
  'SENIOR DANCE INSTRUCTOR',
  'SENIOR DATA ARCHITECT MANAGER',
  'SENIOR DATA CENTER OPERATOR',
  'SENIOR DATA COMMUNICATION ANALYST',
  'SENIOR DATA NETWORK ANALYST',
  'SENIOR DATA NETWORK ENGINEERING ANALYST',
  'SENIOR DATA PROCESSING MANAGER',
  'SENIOR DATA RESEARCH EXECUTIVE',
  'Senior Data Scientist',
  'SENIOR DATA SECURITY ANALYST',
  'SENIOR DATA WAREHOUSING ANALYST',
  'SENIOR DATA WAREHOUSING CONSULTANT',
  'SENIOR DATABASE ADMINISTRATOR',
  'SENIOR DATABASE ANALYST',
  'SENIOR DATABASE ARCHITECT',
  'SENIOR DATABASE CONSULTANT',
  'SENIOR DATABASE COORDINATOR',
  'SENIOR DATABASE DEVELOPER',
  'SENIOR DATABASE EXECUTIVE',
  'SENIOR DATABASE MANAGER',
  'SENIOR DATABASE PROGRAMMER',
  'SENIOR DATABASE SPECIALIST',
  'SENIOR DATAWAREHOUSE CONSULTANT',
  'SENIOR DATAWAREHOUSING ANALYST',
  'SENIOR DECORATOR, INTERIOR',
  'SENIOR DEMAND GENERATION MANAGER',
  'SENIOR DENTAL SURGEON',
  'SENIOR DENTAL TECHNICIAN',
  'SENIOR DENTIST, SPECIALISED',
  'SENIOR DEPARTMENT HEAD',
  'SENIOR DEPARTMENT MANAGER',
  'SENIOR DEPLOYMENT PROJECT LEADER',
  'SENIOR DEPLOYMENT PROJECT MANAGER',
  'SENIOR DEPUTY DIRECTOR',
  'SENIOR DEPUTY MANAGER',
  'SENIOR DERIVATIVES ANALYST',
  'SENIOR DESIGN ARCHITECT',
  'SENIOR DESIGN CONSULTANT',
  'SENIOR DESIGN COORDINATOR',
  'SENIOR DESIGN DIRECTOR',
  'SENIOR DESIGN DRAFTSPERSON',
  'SENIOR DESIGN ENGINEER',
  'SENIOR DESIGN ENGINEER (MARINE)',
  'SENIOR DESIGN EXECUTIVE',
  'SENIOR DESIGN MANAGER',
  'SENIOR DESIGNER',
  'SENIOR DESIGNER, EXHIBITION',
  'SENIOR DESIGNER, FASHION',
  'SENIOR DESIGNER, FURNITURE',
  'SENIOR DESIGNER, GARMENT',
  'SENIOR DESIGNER, GRAPHIC',
  'SENIOR DESIGNER, INDUSTRIAL PRODUCTS',
  'SENIOR DESIGNER, INTEGRATED CIRCUIT',
  'SENIOR DESIGNER, INTERIOR',
  'SENIOR DESIGNER, INTERIOR DECORATION',
  'SENIOR DESIGNER, JEWELLERY',
  'SENIOR DESIGNER, SYSTEMS (COMPUTER)',
  'SENIOR DESKTOP ARTIST',
  'SENIOR DESKTOP SUPPORT ENGINEER',
  'SENIOR DEVELOPMENT DIRECTOR',
  'SENIOR DEVELOPMENT ENGINEER',
  'SENIOR DEVELOPMENT SPECIALIST',
  'SENIOR DEVICE ENGINEER',
  'SENIOR DIETITIAN',
  'SENIOR DIGITAL ARTIST',
  'SENIOR DIGITAL COMPUTER OPERATOR',
  'SENIOR DIGITAL IMAGING ARTIST',
  'SENIOR DIGITAL MANAGER',
  'SENIOR DIGITAL MEDIA EDITOR',
  'SENIOR DIGITAL SYSTEMS ENGINEER',
  'SENIOR DIRECTING MANAGER',
  'SENIOR DIRECTOR',
  'SENIOR DIRECTOR (BANKING INDUSTRY)',
  'SENIOR DIRECTOR (MANUFACTURING INDUSTRY)',
  'SENIOR DIRECTOR (MEDIA INDUSTRY)',
  'SENIOR DIRECTOR OF COMMERCIALS',
  'SENIOR DIRECTOR OF CORPORATE AND INTERNATIONAL RELATIONS',
  'SENIOR DIRECTOR, CLIENT SOLUTIONS',
  'SENIOR DIRECTOR, COMPANY',
  'SENIOR DIRECTOR, MANAGING',
  'SENIOR DIRECTOR, PROJECT EXECUTION',
  'SENIOR DISTRIBUTION EXECUTIVE',
  'SENIOR DISTRIBUTION MANAGER',
  'SENIOR DIVISION HEAD',
  'SENIOR DOCUMENT CONTROL MANAGER',
  'SENIOR DOCUMENT CONTROLLER',
  'SENIOR DOCUMENTATION SPECIALIST MANAGER',
  'SENIOR DRAFT ENGINEER',
  'SENIOR DRAFTING DESIGNER',
  'SENIOR DRAFTING SUPERVISOR',
  'SENIOR DRAFTSMAN',
  'SENIOR DRAFTSPERSON',
  'SENIOR DRAUGHTSMAN (GENERAL)',
  'SENIOR DRAUGHTSMAN, ARCHITECTURAL',
  'SENIOR DRAUGHTSMAN, CIVIL ENGINEERING',
  'SENIOR DRAUGHTSMAN, ELECTRICAL',
  'SENIOR DRAUGHTSMAN, ELECTRONICS',
  'SENIOR DRAUGHTSMAN, INTERIOR DESIGN',
  'SENIOR DRAUGHTSMAN, MECHANICAL',
  'SENIOR DRAUGHTSMAN, STRUCTURAL ENGINEERING',
  'SENIOR DREDGING ENGINEER',
  'SENIOR DRILLING SYSTEM ENGINEER',
  'SENIOR DUTY MANAGER',
  'SENIOR EBUSINESS MANAGER',
  'SENIOR E-COMMERCE MANAGER',
  'SENIOR ECONOMIST (GENERAL)',
  'SENIOR ECONOMIST (SPECIALISED)',
  'SENIOR EDITOR',
  'SENIOR EDITOR, ART AND GRAPHIC',
  'SENIOR EDITOR, BOOK',
  'SENIOR EDITOR, DIGITAL MEDIA',
  'SENIOR EDITOR, RADIO, TELEVISION AND VIDEO',
  'SENIOR EDITOR, SOUND (MOTION PICTURE FILM)',
  'Senior Educarer (Early Childhood Care And Education)',
  'SENIOR EDUCATION CONSULTANT',
  'SENIOR EDUCATION COUNSELLOR',
  'SENIOR EDUCATION DEVELOPMENT TRAINER',
  'SENIOR EDUCATION METHODS ADVISER',
  'SENIOR EDUCATION OFFICER',
  'SENIOR ELECTRIC POWER ENGINEER',
  'SENIOR ELECTRICAL AND ELECTRONICS ENGINEER',
  'SENIOR ELECTRICAL AND INSTRUMENT TECHNICIAN',
  'SENIOR ELECTRICAL AND INSTRUMENTATION ENGINEER',
  'SENIOR ELECTRICAL AND INSTRUMENTATION INSPECTOR',
  'SENIOR ELECTRICAL AND INSTRUMENTATION TECHNICIAN',
  'SENIOR ELECTRICAL COMMISSIONING ENGINEER',
  'SENIOR ELECTRICAL COMMISSIONING TECHNICIAN',
  'SENIOR ELECTRICAL DESIGN ENGINEER',
  'SENIOR ELECTRICAL DESIGNER',
  'SENIOR ELECTRICAL DRAUGHTSMAN',
  'SENIOR ELECTRICAL ENGINEER (GENERAL)',
  'SENIOR ELECTRICAL ENGINEER (POWER DISTRIBUTION AND TRANSMISSION)',
  'SENIOR ELECTRICAL ENGINEER (POWER GENERATION)',
  'SENIOR ELECTRICAL ENGINEERING ASSISTANT (GENERAL)',
  'SENIOR ELECTRICAL ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR ELECTRICAL ENGINEERING TECHNICIAN (HIGH VOLTAGE)',
  'SENIOR ELECTRICAL INSTRUMENT ENGINEER',
  'SENIOR ELECTRICAL MAINTENANCE ENGINEER',
  'SENIOR ELECTRICAL MAINTENANCE TECHNICIAN',
  'SENIOR ELECTRICAL NETWORK ENGINEER',
  'SENIOR ELECTRICAL TECHNICIAN',
  'SENIOR ELECTRICIAN',
  'SENIOR ELECTRONIC DESIGN ENGINEER',
  'SENIOR ELECTRONICS ENGINEER (GENERAL)',
  'SENIOR ELECTRONICS ENGINEERING ASSISTANT (GENERAL)',
  'SENIOR ELECTRONICS ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR ELECTRONICS SERVICE ENGINEER',
  'SENIOR ELEVATOR ENGINEER',
  'SENIOR EMBEDDED SOFTWARE ENGINEER',
  'SENIOR EMBEDDED SYSTEM ENGINEER',
  'SENIOR EMPLOYMENT COUNSELLOR',
  'SENIOR ENERGY ENGINEER',
  'SENIOR ENGINEER',
  'Senior Engineer (Ndt Level 3) (Aircraft Engine / Component Maintenance)',
  'Senior Engineer (Ndt Level 3) (Aircraft Maintenance)',
  'Senior Engineer (Ndt Level 3) (Manufacturing)',
  'Senior Engineer (Precision Engineering)',
  'SENIOR ENGINEER OFFICER, MARINE',
  'SENIOR ENGINEER SCIENTIST',
  'SENIOR ENGINEER, AIR-CONDITIONING',
  'Senior Engineer, Artificial Intelligence',
  'SENIOR ENGINEER, AUDIO EQUIPMENT',
  'SENIOR ENGINEER, AUTOMATION',
  'SENIOR ENGINEER, BIOMEDICAL',
  'SENIOR ENGINEER, BRIDGE CONSTRUCTION',
  'SENIOR ENGINEER, BUILDING CONSTRUCTION',
  'SENIOR ENGINEER, CAD-CAM',
  'SENIOR ENGINEER, CHEMICAL (GENERAL)',
  'SENIOR ENGINEER, CIVIL (GENERAL)',
  'SENIOR ENGINEER, COMPUTER SYSTEMS',
  'SENIOR ENGINEER, CUSTOMER SERVICE',
  'SENIOR ENGINEER, ELECTRICAL (GENERAL)',
  'SENIOR ENGINEER, ELECTRONICS (GENERAL)',
  'SENIOR ENGINEER, ELEVATOR',
  'SENIOR ENGINEER, HARDWARE (COMPUTER)',
  'SENIOR ENGINEER, HYDRAULICS',
  'SENIOR ENGINEER, INDUSTRIAL MACHINERY AND TOOLS',
  'SENIOR ENGINEER, INSTRUMENTATION',
  'SENIOR ENGINEER, LIFT',
  'SENIOR ENGINEER, MACHINERY AND TOOLS (INDUSTRIAL)',
  'SENIOR ENGINEER, MANUFACTURING (GENERAL)',
  'SENIOR ENGINEER, MARINE',
  'SENIOR ENGINEER, MECHANICAL (AERONAUTICAL)',
  'SENIOR ENGINEER, MECHANICAL (AUTOMOTIVE)',
  'SENIOR ENGINEER, MECHANICAL (GENERAL)',
  'SENIOR ENGINEER, MECHANICAL (HEATING, VENTILATION AND REFRIGERATION)',
  'SENIOR ENGINEER, MECHANICAL (INDUSTRIAL MACHINERY AND TOOLS)',
  'SENIOR ENGINEER, MECHANICAL (MARINE)',
  'SENIOR ENGINEER, MECHANICAL (MOTORS AND ENGINES, EXCEPT MARINE)',
  'SENIOR ENGINEER, METHODS',
  'SENIOR ENGINEER, MINING (PETROLEUM AND NATURAL GAS)',
  'SENIOR ENGINEER, PILING',
  'SENIOR ENGINEER, POWER DISTRIBUTION AND TRANSMISSION',
  'SENIOR ENGINEER, PROCUREMENT',
  'SENIOR ENGINEER, PRODUCTION',
  'SENIOR ENGINEER, QUALITY ASSURANCE',
  'SENIOR ENGINEER, ROBOTIC',
  'SENIOR ENGINEER, SAFETY (INDUSTRIAL)',
  'SENIOR ENGINEER, SEMI-CONDUCTOR',
  'SENIOR ENGINEER, SOFTWARE',
  'SENIOR ENGINEER, STRUCTURAL (BUILDINGS)',
  'SENIOR ENGINEER, STRUCTURAL (GENERAL)',
  'SENIOR ENGINEER, SYSTEMS (COMPUTER)',
  'SENIOR ENGINEER, TELECOMMUNICATIONS',
  'SENIOR ENGINEER, TUNNEL CONSTRUCTION',
  'SENIOR ENGINEERING ASSISTANT',
  'SENIOR ENGINEERING ASSISTANT, CHEMICAL (GENERAL)',
  'SENIOR ENGINEERING ASSISTANT, ELECTRONICS (GENERAL)',
  'SENIOR ENGINEERING ASSISTANT, MANUFACTURING (GENERAL)',
  'SENIOR ENGINEERING ASSISTANT, MECHANICAL (MOTORS AND ENGINES)',
  'SENIOR ENGINEERING ASSISTANT, PRODUCTION',
  'SENIOR ENGINEERING ASSISTANT, REFRIGERATION',
  'SENIOR ENGINEERING COORDINATOR',
  'SENIOR ENGINEERING DIRECTOR',
  'SENIOR ENGINEERING DIVISION MANAGER',
  'SENIOR ENGINEERING GEOLOGIST',
  'SENIOR ENGINEERING INSPECTOR',
  'SENIOR ENGINEERING MANAGER',
  'SENIOR ENGINEERING TECHNICIAN',
  'SENIOR ENROLLED NURSE',
  'SENIOR ENTERTAINMENT MANAGER',
  'SENIOR ENVIRONMENTAL ENGINEER',
  'SENIOR ENVIRONMENTAL HEALTH OFFICER',
  'SENIOR ENVIRONMENTAL SCIENTIST',
  'SENIOR EPIDEMIOLOGY MANAGER',
  'SENIOR EQUIPMENT ENGINEER',
  'Senior Equipment Engineer (Electronics)',
  'SENIOR EQUIPMENT MANAGER',
  'Senior Equipment Specialist',
  'SENIOR EQUIPMENT SUPERVISOR',
  'SENIOR EQUIPMENT TECHNICIAN',
  'SENIOR EQUITIES ANALYST',
  'SENIOR ESTIMATOR',
  'SENIOR EVALUATION MANAGER',
  'SENIOR EVENTS COORDINATOR',
  'SENIOR EVENTS DESIGNER',
  'SENIOR EVENTS EXECUTIVE',
  'SENIOR EVENTS MANAGER',
  'SENIOR EVENTS MARKETING EXECUTIVE',
  'SENIOR EXECUTIVE',
  'SENIOR EXECUTIVE CHEF',
  'SENIOR EXECUTIVE DIRECTOR',
  'SENIOR EXECUTIVE DIRECTOR (COMPANY)',
  'SENIOR EXECUTIVE DIRECTOR (REAL ESTATE)',
  'SENIOR EXECUTIVE ENGINEER',
  'SENIOR EXECUTIVE MANAGER',
  'SENIOR EXECUTIVE OFFICER',
  'SENIOR EXECUTIVE PRODUCER, CURRENT AFFAIRS',
  'SENIOR EXECUTIVE SECRETARY',
  'SENIOR EXECUTIVE SOUS CHEF',
  'SENIOR EXECUTIVE VICE PRESIDENT',
  'SENIOR EXECUTIVE, PERSONNEL',
  'SENIOR EXHIBITION DESIGNER',
  'SENIOR EXHIBITION ORGANISER',
  'SENIOR EXPEDITOR',
  'SENIOR EXPORT DIRECTOR',
  'SENIOR EXPORT MANAGER',
  'SENIOR EXPORT MARKETING EXECUTIVE',
  'SENIOR EXPORT SALES EXECUTIVE',
  'SENIOR EXPORT SALES MANAGER',
  'SENIOR FABRICATION MANAGER',
  'SENIOR FABRICATION TECHNICIAN',
  'SENIOR FACADE DESIGNER',
  'SENIOR FACILITIES MAINTENANCE TECHNICIAN',
  'SENIOR FACILITIES MANAGER',
  'Senior Facility Engineer (Electronics)',
  'SENIOR FACILITY EXECUTIVE',
  'SENIOR FACILITY SERVICES SPECIALIST',
  'SENIOR FACTORY MANAGER',
  'SENIOR FAILURE ANALYSIS ENGINEER',
  'SENIOR FAILURE ANALYSIS TECHNICIAN',
  'SENIOR FARM MANAGER',
  'SENIOR FARM TECHNICIAN (HORTICULTURE)',
  'SENIOR FASHION AND GARMENT DESIGNER',
  'SENIOR FASHION CONSULTANT',
  'SENIOR FASHION DESIGNER',
  'SENIOR FASHION MERCHANDISER',
  'SENIOR FAST FOOD RESTAURANT MANAGER',
  'SENIOR FELLOW',
  'SENIOR FIELD APPLICATION ENGINEER',
  'SENIOR FIELD ENGINEER',
  'SENIOR FIELD ENGINEER, INFORMATION TECHNOLOGY (IT)',
  'SENIOR FIELD MANAGER',
  'SENIOR FIELD SALES MANAGER',
  'SENIOR FIELD SERVICE ENGINEER',
  'SENIOR FIELD SERVICE MANAGER',
  'SENIOR FIELD SERVICE SPECIALIST',
  'SENIOR FIELD SERVICE TECHNICIAN',
  'SENIOR FIELD SUPPORT ENGINEER',
  'SENIOR FIELD SURVEYOR',
  'SENIOR FIELD TECHNICAL SPECIALIST',
  'SENIOR FINANCE ADVISOR',
  'SENIOR FINANCE ANALYST',
  'SENIOR FINANCE AND ADMINISTRATIVE MANAGER',
  'SENIOR FINANCE CONTROLLER',
  'SENIOR FINANCE DIRECTOR',
  'SENIOR FINANCE EXECUTIVE',
  'SENIOR FINANCE MANAGER',
  'SENIOR FINANCE OFFICER',
  'SENIOR FINANCIAL ACCOUNTANT',
  'SENIOR FINANCIAL ACCOUNTING EXECUTIVE',
  'SENIOR FINANCIAL ACCOUNTING MANAGER',
  'SENIOR FINANCIAL ADVISOR',
  'SENIOR FINANCIAL ANALYSIS MANAGER',
  'SENIOR FINANCIAL ANALYST',
  'SENIOR FINANCIAL AUDITOR',
  'SENIOR FINANCIAL CONSULTANT',
  'SENIOR FINANCIAL CONTROLLER',
  'SENIOR FINANCIAL ENGINEER',
  'SENIOR FINANCIAL EXECUTIVE',
  'SENIOR FINANCIAL MANAGER',
  'SENIOR FINANCIAL PLANNING MANAGER',
  'SENIOR FINANCIAL PRODUCT CONTROLLER',
  'SENIOR FINANCIAL SERVICES CONSULTANT',
  'SENIOR FIRMWARE ENGINEER',
  'SENIOR FITNESS CONSULTANT',
  'SENIOR FLAVORIST',
  'SENIOR FLEET MANAGER',
  'SENIOR FLEET OPERATION MANAGER',
  'SENIOR FLEET POWER PLANT ENGINEER',
  'SENIOR FLOOR MANAGER',
  'SENIOR FOOD & BEVERAGE DIRECTOR',
  'SENIOR FOOD & BEVERAGE MANAGER',
  'SENIOR FOOD & BEVERAGE OPERATIONS MANAGER',
  'SENIOR FOOD & BEVERAGE OUTLET CAPTAIN',
  'SENIOR FOOD AND BEVERAGE DEPARTMENT MANAGER (HOTEL)',
  'SENIOR FOOD PROCESS ENGINEER',
  'SENIOR FOOD PROCESSING MANAGER',
  'SENIOR FOOD TECHNOLOGIST',
  'SENIOR FOREMAN',
  'SENIOR FORMULATION CHEMIST',
  'SENIOR FOUNDRY METALLURGIST',
  'SENIOR FOUNDRY TECHNICIAN',
  'SENIOR FRAGRANCE DEVELOPMENT MANAGER',
  'SENIOR FRANCHISE MANAGER',
  'SENIOR FRONT OFFICE MANAGER',
  'SENIOR FUND ACCOUNTANT',
  'SENIOR FUND ACCOUNTING MANAGER',
  'SENIOR FUND CONSULTANT',
  'SENIOR FUND MANAGER',
  'SENIOR GAME DESIGNER',
  'SENIOR GAME DEVELOPER',
  'SENIOR GAME PRODUCER',
  'SENIOR GAMES PROGRAMMER',
  'SENIOR GARMENT DESIGNER',
  'SENIOR GENERAL SALES MANAGER',
  'SENIOR GEOLOGICAL ENGINEER',
  'SENIOR GEOLOGIST',
  'SENIOR GEOPHYSICIST',
  'SENIOR GEOTECHNICAL ENGINEER',
  'SENIOR GEOTECHNICAL MANAGER',
  'SENIOR GLOBAL VICE PRESIDENT',
  'SENIOR GOLF COURSE MANAGER',
  'SENIOR GRAPHIC ARTIST',
  'SENIOR GRAPHIC DESIGNER',
  'SENIOR GRAPHIC WEB DESIGNER',
  'SENIOR GROUP MANAGEMENT ACCOUNTANT',
  'SENIOR GROUP PUBLISHER',
  'SENIOR GUEST RELATIONS EXECUTIVE',
  'SENIOR GUEST RELATIONS MANAGER',
  'SENIOR GUEST RELATIONS OFFICER',
  'SENIOR GUEST SERVICES MANAGER',
  'SENIOR HAIR CONSULTANT',
  'SENIOR HAIR RESTORATION TECHNOLOGIST',
  'SENIOR HAIRSTYLIST',
  'SENIOR HALL MANAGER',
  'SENIOR HARDWARE ENGINEER (COMPUTER)',
  'SENIOR HEALTH CARE CONSULTANT',
  'SENIOR HEALTH CARE EXECUTIVE',
  'SENIOR HEALTH, SAFETY & ENVIRONMENTAL MANAGER',
  'SENIOR HEAT TRANSFER ENGINEER',
  'SENIOR HEAVY EQUIPMENT TECHNICIAN',
  'SENIOR HEDGE FUND ADMINISTRATOR',
  'SENIOR HEDGE FUND MANAGER',
  'SENIOR HELPDESK CONSULTANT',
  'SENIOR HELPDESK ENGINEER',
  'SENIOR HORTICULTURAL TECHNICIAN',
  'SENIOR HORTICULTURE DESIGNER',
  'SENIOR HORTICULTURIST',
  'SENIOR HOTEL EXECUTIVE',
  'SENIOR HULL ENGINEER',
  'SENIOR HUMAN RESOURCE & ADMINISTRATION MANAGER',
  'SENIOR HUMAN RESOURCE & ADMINISTRATIVE MANAGER',
  'SENIOR HUMAN RESOURCE & ADMINISTRATIVE OFFICER',
  'SENIOR HUMAN RESOURCE ADVISOR',
  'SENIOR HUMAN RESOURCE ANALYST',
  'SENIOR HUMAN RESOURCE BUSINESS PARTNER',
  'SENIOR HUMAN RESOURCE CONSULTANT',
  'SENIOR HUMAN RESOURCE DEVELOPMENT OFFICER',
  'SENIOR HUMAN RESOURCE DIRECTOR',
  'SENIOR HUMAN RESOURCE EXECUTIVE',
  'SENIOR HUMAN RESOURCE MANAGER',
  'SENIOR HUMAN RESOURCE OFFICER',
  'SENIOR HUMAN RESOURCE SPECIALIST',
  'SENIOR HVAC ENGINEER',
  'SENIOR HYDRAULIC TECHNICIAN',
  'SENIOR HYDRAULICS ENGINEER',
  'SENIOR HYDROGRAPHIC ENGINEER',
  'SENIOR HYDROGRAPHIC SURVEYOR',
  'SENIOR HYDROLOGIST',
  'Senior Hypertext Preprocessor (Php) Developer',
  'SENIOR IMPLEMENTATION CONSULTANT',
  'SENIOR IMPLEMENTATION DIRECTOR',
  'SENIOR IMPLEMENTATION ENGINEER',
  'SENIOR IMPLEMENTATION PROJECT MANAGER',
  'SENIOR IMPORT & EXPORT COORDINATOR',
  'SENIOR IMPORT MANAGER',
  'SENIOR INCINERATION PROCESS SUPERVISOR',
  'SENIOR INDEX ANALYST',
  'SENIOR INDUSTRIAL ENGINEER',
  'SENIOR INDUSTRIAL PRODUCTS DESIGNER',
  'SENIOR INDUSTRIAL RELATIONS MANAGER',
  'SENIOR INDUSTRY DEVELOPMENT MANAGER',
  'SENIOR INFOCOMM EXECUTIVE',
  'SENIOR INFORMATION & COMMUNICATION TECHNOLOGY MANAGER',
  'SENIOR INFORMATION ENGINEER',
  'SENIOR INFORMATION EXECUTIVE',
  'SENIOR INFORMATION MANAGEMENT MANAGER',
  'SENIOR INFORMATION PROGRAMMER',
  'SENIOR INFORMATION SECURITY ADMINISTRATOR',
  'Senior Information Security Consultant',
  'Senior Information Security Manager',
  'SENIOR INFORMATION SERVICES EXECUTIVE',
  'Senior Information System Analyst',
  'Senior Information System Engineer',
  'SENIOR INFORMATION SYSTEM OFFICER',
  'Senior Information Systems Architect',
  'SENIOR INFORMATION SYSTEMS MANAGER',
  'SENIOR INFORMATION TECHNOLOGY (IT) FIELD ENGINEER',
  'SENIOR INFORMATION TECHNOLOGY ANALYST',
  'SENIOR INFORMATION TECHNOLOGY AUDITOR',
  'SENIOR INFORMATION TECHNOLOGY BUSINESS ANALYST',
  'SENIOR INFORMATION TECHNOLOGY BUSINESS SOLUTION MANAGER',
  'Senior Information Technology Consultant',
  'SENIOR INFORMATION TECHNOLOGY COORDINATOR',
  'SENIOR INFORMATION TECHNOLOGY DIRECTOR',
  'SENIOR INFORMATION TECHNOLOGY ENGINEER',
  'Senior Information Technology Executive',
  'SENIOR INFORMATION TECHNOLOGY HELPDESK COORDINATOR',
  'SENIOR INFORMATION TECHNOLOGY INFRASTRUCTURE ANALYST',
  'SENIOR INFORMATION TECHNOLOGY MANAGER',
  'SENIOR INFORMATION TECHNOLOGY PRE-SALES CONSULTANT',
  'SENIOR INFORMATION TECHNOLOGY PROGRAM MANAGER',
  'SENIOR INFORMATION TECHNOLOGY PROJECT LEADER',
  'SENIOR INFORMATION TECHNOLOGY PROJECT MANAGER',
  'SENIOR INFORMATION TECHNOLOGY QUALITY ASSURANCE SPECIALIST',
  'SENIOR INFORMATION TECHNOLOGY SECURITY SPECIALIST',
  'SENIOR INFORMATION TECHNOLOGY SPECIALIST',
  'SENIOR INFORMATION TECHNOLOGY SUPPORT ENGINEER',
  'SENIOR INFORMATION TECHNOLOGY SUPPORT EXECUTIVE',
  'SENIOR INFORMATION TECHNOLOGY SUPPORT MANAGER',
  'SENIOR INFORMATION TECHNOLOGY SUPPORT OFFICER',
  'SENIOR INFORMATION TECHNOLOGY SYSTEM SUPPORT SPECIALIST',
  'SENIOR INFORMATION TECHNOLOGY TECHNICIAN',
  'SENIOR INFORMATION VISUALISATION ENGINEER',
  'SENIOR INFRASTRUCTURE ENGINEER',
  'SENIOR INFRASTRUCTURE SUPPORT SPECIALIST',
  'Senior Infrastructure Technical Specialist',
  'SENIOR INSPECTION ENGINEER',
  'SENIOR INSPECTION TECHNICIAN',
  'SENIOR INSTALLATION ENGINEER',
  'SENIOR INSTALLATION MANAGER',
  'SENIOR INSTALLATION TECHNICIAN',
  'SENIOR INSTRUCTOR',
  'SENIOR INSTRUCTOR, DANCE',
  'SENIOR INSTRUCTOR, MUSIC',
  'SENIOR INSTRUMENT DESIGNER',
  'SENIOR INSTRUMENTATION AND ELECTRICAL ENGINEER',
  'SENIOR INSTRUMENTATION AND ELECTRICAL MAINTENANCE ENGINEER',
  'SENIOR INSTRUMENTATION AND ELECTRICAL MANAGER',
  'SENIOR INSTRUMENTATION ENGINEER',
  'SENIOR INSTRUMENTATION TECHNICIAN',
  'SENIOR INTEGRATED CIRCUIT DESIGNER',
  'SENIOR INTEGRATED CONTROL SYSTEM ENGINEER',
  'SENIOR INTEGRATION ENGINEER',
  'Senior Integration Engineer (Electronics)',
  'Senior Integration Specialist',
  'SENIOR INTEGRATION TECHNICAL PROJECT MANAGER',
  'SENIOR INTERACTIVE AND DIGITAL MEDIA PRODUCER',
  'SENIOR INTERACTIVE AND DIGITAL MEDIA PRODUCTION MANAGER',
  'SENIOR INTERIOR ARCHITECT',
  'SENIOR INTERIOR CONSULTANT',
  'SENIOR INTERIOR DECORATOR',
  'SENIOR INTERIOR DESIGN DIRECTOR',
  'SENIOR INTERIOR DESIGN DRAUGHTSMAN',
  'SENIOR INTERIOR DESIGNER',
  'SENIOR INTERIOR VISUALIZER',
  'SENIOR INTERNAL AUDIT ASSISTANT',
  'SENIOR INTERNAL AUDIT EXECUTIVE',
  'SENIOR INTERNAL AUDIT MANAGER',
  'SENIOR INTERNAL AUDIT SUPERVISOR',
  'SENIOR INTERNAL AUDITOR',
  'SENIOR INTERNAL MEDICINE PHYSICIAN OR GENERAL PHYSICIAN',
  'SENIOR INTERNAL SALES ENGINEER',
  'SENIOR INTERNATIONAL EVENTS MANAGER',
  'SENIOR INTERPRETER',
  'SENIOR INVENTORY COORDINATOR',
  'SENIOR INVENTORY MANAGER',
  'SENIOR INVESTIGATOR',
  'SENIOR INVESTMENT ADVISOR',
  'SENIOR INVESTMENT ANALYST',
  'SENIOR INVESTMENT BANKER',
  'SENIOR INVESTMENT CONSULTANT',
  'SENIOR INVESTMENT MANAGER',
  'SENIOR INVESTMENT SALES SPECIALIST',
  'SENIOR INVESTMENT STRATEGIST',
  'SENIOR INVESTOR RELATIONS MANAGER',
  'SENIOR IT AUDIT EXECUTIVE',
  'SENIOR IT DEVELOPMENT OFFICER',
  'SENIOR JAVA DEVELOPER',
  'SENIOR JEWELLERY DESIGNER',
  'SENIOR JOB EVALUATOR',
  'SENIOR JOINT GENERAL MANAGER',
  'SENIOR JOURNALIST',
  'SENIOR JOURNALIST, RADIO AND TELEVISION',
  'SENIOR KEY ACCOUNT EXECUTIVE',
  'SENIOR KEY ACCOUNT MANAGER',
  'SENIOR KEY ACCOUNTS MANAGER',
  'SENIOR KITCHEN EQUIPMENT TECHNICIAN',
  'SENIOR LABORATORY ANALYST',
  'SENIOR LABORATORY ASSISTANT',
  'SENIOR LABORATORY ENGINEER',
  'SENIOR LABORATORY EXECUTIVE',
  'SENIOR LABORATORY MANAGER',
  'SENIOR LABORATORY TECHNICIAN',
  'SENIOR LABORATORY TECHNICIAN (CHEMISTRY)',
  'SENIOR LABORATORY TECHNOLOGIST',
  'SENIOR LAND SURVEYOR',
  'SENIOR LANDSCAPE ARCHITECT',
  'SENIOR LANDSCAPE ARCHITECTURE ASSISTANT',
  'SENIOR LANDSCAPE DESIGNER',
  'SENIOR LANGUAGE TEACHER',
  'SENIOR LASER ENGINEER',
  'SENIOR LAYOUT ENGINEER',
  'SENIOR LEAD ENGINEER (CIVIL)',
  'Senior Lead Teacher (Early Childhood Care And Education)',
  'SENIOR LEAD TECHNICIAN',
  'SENIOR LEADERSHIP DEVELOPMENT MANAGER',
  'SENIOR LEARNING & DEVELOPMENT EXECUTIVE',
  'SENIOR LEARNING & DEVELOPMENT MANAGER',
  'SENIOR LEASE MANAGEMENT MANAGER',
  'SENIOR LECTURER',
  'SENIOR LECTURER, POLYTECHNIC',
  'SENIOR LECTURER, UNIVERSITY',
  'SENIOR LEGAL ASSISTANT',
  'SENIOR LEGAL CONSULTANT',
  'SENIOR LEGAL MANAGER',
  'SENIOR LEGAL SECRETARY',
  'SENIOR LENDING SPECIALIST',
  'SENIOR LIBRARIAN',
  'SENIOR LIBRARY OFFICER',
  'SENIOR LIFESTYLE CONSULTANT',
  'SENIOR LIFT ENGINEER',
  'SENIOR LIFT TECHNICIAN',
  'SENIOR LIGHTING DESIGNER',
  'SENIOR LIGHTING TECHNICIAN',
  'SENIOR LOANS OFFICER',
  'SENIOR LOGISTICS ADMINISTRATOR',
  'SENIOR LOGISTICS ANALYST',
  'SENIOR LOGISTICS AND PROCUREMENT COORDINATOR',
  'SENIOR LOGISTICS COORDINATOR',
  'SENIOR LOGISTICS DIRECTOR',
  'SENIOR LOGISTICS ENGINEER',
  'SENIOR LOGISTICS EXECUTIVE',
  'SENIOR LOGISTICS MANAGER',
  'SENIOR LOGISTICS SUPERVISOR',
  'SENIOR LOSS ASSESSOR',
  'SENIOR LOUNGE MANAGER',
  'SENIOR MACHINERY AND TOOLS ENGINEER (INDUSTRIAL)',
  'SENIOR MACHINERY ENGINEER',
  'SENIOR MACHINERY MAINTENANCE ENGINEER',
  'SENIOR MACHINERY MECHANIC (GENERAL)',
  'SENIOR MACHINERY TECHNICIAN',
  'SENIOR MACHINING AND TOOLING TECHNICIAN',
  'SENIOR MACHINING TECHNICIAN',
  'SENIOR MACHINIST',
  'Senior Machinist (Precision Engineer)',
  'SENIOR MAINTENANCE ENGINEER',
  'SENIOR MAINTENANCE MANAGER',
  'SENIOR MAINTENANCE OFFICER',
  'SENIOR MAINTENANCE PLANNER',
  'SENIOR MAINTENANCE TECHNICIAN',
  'SENIOR MANAGEMENT ACCOUNTANT',
  'SENIOR MANAGEMENT CONSULTANT',
  'SENIOR MANAGEMENT EXECUTIVE',
  'SENIOR MANAGEMENT INFORMATION SYSTEMS EXECUTIVE',
  'SENIOR MANAGEMENT INFORMATION SYSTEMS MANAGER',
  'SENIOR MANAGEMENT SUPERVISOR',
  'SENIOR MANAGEMENT SUPPORT EXECUTIVE',
  'SENIOR MANAGER',
  'SENIOR MANAGER, ACCOUNTING (FINANCIAL DEPARTMENT)',
  'SENIOR MANAGER, ADMINISTRATION',
  'SENIOR MANAGER, ADVERTISING',
  'SENIOR MANAGER, BANK (BRANCH)',
  'SENIOR MANAGER, BUSINESS DEVELOPMENT',
  'SENIOR MANAGER, COMPUTER AND SYSTEMS INFORMATION',
  'SENIOR MANAGER, COMPUTER OPERATIONS AND NETWORK',
  'SENIOR MANAGER, CUSTOMER RELATIONSHIP MANAGEMENT',
  'SENIOR MANAGER, FINANCE',
  'SENIOR MANAGER, FOOD AND BEVERAGE DEPARTMENT (HOTEL)',
  'SENIOR MANAGER, FUND',
  'SENIOR MANAGER, HOTEL',
  'SENIOR MANAGER, HUMAN RESOURCE',
  'SENIOR MANAGER, INFORMATION SYSTEMS',
  'SENIOR MANAGER, INFORMATION TECHNOLOGY',
  'SENIOR MANAGER, INTERIOR DESIGN',
  'SENIOR MANAGER, MARKETING',
  'SENIOR MANAGER, NIGHTCLUB',
  'SENIOR MANAGER, OPERATIONS (AIRPORT)',
  'SENIOR MANAGER, OPERATIONS (CONSTRUCTION)',
  'SENIOR MANAGER, OPERATIONS (POSTAL SERVICE)',
  'SENIOR MANAGER, OPERATIONS (TELECOMMUNICATIONS)',
  'SENIOR MANAGER, OPERATIONS (TRANSPORT)',
  'SENIOR MANAGER, PROCUREMENT',
  'SENIOR MANAGER, PRODUCTION (CONSTRUCTION)',
  'SENIOR MANAGER, PRODUCTION (MANUFACTURING)',
  'SENIOR MANAGER, PROJECT (CONSTRUCTION)',
  'SENIOR MANAGER, PUBLIC RELATIONS',
  'SENIOR MANAGER, PURCHASING',
  'SENIOR MANAGER, QUALITY ASSURANCE',
  'SENIOR MANAGER, RESEARCH AND DEVELOPMENT',
  'SENIOR MANAGER, RESTAURANT',
  'SENIOR MANAGER, RETAIL TRADE',
  'SENIOR MANAGER, SELF-SERVICE STORE',
  'SENIOR MANAGER, STAGE',
  'SENIOR MANAGER, SUPPLY AND DISTRIBUTION',
  'SENIOR MANAGER, TELECOMMUNICATIONS OPERATIONS',
  'SENIOR MANAGER, TRAINING',
  'SENIOR MANAGER, TREASURY',
  'SENIOR MANAGING DIRECTOR',
  'SENIOR MANAGING DIRECTOR (BANKING INDUSTRY)',
  'SENIOR MANIPULATIVE THERAPIST',
  'SENIOR MANPOWER DEVELOPMENT MANAGER',
  'SENIOR MANUFACTURING ENGINEER (GENERAL)',
  'Senior Manufacturing Engineer (Manufacturing)',
  'SENIOR MANUFACTURING ENGINEERING ASSISTANT (GENERAL)',
  'SENIOR MANUFACTURING ENGINEERING MANAGER',
  'SENIOR MANUFACTURING ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR MANUFACTURING EXECUTIVE',
  'SENIOR MANUFACTURING MANAGER',
  'SENIOR MANUFACTURING OPERATIONS MANAGER',
  'SENIOR MANUFACTURING SPECIALIST',
  'SENIOR MANUFACTURING SYSTEMS ENGINEER',
  'SENIOR MANUFACTURING TECHNICIAN',
  'SENIOR MANUFACTURING TEST ENGINEER',
  'SENIOR MARINE ELECTRICAL ENGINEER',
  'SENIOR MARINE ELECTRICAL TECHNICIAN',
  'SENIOR MARINE ELECTRONIC ENGINEER',
  'SENIOR MARINE ENGINE ROOM SPECIALIST',
  'SENIOR MARINE ENGINEER',
  'SENIOR MARINE ENGINEERING ASSISTANT',
  'SENIOR MARINE ENGINEERING TECHNICIAN',
  'SENIOR MARINE MANAGER',
  'SENIOR MARINE MECHANICAL ENGINEER',
  'SENIOR MARINE OPERATIONS EXECUTIVE',
  'SENIOR MARINE OPERATIONS MANAGER',
  'SENIOR MARINE PIPING ENGINEER',
  'SENIOR MARINE SERVICE ENGINEER',
  'SENIOR MARINE SUPERINTENDENT (TECHNICAL)',
  'SENIOR MARINE SUPERVISOR',
  'SENIOR MARINE SURVEYOR',
  'SENIOR MARINE SURVEYOR (SHIP AND NAUTICAL)',
  'SENIOR MARINE SYSTEMS ENGINEER',
  'SENIOR MARINE TECHNICIAN',
  'SENIOR MARITIME CENTRE MANAGER',
  'SENIOR MARKET ANALYST',
  'SENIOR MARKET COORDINATOR',
  'SENIOR MARKET DEVELOPMENT MANAGER',
  'SENIOR MARKET PLANNER',
  'SENIOR MARKET RESEARCH ANALYST',
  'SENIOR MARKET RESEARCHER',
  'SENIOR MARKETING ADVISOR',
  'SENIOR MARKETING COMMUNICATIONS EXECUTIVE',
  'SENIOR MARKETING COMMUNICATIONS MANAGER',
  'SENIOR MARKETING CONSULTANT',
  'SENIOR MARKETING COORDINATOR',
  'SENIOR MARKETING DIRECTOR',
  'SENIOR MARKETING ENGINEER',
  'SENIOR MARKETING EXECUTIVE',
  'SENIOR MARKETING EXECUTIVE (PLANNING)',
  'SENIOR MARKETING MANAGER',
  'SENIOR MARKETING OFFICER',
  'SENIOR MARKETING OPERATIONS MANAGER',
  'SENIOR MARKETING PROJECT MANAGER',
  'SENIOR MARKETING REPRESENTATIVE',
  'SENIOR MARKETING SALES EXECUTIVE',
  'SENIOR MARKETING STRATEGY MANAGER',
  'SENIOR MARKETING SUPPORT EXECUTIVE',
  'SENIOR MATERIAL CONTROL EXECUTIVE',
  'SENIOR MATERIALS CONTROLLER',
  'SENIOR MATERIALS ENGINEER',
  'SENIOR MATERIALS LOGISTICS MANAGER',
  'SENIOR MATERIALS MANAGER',
  'SENIOR MATERIALS PROCUREMENT REPRESENTATIVE',
  'SENIOR MATHEMATICAL TECHNICIAN',
  'SENIOR MATHEMATICIAN',
  'SENIOR MEASUREMENT TECHNICIAN',
  'SENIOR MECHANIC, MACHINERY (GENERAL)',
  'SENIOR MECHANICAL & ELECTRICAL (M&E) COORDINATOR',
  'SENIOR MECHANICAL & ELECTRICAL (M&E) ENGINEER',
  'SENIOR MECHANICAL & ELECTRICAL (M&E) SUPERVISOR',
  'SENIOR MECHANICAL & ELECTRICAL (M&E) TECHNICIAN',
  'SENIOR MECHANICAL ASSISTANT',
  'SENIOR MECHANICAL COMMISSIONING ENGINEER',
  'SENIOR MECHANICAL DESIGN ENGINEER',
  'SENIOR MECHANICAL DESIGNER',
  'SENIOR MECHANICAL DRAUGHTSMAN',
  'SENIOR MECHANICAL ENGINEER',
  'SENIOR MECHANICAL ENGINEER (AUTOMOTIVE)',
  'SENIOR MECHANICAL ENGINEER (GENERAL)',
  'SENIOR MECHANICAL ENGINEER (INDUSTRIAL MACHINERY AND TOOLS)',
  'SENIOR MECHANICAL ENGINEER (MARINE)',
  'SENIOR MECHANICAL ENGINEER (MOTORS AND ENGINES, EXCEPT MARINE)',
  'SENIOR MECHANICAL ENGINEERING ASSISTANT (GENERAL)',
  'SENIOR MECHANICAL ENGINEERING ASSISTANT (MOTORS AND ENGINES)',
  'SENIOR MECHANICAL ENGINEERING TECHNICIAN (GENERAL)',
  'SENIOR MECHANICAL ENGINEERING TECHNICIAN (MOTORS AND ENGINES)',
  'SENIOR MECHANICAL MAINTENANCE ENGINEER',
  'SENIOR MECHANICAL MAINTENANCE TECHNICIAN',
  'SENIOR MECHANICAL PACKAGE ENGINEER',
  'SENIOR MECHANICAL SUPERINTENDENT',
  'SENIOR MECHANICAL SUPERVISOR',
  'SENIOR MECHANICAL TECHNICIAN',
  'SENIOR MECHANIST',
  'SENIOR MECHANTRONICS ENGINEER',
  'SENIOR MEDIA DESIGNER',
  'SENIOR MEDIA DIRECTOR',
  'SENIOR MEDIA ENGINEER',
  'SENIOR MEDIA EXECUTIVE',
  'SENIOR MEDIA MANAGER',
  'SENIOR MEDIA SALES EXECUTIVE',
  'SENIOR MEDICAL ADVISOR',
  'SENIOR MEDICAL DIRECTOR',
  'SENIOR MEDICAL LABORATORY TECHNOLOGIST',
  'SENIOR MEDICAL MANAGER',
  'SENIOR MEDICAL PHOTOGRAPHER',
  'SENIOR MEDICAL TECHNOLOGIST',
  'SENIOR MERCHANDISER',
  'SENIOR MERCHANDISING EXECUTIVE',
  'SENIOR MERCHANDISING MANAGER',
  'SENIOR METHOD ENGINEER',
  'SENIOR METHODS ENGINEER',
  'SENIOR METROLOGY ENGINEER',
  'SENIOR MICROBIOLOGIST',
  'SENIOR MILLING TECHNOLOGIST',
  'SENIOR MOBILE ENGINEER',
  'SENIOR MOBILE PRODUCT MANAGER',
  'SENIOR MOBILE SERVICE ENGINEER',
  'SENIOR MORTGAGE ANALYST',
  'SENIOR MOTOR VEHICLE TECHNICIAN',
  'SENIOR MOULD DESIGNER',
  'SENIOR MOULD MAINTENANCE SENIOR TECHNICIAN',
  'SENIOR MULTIMEDIA ANIMATOR',
  'SENIOR MULTIMEDIA ARTIST',
  'SENIOR MULTIMEDIA ARTIST AND ANIMATOR',
  'SENIOR MULTIMEDIA DESIGNER',
  'SENIOR MULTIMEDIA DEVELOPER',
  'SENIOR MULTIMEDIA EXECUTIVE',
  'SENIOR MULTIMEDIA MANAGER',
  'SENIOR MULTIMEDIA MARKETING MANAGER',
  'SENIOR MULTIMEDIA PROGRAMMER',
  'SENIOR MUSIC COMPOSER',
  'SENIOR MUSIC DIRECTOR',
  'SENIOR MUSIC INSTRUCTOR',
  'SENIOR MUSIC LOUNGE MANAGER',
  'SENIOR NAVAL ARCHITECT',
  'SENIOR NETWORK ADMINISTRATOR',
  'SENIOR NETWORK ANALYST',
  'Senior Network Consultant',
  'SENIOR NETWORK CONSULTING ENGINEER',
  'SENIOR NETWORK ENGINEER/MANAGER',
  'SENIOR NETWORK MANAGEMENT CENTRE ENGINEER',
  'SENIOR NETWORK MANAGER',
  'SENIOR NETWORK OPERATIONS ENGINEER',
  'SENIOR NETWORK SECURITY ANALYST',
  'SENIOR NETWORK SPECIALIST',
  'SENIOR NETWORK STRATEGY MANAGER',
  'SENIOR NETWORK SUPPORT ANALYST',
  'SENIOR NETWORK SUPPORT ENGINEER',
  'SENIOR NETWORK SUPPORT TECHNICIAN',
  'SENIOR NETWORK SYSTEMS ANALYST',
  'SENIOR NETWORK SYSTEMS ENGINEER',
  'SENIOR NETWORK SYSTEMS PROGRAMMER',
  'SENIOR NETWORK TECHNICIAN',
  'SENIOR NETWORK TRANSMISSION EXECUTIVE',
  'SENIOR NETWORKING MANAGER',
  'SENIOR NEW PRODUCT INTRODUCTION ENGINEER',
  'SENIOR NONDESTRUCTIVE TESTING ENGINEER',
  'SENIOR NUCLEAR MEDICINE TECHNOLOGIST',
  'SENIOR NURSE MANAGER',
  'SENIOR NUTRITIONIST',
  'Senior Occupational Health Manager',
  'Senior Occupational Therapist',
  'SENIOR OCEANOGRAPHER, GEOPHYSICAL',
  'SENIOR OFFSHORE FACILITY MANAGER',
  'SENIOR OIL SPILL SPECIALIST',
  'SENIOR OPERATION MANAGER',
  'SENIOR OPERATION OFFICER',
  'SENIOR OPERATIONS ACCOUNTANT',
  'SENIOR OPERATIONS ADMINISTRATOR',
  'SENIOR OPERATIONS ADVISOR',
  'SENIOR OPERATIONS ANALYST',
  'SENIOR OPERATIONS ASSISTANT',
  'SENIOR OPERATIONS DIRECTOR',
  'SENIOR OPERATIONS ENGINEER',
  'SENIOR OPERATIONS EXECUTIVE',
  'SENIOR OPERATIONS MANAGER',
  'SENIOR OPERATIONS MANAGER (CONSTRUCTION)',
  'SENIOR OPERATIONS MANAGER (GENERAL)',
  'SENIOR OPERATIONS MANAGER (PORT)',
  'SENIOR OPERATIONS OFFICER',
  'SENIOR OPERATIONS OFFICER, FLIGHT',
  'SENIOR OPERATIONS SPECIALIST',
  'SENIOR OPERATIONS TECHNICIAN',
  'SENIOR OPERATOR',
  'SENIOR OPTICAL ENGINEER',
  'SENIOR ORGANISATIONAL DEVELOPMENT MANAGER',
  'SENIOR ORGANISATIONAL EXCELLENCE MANAGER',
  'SENIOR ORGANISER, SALES (REGIONAL OR OVERSEAS)',
  'SENIOR PACKAGING ENGINEER',
  'SENIOR PACKAGING TECHNICIAN',
  'SENIOR PASTOR',
  'SENIOR PASTRY CHEF',
  'SENIOR PATENT MANAGER',
  'SENIOR PATENT TRADEMARK CONSULTANT',
  'SENIOR PAYROLL EXECUTIVE',
  'SENIOR PAYROLL MANAGER',
  'SENIOR PERFORMANCE ANALYST',
  'SENIOR PERFORMANCE CONSULTANT',
  'SENIOR PERFORMANCE ENGINEER',
  'SENIOR PERSONNEL EXECUTIVE',
  'SENIOR PERSONNEL OFFICER',
  'SENIOR PET GROOMING INSTRUCTOR',
  'SENIOR PETROCHEMICALS TECHNICIAN (CHEMICAL ENGINEERING)',
  'SENIOR PHARMACEUTICAL SALES REPRESENTATIVE',
  'SENIOR PHARMACEUTICAL TECHNICIAN',
  'SENIOR PHARMACIST',
  'SENIOR PHLEBOTOMIST',
  'SENIOR PHOTOGRAPHER (GENERAL)',
  'SENIOR PHOTOGRAPHER, ADVERTISING',
  'SENIOR PHYSICAL THERAPIST',
  'SENIOR PHYSICIST (GENERAL)',
  'SENIOR PHYSICS TECHNICIAN',
  'SENIOR PHYSIOLOGIST',
  'SENIOR PHYSIOTHERAPIST',
  'SENIOR PIPE STRESS ENGINEER',
  'SENIOR PIPING DESIGN ENGINEER',
  'SENIOR PIPING DESIGNER',
  'SENIOR PIPING ENGINEER',
  'SENIOR PIPING SUPERINTENDENT',
  'SENIOR PIPING TECHNICIAN',
  'SENIOR PLACEMENT OFFICER',
  'SENIOR PLANNER',
  'SENIOR PLANNER, URBAN',
  'SENIOR PLANNING ANALYST',
  'SENIOR PLANNING AND FORECASTING MANAGER',
  'SENIOR PLANNING ENGINEER',
  'SENIOR PLANNING EXECUTIVE',
  'Senior Planning Executive (Manufacturing)',
  'SENIOR PLANNING MANAGER',
  'SENIOR PLANNING OFFICER',
  'SENIOR PLANT BIOTECHNOLOGIST',
  'SENIOR PLANT COMPUTING ENGINEER',
  'SENIOR PLANT MAINTENANCE ENGINEER',
  'SENIOR PLANT OPERATION ENGINEER',
  'SENIOR POLYMER ENGINEER',
  'SENIOR PORTFOLIO ANALYST',
  'SENIOR PORTFOLIO MANAGER',
  'SENIOR POSTDOCTORAL RESEARCH FELLOW',
  'SENIOR POWER DISTRIBUTION TECHNICIAN (ELECTRICITY)',
  'SENIOR PRECISION ENGINEER',
  'SENIOR PRECISION ENGINEERING TECHNICIAN',
  'SENIOR PRECISION GRINDING MACHINE SETTER-OPERATOR',
  'SENIOR PRECISION MACHINE TECHNICIAN',
  'SENIOR PRECISION MACHINIST',
  'SENIOR PRE-SALES CONSULTANT',
  'Senior Pre-School Teacher (Early Childhood Care And Education)',
  'SENIOR PRICING ANALYST',
  'SENIOR PRICING MANAGER',
  'SENIOR PRIMARY SCHOOL TEACHER',
  'SENIOR PRINCIPAL BUSINESS CONSULTANT',
  'SENIOR PRINCIPAL CONSULTANT',
  'SENIOR PRINCIPAL ENGINEER',
  'SENIOR PRINCIPAL INVESTIGATOR',
  'SENIOR PRINT PRODUCTION MANAGER',
  'SENIOR PRINTING TECHNICIAN',
  'SENIOR PRIVATE BANKER',
  'SENIOR PROCESS (CONTROL) ENGINEER',
  'SENIOR PROCESS ANALYST',
  'SENIOR PROCESS DESIGN ENGINEER',
  'SENIOR PROCESS ENGINEER',
  'Senior Process Engineer (Electronics)',
  'SENIOR PROCESS ENGINEER MANAGER',
  'SENIOR PROCESS MANAGER',
  'SENIOR PROCESS SAFETY ENGINEER',
  'SENIOR PROCESS TECHNICIAN',
  'SENIOR PROCUREMENT ANALYST',
  'SENIOR PROCUREMENT COORDINATOR',
  'SENIOR PROCUREMENT DIRECTOR',
  'SENIOR PROCUREMENT ENGINEER',
  'SENIOR PROCUREMENT EXECUTIVE',
  'SENIOR PROCUREMENT MANAGER',
  'SENIOR PROCUREMENT OFFICER',
  'SENIOR PRODUCER',
  'SENIOR PRODUCER, RADIO AND TELEVISION',
  'SENIOR PRODUCT ADMINISTRATOR',
  'SENIOR PRODUCT ANALYST',
  'SENIOR PRODUCT APPLICATION ENGINEER',
  'SENIOR PRODUCT BUSINESS MANAGER',
  'SENIOR PRODUCT DESIGN ENGINEER',
  'SENIOR PRODUCT DESIGNER',
  'SENIOR PRODUCT DEVELOPMENT ENGINEER',
  'SENIOR PRODUCT DEVELOPMENT EXECUTIVE',
  'SENIOR PRODUCT DEVELOPMENT MANAGER',
  'SENIOR PRODUCT ENGINEER',
  'SENIOR PRODUCT ENGINEERING DIRECTOR',
  'SENIOR PRODUCT ENGINEERING MANAGER',
  'SENIOR PRODUCT EXECUTIVE',
  'SENIOR PRODUCT LINE MANAGER',
  'SENIOR PRODUCT MANAGER',
  'SENIOR PRODUCT MARKETING DIRECTOR',
  'SENIOR PRODUCT MARKETING ENGINEER',
  'SENIOR PRODUCT MARKETING MANAGER',
  'SENIOR PRODUCT PRODUCER',
  'SENIOR PRODUCT QUALITY ENGINEER',
  'SENIOR PRODUCT SALES REPRESENTATIVE',
  'SENIOR PRODUCT SALES SPECIALIST',
  'SENIOR PRODUCT SPECIALIST',
  'SENIOR PRODUCT SUPPORT ENGINEER',
  'SENIOR PRODUCT SUPPORT MANAGER',
  'SENIOR PRODUCT SUPPORT TECHNICIAN',
  'SENIOR PRODUCT TEST ENGINEER',
  'SENIOR PRODUCTION CONTROL MANAGER',
  'SENIOR PRODUCTION CONTROL PLANNER',
  'SENIOR PRODUCTION COORDINATOR',
  'SENIOR PRODUCTION DIRECTOR',
  'SENIOR PRODUCTION ENGINEER',
  'Senior Production Engineer (Marine And Offshore)',
  'SENIOR PRODUCTION ENGINEERING TECHNICIAN',
  'SENIOR PRODUCTION EXECUTIVE',
  'SENIOR PRODUCTION MANAGER',
  'SENIOR PRODUCTION MANAGER (CONSTRUCTION)',
  'SENIOR PRODUCTION MANAGER (MANUFACTURING)',
  'SENIOR PRODUCTION OPERATIONS MANAGER',
  'SENIOR PRODUCTION PLANNER',
  'SENIOR PRODUCTION QUALITY ENGINEER',
  'SENIOR PRODUCTION SPECIALIST',
  'SENIOR PRODUCTION SUPERVISOR',
  'SENIOR PRODUCTION TECHNICIAN',
  'SENIOR PRODUCTION/MATERIALS PLANNER',
  'SENIOR PRODUCTS DESIGNER',
  'SENIOR PROFESSOR',
  'SENIOR PROGRAM DIRECTOR',
  'SENIOR PROGRAMME COORDINATOR',
  'SENIOR PROGRAMME DIRECTOR',
  'SENIOR PROGRAMME EXECUTIVE',
  'SENIOR PROGRAMME MANAGER',
  'SENIOR PROGRAMMER, ANALYST',
  'SENIOR PROGRAMMER, APPLICATION',
  'SENIOR PROGRAMMER, MULTIMEDIA',
  'SENIOR PROGRAMMER, SYSTEMS',
  'SENIOR PROGRAMMER, TECHNICAL',
  'SENIOR PROJECT ADMINISTRATOR',
  'SENIOR PROJECT ANALYST',
  'SENIOR PROJECT APPLICATION ENGINEER',
  'SENIOR PROJECT ASSISTANT',
  'SENIOR PROJECT CONSULTANT',
  'SENIOR PROJECT CONTROL ENGINEER',
  'SENIOR PROJECT CONTROL EXECUTIVE',
  'SENIOR PROJECT CONTROL MANAGER',
  'SENIOR PROJECT CONTROL SPECIALIST',
  'SENIOR PROJECT CONTROLLER',
  'SENIOR PROJECT COORDINATION MANAGER',
  'SENIOR PROJECT COORDINATOR',
  'SENIOR PROJECT DESIGN ENGINEER',
  'SENIOR PROJECT DESIGN MANAGER',
  'SENIOR PROJECT DESIGNER',
  'SENIOR PROJECT DEVELOPER',
  'SENIOR PROJECT DEVELOPMENT EXECUTIVE',
  'SENIOR PROJECT DEVELOPMENT MANAGER',
  'SENIOR PROJECT DIRECTOR',
  'SENIOR PROJECT ENGINEER',
  'Senior Project Engineer (Marine And Offshore)',
  'SENIOR PROJECT ENGINEER/MANAGER (ENGINEERING)',
  'SENIOR PROJECT ESTIMATING ENGINEER',
  'SENIOR PROJECT EXECUTIVE',
  'SENIOR PROJECT FINANCE DIRECTOR',
  'SENIOR PROJECT MANAGEMENT DIRECTOR',
  'SENIOR PROJECT MANAGEMENT MANAGER',
  'SENIOR PROJECT MANAGER',
  'SENIOR PROJECT MANAGER (CONSTRUCTION)',
  'SENIOR PROJECT MANAGER (MARINE)',
  'SENIOR PROJECT MANAGER, INFORMATION TECHNOLOGY',
  'SENIOR PROJECT PLANNER',
  'SENIOR PROJECT PROCUREMENT AND CONTRACTS MANAGER',
  'SENIOR PROJECT QUANTITY SURVEYOR',
  'SENIOR PROJECT SALES ENGINEER',
  'SENIOR PROJECT SERVICES MANAGER',
  'SENIOR PROJECT SUPERVISOR',
  'SENIOR PROMOTIONS EXECUTIVE',
  'SENIOR PROPERTY MANAGEMENT EXECUTIVE',
  'SENIOR PROPOSAL ENGINEER',
  'SENIOR PROSTHETIST/ORTHOTIST',
  'SENIOR PROTOCOL EXECUTIVE',
  'SENIOR PUBLIC AFFAIRS MANAGER',
  'SENIOR PUBLIC RELATIONS EXECUTIVE',
  'SENIOR PUBLIC RELATIONS MANAGER',
  'SENIOR PUBLIC RELATIONS OFFICER',
  'SENIOR PUBLISHING EXECUTIVE',
  'SENIOR PURCHASER',
  'SENIOR PURCHASING CONTROLLER',
  'SENIOR PURCHASING COORDINATOR',
  'SENIOR PURCHASING DIRECTOR',
  'SENIOR PURCHASING EXECUTIVE',
  'SENIOR PURCHASING MANAGER',
  'SENIOR QUALITY ASSESSMENT MANAGER',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL ADMINISTRATOR',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL ANALYST',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL ASSISTANT',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL ENGINEER',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL EXECUTIVE',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL INSPECTOR',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL MANAGER',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL OFFICER',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL SPECIALIST',
  'SENIOR QUALITY ASSURANCE & QUALITY CONTROL TECHNICIAN',
  'SENIOR QUALITY ASSURANCE ADMINISTRATOR',
  'SENIOR QUALITY ASSURANCE ANALYST',
  'SENIOR QUALITY ASSURANCE ASSISTANT',
  'SENIOR QUALITY ASSURANCE AUDITOR',
  'SENIOR QUALITY ASSURANCE ENGINEER',
  'SENIOR QUALITY ASSURANCE INSPECTOR',
  'SENIOR QUALITY ASSURANCE MANAGER',
  'SENIOR QUALITY ASSURANCE OFFICER',
  'SENIOR QUALITY ASSURANCE SPECIALIST',
  'SENIOR QUALITY ASSURANCE SPECIALIST, INFORMATION TECHNOLOGY',
  'SENIOR QUALITY ASSURANCE TECHNICIAN',
  'SENIOR QUALITY ASSURANCE TESTER',
  'SENIOR QUALITY CONTROL ANALYST',
  'SENIOR QUALITY CONTROL ASSISTANT',
  'SENIOR QUALITY CONTROL CHEMIST',
  'SENIOR QUALITY CONTROL ENGINEER',
  'SENIOR QUALITY CONTROL EXECUTIVE',
  'SENIOR QUALITY CONTROL INSPECTOR',
  'SENIOR QUALITY CONTROL MANAGER',
  'SENIOR QUALITY CONTROL OFFICER',
  'SENIOR QUALITY CONTROL SPECIALIST',
  'SENIOR QUALITY CONTROL TECHNICIAN',
  'SENIOR QUALITY DIRECTOR',
  'SENIOR QUALITY ENGINEER',
  'Senior Quality Engineer (Aircraft Engine / Component Maintenance)',
  'Senior Quality Engineer (Electronics) ',
  'SENIOR QUALITY IMPROVEMENT EXECUTIVE',
  'SENIOR QUALITY INSPECTOR',
  'SENIOR QUALITY MANAGER',
  'SENIOR QUANTITATIVE ANALYST',
  'SENIOR QUANTITY SURVEYOR',
  'SENIOR QUOTE ANALYST',
  'SENIOR R&D ENGINEER',
  'SENIOR RADIATION THERAPIST',
  'SENIOR RADIO ENGINEER',
  'SENIOR RADIO FREQUENCY ENGINEER',
  'SENIOR RADIOGRAPHER, DIAGNOSTIC',
  'SENIOR RADIOGRAPHY TECHNICIAN (MEDICAL)',
  'SENIOR RAIL TRACK ENGINEER',
  'SENIOR REACTOR SPECIALIST',
  'SENIOR REAL ESTATE ADMINISTRATOR',
  'SENIOR REAL ESTATE ADVISOR',
  'SENIOR RECRUITMENT CONSULTANT',
  'SENIOR RECRUITMENT EXECUTIVE',
  'SENIOR RECRUITMENT MANAGER',
  'SENIOR REFRIGERATION ENGINEER',
  'SENIOR REFRIGERATION ENGINEERING TECHNICIAN',
  'SENIOR REFRIGERATION TECHNICIAN',
  'SENIOR REGIONAL CONTROLLER',
  'SENIOR REGIONAL CUSTOMER MANAGER',
  'SENIOR REGIONAL MANAGER',
  'SENIOR REGISTRAR (MEDICAL DOCTOR)',
  'SENIOR REGULATORY AFFAIRS ASSOCIATE',
  'SENIOR REGULATORY AFFAIRS EXECUTIVE',
  'SENIOR REGULATORY AFFAIRS MANAGER',
  'SENIOR REGULATORY COMPLIANCE ENGINEER',
  'SENIOR RELATIONSHIP MANAGEMENT CONSULTANT',
  'SENIOR RELATIONSHIP MANAGEMENT EXECUTIVE',
  'SENIOR RELATIONSHIP MANAGER',
  'SENIOR RELIABILITY ENGINEER',
  'SENIOR RESEARCH ANALYST, OPERATIONS',
  'SENIOR RESEARCH ANALYST, SOCIAL',
  'SENIOR RESEARCH AND DEVELOPMENT MANAGER',
  'SENIOR RESEARCH ASSISTANT',
  'SENIOR RESEARCH ASSOCIATE',
  'SENIOR RESEARCH CONSULTANT',
  'SENIOR RESEARCH ENGINEER',
  'SENIOR RESEARCH EXECUTIVE',
  'SENIOR RESEARCH FELLOW',
  'SENIOR RESEARCH MANAGER',
  'SENIOR RESEARCH SCIENTIST (BIOMEDICAL SCIENCE)',
  'SENIOR RESEARCH SCIENTIST (ENVIRONMENTAL ENGINEERING)',
  'SENIOR RESEARCH SCIENTIST (PHYSICAL AND ENGINEERING SCIENCE)',
  'SENIOR RESEARCH TECHNOLOGIST',
  'SENIOR RESEARCH WRITER',
  'SENIOR RESEARCHER',
  'SENIOR RESEARCHER, MARKET',
  'SENIOR RESERVATIONS EXECUTIVE',
  'SENIOR RESERVATIONS MANAGER',
  'SENIOR RESIDENT ARCHITECT',
  'SENIOR RESIDENT ENGINEER',
  'SENIOR RESIDENT PHYSICIAN',
  'SENIOR RESIDENT SUPERVISOR',
  'SENIOR RESOURCING OFFICER',
  'SENIOR RESPIRATORY THERAPIST',
  'SENIOR RESTAURANT AND CATERING MANAGER',
  'SENIOR RESTAURANT EXECUTIVE',
  'SENIOR RESTAURANT MANAGER',
  'SENIOR RESTAURANT SUPERVISOR',
  'SENIOR RETAIL ASSOCIATE',
  'SENIOR RETAIL DEVELOPMENT MANAGER',
  'SENIOR RETAIL EXECUTIVE',
  'SENIOR RETAIL MANAGER',
  'SENIOR RETAIL TRADE MANAGER',
  'SENIOR REVENUE MANAGER',
  'SENIOR REVENUE OPERATIONS AGENT',
  'SENIOR REVENUE OPERATIONS SPECIALIST',
  'SENIOR RIG TECHNICIAN',
  'SENIOR RISK ANALYST',
  'SENIOR RISK CONSULTANT',
  'SENIOR RISK CONTROLLER',
  'SENIOR RISK MANAGEMENT EXECUTIVE',
  'SENIOR RISK MANAGER',
  'SENIOR ROBOTIC ENGINEER',
  'SENIOR ROTATING EQUIPMENT ENGINEER',
  'SENIOR SAFETY CONSULTANT',
  'SENIOR SAFETY COORDINATOR',
  'SENIOR SAFETY ENGINEER (INDUSTRIAL)',
  'SENIOR SAFETY MANAGER',
  'SENIOR SAFETY SUPERVISOR',
  'SENIOR SALES & LOGISTICS MANAGER',
  'SENIOR SALES & MARKETING ENGINEER',
  'SENIOR SALES & MARKETING EXECUTIVE',
  'SENIOR SALES & MARKETING MANAGER',
  'SENIOR SALES & MARKETING SUPERVISOR',
  'SENIOR SALES ACCOUNT MANAGER',
  'SENIOR SALES ADMINISTRATOR',
  'SENIOR SALES ADVISOR',
  'SENIOR SALES ANALYST',
  'SENIOR SALES AND MARKETING DIRECTOR',
  'SENIOR SALES ASSOCIATE',
  'SENIOR SALES CONSULTANT',
  'SENIOR SALES COORDINATOR',
  'SENIOR SALES DEVELOPMENT MANAGER',
  'SENIOR SALES DIRECTOR',
  'SENIOR SALES ENGINEER',
  'SENIOR SALES ENGINEER, TECHNICAL',
  'SENIOR SALES EXECUTIVE',
  'SENIOR SALES MANAGER',
  'SENIOR SALES OPERATIONS EXECUTIVE',
  'SENIOR SALES ORGANISER (REGIONAL OR OVERSEAS)',
  'SENIOR SALES PLANNING MANAGER',
  'SENIOR SALES REPRESENTATIVE (MEDICAL AND PHARMACEUTICAL PRODUCTS)',
  'SENIOR SALES REPRESENTATIVE, COMPUTER',
  "SENIOR SALES REPRESENTATIVE, MANUFACTURER'S",
  'SENIOR SALES REPRESENTATIVE, TECHNICAL',
  'SENIOR SALES REPRESENTATIVE, TECHNICAL EQUIPMENT',
  'SENIOR SALES SPECIALIST',
  'SENIOR SALES SUPPORT ENGINEER',
  'SENIOR SALES SUPPORT EXECUTIVE',
  'SENIOR SALES SUPPORT MANAGER',
  'SENIOR SALES SUPPORT SPECIALIST',
  'SENIOR SALES TEAM LEADER',
  'SENIOR SALES TRAINING MANAGER',
  'Senior Sap Consultant',
  'SENIOR SAP PRE-SALES CONSULTANT',
  'SENIOR SAP PRE-SALES PROJECT MANAGER',
  'Senior Sap Project Manager',
  'Senior Sap Specialist',
  'SENIOR SCIENCE OFFICER',
  'SENIOR SCIENTIFIC OFFICER',
  'SENIOR SCIENTIST',
  'SENIOR SCRIPT WRITER',
  'SENIOR SEAFREIGHT EXECUTIVE',
  'SENIOR SECONDARY SCHOOL TEACHER',
  'SENIOR SECRETARY, COMPANY (EXECUTIVE)',
  'SENIOR SECTION MANAGER',
  'SENIOR SECURITY ANALYST, INFORMATION TECHNOLOGY',
  'SENIOR SECURITY MANAGER',
  'SENIOR SECURITY SCIENTIST',
  'SENIOR SECURITY SOFTWARE ENGINEER',
  'SENIOR SECURITY SPECIALIST, INFORMATION TECHNOLOGY',
  'SENIOR SECURITY SYSTEMS ENGINEER',
  'SENIOR SEGMENT MARKETING MANAGER',
  'SENIOR SELF-SERVICE STORE MANAGER',
  'SENIOR SERVER SUPPORT ANALYST',
  'SENIOR SERVICE ADVISER (CUSTOMER AFTER-SALES SERVICE)',
  'SENIOR SERVICE COORDINATOR',
  'SENIOR SERVICE DELIVERY MANAGER',
  'SENIOR SERVICE ENGINEER',
  'SENIOR SERVICE EXECUTIVE',
  'SENIOR SERVICE MANAGER',
  'SENIOR SERVICE SUPPORT COORDINATOR',
  'SENIOR SERVICE TEAM MANAGER',
  'SENIOR SERVICE TECHNICIAN',
  'SENIOR SETTLEMENTS OFFICER',
  'SENIOR SHEETMETAL TECHNICIAN',
  'SENIOR SHIFT MANAGER',
  'SENIOR SHIPPING ADMINISTRATOR',
  'SENIOR SHIPPING COORDINATOR',
  'SENIOR SHIPPING EXECUTIVE',
  'SENIOR SHIPPING MANAGER',
  'SENIOR SHIPWRIGHT ENGINEER',
  'SENIOR SHIPYARD PLANNER',
  'SENIOR SHOOTING COACH',
  'SENIOR SHOP ASSISTANT',
  'SENIOR SHOP MANAGER',
  'SENIOR SHOP SALES EXECUTIVE',
  'SENIOR SIGNAL ENGINEER',
  'SENIOR SIGNCRAFT DESIGNER',
  'SENIOR SITE CHECKER',
  'SENIOR SITE COORDINATOR',
  'SENIOR SITE ENGINEER',
  'SENIOR SITE MANAGER',
  'SENIOR SOFTWARE ANALYST',
  'Senior Software Architect',
  'Senior Software Consultant',
  'SENIOR SOFTWARE DESIGN ENGINEER',
  'Senior Software Developer',
  'SENIOR SOFTWARE DEVELOPMENT ENGINEER',
  'SENIOR SOFTWARE DEVELOPMENT MANAGER',
  'SENIOR SOFTWARE ENGINEER',
  'SENIOR SOFTWARE MANAGER',
  'SENIOR SOFTWARE PROGRAMMER',
  'SENIOR SOFTWARE QUALITY ANALYST',
  'Senior Software Specialist',
  'Senior Software Support Executive',
  'Senior Software Support Specialist',
  'Senior Software Technical Consultant',
  'SENIOR SOFTWARE TECHNICIAN',
  'SENIOR SOFTWARE TESTING ANALYST',
  'SENIOR SOFTWARE TESTING ENGINEER',
  'SENIOR SOLAR ENGINEER',
  'SENIOR SOLUTIONS ANALYST',
  'SENIOR SOLUTIONS ARCHITECT',
  'SENIOR SOLUTIONS CONSULTANT',
  'Senior Solutions Developer',
  'Senior Solutions Engineer',
  'SENIOR SOLUTIONS MANAGER',
  'SENIOR SOLUTIONS QUALITY ENGINEER',
  'SENIOR SONOGRAPHER',
  'SENIOR SOUND AND LIGHTING TECHNICIAN',
  'SENIOR SOUND DESIGNER',
  'SENIOR SOURCING ENGINEER',
  'SENIOR SOURCING EXECUTIVE',
  'SENIOR SOURCING MANAGER',
  'SENIOR SOUS CHEF',
  'Senior Spa Director',
  'Senior Spa Manager',
  'SENIOR SPECIALIST',
  'SENIOR SPEECH PATHOLOGIST',
  'SENIOR SPEECH THERAPIST',
  'SENIOR SPONSORSHIP SALES MANAGER',
  'SENIOR STAFF',
  'SENIOR STAFF NURSE',
  'SENIOR STAFF SOFTWARE ENGINEER',
  'SENIOR STAFF WRITER',
  'SENIOR STAGE DESIGNER',
  'SENIOR STAGE MANAGER',
  'SENIOR STAGE SET DESIGNER',
  'SENIOR STATISTICAL ANALYST',
  'SENIOR STEEL ENGINEER',
  'SENIOR STEEL STRUCTURE DESIGNER',
  'SENIOR STORAGE ADMINISTRATOR',
  'SENIOR STORAGE ENGINEER',
  'SENIOR STORE DESIGN MANAGER',
  'SENIOR STORE DESIGNER',
  'SENIOR STORE MANAGER',
  'SENIOR STRATEGIC PLANNING DIRECTOR',
  'SENIOR STRATEGIC PLANNING MANAGER',
  'SENIOR STRATEGIC SOURCING MANAGER',
  'SENIOR STRATEGY ANALYST',
  'SENIOR STRATEGY AND PLANNING MANAGER',
  'SENIOR STRATEGY CONSULTANT',
  'SENIOR STRATEGY DIRECTOR',
  'SENIOR STRUCTURAL DESIGN COORDINATOR',
  'SENIOR STRUCTURAL DESIGN ENGINEER',
  'SENIOR STRUCTURAL DESIGNER',
  'SENIOR STRUCTURAL DETAILER',
  'SENIOR STRUCTURAL DRAUGHTSMAN',
  'SENIOR STRUCTURAL ENGINEER (BUILDINGS)',
  'SENIOR STRUCTURAL ENGINEER (GENERAL)',
  'SENIOR STRUCTURAL INSPECTOR',
  'SENIOR STRUCTURAL MANAGER',
  'SENIOR STRUCTURAL STEEL DETAILER',
  'SENIOR STRUCTURAL STEEL ENGINEER',
  'SENIOR STRUCTURAL SUPERVISOR',
  'SENIOR STRUCTURAL TECHNICIAN',
  'SENIOR STRUCTURER',
  'SENIOR STUDIO DIRECTOR',
  'SENIOR SUBCONTRACT ENGINEER',
  'SENIOR SUB-EDITOR',
  'SENIOR SUPERVISOR',
  'SENIOR SUPERVISOR, TELECOMMUNICATIONS SERVICE',
  'SENIOR SUPPLIER QUALITY ENGINEER',
  'SENIOR SUPPLY CHAIN ANALYST',
  'SENIOR SUPPLY CHAIN CONSULTANT',
  'SENIOR SUPPLY CHAIN DIRECTOR',
  'SENIOR SUPPLY CHAIN ENGINEER',
  'SENIOR SUPPLY CHAIN EXECUTIVE',
  'SENIOR SUPPLY CHAIN MANAGER',
  'SENIOR SUPPLY CHAIN PLANNER',
  'SENIOR SUPPLY MANAGEMENT MANAGER',
  'SENIOR SUPPLY MANAGER',
  'SENIOR SUPPORT ENGINEER',
  'SENIOR SUPPORT EXECUTIVE',
  'SENIOR SUPPORT MANAGER',
  'SENIOR SUPPORT SPECIALIST, INFORMATION TECHNOLOGY',
  'SENIOR SURGEON, VETERINARY',
  'SENIOR SURVEYOR (GENERAL)',
  'SENIOR SURVEYOR, HYDROGRAPHIC',
  'SENIOR SURVEYOR, LAND',
  'SENIOR SURVEYOR, MARINE',
  'SENIOR SURVEYOR, MARINE (SHIP AND NAUTICAL)',
  'SENIOR SURVEYOR, QUANTITY',
  'SENIOR SURVEYOR, TOPOGRAPHICAL',
  'SENIOR SYSTEM ADMINISTRATOR',
  'SENIOR SYSTEM ANALYST',
  'SENIOR SYSTEM SUPPORT ASSOCIATE',
  'SENIOR SYSTEM SUPPORT SPECIALIST',
  'SENIOR SYSTEM SUPPORT SUPERVISOR',
  'SENIOR SYSTEMS ADMINISTRATOR',
  'SENIOR SYSTEMS ANALYST',
  'Senior Systems Architect',
  'Senior Systems Consultant',
  'SENIOR SYSTEMS DESIGN ENGINEER',
  'SENIOR SYSTEMS DESIGNER',
  'SENIOR SYSTEMS DESIGNER (COMPUTER)',
  'Senior Systems Developer',
  'SENIOR SYSTEMS ENGINEER',
  'SENIOR SYSTEMS ENGINEER (COMPUTER)',
  'Senior Systems Integrator',
  'SENIOR SYSTEMS MANAGER',
  'SENIOR SYSTEMS PROGRAMMER',
  'Senior Systems Project Consultant',
  'SENIOR SYSTEMS PROJECT MANAGER',
  'Senior Systems Specialist',
  'Senior Systems Technologist',
  'SENIOR TALENT MANAGEMENT MANAGER',
  'SENIOR TALENT MANAGER',
  'SENIOR TANKER FLEET MANAGER',
  'SENIOR TANKER OPERATOR',
  'SENIOR TAX ADVISOR',
  'SENIOR TAX ASSOCIATE',
  'SENIOR TAX CONSULTANT',
  'SENIOR TAX DIRECTOR',
  'SENIOR TAX EXECUTIVE',
  'SENIOR TAX MANAGER',
  'SENIOR TEACHER OF THE MENTALLY HANDICAPPED',
  'SENIOR TEACHER, COMMERCIAL SCHOOL',
  'SENIOR TEACHER, COMPUTER (COMPUTER TRAINING SCHOOL)',
  'SENIOR TEACHER, EXTRACURRICULAR SUBJECTS',
  'SENIOR TEACHER, KINDERGARTEN',
  'SENIOR TEACHER, LANGUAGE (LANGUAGE SCHOOL)',
  'SENIOR TEACHER, LANGUAGE SCHOOL',
  'SENIOR TEACHER, MENTALLY HANDICAPPED',
  'SENIOR TEACHER, NURSERY SCHOOL',
  'SENIOR TEACHER, PRE-PRIMARY SCHOOL',
  'SENIOR TEACHER, PRE-UNIVERSITY',
  'SENIOR TEACHER, PRIMARY SCHOOL',
  'SENIOR TEACHER, SECONDARY SCHOOL',
  'SENIOR TEAM MANAGER',
  'SENIOR TECHNICAL ADVISER',
  'SENIOR TECHNICAL ADVISOR',
  'SENIOR TECHNICAL ANALYST',
  'Senior Technical Architect',
  'SENIOR TECHNICAL ASSISTANT',
  'SENIOR TECHNICAL CONSULTANT',
  'SENIOR TECHNICAL CONTROLLER',
  'SENIOR TECHNICAL COORDINATOR',
  'SENIOR TECHNICAL DESIGNER',
  'SENIOR TECHNICAL DEVELOPER',
  'SENIOR TECHNICAL DIRECTOR',
  'SENIOR TECHNICAL ENGINEER',
  'SENIOR TECHNICAL EQUIPMENT SALES REPRESENTATIVE',
  'SENIOR TECHNICAL EXECUTIVE',
  'SENIOR TECHNICAL HELPDESK SPECIALIST',
  'SENIOR TECHNICAL INNOVATION MANAGER',
  'SENIOR TECHNICAL INSPECTOR',
  'SENIOR TECHNICAL INSTALLATION ADVISOR',
  'SENIOR TECHNICAL INSTRUCTOR',
  'SENIOR TECHNICAL LEADER',
  'SENIOR TECHNICAL MANAGER',
  'SENIOR TECHNICAL OFFICER',
  'SENIOR TECHNICAL PROGRAMMER',
  'SENIOR TECHNICAL PROJECT MANAGER',
  'SENIOR TECHNICAL REPRESENTATIVE',
  'SENIOR TECHNICAL SALES CO-ORDINATOR',
  'SENIOR TECHNICAL SALES ENGINEER',
  'SENIOR TECHNICAL SALES EXECUTIVE',
  'SENIOR TECHNICAL SALES MANAGER',
  'SENIOR TECHNICAL SERVICE ADVISER',
  'SENIOR TECHNICAL SERVICE ENGINEER',
  'SENIOR TECHNICAL SERVICE REPRESENTATIVE',
  'SENIOR TECHNICAL SERVICES MANAGER',
  'SENIOR TECHNICAL SPECIALIST',
  'SENIOR TECHNICAL SUPPORT COORDINATOR',
  'SENIOR TECHNICAL SUPPORT ENGINEER',
  'SENIOR TECHNICAL SUPPORT EXECUTIVE',
  'SENIOR TECHNICAL SUPPORT MANAGER',
  'SENIOR TECHNICAL SUPPORT REPRESENTATIVE',
  'SENIOR TECHNICAL SUPPORT SPECIALIST',
  'SENIOR TECHNICAL WRITER',
  'SENIOR TECHNICIAN',
  'Senior Technician (Aircraft Engine / Component Maintenance - Avionics)',
  'Senior Technician (Aircraft Engine / Component Maintenance - Mechanical)',
  'Senior Technician (Aircraft Engine/Engine Component Repair And Overhaul)',
  'Senior Technician (Avionics) (Aircraft Maintenance)',
  'Senior Technician (Mechanical) (Aircraft Maintenance)',
  'SENIOR TECHNICIAN, AERONAUTICAL ENGINEERING',
  'SENIOR TECHNICIAN, AIR-CONDITIONING ENGINEERING',
  'SENIOR TECHNICIAN, AUDIO AND VIDEO EQUIPMENT',
  'SENIOR TECHNICIAN, AUTOMATION',
  'SENIOR TECHNICIAN, AUTOMOTIVE ENGINEERING',
  'SENIOR TECHNICIAN, BUILDING',
  'SENIOR TECHNICIAN, CHEMICAL ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, CIVIL ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, COMPUTER',
  'SENIOR TECHNICIAN, COMPUTER SYSTEMS',
  'SENIOR TECHNICIAN, ELECTRICAL ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, ELECTRICAL ENGINEERING (HIGH VOLTAGE)',
  'SENIOR TECHNICIAN, ELECTRONIC PRE-PRESS',
  'SENIOR TECHNICIAN, ELECTRONICS ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, INSTRUMENTATION',
  'SENIOR TECHNICIAN, LAND SURVEYING',
  'SENIOR TECHNICIAN, MANUFACTURING ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, MARINE ENGINEERING',
  'SENIOR TECHNICIAN, MECHANICAL ENGINEERING (GENERAL)',
  'SENIOR TECHNICIAN, MECHANICAL ENGINEERING (MOTORS AND ENGINES)',
  'SENIOR TECHNICIAN, POWER DISTRIBUTION (ELECTRICITY)',
  'SENIOR TECHNICIAN, POWER GENERATION AND TRANSMISSION',
  'SENIOR TECHNICIAN, PRODUCTION ENGINEERING',
  'SENIOR TECHNICIAN, QUALITY ASSURANCE',
  'SENIOR TECHNICIAN, RADIO AND TELEVISION',
  'SENIOR TECHNICIAN, REFRIGERATION ENGINEERING',
  'SENIOR TECHNICIAN, SEMI-CONDUCTOR',
  'SENIOR TECHNICIAN, TELECOMMUNICATIONS',
  'SENIOR TECHNICIAN, VETERINARY',
  'SENIOR TECHNOLOGIST, FOOD AND DRINK',
  'Senior Technology Analyst',
  'Senior Technology Architect',
  'Senior Technology Consultant',
  'SENIOR TECHNOLOGY DIRECTOR',
  'SENIOR TECHNOLOGY ENGINEER',
  'Senior Technology Manager',
  'Senior Technology Project Manager',
  'SENIOR TECHNOLOGY RESEARCH MANAGER',
  'SENIOR TECHNOLOGY SPECIALIST',
  'SENIOR TELECOMMUNICATIONS ENGINEER',
  'SENIOR TELECOMMUNICATIONS TECHNICIAN',
  'SENIOR TELESALES EXECUTIVE',
  'SENIOR TELEVISION PRODUCER',
  'SENIOR TENDER CONTROL MANAGER',
  'SENIOR TERRITORY MANAGER',
  'SENIOR TEST ANALYSIS ENGINEER',
  'SENIOR TEST ANALYST',
  'SENIOR TEST BACKEND ASSOCIATE ENGINEER',
  'SENIOR TEST DEVELOPMENT ENGINEER',
  'SENIOR TEST ENGINEER',
  'SENIOR TEST PRODUCT ENGINEER',
  'SENIOR TEST SPECIALIST',
  'SENIOR TICKETING MANAGER',
  'SENIOR TOOL DESIGNER',
  'SENIOR TOOL/MOULD DESIGNER',
  'SENIOR TOOLING ENGINEER',
  'SENIOR TOOLING TECHNICIAN',
  'SENIOR TOUR MANAGER',
  'SENIOR TRADE EXECUTIVE',
  'SENIOR TRADE MANAGER',
  'SENIOR TRADEMARK EXECUTIVE',
  'SENIOR TRADING ANALYST',
  'SENIOR TRADING DIRECTOR',
  'SENIOR TRADING EXECUTIVE',
  'SENIOR TRADING MANAGER',
  'SENIOR TRAFFIC ENGINEER',
  'SENIOR TRAFFIC PLANNER',
  'SENIOR TRAINING & DEVELOPMENT MANAGER',
  'SENIOR TRAINING AND EDUCATION MANAGER',
  'SENIOR TRAINING EXECUTIVE',
  'SENIOR TRAINING MANAGER',
  'SENIOR TRAINING OFFICER',
  'SENIOR TRANSFER PRICING MANAGER',
  'SENIOR TRANSFORMATION MANAGER',
  'SENIOR TRANSFORMER ENGINEER',
  'SENIOR TRANSITION MANAGER',
  'SENIOR TRANSPORT COORDINATOR',
  'SENIOR TRANSPORT MANAGER',
  'SENIOR TRANSPORT OPERATIONS MANAGER',
  'SENIOR TRANSPORTATION MANAGER',
  'SENIOR TRAVEL SERVICES MANAGER',
  'SENIOR TREASURY ANALYST',
  'SENIOR TREASURY MANAGER',
  'SENIOR TRIBON ENGINEER',
  'SENIOR TRUST OFFICER',
  'SENIOR TRUST RELATIONSHIP MANAGER',
  'SENIOR TUNNEL CONSTRUCTION ENGINEER',
  'SENIOR TUNNEL ENGINEER',
  'SENIOR TUNNEL SUPERVISOR',
  'SENIOR TUNNEL SURVEYOR',
  'SENIOR UNDERWRITING ADMINISTRATOR',
  'SENIOR UNDERWRITING MANAGER',
  'SENIOR UNIX ENGINEER',
  'SENIOR URBAN PLANNER',
  'Senior User Interface Architect',
  'SENIOR VALIDATION ENGINEER',
  'SENIOR VALUER',
  'SENIOR VEHICLE TECHNICIAN',
  'SENIOR VESSEL MANAGER',
  'SENIOR VESSEL OPERATIONS MANAGER',
  'SENIOR VESSEL OPERATOR',
  'SENIOR VETERINARIAN',
  'SENIOR VETERINARY SURGEON',
  'SENIOR VETERINARY TECHNICIAN',
  'SENIOR VICE PRESIDENT',
  'SENIOR VICE PRESIDENT (MEDIA INDUSTRY)',
  'SENIOR VICE PRESIDENT, INSTITUTIONAL SALES',
  'SENIOR VIDEO EDITOR',
  'SENIOR VIDEO EQUIPMENT ENGINEER',
  'SENIOR VIDEO PRODUCTION SPECIALIST',
  'SENIOR VISITING FELLOW',
  'SENIOR VISITING PROFESSOR',
  'SENIOR VISUAL EFFECTS ARTIST',
  'SENIOR VISUAL ILLUSTRATOR',
  'SENIOR VISUAL MERCHANDISER',
  'SENIOR VISUAL MERCHANDISING EXECUTIVE',
  'SENIOR VISUAL MERCHANDISING MANAGER',
  'SENIOR VISUALISER',
  'SENIOR VOCAL GROUP CONDUCTOR',
  'SENIOR WAREHOUSE EXECUTIVE',
  'SENIOR WAREHOUSE MANAGER',
  'SENIOR WATCH TECHNICIAN',
  'SENIOR WEB ADMINISTRATOR',
  'SENIOR WEB ANALYST',
  'SENIOR WEB APPLICATION DEVELOPER',
  'SENIOR WEB CONSULTANT',
  'SENIOR WEB DESIGNER',
  'SENIOR WEB DEVELOPER',
  'SENIOR WEB ENGINEER',
  'SENIOR WEB EXECUTIVE',
  'SENIOR WEB MARKETING EXECUTIVE',
  'SENIOR WEB PROGRAMMER',
  'SENIOR WEB SUPPORT ENGINEER',
  'SENIOR WEBSITE DESIGNER',
  'SENIOR WEBSITE DEVELOPER',
  'SENIOR WELDER',
  'SENIOR WELDING ENGINEER',
  'SENIOR WELDING INSPECTOR',
  'SENIOR WELDING MANAGER',
  'SENIOR WELDING TECHNICIAN',
  'SENIOR WIRELESS ENGINEER',
  'SENIOR WOODCRAFT TECHNICIAN',
  'SENIOR WORKFLOW ANALYST',
  'Senior Workplace Safety And Health Officer (Marine And Offshore)',
  'SENIOR WORKSHOP ENGINEER',
  'SENIOR WORKSHOP MANAGER',
  'SENIOR WORKSHOP SUPERVISOR',
  'SENIOR WORKSHOP TECHNICIAN',
  'SENIOR WRITER, SCRIPT',
  'Server (Food Services)',
  'SERVER SUPPORT ANALYST',
  'SERVICE ADVISER (CUSTOMER AFTER-SALES SERVICE)',
  'SERVICE CENTRE SUPERVISOR',
  'SERVICE COORDINATOR',
  'SERVICE CREW',
  'SERVICE DELIVERY MANAGER',
  'SERVICE ENGINEER',
  'SERVICE ENGINEER (ELECTRICAL)',
  'SERVICE EXECUTIVE',
  'SERVICE MANAGER',
  'SERVICE QUALITY ANALYST',
  'Service Supervisor (Food Services)',
  'SERVICE SUPPORT COORDINATOR',
  'SERVICE TEAM MANAGER',
  'SERVICE TECHNICIAN',
  'SET DESIGNER',
  'SETTLEMENTS OFFICER',
  'Sewerage / Sanitary Engineer',
  'SEWERAGE ENGINEER',
  'Sewing Machine Operator',
  'SEWING MACHINE OPERATOR (GENERAL)',
  'SHEET METAL WORKER',
  'SHEET METAL WORKER (GENERAL)',
  'Sheet Metal Worker, Aircraft',
  'SHEETMETAL TECHNICIAN',
  'SHIFT LEADER',
  'SHIFT MANAGER',
  'Ship Agent',
  'SHIP BROKER',
  'SHIP BUILDING AND REPAIRING SUPERVISOR',
  'SHIP CHARTERER',
  'SHIP CONSTRUCTION ENGINEER',
  'SHIP CREWING EXECUTIVE',
  'SHIP ELECTRICIAN',
  'Ship Operator',
  'SHIP PLATER',
  'SHIP PLUMBER',
  'SHIP RIGGER',
  'SHIP-BUILDING AND REPAIRING SUPERVISOR AND GENERAL FOREMAN',
  'SHIP-MASTER',
  'SHIPPING ADMINISTRATOR',
  'SHIPPING AGENT',
  'SHIPPING ASSISTANT',
  'SHIPPING BROKER',
  'SHIPPING CLERK',
  'Shipping Clerk (Documentation)',
  'SHIPPING CLERK (FREIGHT)',
  'SHIPPING COORDINATOR',
  'SHIPPING EXECUTIVE',
  'SHIPPING MANAGER',
  'SHIPPING OFFICER',
  'SHIPPING OPERATIONS MANAGER',
  'SHIPPING SERVICES MANAGER',
  'Shipping Specialist',
  "SHIP'S COOK",
  "SHIP'S ENGINEER",
  "SHIP'S NAVIGATING OFFICER",
  "SHIP'S STEWARD",
  'SHIPWRIGHT ENGINEER',
  'SHIPYARD FOREMAN',
  'SHIPYARD MANAGER',
  'SHIPYARD OPERATIONS MANAGER',
  'SHIPYARD PLANNER',
  'SHOE SEWER',
  'SHOOTING COACH',
  'SHOP ASSISTANT',
  'SHOP MANAGER',
  'Shop Sales Assistant',
  'SHOP SALES EXECUTIVE',
  'SHOP SALES MANAGER',
  'SHOPKEEPER',
  'SHOT FIRER (MINE AND QUARRY)',
  'SHOWROOM SUPERVISOR (RETAIL TRADE)',
  'SHOWROOM SUPERVISOR (WHOLESALE TRADE)',
  'SHUNTER, TRAIN',
  'SHUTDOWN PLANNER',
  'SIGNAL ENGINEER',
  'SIGNAL PROCESSING ENGINEER',
  'SIGNCRAFT DESIGNER',
  'Silk Screen / Block / Textile Printer',
  'SILK SCREEN PRINTER',
  'SIMULATOR INSTRUCTOR',
  'SINGER',
  'SINGER, POPULAR MUSIC',
  'SINGING TEACHER (OTHER THAN SECONDARY SCHOOL)',
  'SITE AGENT',
  'SITE CHECKER',
  'SITE COORDINATOR',
  'SITE DIRECTOR',
  'SITE ENGINEER',
  'SITE MANAGER',
  'SITE SAFETY SUPERVISOR',
  'SITE START-UP LEAD',
  'SITE START-UP SPECIALIST',
  'SITE SUPERINTENDENT',
  'SITE SUPERVISOR',
  'SITE SUPPORT ASSOCIATE',
  'SKIN CARE SPECIALIST',
  'SKINCARE &/OR COSMETICS ADVISER',
  'SKIPPER, YACHT',
  'SLIMMING CONSULTANT',
  'SLITTING OPERATOR',
  'SOCCER PLAYER',
  'SOCIAL MEDIA MARKETING EXECUTIVE',
  'SOCIAL MEDIA MARKETING MANAGER',
  'SOCIAL WELFARE CASEWORKER',
  'SOCIAL WELFARE MANAGER',
  'SOCIAL WELFARE WORKER',
  'SOCIAL WORK ASSISTANT',
  'SOCIAL WORKER (GENERAL)',
  'SOCIAL WORKER, COMMUNITY',
  'SOCIAL WORKER, MEDICAL',
  'SOFTWARE ANALYST',
  'Software And Applications Developer',
  'Software And Applications Developer And Analyst Nec',
  'Software And Applications Manager',
  'Software Architect',
  'Software Consultant',
  'SOFTWARE DESIGN ENGINEER',
  'Software Developer',
  'SOFTWARE DEVELOPMENT ENGINEER',
  'SOFTWARE DEVELOPMENT MANAGER',
  'SOFTWARE ENGINEER',
  'SOFTWARE LOCALIZATION TESTER',
  'SOFTWARE MANAGER',
  'SOFTWARE PROGRAMMER',
  'SOFTWARE QUALITY ANALYST',
  'Software Specialist',
  'Software Support Executive',
  'Software Support Specialist',
  'Software Technical Consultant',
  'SOFTWARE TECHNICIAN',
  'SOFTWARE TESTING ANALYST',
  'SOFTWARE TESTING ENGINEER',
  'SOIL ENGINEER',
  'SOIL TECHNICIAN',
  'SOLAR ENGINEER',
  'SOLDERER',
  'Solution Architect',
  'SOLUTIONS ANALYST',
  'SOLUTIONS ARCHITECT',
  'SOLUTIONS CONSULTANT',
  'Solutions Developer',
  'Solutions Engineer',
  'Solutions Integrator',
  'SOLUTIONS MANAGER',
  'SOLUTIONS QUALITY ENGINEER',
  'SOMMELIER',
  'SONOGRAPHER',
  'SOUND DESIGNER',
  'SOUND ENGINEER',
  'SOUND RECORDING EQUIPMENT OPERATOR',
  'SOUND TECHNICIAN',
  'SOURCING ENGINEER',
  'SOURCING EXECUTIVE',
  'SOURCING MANAGER',
  'SOUS CHEF',
  'Spa Director',
  'Spa Manager',
  'Spa Therapist',
  'Spa Therapist Supervisor',
  'SPARRING PARTNER (TABLE TENNIS)',
  'SPEAKER (RELIGIOUS)',
  'SPECIAL EDUCATION TEACHER',
  'SPECIALIST',
  'SPECIALIST ASSOCIATE',
  'SPECIALIST VALUER',
  'Speech And Drama Instructor (Extracurriculum)',
  'SPEECH AND DRAMA TEACHER',
  'SPEECH AND LANGUAGE PATHOLOGIST',
  'SPEECH AND LANGUAGE THERAPIST',
  'SPEECH LANGUAGE THERAPIST',
  'SPEECH THERAPIST',
  'SPICE MILLING MACHINE OPERATOR',
  'SPINNING FRAME OPERATOR (THREAD AND YARN)',
  'SPONSORSHIP SALES EXECUTIVE',
  'SPONSORSHIP SALES MANAGER',
  'SPORTS CENTRE MANAGER',
  'Sports Coach',
  'SPORTS COORDINATOR',
  'SPORTS DEVELOPMENT OFFICER',
  'SPORTS INSTRUCTOR',
  'SPORTS MARKETING CONSULTANT',
  'SPORTS THERAPIST',
  'SPRAY PAINTER',
  'SPRAY PAINTER (EXCEPT CONSTRUCTION AND MOTOR VEHICLES)',
  'Spray Painter (Except Ships, Motor Vehicles And Signs)',
  'SPRAY PAINTER (MOTOR VEHICLE ASSEMBLY)',
  'SPRAY PAINTER (MOTOR VEHICLE)',
  'SPRAYER, METAL',
  'SPRING MAKER',
  'SQUASH COACH',
  'STABLE HAND',
  'STABLE SUPERVISOR',
  'STAFF',
  'Staff Engineer',
  'STAFF NURSE',
  'STAFF RECORDS CLERK',
  'STAFF SOFTWARE ENGINEER',
  'STAFF TRAINING ORGANISER',
  'STAFF WRITER',
  'STAGE DIRECTOR',
  'STAGE MANAGER',
  'STAGE PRODUCER',
  'STAGE TECHNICAL CREW',
  'STALL ASSISTANT (COFFEE SHOP)',
  'STALL ASSISTANT (OTHER THAN PREPARED FOOD OR DRINKS)',
  'STALL HOLDER (OTHER THAN PREPARED FOOD OR DRINKS)',
  'STALL HOLDER (PREPARED FOOD OR DRINKS)',
  'STALL SUPERVISOR',
  'STARTER (SPORTS)',
  'Station Chef',
  'STATION MANAGER',
  'STATIONARY MACHINE SUPERVISOR AND GENERAL FOREMAN',
  'Stationary Plant And Machine Supervisor / General Foreman',
  'STATISTICIAN',
  'STEAM ENGINEER',
  'STEEL DETAILER',
  'STEEL ENGINEER',
  'STEEL ERECTOR, CONSTRUCTIONAL',
  'STEEL REINFORCEMENT WORKER',
  'STEEL WORKER, STRUCTURAL (WORKSHOP)',
  'STEEPLEJACK',
  'STENOGRAPHER',
  'STENOGRAPHIC SECRETARY',
  'STEVEDORE',
  'STEWARD, AIRCRAFT',
  'STEWARD, CHIEF (SHIP)',
  'STEWARD, HOTEL',
  'STIPENDIARY STEWARD',
  'STOCK CONTROLLER',
  'STOCK RECORDS CLERK',
  'STOCK SUPERVISOR',
  'STOCKBROKER',
  'STONE CUTTER AND FINISHER',
  'STONE PANEL INSTALLER',
  'STONE SETTER',
  'Stonemason',
  'STORAGE ADMINISTRATOR',
  'STORAGE ENGINEER',
  'STORE ASSISTANT',
  'Store Assistant (Manufacturing)',
  'STORE CASHIER',
  'STORE CONTROLLER',
  'STORE DESIGN MANAGER',
  'STORE DESIGNER',
  'STORE MANAGER',
  'Store Manager (Retail)',
  'STORE SUPERVISOR',
  'STOREKEEPER',
  'STOREMAN',
  'STORES ASSISTANT',
  'STORES CLERK',
  'STORES SUPERVISOR',
  'STRATEGIC MANAGEMENT DIRECTOR',
  'STRATEGIC PLANNER',
  'STRATEGIC PLANNING DIRECTOR',
  'STRATEGIC PLANNING MANAGER',
  'STRATEGIC PROJECT MANAGER',
  'STRATEGIC RESEARCH PROGRAM MANAGER',
  'STRATEGIC SOURCING MANAGER',
  'STRATEGY ANALYST',
  'STRATEGY AND PLANNING MANAGER',
  'STRATEGY CONSULTANT',
  'STRATEGY DIRECTOR',
  'STREET SWEEPER AND CLEANER (EXCEPT DRIVER, MECHANICAL SWEEPER)',
  'STRENGTH AND CONDITIONING COACH',
  'STRUCTURAL DESIGN COORDINATOR',
  'STRUCTURAL DESIGN ENGINEER',
  'STRUCTURAL DESIGNER',
  'STRUCTURAL DETAILER',
  'STRUCTURAL DRAUGHTSMAN',
  'STRUCTURAL ENGINEER (BUILDINGS)',
  'STRUCTURAL ENGINEER (GENERAL)',
  'STRUCTURAL ENGINEER, ASSISTANT',
  'STRUCTURAL ENGINEERING ASSISTANT (GENERAL)',
  'STRUCTURAL ENGINEERING DRAUGHTSMAN',
  'Structural Engineering Technician',
  'STRUCTURAL ENGINEERING TECHNICIAN (GENERAL)',
  'STRUCTURAL INSPECTOR',
  'STRUCTURAL MANAGER',
  'STRUCTURAL METAL MARKER',
  'STRUCTURAL STEEL AND SHIP PAINTER',
  'STRUCTURAL STEEL DETAILER',
  'STRUCTURAL STEEL ENGINEER',
  'STRUCTURAL STEEL ERECTOR',
  'STRUCTURAL STEEL WORKER (WORKSHOP)',
  'Structural Steel Worker (Workshop) / Fabricator',
  'STRUCTURAL SUPERVISOR',
  'STRUCTURAL TECHNICIAN',
  'STRUCTURER',
  'STUDENT TEACHER',
  'STUDENT VOCATIONAL COUNSELLOR',
  'STUDIO COORDINATOR',
  'STUDIO DESIGNER',
  'STUDIO DIRECTOR',
  'STUDIO MANAGER',
  'SUBCONTRACT ENGINEER',
  'SUBCONTRACTS ASSISTANT',
  'SUB-EDITOR',
  'SUPERINTENDENT',
  'SUPERINTENDENT ENGINEER',
  'SUPERINTENDENT, MARINE (DECK)',
  'SUPERINTENDENT, MARINE (ENGINEER)',
  'SUPERINTENDENT, MARINE (TECHNICAL)',
  'SUPERINTENDENT, TERMINAL (WATER TRANSPORT)',
  'SUPERMARKET MANAGER',
  'SUPERVISOR',
  'Supervisor (Marine And Offshore)',
  'SUPERVISOR (MIXED FARMING)',
  'Supervisor / General Foreman (Building And Related Trades)',
  'Supervisor / General Foreman (Electrical And Electronic Trades)',
  'Supervisor / General Foreman (Food Processing, Woodworking, Garment, Leather And Related Trades)',
  'Supervisor / General Foreman (Metal, Machinery And Related Trades)',
  'Supervisor / General Foreman (Precision, Handicraft, Printing And Related Trades)',
  'SUPERVISOR AND GENERAL FOREMAN',
  'SUPERVISOR AND GENERAL FOREMAN (ASSEMBLERS AND QUALITY CHECKERS)',
  'SUPERVISOR AND GENERAL FOREMAN (BEVERAGE MAKING)',
  'SUPERVISOR AND GENERAL FOREMAN (BUILDING TRADES)',
  'SUPERVISOR AND GENERAL FOREMAN (BUILDING)',
  'SUPERVISOR AND GENERAL FOREMAN (CHEMICAL PROCESSING)',
  'SUPERVISOR AND GENERAL FOREMAN (CHEMICAL PRODUCTS MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (CLOTHING MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (CONSTRUCTION)',
  'SUPERVISOR AND GENERAL FOREMAN (ELECTRICAL AND ELECTRONIC INSTALLATION AND REPAIR)',
  'SUPERVISOR AND GENERAL FOREMAN (ELECTRICAL EQUIPMENT MANUFACTURING AND REPAIRING)',
  'SUPERVISOR AND GENERAL FOREMAN (ELECTRICITY GENERATION AND DISTRIBUTION)',
  'SUPERVISOR AND GENERAL FOREMAN (ELECTRONIC EQUIPMENT MANUFACTURING AND REPAIRING)',
  'SUPERVISOR AND GENERAL FOREMAN (FOOD MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (FURNITURE AND RELATED PRODUCTS MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (GLASS PRODUCTS MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (LEATHER GOODS MAKING)',
  'SUPERVISOR AND GENERAL FOREMAN (M&E)',
  'SUPERVISOR AND GENERAL FOREMAN (METAL AND MACHINERY TRADES)',
  'SUPERVISOR AND GENERAL FOREMAN (METAL PROCESSING)',
  'SUPERVISOR AND GENERAL FOREMAN (NON-ELECTRICAL/NON-ELECTRONIC MACHINERY MANUFACTURING AND REPAIR)',
  'SUPERVISOR AND GENERAL FOREMAN (PETROLEUM REFINING)',
  'SUPERVISOR AND GENERAL FOREMAN (PLASTIC PRODUCTS MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (PLUMBING INSTALLATION AND REPAIR)',
  'SUPERVISOR AND GENERAL FOREMAN (PRINTING)',
  'SUPERVISOR AND GENERAL FOREMAN (SHIP BUILDING AND REPAIRING)',
  'SUPERVISOR AND GENERAL FOREMAN (STATIONARY PLANT AND MACHINE)',
  'SUPERVISOR AND GENERAL FOREMAN (TEXTILE FIBRE PROCESSING AND TEXTILE MANUFACTURING)',
  'SUPERVISOR AND GENERAL FOREMAN (WOOD PROCESSING)',
  'SUPERVISOR AND GENERAL FOREMAN (WOOD PRODUCTS MAKING)',
  'SUPERVISOR, ACCOUNTS SECTION',
  'SUPERVISOR, AIR TRANSPORT SERVICE',
  'SUPERVISOR, CLERICAL (GENERAL)',
  'SUPERVISOR, COURIER SERVICE',
  'SUPERVISOR, ELECTRONIC DATA PROCESSING',
  'SUPERVISOR, FLOOR (RETAIL TRADE)',
  'SUPERVISOR, GENERAL OFFICE',
  'SUPERVISOR, HOUSEKEEPING (EXCEPT PRIVATE SERVICE)',
  'SUPERVISOR, OFFICE (GENERAL)',
  'SUPERVISOR, ROAD TRANSPORT SERVICE',
  'SUPERVISOR, SALES',
  'SUPERVISOR, STORES',
  'SUPERVISOR, TELECOMMUNICATIONS SERVICE',
  'SUPPLIER QUALITY ENGINEER',
  'Supply And Distribution / Logistics / Warehousing Manager',
  'SUPPLY AND DISTRIBUTION MANAGER',
  'SUPPLY CHAIN ANALYST',
  'SUPPLY CHAIN APPLICATIONS MANAGER',
  'SUPPLY CHAIN ASSISTANT',
  'SUPPLY CHAIN BUSINESS PARTNER',
  'SUPPLY CHAIN BUYER',
  'SUPPLY CHAIN CONSULTANT',
  'SUPPLY CHAIN DEVELOPMENT DIRECTOR',
  'SUPPLY CHAIN DIRECTOR',
  'SUPPLY CHAIN ENGAGEMENT MANAGER',
  'SUPPLY CHAIN ENGINEER',
  'SUPPLY CHAIN EXECUTIVE',
  'SUPPLY CHAIN FINANCE MANAGER',
  'SUPPLY CHAIN MANAGER',
  'SUPPLY CHAIN PLANNER',
  'SUPPLY CHAIN PLANNING MANAGER',
  'SUPPLY CHAIN SOURCING MANAGER',
  'SUPPLY CHAIN TECHNOLOGY ASSISTANT',
  'SUPPLY CHAIN TECHNOLOGY ASSISTANT MANAGER',
  'SUPPLY CHAIN TECHNOLOGY DIRECTOR',
  'SUPPLY CHAIN TECHNOLOGY MANAGER',
  'SUPPLY CHAIN VICE PRESIDENT',
  'SUPPLY MANAGEMENT ASSISTANT',
  'SUPPLY MANAGEMENT MANAGER',
  'SUPPLY MANAGER',
  'SUPPORT ENGINEER',
  'SUPPORT EXECUTIVE',
  'SUPPORT MANAGER',
  'SUPPORT SPECIALIST, INFORMATION TECHNOLOGY',
  'SURGEON, ORTHOPAEDIC',
  'SURGEON, SPECIALISED',
  'SURGEON, VETERINARY',
  'SURVEYOR',
  'SURVEYOR (GENERAL)',
  'SURVEYOR, HYDROGRAPHIC',
  'SURVEYOR, LAND',
  'SURVEYOR, MARINE',
  'SURVEYOR, QUANTITY',
  'SURVEYOR, TOPOGRAPHICAL',
  "SURVEYOR'S TECHNICIAN",
  'SUSTAINABILITY MANAGER',
  'SWIMMING COACH',
  'SYSTEM ADMINISTRATOR',
  'SYSTEM ANALYST',
  'SYSTEM DEVELOPER',
  'SYSTEM SUPERVISOR',
  'SYSTEM SUPPORT ASSOCIATE',
  'SYSTEM SUPPORT SPECIALIST',
  'SYSTEM SUPPORT SUPERVISOR',
  'SYSTEMS ADMINISTRATOR',
  'SYSTEMS ANALYST',
  'Systems Architect',
  'Systems Consultant',
  'SYSTEMS DESIGN ENGINEER',
  'SYSTEMS DESIGNER',
  'Systems Developer',
  'SYSTEMS ENGINEER',
  'SYSTEMS ENGINEER (COMPUTER)',
  'Systems Integrator',
  'SYSTEMS MANAGER',
  'SYSTEMS OPERATOR',
  'SYSTEMS PROGRAMMER',
  'Systems Project Consultant',
  'SYSTEMS PROJECT MANAGER',
  'Systems Specialist',
  'Systems Technologist',
  'TABLE TENNIS COACH',
  'TABLE TENNIS PLAYER',
  'TAEKWONDO INSTRUCTOR',
  'TAILOR',
  'TAILOR (MADE-TO-MEASURE GARMENT)',
  'TAILOR (READY-TO-WEAR GARMENT)',
  'Tailor / Dressmaker',
  "TAILOR'S CUTTER",
  'TALENT ACQUISITION SPECIALIST',
  'TALENT DIRECTOR',
  'TALENT MANAGEMENT MANAGER',
  'TALENT MANAGER',
  'TALLY CLERK',
  'TANKAGE DESIGN ENGINEER',
  'TANKER BROKER',
  'TANKER DRIVER',
  'TANKER FLEET MANAGER',
  'TANKER OPERATOR',
  'TATTOO ARTIST',
  'TATTOOIST',
  'TAX ADVISOR',
  'TAX ASSISTANT',
  'Tax Assistant Associate',
  'TAX ASSOCIATE',
  'TAX CONSULTANT',
  'TAX DIRECTOR',
  'TAX EXECUTIVE',
  'TAX MANAGER',
  'TAX OFFICER',
  'TAX SENIOR',
  'TAX SENIOR MANAGER',
  'TEA TASTER',
  'TEACHER (INTERNATIONAL SCHOOL)',
  'TEACHER LIBRARIAN',
  'TEACHER OF THE BLIND',
  'TEACHER OF THE MENTALLY HANDICAPPED',
  'TEACHER, ART (OTHER THAN SECONDARY AND VOCATIONAL SCHOOL)',
  'TEACHER, CALLIGRAPHY',
  'TEACHER, CHILD CARE',
  'TEACHER, COMMERCIAL SCHOOL',
  'TEACHER, DANCING (PRIVATE)',
  'TEACHER, EXTRACURRICULAR SUBJECTS',
  'TEACHER, JUNIOR COLLEGE',
  'TEACHER, KINDERGARTEN',
  'TEACHER, LANGUAGE (LANGUAGE SCHOOL)',
  'TEACHER, LANGUAGE SCHOOL',
  'TEACHER, MENTALLY HANDICAPPED',
  'TEACHER, MUSIC (OTHER THAN SECONDARY SCHOOL)',
  'TEACHER, NURSERY SCHOOL',
  'TEACHER, PRIMARY SCHOOL',
  'TEACHER, RELIEF',
  'TEACHER, SECONDARY SCHOOL',
  'TEACHER, STUDENT',
  'TEACHER, UNIVERSITY',
  'TEACHER, UNTRAINED',
  'TEACHER, VOCATIONAL SCHOOL',
  "Teachers' Aide",
  "TEACHER'S AIDE",
  'TEACHING AID SPECIALIST',
  'TEACHING ASSISTANT',
  'Teaching Assistants (Universities)',
  'TEACHING FELLOW',
  'TEACHING METHODS ADVISER',
  'TEAM MANAGER',
  'Technical / Engineering Services Manager (Eg Shipyard Manager)',
  'Technical / Vocational / Commercial Education Institute Teacher',
  'TECHNICAL ADMINISTRATOR',
  'TECHNICAL ADVISER',
  'TECHNICAL ADVISOR',
  'TECHNICAL ANALYST',
  'TECHNICAL AND RESEARCH DIRECTOR',
  'TECHNICAL AND RESEARCH MANAGER',
  'Technical Architect',
  'TECHNICAL ASSISTANT',
  'TECHNICAL ASSOCIATE',
  'TECHNICAL CONSULTANT',
  'TECHNICAL CONTROLLER',
  'TECHNICAL COORDINATOR',
  'TECHNICAL DESIGNER',
  'TECHNICAL DEVELOPER',
  'TECHNICAL DEVELOPMENT DIRECTOR',
  'TECHNICAL DIRECTOR',
  'TECHNICAL ENGINEER',
  'TECHNICAL EQUIPMENT SALES REPRESENTATIVE',
  'TECHNICAL EXECUTIVE',
  'TECHNICAL HELPDESK SPECIALIST',
  'TECHNICAL INNOVATION MANAGER',
  'TECHNICAL INSPECTOR',
  'TECHNICAL INSTALLATION ADVISOR',
  'TECHNICAL INSTRUCTOR',
  'TECHNICAL LEADER',
  'Technical Manager',
  'Technical Manager (Sea Transport)',
  'TECHNICAL OFFICER',
  'Technical Operations Manager (Fleet Management)',
  'TECHNICAL PROGRAMMER',
  'TECHNICAL PROJECT MANAGER',
  'TECHNICAL PURCHASER',
  'TECHNICAL REPRESENTATIVE',
  'TECHNICAL SALES CO-ORDINATOR',
  'TECHNICAL SALES ENGINEER',
  'TECHNICAL SALES EXECUTIVE',
  'TECHNICAL SALES MANAGER',
  'TECHNICAL SALES REPRESENTATIVE',
  'TECHNICAL SALESMAN',
  'TECHNICAL SERVICE ADVISER',
  'TECHNICAL SERVICE ENGINEER',
  'TECHNICAL SERVICE REPRESENTATIVE',
  'TECHNICAL SERVICES MANAGER',
  'TECHNICAL SPECIALIST',
  'TECHNICAL SUPERINTENDENT',
  'TECHNICAL SUPERVISOR',
  'TECHNICAL SUPPORT ASSOCIATE',
  'TECHNICAL SUPPORT COORDINATOR',
  'TECHNICAL SUPPORT ENGINEER',
  'TECHNICAL SUPPORT EXECUTIVE',
  'TECHNICAL SUPPORT MANAGER',
  'TECHNICAL SUPPORT OFFICER',
  'TECHNICAL SUPPORT REPRESENTATIVE',
  'TECHNICAL SUPPORT SPECIALIST',
  'TECHNICAL WRITER',
  'TECHNICIAN',
  'Technician (Avionics) (Aircraft Maintenance)',
  'Technician (Component Repair & Overhaul - Avionics) (Aircraft Engine / Component Maintenance)',
  'Technician (Component Repair & Overhaul - Mechanical) (Aircraft Engine / Component Maintenance)',
  'Technician (Electronics)',
  'Technician (Engine/Engine Component Repair And Overhaul)(Aircraft Engine / Component Maintenance)',
  'Technician (Marine And Offshore)',
  'Technician (Sea Transport)',
  'TECHNICIAN, AERONAUTICAL ENGINEERING',
  'TECHNICIAN, AIR-CONDITIONING ENGINEERING',
  'TECHNICIAN, AUTOMATION',
  'TECHNICIAN, AUTOMOTIVE ENGINEERING',
  'TECHNICIAN, BROADCASTING OPERATIONS',
  'TECHNICIAN, BUILDING',
  'TECHNICIAN, CHEMICAL ENGINEERING (GENERAL)',
  'TECHNICIAN, CHEMISTRY',
  'TECHNICIAN, CIVIL ENGINEERING (GENERAL)',
  'TECHNICIAN, COMPUTER',
  'TECHNICIAN, COMPUTER SYSTEMS',
  'TECHNICIAN, ELECTRICAL ENGINEERING (GENERAL)',
  'TECHNICIAN, ELECTRONICS ENGINEERING (GENERAL)',
  'TECHNICIAN, INSTRUMENTATION',
  'TECHNICIAN, LABORATORY (CHEMISTRY)',
  'TECHNICIAN, MANUFACTURING ENGINEERING (GENERAL)',
  'TECHNICIAN, MARINE ENGINEERING',
  'TECHNICIAN, MECHANICAL ENGINEERING (GENERAL)',
  'TECHNICIAN, MECHANTRONICS',
  'TECHNICIAN, OIL AND GAS WELL DRILLING',
  'TECHNICIAN, POWER GENERATION AND TRANSMISSION',
  'TECHNICIAN, PRODUCTION ENGINEERING',
  'TECHNICIAN, QUALITY ASSURANCE',
  'TECHNICIAN, SEMI-CONDUCTOR',
  "TECHNICIAN, SURVEYOR'S",
  'TECHNICIAN, TELECOMMUNICATIONS',
  'TECHNICIAN, TELEVISION ENGINEERING',
  'TECHNICIAN, VENTILATION ENGINEERING',
  'TECHNICIAN, VETERINARY',
  'TECHNICIAN, VIDEO EQUIPMENT',
  'TECHNOLOGIST, BREWERY',
  'TECHNOLOGIST, FOOD AND DRINK',
  'Technology Analyst',
  'Technology Architect',
  'Technology Consultant',
  'TECHNOLOGY DIRECTOR',
  'TECHNOLOGY ENGINEER',
  'Technology Manager',
  'Technology Project Manager',
  'TECHNOLOGY RESEARCH MANAGER',
  'TECHNOLOGY SPECIALIST',
  'TELECOMMUNICATIONS AND TELEPHONE MECHANIC',
  'TELECOMMUNICATIONS ENGINEER',
  'Telecommunications Installer / Servicer',
  'TELECOMMUNICATIONS MANAGER',
  'TELECOMMUNICATIONS TECHNICIAN',
  'TELEMARKETER',
  'TELESALES CONSULTANT',
  'TELESALES EXECUTIVE',
  'Television / Motion Picture Camera Operator',
  'TELEVISION CAMERA OPERATOR',
  'TELLER, BANK',
  'TENDER CONTROL MANAGER',
  'TENDERING MANAGER',
  'TENNIS COACH',
  'TENNIS PLAYER',
  'TERRITORY MANAGER',
  'TEST ANALYSIS ENGINEER',
  'TEST ANALYST',
  'TEST BACKEND ASSOCIATE ENGINEER',
  'TEST DEVELOPMENT ENGINEER',
  'TEST ENGINEER',
  'TEST PROCESS ENGINEER',
  'TEST PRODUCT ENGINEER',
  'TEST SPECIALIST',
  'TESTER (ELECTRICAL EQUIPMENT)',
  'TEXTILE DESIGNER',
  'TEXTILE ENGINEER',
  'THEATRE EXECUTIVE',
  'THEOLOGIAN',
  'THERAPIST AIDE',
  'THERAPIST ASSISTANT',
  'THERAPIST ASSISTANT IN SPEECH THERAPY',
  'THERMAL DESIGN ENGINEER',
  'THINFILM COATING ENGINEER',
  'THIRD ENGINEER (SHIP)',
  'TICKETING ASSISTANT MANAGER',
  'TICKETING CLERK (TRAVEL AGENCY)',
  'TICKETING CONSULTANT',
  'TICKETING EXECUTIVE',
  'TICKETING MANAGER',
  'TICKETING OFFICER',
  'TICKETING SUPERVISOR',
  'TILE AND BRICK MOULDER',
  'TILE LAYER (COMPOSITION TILES)',
  'TILE SETTER',
  'TILER, FLOOR',
  'TILER, WALL',
  'TIMBERMAN (CONSTRUCTION)',
  'TIME KEEPER (TRANSPORT)',
  'TIMEKEEPER (EXCEPT TRANSPORT)',
  'TOBACCO FLAVORIST',
  'TODDLER EDUCARER',
  'Tool / Mould Designer',
  'TOOL AND DIE MAKER',
  'TOOL DESIGN ENGINEER',
  'TOOL DESIGNER',
  'TOOL GRINDER (EXCEPT MACHINE TOOL)',
  'TOOL/MOULD DESIGNER',
  'TOOLING ENGINEER',
  'TOOLING TECHNICIAN',
  'TOOLMAKER',
  'TOOLROOM ENGINEER',
  'TOOLS AND INDUSTRIAL MACHINERY ENGINEER',
  'TOPOGRAPHICAL SURVEYOR',
  'TOUR CONSULTANT',
  'TOUR COORDINATOR',
  'TOUR EXECUTIVE',
  'Tour Guide',
  'TOUR LEADER',
  'TOUR MANAGER',
  'TOURIST GUIDE',
  'TOWER CRANE OPERATOR',
  'Trade Broker (Including Oil And Bunker Trader)',
  'TRADE EXECUTIVE',
  'TRADE MANAGER',
  'TRADEMARK EXECUTIVE',
  'TRADER',
  'TRADER, COMMODITY (FINANCIAL)',
  'TRADING ANALYST',
  'TRADING ASSOCIATE',
  'TRADING DIRECTOR',
  'TRADING EXECUTIVE',
  'TRADING MANAGER',
  'Traditional And Complementary Medicine Associate Professionals (Eg Homeopath And Naturopath)',
  'TRADITIONAL CHINESE MEDICINE (TCM) MASSAGE THERAPISTS',
  'TRADITIONAL CHINESE MEDICINE (TCM) PHYSICIANS',
  'TRADITIONAL CHINESE MEDICINE (TCM) PRACTITIONERS',
  'TRADITIONAL CHINESE MEDICINE (TCM) TUINA THERAPISTS',
  'Traditional Chinese Medicine Physician',
  'TRAFFIC CLERK',
  'Traffic Controller',
  'TRAFFIC CONTROLLER, AIR',
  'TRAFFIC CONTROLLER, SEA',
  'Traffic Coordinator',
  'TRAFFIC ENGINEER',
  'TRAFFIC EXECUTIVE',
  'TRAFFIC MANAGER',
  'TRAFFIC PLANNER',
  'TRAILER TRUCK DRIVER',
  'Trailer-Truck Driver',
  'TRAIN DRIVER',
  'TRAINEE SOLICITOR (FOREIGN LAW FIRM/SINGAPORE LAW FIRM/JOINT LAW VENTURE)',
  'TRAINER',
  'TRAINER (ZOO)',
  'TRAINER, RACE HORSE',
  'TRAINING & DEVELOPMENT MANAGER',
  'TRAINING AND EDUCATION MANAGER',
  'TRAINING COORDINATOR',
  'TRAINING DEVELOPMENT MANAGER',
  'TRAINING DIRECTOR',
  'TRAINING EXECUTIVE',
  'TRAINING MANAGER',
  'TRAINING OFFICER',
  'TRANSACTION MONITORING EVALUATOR',
  'TRANSFER PRICING MANAGER',
  'TRANSFER PRICING OFFICER',
  'TRANSFORMATION MANAGER',
  'TRANSFORMER ENGINEER',
  'TRANSITION MANAGER',
  'TRANSLATOR',
  'TRANSPLANT COORDINATOR',
  'Transport Assistant Manager',
  'TRANSPORT COORDINATOR',
  'TRANSPORT MANAGER',
  'TRANSPORT OPERATIONS EXECUTIVE',
  'TRANSPORT OPERATIONS MANAGER',
  'TRANSPORT OPERATIONS MANAGER (EXCEPT PORT/SHIPPING OPERATIONS)',
  'TRANSPORT SUPERINTENDENT',
  'TRANSPORT SUPERVISOR',
  'TRANSPORTATION MANAGER',
  'TRAUMA COUNSELLOR',
  'Travel Agency / Service Clerk',
  'TRAVEL AGENCY CLERK',
  'TRAVEL AGENT',
  'TRAVEL AND TOURIST GUIDE',
  'TRAVEL CONSULTANT',
  'TRAVEL SERVICES MANAGER',
  'TREASURER',
  'TREASURY ANALYST',
  'TREASURY DIRECTOR',
  'TREASURY MANAGER',
  'TRENCH CUTTER MACHINE OPERATOR',
  'TRIATHLON COACH',
  'TRUCK DRIVER',
  'TRUCK MECHANIC',
  'TRUCK OPERATOR, FORK LIFT',
  'TRUST OFFICER',
  'TRUST RELATIONSHIP MANAGER',
  'TUNER, MUSICAL INSTRUMENT',
  'TUNNEL ENGINEER',
  'TUNNEL SUPERVISOR',
  'TUNNEL SURVEYOR',
  'Tunnelling Machine Operator (Construction)',
  'Tunnelling Machine Operator (Including Pipe Jacking Machine Operator)',
  'TURBINE SPECIALIST',
  'TURNAROUND COORDINATOR',
  'TURNAROUND ENGINEER',
  'TURNER',
  'TURNOVER ENGINEER',
  'TUTOR',
  'TUTOR, PRIVATE',
  'TUTOR, PRIVATE (PRIMARY EDUCATION)',
  'TUTOR, PRIVATE (SECONDARY EDUCATION)',
  'Tutors (Universities)',
  'TYPE-SETTER (COMPOSING)',
  'TYPIST',
  'TYRE REBUILDER',
  'Ui Designer',
  'UNDERTAKER',
  'Underwater Diver',
  'UNDERWRITER',
  'UNDERWRITER, INSURANCE',
  'UNDERWRITING ADMINISTRATOR',
  'UNDERWRITING ASSISTANT',
  'UNDERWRITING DEPARTMENT MANAGER',
  'UNDERWRITING MANAGER',
  'UNIVERSITY LECTURER',
  'UNIVERSITY TEACHER',
  'UNIX ENGINEER',
  'UNTRAINED TEACHER',
  'UPHOLSTERER, AUTOMOBILE',
  'UPHOLSTERER, FURNITURE',
  'UPHOLSTERER, VEHICLE',
  'URBAN PLANNER',
  'User Experience Designer',
  'User Interface Architect',
  'UTILITY OPERATOR',
  'Ux Designer',
  'VALIDATION ENGINEER',
  'VALUER',
  'VARNISHER (WOODEN FURNITURE FINISHING)',
  'VASCULAR TECHNOLOGIST',
  'VECTOR CONTROL TECHNICIAN',
  'VEHICLE BODY REPAIRMAN',
  'VEHICLE ELECTRICIAN',
  'VEHICLE GLAZIER',
  'VEHICLE INSPECTOR (GOVERNMENT)',
  'VEHICLE TECHNICIAN',
  'VEHICLE UPHOLSTERER',
  'VENDING MACHINE REPAIRER AND SERVICER',
  'VENTURE CONTROLLER',
  'Vertical Product Manager',
  'VESSEL MANAGER',
  'VESSEL OPERATIONS EXECUTIVE',
  'VESSEL OPERATIONS MANAGER',
  'VESSEL OPERATOR',
  'VETERINARIAN',
  'VETERINARY ASSISTANT',
  'VETERINARY NURSE',
  'VETERINARY PATHOLOGIST',
  'VETERINARY SURGEON',
  'VETERINARY TECHNICIAN',
  'Veterinary Technician / Assistant',
  'Vfx Artist',
  'VICAR',
  'VICE CHAIRMAN',
  'VICE DEAN',
  'VICE PRESIDENT',
  'VICE PRESIDENT (MANUFACTURING INDUSTRY)',
  'VICE PRESIDENT (MEDIA INDUSTRY)',
  'VICE PRESIDENT, ASIA PACIFIC',
  'VICE PRESIDENT, AUDIT',
  'VICE PRESIDENT, CONSUMER MARKET INSIGHT',
  'VICE PRESIDENT, CUSTOMER PROGRAM MANAGEMENT',
  'VICE PRESIDENT, CUSTOMER SERVICE EXCELLENCE',
  'VICE PRESIDENT, FINANCE',
  'VICE PRESIDENT, FINANCE & CONTROLLER',
  'VICE PRESIDENT, HUMAN RESOURCES',
  'Vice President, Information Technology',
  'VICE PRESIDENT, INSTITUTIONAL SALES',
  'VICE PRESIDENT, LEGAL',
  'VICE PRESIDENT, MARKETING OPERATIONS',
  'VICE PRESIDENT, PRODUCT DEVELOPMENT AND LICENSING',
  'VICE PRESIDENT, SCIENTIFIC AND REGULATORY AFFAIRS',
  'VICE PRINCIPAL (COMMERCIAL SCHOOL)',
  'VICE PRINCIPAL (INTERNATIONAL SCHOOL)',
  'VICE PRINCIPAL, SCHOOL',
  'VIDEO CAMERAMAN',
  'VIDEO EDITOR',
  'VIDEO EQUIPMENT ENGINEER',
  'VIDEO PRODUCTION SPECIALIST',
  'VIDEO SYSTEMS MANAGER',
  'VIDEO TECHNICAL MANAGER',
  'VIDEOGRAPHER',
  'VIOLINIST',
  'VISITING ASSISTANT PROFESSOR',
  'VISITING ASSOCIATE PROFESSOR',
  'VISITING FELLOW',
  'VISITING PROFESSOR',
  'VISITING RESEARCH FELLOW',
  'VISITING SCHOLAR',
  'VISITING SENIOR RESEARCH FELLOW',
  'VISUAL ARTS TEACHER',
  'VISUAL EFFECTS ARTIST',
  'VISUAL ILLUSTRATOR',
  'VISUAL MERCHANDISER',
  'Visual Merchandising Associate',
  'VISUAL MERCHANDISING DEVELOPMENT MANAGER',
  'VISUAL MERCHANDISING EXECUTIVE',
  'VISUAL MERCHANDISING MANAGER',
  'VISUALISER',
  'VOCAL GROUP CONDUCTOR',
  'VOCALIST',
  'WAFER TEST ENGINEER',
  'WAITER',
  'WAITER (GENERAL)',
  'Waiter And Bartender Nec (Eg Food Checker (Catering Services))',
  'WAITER SUPERVISOR',
  'WAITER, HEAD',
  'WAITER, WINE',
  'WAITRESS',
  'WAITRESS SUPERVISOR',
  'WALL TILER',
  'WALLPAPER HANGER',
  'WAREHOUSE ANALYST',
  'WAREHOUSE ASSISTANT',
  'WAREHOUSE ASSISTANT MANAGER',
  'WAREHOUSE CLERK',
  'WAREHOUSE EXECUTIVE',
  'WAREHOUSE MANAGER',
  'Warehouse Officer',
  'Warehouse Operations Executive',
  'Warehouse Operations Manager',
  'Warehouse Storekeeper',
  'WAREHOUSE SUPERVISOR',
  'WAREHOUSING DEPARTMENT MANAGER',
  'WAREHOUSING MANAGER',
  'WASTE TREATMENT OPERATOR (RADIO-ACTIVE MATERIALS)',
  'WATCH AND CLOCK ASSEMBLER',
  'WATCH AND CLOCK REPAIRER',
  'WATCH TECHNICIAN',
  'WATCHMAN',
  'WATER PUMPMAN',
  'WATER TREATMENT PLANT OPERATOR (WATER WORKS)',
  'WATER WORKS TREATMENT PLANT OPERATOR',
  'WEALTH RELATIONSHIP MANAGER',
  'WEB ADMINISTRATOR',
  'WEB ANALYST',
  'Web Application Architect',
  'WEB APPLICATION DEVELOPER',
  'WEB ARCHITECT',
  'WEB CONSULTANT',
  'WEB DESIGNER',
  'WEB DEVELOPER',
  'WEB ENGINEER',
  'WEB EXECUTIVE',
  'WEB MARKETING EXECUTIVE',
  'WEB MASTER',
  'WEB PROGRAMMER',
  'WEB SUPPORT ENGINEER',
  'WEBMASTER',
  'Website Administrator / Webmaster',
  'WEBSITE DESIGNER',
  'WEBSITE DEVELOPER',
  'WEDDING PLANNER',
  'WELDER',
  'WELDING ENGINEER',
  'WELDING FOREMAN',
  'WELDING INSPECTOR',
  'WELDING MANAGER',
  'WELDING SUPERVISOR',
  'WELDING TECHNICIAN',
  'WELFARE OFFICER FOR THE PHYSICALLY HANDICAPPED',
  'WELFARE WORKER',
  'Wellness Centre Manager',
  'WHARF SUPERINTENDENT',
  'WHOLESALE TRADE MANAGER',
  'WHOLESALE TRADE SALESMAN',
  'WINCH OPERATOR',
  'WINDOW DISPLAY DESIGNER',
  'WINE BLENDER',
  'Wine Specialist',
  'Wire-Coating Machine Operator',
  'WIRELESS ENGINEER',
  'WOOD BLOCK FLOOR LAYER',
  'WOODCRAFT TECHNICIAN',
  'WOODWORKING MACHINE OPERATOR (GENERAL)',
  'Worker Not Reporting Any Occupation',
  'WORKFLOW ANALYST',
  'WORKING PROPRIETOR (BUSINESS SERVICES AND ADMINISTRATIVE SERVICES)',
  'WORKING PROPRIETOR (PERSONAL SERVICE)',
  'WORKING PROPRIETOR (RETAIL TRADE)',
  'WORKING PROPRIETOR (SPORTS, RECREATION, ARTS AND OTHER SERVICES)',
  'WORKING PROPRIETOR (WHOLESALE TRADE)',
  'WORKPLACE INSPECTOR (OCCUPATIONAL SAFETY)',
  'Workplace Safety And Health Coordinator (Marine And Offshore)',
  'WORKPLACE SAFETY AND HEALTH MANAGER',
  'Workplace Safety And Health Manager (Marine And Offshore)',
  'Workplace Safety And Health Officer (Marine And Offshore)',
  'WORKPLACE SAFETY HEALTH OFFICER',
  'WORKS MANAGER (PRODUCTION DEPARTMENT)',
  'WORKSHOP ENGINEER',
  'WORKSHOP MANAGER',
  'WORKSHOP MECHANIC (AUTOMOBILE)',
  'WORKSHOP SUPERVISOR',
  'WORKSHOP TECHNICIAN',
  'WRITER',
  'WRITER, TECHNICAL',
  'YACHT CREWMAN',
  'YACHT SKIPPER',
  'YIELD TECHNICIAN',
  'YOGA INSTRUCTOR',
  'YOUTH LEADER',
  'YOUTH LEADERSHIP TEACHER',
  'YOUTH PASTOR',
  'YOUTH RESIDENTIAL ASSISTANT',
  'YOUTH WORKER',
]
