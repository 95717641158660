// icon:bxl-facebook | Boxicons https://boxicons.com/ | Atisa

export const BxlFacebook = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0014.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z" />
    </svg>
  )
}
