import { forwardRef, memo, SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

const MemoWorkflowSvgr = memo(
  forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 284 472"
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="m68.675 128.141-45.24 1.56-2.34 17.94v8.58h56.94l-7.8-21.06-1.56-7.02Z"
      />
      <path
        fill="#fff"
        d="m36.695 66.52 10.14 2.34 6.24 10.92 10.14-8.58 5.46.78 10.14 4.68 4.68 7.02 1.56 14.82 1.56 12.481 10.14-24.96 1.56-7.8 3.12-3.12 3.12-2.34 2.34 1.56 1.56-1.56 4.68-3.9 1.56 3.9 2.34 2.34-4.68 2.34-1.56 2.34-3.9 7.8c.089 16.815-1.042 25.881-7.8 40.56-6.24 8.58-24.18 10.14-29.64-8.58l-.78-7.8v17.94l-46.02 2.34 2.34-10.92-11.7 1.56c-4.438-.47-6.601-1.413-9.36-5.46v-7.8l3.12-10.92 7.8-17.16 6.24-11.7 5.46-2.34 10.14-.78Z"
      />
      <path
        fill="#fff"
        d="m56.975 36.88 11.7 4.68V55.6l-7.02 7.8v7.8l1.56.78-10.14 7.8-7.02-10.92 3.12-11.7-2.34-9.36 10.14-10.92Z"
      />
      <path
        fill="#E4E7F6"
        d="M105 7.24A6.24 6.24 0 0 1 111.24 1h51.867a6.24 6.24 0 0 1 6.24 6.24v75.357a6.24 6.24 0 0 1-6.24 6.24H111.24a6.24 6.24 0 0 1-6.24-6.24V7.24Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M105.335 7.24a6.24 6.24 0 0 1 6.24-6.24h51.867a6.24 6.24 0 0 1 6.24 6.24v75.357a6.24 6.24 0 0 1-6.24 6.24h-51.867a6.24 6.24 0 0 1-6.24-6.24V7.24Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M123.382 23.604h28.642a.382.382 0 0 0 0-.764h-28.642a.382.382 0 0 0 0 .764Z"
      />
      <path
        fill="#E4E7F6"
        d="M105 161.68a6.24 6.24 0 0 1 6.24-6.24h51.867a6.24 6.24 0 0 1 6.24 6.24v75.358a6.24 6.24 0 0 1-6.24 6.24H111.24a6.24 6.24 0 0 1-6.24-6.24V161.68Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M105.335 161.68a6.24 6.24 0 0 1 6.24-6.24h51.867a6.24 6.24 0 0 1 6.24 6.24v75.358a6.24 6.24 0 0 1-6.24 6.24h-51.867a6.24 6.24 0 0 1-6.24-6.24V161.68Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M123.382 178.326h28.642a.382.382 0 1 0 0-.764h-28.642a.382.382 0 1 0 0 .764ZM123.382 192.605h28.642a.382.382 0 1 0 0-.764h-28.642a.382.382 0 1 0 0 .764Z"
      />
      <path
        fill="#E4E7F6"
        d="M105 317.68a6.24 6.24 0 0 1 6.24-6.24h51.867a6.24 6.24 0 0 1 6.24 6.24v75.358a6.24 6.24 0 0 1-6.24 6.24H111.24a6.24 6.24 0 0 1-6.24-6.24V317.68Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M105.335 317.68a6.24 6.24 0 0 1 6.24-6.24h51.867a6.24 6.24 0 0 1 6.24 6.24v75.358a6.24 6.24 0 0 1-6.24 6.24h-51.867a6.24 6.24 0 0 1-6.24-6.24V317.68Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M123.382 334.326h28.642a.382.382 0 1 0 0-.764h-28.642a.382.382 0 1 0 0 .764ZM123.382 348.605h28.642a.382.382 0 1 0 0-.764h-28.642a.382.382 0 1 0 0 .764ZM123.382 362.884h28.642a.383.383 0 0 0 0-.765h-28.642a.382.382 0 1 0 0 .765ZM123.382 377.155h28.642a.382.382 0 1 0 0-.764h-28.642a.382.382 0 1 0 0 .764Z"
      />
      <path
        fill="#fff"
        d="m209.075 205.36-11.7 2.34v2.34l-1.56 8.58c-1.532 1.827-1.317 2.851 0 4.68l.78 5.46c1.934 3.164 3.608 3.52 7.02 3.12l1.56 5.46-10.14 2.34-7.8 3.9-3.12 3.9-.78 5.46-1.56 7.8-3.12 12.48-15.6-25.74.78-3.9h-13.26c-1.339 1.52-1.509 2.374-.78 3.9l2.34 3.9 4.68 7.8 7.8 22.62 8.58 10.14c5.305 3.119 7.816 3.614 10.92.78l5.46-7.02 4.68-15.6 1.56 12.48v3.12l2.34 1.56 38.22-1.56 1.56-2.34v-3.12l-1.56-10.14.78-1.56 8.58 11.7 7.02 3.9 7.8-1.56 3.9-5.46 3.12-7.02 2.34-11.7.78-7.8.78-3.9h3.9c1.288-.305 1.502-.933 1.56-2.34.675 1.144 1.22 1.193 2.34.78-.032 1.275.328 1.626 1.56 1.56 0 0 .892.914 1.56.78.944-.189.78-2.34.78-2.34l-.78-3.12-1.56-3.12-5.46-3.9-4.68-.78-2.34 1.56-3.9 5.46-6.24 10.14-4.68 11.7-4.68-7.8-7.8-14.82-3.12-3.9-7.02-2.34h-4.68l-9.36 1.56-3.12-10.14 5.46-7.02-10.14-13.26Z"
      />
      <path
        fill="#fff"
        d="m242.614 323.141-52.26-.78 1.56-7.02 1.56-9.36 3.12-9.36 1.56-9.36 39.78 4.68 4.68 19.5v11.7Z"
      />
      <path
        fill="#000"
        d="M199.708 216.735a.167.167 0 0 1 0 .283l.268-.115h-.084l.329.191v-.076.298c.038-.158.092-.311.16-.459.176-.458-.566-.657-.764-.198a.94.94 0 0 0-.084.818.369.369 0 0 0 .328.191c.719-.046.826-1.086.329-1.483-.497-.398-.933.237-.543.542l.061.008ZM210.892 221.559c2.431-.398 5.565-2.836 4.686-5.641a2.377 2.377 0 0 0-2.362-1.659 3.308 3.308 0 0 0-2.782 1.949c-.237.436.42.818.657.39a2.621 2.621 0 0 1 2.125-1.575c1.674-.038 1.957 1.72 1.429 2.928a5.082 5.082 0 0 1-3.952 2.866c-.489.077-.282.818.199.765v-.023Z"
      />
      <path
        fill="#000"
        d="M213.743 216.789c-.367-.474-.963-.245-1.315.122a5.22 5.22 0 0 0-1.207 2.079c0 .115.145.168.183.046a5.6 5.6 0 0 1 .68-1.429c.276-.405.987-1.338 1.529-.688.077.099.214 0 .138-.13h-.008Z"
      />
      <path
        fill="#000"
        d="M212.749 217.797c-.16-.504-.696-.313-.963 0-.077.092.053.23.137.138.085-.092.505-.504.643-.069.038.123.221.069.183-.046v-.023ZM202.117 214.128a3.057 3.057 0 0 0-1.636-1.276c-.466-.161-.665.581-.199.764.485.168.9.494 1.178.925.267.413.924 0 .657-.382v-.031Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M199.41 205.438c-1.322 3.822-2.667 7.315-3.264 11.397a12.206 12.206 0 0 1-1.322 3.745c-.12.166-.164.375-.12.575.044.2.171.372.349.472h.054c.392.216.639.624.65 1.071a17.827 17.827 0 0 0 .993 6.344 4.945 4.945 0 0 0 4.923 2.851c2.431-.152 4.541-1.781 6.513-3.057"
      />
      <path
        fill="#000"
        d="M203.271 232.039a40.665 40.665 0 0 1 1.261 5.656c.099.482.841.275.764-.206a40.278 40.278 0 0 0-1.269-5.649c-.16-.466-.902-.268-.764.199h.008ZM212.306 222.032a116.226 116.226 0 0 0 4.77 13.759c.199.451.856.062.658-.382-1.965-4.372-3.287-9.012-4.694-13.583-.145-.466-.879-.268-.764.206h.03ZM200.519 221.436a3.05 3.05 0 0 1-1.528.764c-.482.1-.283.834.198.765a3.904 3.904 0 0 0 1.896-.979c.367-.336-.176-.871-.543-.542l-.023-.008Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M217.404 235.602s17.222-5.718 23.406 4.181l12.598 22.932s2.491-6.077 5.977-12.72c3.486-6.642 7.644-14.814 11.787-15.463 4.143-.65 12.452 4.173 11.466 10.648M236.606 282.665a120.227 120.227 0 0 1-39.221 2.698"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M198.379 285.455v2.117a15.569 15.569 0 0 1-.665 4.472c-1.85 6.115-6.949 23.49-7.056 30.714M235.215 282.956s.084 1.529 1.452 5.129c1.368 3.6 6.788 21.625 5.917 35.124"
      />
      <path
        fill="#000"
        d="M195.092 209.779c2.698.565 9.119 1.444 12.452-2.041 0 0-.765 2.178 1.062 4.923 1.827 2.744.52 3.737 1.055 4.227.535.489 1.017.474 1.682-.711s3.111-2.691 3.822-.398c.711 2.294-1.529 4.136-2.079 4.411-.551.275-1.2 1.009-.375 2.805.53 1.178.977 2.391 1.338 3.631 0 0 8.355-4.647 8.171-13.759a10.755 10.755 0 0 0-7.995-10.518 3.428 3.428 0 0 1-.933-.421 12.467 12.467 0 0 0-11.466-.886c-4.9 1.895-6.834 5.274-7.552 7.116a1.178 1.178 0 0 0 .818 1.621Z"
      />
      <path
        fill="#000"
        d="M219.499 207.754s.428-12.834 8.76-13.301c8.332-.466 12.995 11.742 15.426 12.124 0 0-.864 4.418-2.676 6.421 0 0-4.372-3.134-5.205-4.686 0 0 2.568 4.586 4.212 5.962 0 0-2.859 4.296-5.16 6.116a1.064 1.064 0 0 1-1.667-.52c-.825-2.553-2.958-8.279-6.466-11.596-3.379-3.195-6.475 1.016-6.475 1.016"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M198.379 287.373c-1.277-.565-3.15-.825-2.492-3.455.657-2.629-1.2-15.288-1.2-15.288s-3.822 17.795-10.174 23.911c-6.353 6.115-17.681-3.998-21.916-16.817-4.235-12.819-5.782-18.104-5.782-18.104l-3.12-4.68-3.121-3.9c-1.08-2.643-1.094-3.815 0-5.46"
      />
      <path
        fill="#000"
        d="M193.135 262.815c1.017 3.241 1.307 6.619 1.697 9.975.061.482.826.489.764 0-.397-3.425-.687-6.88-1.719-10.174-.146-.474-.887-.268-.765.199h.023ZM180.79 262.064c1.452 2.194 3.692 3.898 5.725 5.526 2.033 1.629 4.785 2.745 6.88 4.503.367.321.909-.222.535-.535-1.766-1.529-4.006-2.37-5.878-3.761-2.294-1.697-4.984-3.723-6.574-6.115-.268-.413-.933 0-.658.382h-.03ZM177.412 271.581a21.032 21.032 0 0 1 2.499 5.259c.146.467.887.268.765-.206a21.35 21.35 0 0 0-2.576-5.435c-.268-.413-.933 0-.658.382h-.03ZM194.962 239.729a17.924 17.924 0 0 0 19.224 11.236c4.25-.634 7.423-2.652 9.502-6.428a21.208 21.208 0 0 0 2.293-6.246c.222-1.223.871-3.562-.359-4.364-.413-.275-.765.39-.382.657.642.421.099 3.004 0 3.501a18.267 18.267 0 0 1-.681 2.561 20.42 20.42 0 0 1-2.538 5.083c-2.293 3.226-6.031 4.518-9.868 4.655a17.138 17.138 0 0 1-16.488-10.862c-.176-.451-.917-.252-.764.207h.061Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M235.773 285.454s2.531-1.529 2.118-3.333c-.413-1.803-2.4-17.749-1.284-23.107"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M236.369 267.385s10.648 19.875 20.983 16.909c10.335-2.966 13.079-27.863 13.201-31.723.122-3.86 1.368-5.855 1.368-5.855"
      />
      <path
        fill="#000"
        d="M236.89 267.255a64.329 64.329 0 0 0 11.771-12.842c.283-.405-.374-.765-.657-.39a63.395 63.395 0 0 1-11.657 12.689c-.375.313.168.856.543.543ZM271.608 247.12c1.237.214 2.501.214 3.738 0 .482-.092.275-.826-.206-.765a9.379 9.379 0 0 1-3.333 0c-.482-.084-.688.658-.199.765ZM275.377 241.601a5.225 5.225 0 0 1 1.154 3.249.382.382 0 1 0 .764 0 6.02 6.02 0 0 0-1.383-3.822c-.306-.382-.849.168-.535.543v.03ZM278.756 243.114a4.418 4.418 0 0 1 0 2.294.394.394 0 0 0 .484.485.395.395 0 0 0 .28-.279 5.208 5.208 0 0 0 0-2.698.394.394 0 1 0-.764.198ZM281.285 246.822c.223-.246.35-.563.359-.895 0-.496-.764-.489-.764 0a.55.55 0 0 1-.138.352c-.321.367.215.91.543.543ZM251.833 268.271a30.754 30.754 0 0 1 2.293-6.115c.199-.443-.458-.833-.665-.382a32.443 32.443 0 0 0-2.331 6.275c-.107.482.627.688.764.207l-.061.015ZM214.049 305.956c2.668 1.751 1.972 5.512 1.758 8.225a118.58 118.58 0 0 1-1.062 8.844c-.077.482.657.696.764.207.61-3.742 1.026-7.513 1.246-11.298.138-2.523 0-5.129-2.293-6.643-.413-.267-.764.39-.39.665h-.023ZM181.73 263.387s4.816 12.307 10.19 15.097l1.376-4.419s1.613-2.117-.543-3.134a64.951 64.951 0 0 1-6.543-4.082l-4.48-3.462ZM198.379 288.803a107.405 107.405 0 0 0 23.008 5.045c13.202 1.43 17.261.596 17.238.52l-1.957-6.283s-1.093-3.264-1.208-3.822c-.115-.558-.046-1.69-1.62-.979-1.575.711-13.943 2.064-15.625 2.293-1.682.23-9.868.299-10.701.283-.834-.015-5.198-.16-5.198-.16l-3.241-.23s-1.071-.29-.719.887"
      />
      <path
        fill="#000"
        d="M247.812 254.971s-3.333 12.658-8.079 17.772l-2.232-3.417s-1.72-1.575-.184-2.928c1.537-1.353 5.175-5.121 5.351-5.113.176.007 5.144-6.314 5.144-6.314ZM214.675 305.91s0 2.813-2.996 8.875a62.718 62.718 0 0 1-5.351 8.232h6.475s2.537 1.567 2.736-2.721c.199-4.288.658-11.863.658-11.863l-1.522-2.523ZM207.322 229.447s-1.184 4.212-2.904 5.084l-.597-2.057s-.58-.909.276-1.169M203.577 246.257a69.655 69.655 0 0 0 6.046-.52c.123 0 .123-.207 0-.191a71.07 71.07 0 0 1-6.046.52.098.098 0 0 0-.072.095.1.1 0 0 0 .072.096ZM217.244 243.436a24.622 24.622 0 0 0 4.755-2.37c.099-.069 0-.237-.099-.168a23.982 23.982 0 0 1-4.709 2.354c-.115.046-.069.23.053.184Z"
      />
      <path
        fill="#fff"
        d="M163.834 243.58h-6.24l1.56-2.34c-3.964-.216-4.534-.94-3.12-3.12.791-.704 1.995-.802 4.68-.78 1.8.469 2.288.828 2.34 1.56.966 1.827 1.089 2.841.78 4.68Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M205.144 237.199s-19.217 1.529-21.457 12.995l-5.152 22.573s-3.501-5.642-6.658-10.457c-2.568-3.922-7.552-12.812-8.408-14.57-.192-.393-.259-.836-.192-1.269.306-1.995.964-7.942-1.933-8.408-3.486-.543-6.88.153-5.856 1.735 1.025 1.582 3.364 1.453 3.364 1.453s-1.866 2.744-1.529 4.731"
      />
      <path
        fill="#fff"
        d="m67.114 438.581-38.22-5.46-5.46 21.06-1.56 8.58v8.58h52.26l-1.56-7.8-5.46-24.96Z"
      />
      <path
        fill="#fff"
        d="m59.315 353.56-14.04 13.26 5.46 10.14-3.12 7.02-8.58-1.56h-6.24l-3.9 1.56-3.9 2.34-3.9 4.68-4.68 7.02v-24.18l.78-10.92-2.34-4.68-2.34-.78-2.34-4.68-.78-3.9-3.12 1.56-1.56 1.56-2.34 2.34 2.34 6.24 1.56 4.68-.78 6.24-3.9 18.72-.78 10.92.78 9.36 3.9 7.02c5.66 4.171 9.994 3.309 19.5-3.12l5.46-7.8v7.8l-1.56 10.92-3.12 4.68.78 2.34 2.34 1.56 37.44 3.12.78-.78 1.56-3.12v-6.24l.78-3.9 4.68 13.26c6.02 5.385 8.394 3.655 14.04 2.34l10.14-7.02 7.799-10.14 6.24-8.58 4.68-5.46 4.68-2.34 3.9-6.24h-16.38l-4.68 6.24-7.02 6.24-7.8 4.68-5.46 5.46-3.12-20.28-1.56-7.02-4.68-3.9-7.02-3.12-7.8-.78.78-5.46 8.58-1.56v-9.36l.78-2.34-2.34-3.9-.78-5.46-7.8-4.68Z"
      />
      <path
        fill="#000"
        d="M64.41 363.882a.879.879 0 0 0-.398.879c.009.343.27.627.611.665a.383.383 0 0 0 .329-.191c.153-.299.038-.528-.069-.826-.16-.466-.894-.267-.764.199.058.149.107.302.145.458v-.29.069l.329-.184h-.084l.267.107a.198.198 0 0 1 0-.221c.39-.283 0-.948-.382-.658l.015-.007ZM54.182 369.775a5.12 5.12 0 0 1-4.197-2.293c-.49-.765-.764-1.965 0-2.706 1.093-1.063 2.614-.184 3.325.764.283.398.948 0 .658-.382a3.334 3.334 0 0 0-3.058-1.628 2.392 2.392 0 0 0-2.117 2.155c-.276 2.844 3.004 4.717 5.434 4.793.497 0 .497-.764 0-.764l-.045.061Z"
      />
      <path
        fill="#000"
        d="M50.886 366.267c.696-1.116 2.179 1.345 2.416 1.857.046.107.214 0 .16-.099-.29-.642-1.895-3.218-2.744-1.858-.068.107.1.207.168.1Z"
      />
      <path
        fill="#000"
        d="M52.01 367.077c.085-.397.482-.16.635 0 .153.161.23-.053.138-.137-.299-.253-.849-.39-.956.122a.095.095 0 0 0 .069.115.096.096 0 0 0 .114-.069v-.031ZM63.194 362.201a2.295 2.295 0 0 1 1.223-.879.395.395 0 1 0-.207-.764c-.683.2-1.275.633-1.674 1.223-.282.405.383.764.658.39v.03Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M63.545 353.127c1.789 3.646 3.554 6.933 4.647 10.9a12.15 12.15 0 0 0 1.774 3.555.718.718 0 0 1-.092 1.07h-.053a1.23 1.23 0 0 0-.505 1.139c.314 2.135.245 4.31-.206 6.421a4.94 4.94 0 0 1-4.533 3.44c-2.43.145-4.732-1.208-6.88-2.217"
      />
      <path
        fill="#000"
        d="M61.573 379.231a53.518 53.518 0 0 0-1.95 7.323c-.106.482.635.688.765.206a56.285 56.285 0 0 1 1.95-7.33c.168-.459-.566-.658-.765-.199ZM51.598 370.105c-1.445 4.747-2.515 9.685-4.54 14.233-.2.444.458.833.657.382 2.056-4.586 3.15-9.593 4.586-14.408.145-.474-.588-.673-.764-.207h.061ZM63.309 369.676a3.87 3.87 0 0 0 1.865 1.04c.474.115.68-.627.199-.764a3.169 3.169 0 0 1-1.529-.849c-.352-.344-.894.199-.543.543l.008.03Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M47.454 384.038s-17.443-5.878-23.627 4.021l-7.377 10.388s-.084-6.925.085-15.456c.168-8.531.412-15.938.412-19.041 0-3.104-1.827-7.346-5.228-6.681M28.031 430.941a120.257 120.257 0 0 0 39.222 2.699"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M66.259 433.731v2.294c0 1.386.207 2.764.611 4.089 1.804 5.917 7.002 23.605 7.11 30.905M29.422 431.232s-.084 1.529-1.452 5.13c-1.369 3.6-6.788 21.624-5.917 35.124"
      />
      <path
        fill="#000"
        d="M52.606 364.707a11.134 11.134 0 0 0 1.59 1.705c.36.214.765-.474.765-1.323a1.665 1.665 0 0 1 .634-1.414 7.989 7.989 0 0 0 3.356-6.987 6.668 6.668 0 0 1 1.261 4.587 3.058 3.058 0 0 0 1.705-3.195 2.569 2.569 0 0 0-2.202-2.569 10.186 10.186 0 0 1 3.876 2.293 4.654 4.654 0 0 0 5.435.543.656.656 0 0 0 .153-.932c-1.667-2.294-9.502-11.986-18.346-3.654 0 0-5.588 1.49-6.711 8.592-1.124 7.101-8.462 9.027-6.284 11.466 1.995 2.224 9.448 7.2 15.785 7.024a1.441 1.441 0 0 0 1.391-1.727c-.52-2.538-1.49-7.476-1.528-8.967 0 0-3.906-.863-4.159-3.195-.252-2.331.833-4.227 3.28-2.247Z"
      />
      <path
        fill="#000"
        d="M67.375 357.377s1.812 1.46 1.216 7.239c0 0-1.53-3.975-1.743-4.51-.214-.535-1.055-2.851-1.055-2.851M58.332 377.939s1.047 3.295 2.828 4.136l.436-1.667s.306-1.177-.49-1.368"
      />
      <path
        fill="#000"
        d="M69.347 369.409a86.941 86.941 0 0 0 1.307 10.174.818.818 0 0 1-.581.994c-1.835.489-6.413 1.529-8.967.229 0 0 .375-1.995 1.53-1.33 1.153.665 4.815.337 5.809-1.658.555-.98.881-2.072.955-3.196l-.053-5.213Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M2.753 354.656a23.346 23.346 0 0 0 3.057 8.73 5.134 5.134 0 0 1 .49 3.783c-1.002 4.037-3.158 12.919-4.159 18.575-1.269 7.186-4.816 35.033 9.547 34.536 12.322-.428 19.05-13.82 19.05-13.82s-.207 15.226-2.118 18.88c-1.911 3.654-4.502 6.88.069 8.852"
      />
      <path
        fill="#000"
        d="M71.258 405.335c.673 5.405-.19 10.236-1.773 15.388a30.633 30.633 0 0 0-1.23 6.474c-.169 2.079.726 8.118-1.95 8.584-.482.084-.283.826.199.765 1.82-.321 2.293-1.972 2.362-3.601.16-3.355 0-6.635.764-9.937.704-3.012 1.911-5.847 2.362-8.928a33 33 0 0 0 0-8.722c-.053-.481-.818-.489-.764 0l.03-.023Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M69.47 422.175s.527 17.276 12.857 17.276 24.598-18.445 27.427-22.657c3.057-4.625 5.671-7.973 9.287-10.465a19.092 19.092 0 0 0 5.618-6.719"
      />
      <path
        fill="#000"
        d="m32.312 402.699-2.851 5.351c-.23.436.435.826.665.39l2.843-5.351c.23-.436-.428-.818-.657-.382v-.008ZM16.267 402.652c.338-1.688.534-3.401.588-5.121a.383.383 0 1 0-.764 0 29.43 29.43 0 0 1-.566 4.915c-.091.481.643.688.765.206h-.023ZM5.566 371.137a20.221 20.221 0 0 0 10.9 2.56c.49 0 .497-.764 0-.764a19.304 19.304 0 0 1-10.51-2.461c-.436-.23-.818.428-.39.665ZM104.524 405.535a24.802 24.802 0 0 0 3.134 5.549c.765.941 2.385 3.165 3.715 3.257a.406.406 0 0 0 .375-.283v-.054c.176-.466-.566-.665-.765-.206.253-.657-1.33-1.529-1.773-2.026a16.482 16.482 0 0 1-1.789-2.255 26.343 26.343 0 0 1-2.293-4.364c-.199-.451-.856-.062-.665.382h.061ZM83.045 424.514a16.522 16.522 0 0 1 2.484-3.455c.329-.359-.206-.902-.542-.543a18.09 18.09 0 0 0-2.607 3.608c-.23.436.428.818.665.39ZM3.968 352.011c.194 1.102.517 2.177.963 3.203.191.451.849.061.657-.39a13.756 13.756 0 0 1-.886-3.012c-.084-.489-.818-.283-.765.199h.03ZM6.032 350.727c.13.93.345 1.846.642 2.736.16.466.895.26.765-.206a14.683 14.683 0 0 1-.642-2.737c-.07-.481-.765-.275-.765.207ZM8.745 349.359a16.931 16.931 0 0 0 2.5 6.39c.268.413.925 0 .657-.382a16.473 16.473 0 0 1-2.415-6.207c-.084-.489-.818-.283-.764.199h.022ZM47.195 384.849c1.384 1.751 3.975 2.408 6.115 2.714 2.14.306 5.244.764 7.178-.505.413-.26 0-.925-.382-.657-1.605 1.024-4.319.695-6.115.481-2.118-.237-4.816-.817-6.2-2.576-.305-.389-.84.161-.535.543h-.06ZM51.017 451.865c-1.919 1.964-2.003 4.732-2.14 7.323-.215 4.045-.161 8.1.16 12.139.038.489.803.489.764 0a92.316 92.316 0 0 1-.252-9.861c.084-2.928-.191-6.803 2.003-9.058.344-.352-.191-.895-.535-.543ZM10.136 357.377c-.183 1.376.872 2.155 1.965 2.729l-.077-.604-.764.993c-.306.383.229.925.535.543l.764-.994a.389.389 0 0 0-.076-.603c-.711-.367-1.75-.895-1.613-1.858.061-.482-.673-.688-.764-.206h.03Z"
      />
      <path
        fill="#000"
        d="M32.121 403.868a23.015 23.015 0 0 1-1.598 9.173l.451-4.067s-.45-2.622.352-3.432M27.97 436.363s15.876 5.641 26.647 7.009c10.77 1.369 13.59 1.277 13.59 1.277l-1.796-6.314-.145-3.302s1.162-1.292-2.377-1.109c-3.54.184-14.126.107-14.126.107s-13.4-.665-14.417-1.574c-1.016-.91-4.342-.818-4.342-.818s-1.139-1.399-1.819.932c-.68 2.332-1.215 3.792-1.215 3.792ZM71.938 408.943s3.348 4.862 1.728 10.213c-1.62 5.351-2.576 11.175-2.637 11.007-.061-.168-.52-3.875-.91-4.586a8.326 8.326 0 0 1-.122-5.191c.65-2.018 1.529-5.618 1.529-5.618l.412-5.825ZM50.207 453.615s1.108 8.753 3.019 13.064 2.293 4.808 2.293 4.808h-3.707s-2.943 1.185-2.591-3.302c.205-3.214.23-6.438.076-9.655l.91-4.915Z"
      />
      <path
        fill="#fff"
        d="M116.254 399.581h-7.8c2.549-2.482 3.947-3.63 6.24-3.9l3.9.78v1.56l-2.34 1.56Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M60.113 386.088s18.598.902 20.838 12.36l3.738 22.825s1.345-1.529 9.173-7.529c5.389-4.105 12.23-9.402 14.447-13.14 2.217-3.738 4.976-5.603 7.346-4.854 2.369.749 3.982.764 3.111 2.117a3.061 3.061 0 0 1-2.615 1.239c.364.649.409 1.43.123 2.117M138 89v66M138 243v68"
      />
      <path
        fill="#000"
        d="M65.206 49.62a.894.894 0 0 0-.627.672c-.1.311.023.65.299.826a.42.42 0 0 0 .466-.062c.221-.229.176-.512.176-.81a.382.382 0 0 0-.765 0c.012.16.012.322 0 .482l.115-.275-.061.06.466-.06c.046.122.069.137.061.045-.007-.091 0-.106.076-.145.467-.153.268-.886-.198-.764l-.008.03ZM53.68 52.073a5.122 5.122 0 0 1-3.303-3.554c-.26-1.14.138-2.37 1.4-2.6 1.26-.229 2.048.941 2.361 1.935.153.466.887.267.765-.207a3.364 3.364 0 0 0-2.347-2.461 2.408 2.408 0 0 0-2.737 1.307c-1.261 2.63 1.399 5.48 3.677 6.314.466.168.665-.566.206-.764l-.023.03Z"
      />
      <path
        fill="#000"
        d="M51.63 47.708c1.002-.841 1.66 1.972 1.72 2.492 0 .122.207.122.192 0-.084-.696-.81-3.67-2.049-2.622-.092.076.038.214.138.13Z"
      />
      <path
        fill="#000"
        d="M52.448 48.832c.207-.383.52 0 .612.168.092.168.23 0 .168-.092-.199-.329-.696-.634-.948-.176-.053.107.107.207.168.1ZM64.534 47.685a2.294 2.294 0 0 1 1.529-.505c.489 0 .489-.764 0-.764a3.058 3.058 0 0 0-1.896.604c-.382.298 0 .963.39.665h-.023Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M67.721 39.116c.573 4.021 1.246 7.69 1.063 11.81-.02 1.33.178 2.655.588 3.921a.719.719 0 0 1-.42.994h-.061c-.424.142-.74.496-.834.933a17.988 17.988 0 0 1-2.17 6.039 4.938 4.938 0 0 1-5.351 1.872c-2.355-.603-4.128-2.599-5.825-4.212"
      />
      <path
        fill="#000"
        d="M68.554 56.032c.046.046 2.331 11.879-3.616 12.085-5.947.207-11.466-4.273-10.426-9.585 1.04-5.313-.092-10.656-.871-11.734-.688-.955-3.02-1.735-3.51.275-.488 2.01 1.599 4.365 2.072 4.923.474.558-2.209 6.704-3.004 6.765-.795.061-.198-.596-.367-1.3-1.26-5.297-8.248-9.623-3.669-17.19 1.575-2.6 3.173-3.616 4.38-3.976a8.867 8.867 0 0 0 3.448-2.071c1.17-1.078 3.187-2.156 6.543-2.01 6.474.29 10.87 4.9 10.702 7.682-.092 1.528-1.743 2.698-1.743 2.698l-.42-1.33s1.345 2.377-10.083-1.59a.092.092 0 0 0-.115.115 4.112 4.112 0 0 1-1.17 5.045c-1.07.925-.045 2.904.222 7.766.207 3.6 6.842-1.965 11.627 3.432Z"
      />
      <path
        fill="#000"
        d="M62.27 64.326a22.636 22.636 0 0 0-1.46 6.658c0 .489.765.489.765 0a21.863 21.863 0 0 1 1.437-6.46c.168-.458-.566-.657-.764-.198h.023Z"
      />
      <path
        fill="#fff"
        d="M65.245 55.826a5.006 5.006 0 0 1-1.567-1.261c-.313-.375-.856.168-.543.542a5.482 5.482 0 0 0 1.728 1.376c.436.23.818-.428.382-.657Z"
      />
      <path
        fill="#000"
        d="M49.765 52.34c-.764 5.412-2.4 10.701-3.738 15.968-.122.474.62.68.765.206 1.337-5.297 2.965-10.556 3.737-15.976.07-.481-.665-.688-.764-.198Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M61.193 70.983s20.341 1.827 22.58 13.278l2.653 28.726s.665-3.485 3.822-10.625c3.157-7.14 6.643-16.931 6.803-18.101.16-1.17 1.995-10.289 7.476-10.457M46.394 68.407s-21.449-6.39-26.876 4.303c0 0-18.093 30.148-15.288 43.931 2.805 13.782 38.067.993 54.066-12.789 0 0 6.429-1.529 7.728-4.311 0 0 1.384-2.66 3.493-1.827"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M23.256 121.265a141.917 141.917 0 0 0 46.277 3.18M67.958 124.621l.245 2.469c.107 1.07.435 2.11.558 3.188.458 4.143 7.01 17.405 9.111 25.867M24.946 121.701a28.326 28.326 0 0 1-.765 4.388c-.688 3.057-3.394 18.498-2.958 30.056"
      />
      <path
        fill="#000"
        d="M61.422 90.346a15.12 15.12 0 0 1 6.444-3.593.396.396 0 0 0-.206-.765 15.936 15.936 0 0 0-6.78 3.784c-.36.337.183.88.542.543v.03ZM65.589 91.034a1.017 1.017 0 0 1 1.284.046c.367.329.91-.214.543-.543a1.789 1.789 0 0 0-2.217-.16c-.397.283 0 .948.39.657ZM66.728 94.038a1.345 1.345 0 0 1 1.047 0c.451.19.65-.55.199-.765a1.987 1.987 0 0 0-1.453 0c-.458.176-.26.91.207.765ZM48.084 97.386a39.266 39.266 0 0 0 1.964 8.363c.405 1.123.963 3.432 1.995 4.242.382.298.925-.237.535-.543-1.116-.879-1.605-3.317-2.025-4.586a38.675 38.675 0 0 1-1.705-7.491c-.061-.482-.826-.49-.764 0v.015ZM96.57 86.18a12.132 12.132 0 0 0 10.526 1.208c.466-.16.267-.902-.199-.764a11.375 11.375 0 0 1-9.937-1.132c-.413-.26-.803.398-.383.665l-.007.023ZM47.006 70.77c1.529.764 3.478 1.46 4.716 2.644 1.239 1.185.925 3.058 1.835 4.587a.39.39 0 0 0 .604.076c.97-.94 1.054-2.293 1.964-3.287 1.116-1.185 2.997-1.743 4.449-2.438.443-.214.053-.872-.382-.658-1.43.68-3.249 1.239-4.449 2.294-1.2 1.055-1.086 2.553-2.125 3.554l.596.076c-.91-1.528-.443-3.286-1.788-4.586-1.346-1.3-3.395-2.033-5.03-2.89-.436-.221-.826.436-.39.666v-.039ZM24.839 92.563c.315 2.898.289 5.823-.077 8.714-.06.49.703.49.765 0 .365-2.891.39-5.816.076-8.714-.046-.489-.81-.489-.764 0ZM69.67 107.69a36.97 36.97 0 0 0-.641 16.909c.092.481.833.275.764-.207a36.138 36.138 0 0 1 .642-16.495.397.397 0 0 0-.764-.207ZM53.634 81.952a.612.612 0 1 0 .496.49c0-.123-.206-.123-.19 0 0 .367-.39.764-.704.397a.42.42 0 0 1 .398-.696c.122 0 .122-.183 0-.19ZM53.113 87.257a.612.612 0 1 0 .49.49c0-.115-.207-.123-.192 0 0 .367-.39.764-.703.405a.428.428 0 0 1 .405-.704c.122 0 .115-.183 0-.19Z"
      />
      <path
        fill="#000"
        d="M25.152 93.747s-4.319 9.135-5.114 9.532c0 0 3.104-1.269 3.662-1.452.558-.184 1.765.061 1.765-2.431M66.04 99.542s3.332 4.105-1.606 6.88c-4.938 2.774-10.235 8.783-12.375 10.839-6.658 6.375-13.095 6.352-14.608 6.39-1.514.038 15.525 1.972 18.682 1.705 3.157-.268 9.21-.596 9.295-.765.084-.168 2.484-.068 2.614 1.2.13 1.269.29 3.433.29 3.433s-21.945 7.743-45.252 3.248l1.139-6.413.55-2.882s-.19-1.819-.443-1.918c-.252-.1.466-.367 1.529-.581 1.062-.214 16.052-5.435 19.454-7.721 3.401-2.285 9.631-6.505 9.937-6.879.306-.375 2.775-2.722 3.937-2.683 1.162.038 6.635-2.156 6.856-3.853ZM69.655 109.242s2.293 7.117.848 12.59a21.777 21.777 0 0 1-1.139-3.822c0-.803-.512-3.822 0-5.909.512-2.087.29-2.859.29-2.859ZM52.44 143.77c-1.444 1.178-2.292 2.232-2.438 4.105a50.076 50.076 0 0 0 .1 8.011c0 .489.764.489.764 0a73.698 73.698 0 0 1-.168-5.954 11.876 11.876 0 0 1 .222-3.058 5.277 5.277 0 0 1 2.063-2.538c.383-.313-.16-.848-.542-.543v-.023Z"
      />
      <path
        fill="#000"
        d="M52.18 144.48c-.16.13.819 3.02 1.751 5.657.933 2.637 2.553 6.115 2.553 6.115h-4.326s-1.713.902-1.781-2.209c-.07-3.111.359-7.812.359-7.812l1.445-1.751ZM23.363 72.62l.764.504c.1.07.199-.091.1-.16l-.765-.512c-.107-.069-.198.1-.1.168ZM25.152 74.11a.1.1 0 0 0 0-.192.1.1 0 0 0 0 .192ZM79.99 84.154l.879-.474c.114-.06 0-.221-.092-.168l-.887.474c-.107.061 0 .23.1.168ZM78.774 84.91l.13-.122c.084-.084-.053-.222-.138-.138l-.122.13a.092.092 0 1 0 .13.13Z"
      />
      <path
        fill="#fff"
        d="M107.675 84.46v2.34h-5.46l.78-11.7c2.34-2.34 3.368-1.744 3.9-.78l.78-.78 5.46-3.9c3.12-.78 3.263.48.78 3.12 1.129.637 1.528 1.08.78 2.34 2.443.096 3.643.869 3.12 3.12l-10.14 6.24Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.56}
        d="M117.139 78.246c-.497 0-4.976 5.022-9.624 6.184 0 0-.496 15.288-1.995 24.079-1.498 8.79-5.19 24.239-16.48 25.401-11.29 1.162-19.416-8.141-20.165-19.431M18.195 104.274s5.351-3.738 17.184-4.732c8.279-.703 13.217-2.37 15.426-3.317a4.793 4.793 0 0 0 2.086-1.705c1.361-1.964 4.419-6.061 6.307-5.94"
      />
      <path
        fill="#000"
        d="M106.682 74.027a3.055 3.055 0 0 1-1.268 1.314c-.321.191-.803.314-1.048.62a1.324 1.324 0 0 0-.191.848 3.306 3.306 0 0 1-.672 2.003c-.291.397.374.764.657.39a3.76 3.76 0 0 0 .68-1.407c.13-.55 0-1.162.467-1.529.81-.611 1.528-.864 2.04-1.842.215-.443-.443-.833-.665-.39v-.007ZM108.937 72.78a85.49 85.49 0 0 0 4.586-2.989c.405-.275 0-.94-.382-.657a264.068 264.068 0 0 1-4.586 2.989.382.382 0 0 0 .39.657h-.008ZM112.438 74.378a6.43 6.43 0 0 0 1.758-1.276.382.382 0 0 0-.535-.543 5.893 5.893 0 0 1-1.613 1.162c-.435.221-.053.879.39.657ZM113.341 76.771a6.46 6.46 0 0 0 1.659-1.04c.374-.32-.169-.863-.543-.542a5.832 5.832 0 0 1-1.529.925c-.451.19-.061.856.382.657h.031Z"
      />
      <path fill="#F6F7FC" d="M130 114h16v16h-16z" />
      <path
        fill="#445072"
        d="M140.862 120.195 138 123.057l-2.862-2.862-.943.943 3.805 3.805 3.805-3.805-.943-.943Z"
      />
      <path fill="#F6F7FC" d="M130 269h16v16h-16z" />
      <path
        fill="#445072"
        d="M140.862 275.195 138 278.057l-2.862-2.862-.943.943 3.805 3.805 3.805-3.805-.943-.943Z"
      />
    </svg>
  )),
)

export const WorkflowSvgr = chakra(MemoWorkflowSvgr)
