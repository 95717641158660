export const MultiParty = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg viewBox="0 0 40 40" fill="none" height="1em" width="1em" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m20.383 10.107-1.533 1.43-.137.128.137.128.814.76.12.11.119-.11 3.247-3.03.137-.128-.137-.128-3.247-3.03-.12-.112-.12.111-.813.76-.137.127.137.128 1.533 1.432H15.59a6.236 6.236 0 1 0 0 1.424h4.794Zm-10.276 9.51 1.43 1.533.128.137.128-.137.76-.814.11-.12-.11-.119-3.03-3.247-.128-.137-.128.137-3.031 3.247-.112.12.112.119.76.814.127.137.128-.137 1.432-1.534v4.794a6.236 6.236 0 1 0 1.424 0v-4.793Zm19.786-4.028v4.794l-1.43-1.533-.128-.137-.128.137-.76.814-.11.12.11.119 3.03 3.247.128.137.128-.137 3.031-3.247.111-.12-.11-.119-.76-.814-.128-.137-.128.137-1.432 1.533V15.59a6.237 6.237 0 1 0-1.424 0ZM13.306 9.394a3.912 3.912 0 1 1-7.824 0 3.912 3.912 0 0 1 7.824 0Zm-3.912 17.3a3.912 3.912 0 1 1 0 7.824 3.912 3.912 0 0 1 0-7.824Zm21.212-13.388a3.912 3.912 0 1 1 0-7.824 3.912 3.912 0 0 1 0 7.824Zm3.692 17.22a3.772 3.772 0 1 1-7.544 0 3.772 3.772 0 0 1 7.544 0Zm-3.772 6.316a6.315 6.315 0 1 0 0-12.63 6.315 6.315 0 0 0 0 12.63Z"
    />
  </svg>
)
